import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import moment from 'moment';
import history from '../../history';

import { resetBusinesses, getBusinessesAll, addBusinesses, deleteBusinesses } from '../../actions/businesses'
//import Spinner from '../../gj_modules/gj-strap/components/display/Spinner';
import { Panel } from '../../gj_modules/gj-strap/components/form';
import Full from '../../gj_modules/gj-strap/components/partials/Full';
import FooterCicanord from '../partials/FooterCicanord';

import { ComplexAll } from '../../gj_modules/gj-strap/components/complex'

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { businessStatusType } from '../../data/constants';

import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
import { labelByValue } from '../../gj_modules/gj-strap/lib';
import { projectCategory } from '../../data/constants';

import { required } from '../../lib/validate';

import AvatarUser from '../subpartials/AvatarUser'

class Businesses extends Component {
    componentDidMount() {
    }

    greyLine(e) {
        if (e.Affaires_StatusType === "achieved")
            return "greyLine"
        else
            return ""
    }

    renderTd = (e) => {

        let finalColor = "lightgrey";

        if (e.Affaires_StatusType === "pending") {
            //type = ("(E)");
            finalColor = "orange";
        }else if(e.Affaires_StatusType === "achieved"){
            finalColor = "green";
        }

        return {
            id: e.Affaires_ID,
            key: e.Affaires_ID,
            code: e.Affaires_Code,
            name: e.Affaires_Nom,
            client: e.Entites_Nom,
            date: moment(e.Affaires_DateCreation).format("DD/MM/YYYY"),
            category: labelByValue(e.Affaires_Category, projectCategory),
            type: (
                <React.Fragment>
                    <div className="typeColorBar" style={{ backgroundColor: finalColor }}></div>
                    {labelByValue(e.Affaires_StatusType, businessStatusType)}
                </React.Fragment>
            ),
            customClass: this.greyLine(e),
            lastaction: (
                <React.Fragment>
                    {e.Utilisateurs_Login ? (
                        <React.Fragment>
                            <div className="pull-left" style={{ display: 'inline-block' }}>
                                <AvatarUser
                                    u={{
                                        login: e.Utilisateurs_Login,
                                        name: e.Utilisateurs_Nom,
                                        surname: e.Utilisateurs_Prenom,
                                        userId: e.Utilisateurs_ID,
                                        color: e.Utilisateurs_color
                                    }}
                                />
                            </div>
                            <div style={{ display: 'inline-block' }}>
                                {moment(e.actionDateTarget).format("DD/MM/YY - HH:MM")}
                            </div>
                            <div>
                                {e.actionDescription}
                            </div>
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            )
        }
    }

    deleteRefresh = (response) => {
        const { getBusinessesAll } = this.props;
        getBusinessesAll({ skip: 0, limit: 20, filter: {} });
    }

    render() {

        const { businesses, addBusinesses, deleteBusinesses } = this.props;

        const properties = [ // DataTable Headers
            { short: "code", name: "Code", width: "10%", orderby: true, orderbydefault: true, orderbywaydefault: "desc" },
            { short: "name", name: "Nom", width: "18%", orderby: true }, //15.83
            { short: "client", name: "Client", width: "18%", category: "classic" },
            { short: "date", name: "Date", width: "13%", orderby: true },
            { short: "category", name: "Catégorie", width: "8%", category: "classic" },
            { short: "type", name: "Type", width: "8%", category: "classic" },
            { short: "lastaction", name: "Action", width: "15%", category: "classic" },
            { short: "actions", name: "", width: "8%", category: "actions" }
        ];

        const filters = [
            { type: "search", size: 25, name: "searchBusinessesByCode", placeholder: "Recherche par code" },
            { type: "search", size: 25, name: "searchBusinessesByName", placeholder: "Recherche par nom" },
            { type: "select", size: 30, name: "searchBusinessesByCategory", placeholder: "Catégorie", options: projectCategory },
            { type: "select", size: 20, name: "searchBusinessesByStatusType", placeholder: "Type", options: businessStatusType, default: businessStatusType[0].value },
            {
                type: "button",
                size: 20,
                name: "",
                title: "Ajouter une affaire",
                icon: faPlusCircle,
                fn: () => {
                    modalFunction({
                        submit: (values) => {
                            if (!businesses.pendingAdd) {
                                //console.log(values)
                                addBusinesses(values).then(
                                    function (response) {
                                        history.push("/sic/business/" + response.response.data.business)
                                    }
                                );
                            }
                        },
                        text: (
                            <Panel
                                width={[3, 9]}
                                inputs={[
                                    { short: "name", name: "Nom", type: "text", placeholder: "Nom", validate: required() },
                                    { short: "code", name: "Code", type: "text", placeholder: "Code", validate: required() }
                                ]}
                            />
                        ),
                        header: "Ajouter une affaire",
                        state: "businesses.pendingAdd"
                    }, "popup")
                }
            }
        ]

        const portlet = {
            title: "Affaires",
            links: [
                { name: "Recherche", path: "/sic/businesses" }
            ]
        }

        return (
            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />
                <Full portlet={portlet}>
                    <ComplexAll
                        noEditGlobal
                        getElementsAll={getBusinessesAll}
                        resetElements={resetBusinesses}
                        elements={this.props.businesses}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        noDataMessage={"Pas d'affaire"}
                        viewAction={(id) => (history.push("/sic/business/" + id))}
                        deleteAction={(e) => {
                            modalFunction({
                                btnFunction: () => {
                                    deleteBusinesses(e).then(this.deleteRefresh)
                                },
                                btnText: "Confirmer",
                                text: "Êtes-vous sûr ?",
                                header: "Supprimer une affaire",
                                state: "businesses.pendingDelete"
                            }, "popup")
                        }}
                    />
                </Full>
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetBusinesses,
            getBusinessesAll,
            addBusinesses,
            deleteBusinesses
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        businesses: state.businesses.businesses,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Businesses);
