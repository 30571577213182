import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { getUser } from '../../actions/user';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import moment from 'moment';
import history from '../../history';

import { getContactsAll, addContact, resetContacts, deleteContact } from '../../actions/contacts'
//import Spinner from 'gj_modules/gj-strap/components/display/Spinner';
import { Panel } from '../../gj_modules/gj-strap/components/form';
import FullCenter from '../../gj_modules/gj-strap/components/partials/FullCenter';
import FooterCicanord from '../partials/FooterCicanord';

import { ComplexAll } from '../../gj_modules/gj-strap/components/complex'

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { modalFunction } from '../../gj_modules/gj-strap/components/partials';

import { required } from '../../lib/validate';

import { contactType } from '../../data/constants';


class Contacts extends Component {
    componentDidMount() {
    }

    /*greyLine(e) {
        if (e.Personnes_Prenom === "" || e.Personnes_Prenom === null)
            return "greyLine"
        else
            return ""
    }*/

    renderTd = (e) => {
        return {
            id: e.Personnes_ID,
            key: "contact_" + e.Personnes_ID, //+"_"+e.Entites_Nom,
            name: e.Personnes_Nom,
            surname: e.Personnes_Prenom,
            entity: e.Entites_Nom,
            fonction: e.PersonnesEntites_FonctionLibre,
            //customClass: this.greyLine(e),
            date: moment(e.Personnes_DateModification).format("DD/MM/YYYY")
        }
    }

    render() {
        let pageDefault={
            current: 1,
            skip: 0,
            limit: 200,
            totalSize: 0,
            go: false
        };
        const { contacts, addContact, deleteContact } = this.props;

        const properties = [
            { short: "name", name: "Nom", width: "18%", orderby: true, orderbydefault: true, orderbywaydefault: "desc" },
            { short: "surname", name: "Prénom", width: "18%", orderby: true },
            { short: "entity", name: "Société", width: "18%", category: "classic" },
            { short: "fonction", name: "Fonction", width: "18%", category: "classic" },
            { short: "date", name: "Date", width: "13%", orderby: true },
            { short: "actions", width: "11%", category: "actions" }
        ];

        const filters = [
            { type: "search", size: 30, name: "searchContactsByName", placeholder: "Recherche par nom" },
            { type: "search", size: 30, name: "searchContactsByEntity", placeholder: "Recherche par entreprise" },
            { type: "select", size: 20, name: "searchContactsType", placeholder: "Type", options: contactType },
            {
                type: "button",
                size: 20,
                name: "",
                title: "Ajouter un contact",
                icon: faPlusCircle,
                fn: () => {
                    modalFunction({
                        submit: (values) => {
                            if (!contacts.pendingAdd) {
                                //console.log(values)
                                addContact(values).then(
                                    function (response) {
                                        history.push("/sic/contact/" + response.response.data.contacts)
                                    }
                                );
                            }
                        },
                        text: (
                            <Panel
                                width={[3, 9]}
                                inputs={[
                                    { short: "name", name: "Nom", type: "text", placeholder: "Nom", validate: required() },
                                    { short: "surname", name: "Prénom", type: "text", placeholder: "Prénom", validate: required() },
                                    { short: "phone", name: "Téléphone", type: "text", placeholder: "123456789" },
                                    { short: "email", name: "Email", type: "text", placeholder: "" },
                                    { short: "prospect", name: "Prospect", type: "checkbox", placeholder: "", validate: required() }
                                ]}
                            />
                        ),
                        header: "Ajouter un contact",
                        state: "contacts.pendingAdd"
                    }, "popup")
                }
            }
        ]

        let portlet = {
            title: "Contacts",
            links: [
                { name: "Recherche", path: "/sic/contacts" }
            ]
        }

        return (

            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        getElementsAll={getContactsAll}
                        resetElements={resetContacts}
                        elements={this.props.contacts.contacts}
                        properties={properties}
                        filters={filters}
                        page={pageDefault}
                        renderTd={this.renderTd}
                        pending={this.props.contacts.pending}
                        noDataMessage={`Pas de contacts`}
                        deleteAction={(e) => {
                            modalFunction({
                                btnFunction: () => {
                                    deleteContact(e).then(
                                        function(response) {
                                            if (!response.response.data.deleted) {
                                                modalFunction({
                                                    text: "Le contact ne peut pas être supprimé car il est rattaché à un projet",
                                                    header: "Alerte",
                                                    state: "contacts.pendingDelete"
                                            }, "popup");
                                            }
                                        }
                                    )
                                },
                                btnText: "Confirmer",
                                text: "Êtes-vous sûr ?",
                                header: "Supprimer un contact",
                                state: "contacts.pendingDelete"
                            }, "popup")
                        }}
                        viewAction={(id) => (history.push("/sic/contact/" + id))}
                    />
                </FullCenter>
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getContactsAll,
            addContact,
            deleteContact,
            resetContacts
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        contacts: state.contacts,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contacts);
