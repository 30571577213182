import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { TileContainer, Tile, TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials';

//import moment from 'moment';

import { Panel } from '../../gj_modules/gj-strap/components/form';
import DataTable from '../../gj_modules/gj-strap/components/form/DataTable';
import { Button } from 'react-bootstrap';

import { addProjectAction, deleteProjectAction, updateProjectAction, setProjectStateAction } from '../../actions/projects';
//import { setStateAction } from '../../actions/actions';
import { getEntitiesAll } from '../../actions/entities';
import { getUsersAll } from '../../actions/user';
import { getContactsAll } from '../../actions/contacts';

import { returnFunction, processSubmit } from '../../gj_modules/gj-strap/lib';
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';

import { /*actionType,*/ reminderType } from '../../data/constants';

import { required, validateDate } from '../../lib/validate';
//import { PanelDisplay } from '../../gj_modules/gj-strap/components/display';

//import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import { actionsFormat, actionsUpdatePanel } from '../../lib';

class ProjectActions extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    dataTableActionsRender = () => {
        const { project, getEntitiesAll, getUsersAll, getContactsAll, deleteProjectAction, updateProjectAction, setProjectStateAction } = this.props;

        const properties = [ // DataTable Headers
            { short: "date", width: "20%", category: "classic" },
            { short: "name", width: "25%", category: "classic" },
            { short: "state", width: "5%", category: "classic" },
            { short: "description", width: "30%", category: "classic" },
            { short: "actions", width: "20%", category: "actions" }
        ];

        const array = actionsFormat(project.actions, setProjectStateAction);

        return (
            <DataTable small
                noEditGlobal
                properties={properties}
                collection={array}
                noDataMessage={`Pas d'actions`}
                showAll={3}
                editAction={(e) => {
                    actionsUpdatePanel(
                        e,
                        project.actions,
                        project.pendingActionsUpdate,
                        "projects.project.pendingActionsUpdate",
                        updateProjectAction,
                        getEntitiesAll,
                        getContactsAll,
                        getUsersAll
                    );
                }}
                deleteAction={(e) => {
                    modalFunction({
                        btnFunction: () => {
                            console.log("function delete " + e)
                            deleteProjectAction(e)
                        },
                        btnText: "Confirmer",
                        text: "Êtes-vous sûr ?",
                        header: "Supprimer une action",
                        state: "projects.project.pendingActionsDelete"
                    }, "popup")
                }}
            />
        )
    }

    render() {
        const { project, getEntitiesAll, getUsersAll, getContactsAll, addProjectAction, idProject } = this.props;

        return (
            <React.Fragment>
                {/* Actions */}
                <TileContainer>
                    <Tile size={"full"}>
                        <TileHeader
                            title="Actions"
                            border={true}
                            toolbar={<Button onClick={
                                () => {

                                    let cacheUserIds = [];

                                    if (!project.pendingActionsAdd) {
                                        modalFunction({
                                            submit: (values) => {
                                                if (!project.pendingActionsAdd) {
                                                    //console.log(values)
                                                    addProjectAction(idProject, processSubmit(values))
                                                }
                                            },
                                            text: (
                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        {
                                                            short: "date", name: "Date", type: "datepicker", placeholder: "", validate: validateDate(), data: {
                                                                anytime: true,
                                                                alldays: true
                                                            }
                                                        },
                                                        {
                                                            short: "hours", name: "Heure", type: "selecthour", data: {
                                                                minTime: '00:00',
                                                                maxTime: '24:00',
                                                                step: 30
                                                            }
                                                        },
                                                        { short: "description", name: "Description", type: "textarea", placeholder: "Action", validate: required() },
                                                        {
                                                            short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                //defaultValue: { value:null, label:"", force:true },
                                                                loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 5, "entities.entities", "Entites_ID", "Entites_Nom")
                                                            }
                                                        },
                                                        {
                                                            short: "contactId", name: "Contact", type: "select", placeholder: "Rechercher", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                //defaultValue: { value:null, label:"", force:true },
                                                                loadOptions: returnFunction(getContactsAll, "searchContactsByName", 5, "contacts.contacts", "Personnes_ID", ["Personnes_Prenom", "Personnes_Nom"])
                                                            }
                                                        },
                                                        /*{
                                                            short: "type", name: "Type", type: "select", placeholder: "Type", data: {
                                                                isMulti: false,
                                                                options: actionType
                                                            }
                                                        },*/
                                                        {
                                                            short: "reminderType", name: "Type", type: "select", placeholder: "Type de rappel", validate: required(), data: {
                                                                isMulti: false,
                                                                options: reminderType
                                                            }
                                                        },
                                                        {
                                                            short: "userIds", name: "Utilisateurs", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                                                isMulti: true,
                                                                isAsync: true,
                                                                forceLabel: true,
                                                                cache: cacheUserIds,
                                                                //defaultValue: { value:null, label:"", force:true },
                                                                loadOptions: returnFunction(getUsersAll, "searchUsersByName", 5, "user.users", "Utilisateurs_ID", ["Utilisateurs_Prenom", "Utilisateurs_Nom"])
                                                            }
                                                        }
                                                    ]}
                                                />
                                            ),
                                            header: "Ajouter une action",
                                            state: "projects.project.pendingActionsAdd"
                                        }, "popup")
                                    }
                                }
                            }>
                                {project.pendingActionsAdd ? "O" : "+"}
                            </Button>}
                        />
                        <TileBody>
                            {this.dataTableActionsRender()}
                        </TileBody>
                    </Tile>
                </TileContainer>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getEntitiesAll,
            getUsersAll,
            getContactsAll,
            addProjectAction,
            deleteProjectAction,
            updateProjectAction,
            setProjectStateAction
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        project: state.projects.project
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectActions);