//Project
export const projectType = [
    {
        value: 'moa',
        label: 'MOA'
    },
    {
        value: 'architect',
        label: 'Architecte'
    },
    {
        value: 'bet',
        label: 'BET'
    },
    {
        value: 'enterprise',
        label: 'Entreprise'
    }
];

export const projectStatusType = [
    {
        value: 'pending',
        label: 'En cours'
    },
    {
        value: 'win',
        label: 'Obtenu'
    },
    {
        value: 'over',
        label: 'Non abouti'
    }
];

//Contact
export const civility = [
    {
        value: 1,
        label: 'Maître'
    },
    {
        value: 2,
        label: 'Madame'
    },
    {
        value: 3,
        label: 'Monsieur'
    },
    {
        value: 4,
        label: 'Mademoiselle'
    },
    {
        value: 5,
        label: 'Général'
    },
    {
        value: 6,
        label: 'Lieutenant'
    },
    {
        value: 7,
        label: 'Colonel'
    },
    {
        value: 8,
        label: 'Capitaine'
    },
    {
        value: 9,
        label: 'Commandant'
    },
    {
        value: 10,
        label: 'Caporal chef'
    }
]

export const contactType = [
    {
        value: 'vip',
        label: 'VIP'
    },
    {
        value: 'prospect',
        label: 'Prospect'
    },
    {
        value: 'salarie',
        label: 'Salarié'
    }
];

export const histoType = [
    {
        value: 'gift',
        label: 'Cadeau'
    }
];

//Actions
export const actionType = [
    {
        value: 'cvupdate',
        label: 'Mise à jour CV'
    },
    {
        value: 'photo',
        label: 'Photos'
    },
    {
        value: 'presentationupdate',
        label: 'Dossier présentation'
    },
    {
        value: 'tablereferences',
        label: 'Tableau des références'
    },
    {
        value: 'website',
        label: 'Site internet'
    },
    {
        value: 'opqibi',
        label: 'OPQIBI'
    },
    {
        value: 'certifcapacity',
        label: 'Certificat de capacité'
    },
    {
        value: 'contractprojectsend',
        label: 'Contrat / Projet envoyé'
    },
    {
        value: 'contractsend',
        label: 'Contrat / Envoyé'
    },
    {
        value: 'contractreceived',
        label: 'Contrat / Reçu et signé'
    },
    {
        value: 'dc4send',
        label: 'DC4 / Envoyé'
    },
    {
        value: 'dc4received',
        label: 'DC4 / Reçu et signé'
    },
    {
        value: 'endorsementprojectsend',
        label: 'Avenant / Projet envoyé'
    },
    {
        value: 'endorsementsend',
        label: 'Avenant / Envoyé'
    },
    {
        value: 'endorsementreceived',
        label: 'Avenant / Reçu et signé'
    }
];


export const reminderType = [
    {
        value: 'classic',
        label: 'Rappel'
    },
    {
        value: 'noreminder',
        label: 'Pas de rappel'
    },
    {
        value: 'dayd',
        label: 'Jour J'
    },
    {
        value: 'weekly',
        label: 'Une semaine avant'
    }
];

export const actionStatus = [
    {
        value:"past",
        label:"Actions passées"
    },
    {
        value:"notdone",
        label:"Actions non réalisées"
    }
]

//Businesses
export const businessStatusType = [
    {
        value: 'pending',
        label: 'En cours'
    },
    {
        value: 'achieved',
        label: 'Achevé'
    },
    {
        value: 'studying',
        label: 'En étude'
    }
];

export const businessSector = [
    {
        value: 'public',
        label: 'Public'
    },
    {
        value: 'private',
        label: 'Privé'
    }
];

export const projectCategory = [
    {
        value: 'culture',
        label: 'Équipements culturels et de loisirs'
    },
    {
        value: 'hospital',
        label: 'Équipements hospitaliers'
    },
    {
        value: 'business',
        label: 'Équipements commerciaux'
    },
    {
        value: 'school',
        label: 'Équipements scolaires / universitaires'
    },
    {
        value: 'utility',
        label: 'Équipements fonctionnels'
    },
    {
        value: 'townplanning',
        label: 'Équipements et aménagements urbains'
    },
    {
        value: 'housing',
        label: 'Logements'
    },
];

export const directoriesPart = [
    {
        value: 1,
        label: "MO"
    },
    {
        value: 2,
        label: "MOE"
    },
    {
        value: 3,
        label: "SPS, BC..."
    },
    {
        value: 4,
        label: "Entreprises de Travaux"
    },
    {
        value: 5,
        label: "Autres"
    }
]

//Users
export const roles = {
    ROLE_ADMIN:{
        value: 'admin',
        label: 'Admin',
        rank: 10
    },
    ROLE_CICANORD:{
        value: 'cicanord',
        label: 'Cicanord',
        rank: 20
    },
    ROLE_GUEST:{
        value: 'guest',
        label: 'Invité',
        rank: 40
    }
};

export const rolesSelect = [
    {
        value: 'admin',
        label: "Admin"
    },
    {
        value: 'cicanord',
        label: "Cicanord"
    }
]

//Users preference
export const userFrequency = [
    {
        value: 'weekly',
        label: "Hebdomadaire"
    },
    {
        value: 'daily',
        label: "Journalière"
    }
]

//Search
export const searchType = [
    {
        value: 'contact',
        label: 'Contact'
    },
    {
        value: 'entity',
        label: 'Entité'
    },
    {
        value: 'business',
        label: 'Affaire'
    },
    {
        value: 'project',
        label: 'Projet'
    },
    {
        value: 'eproject',
        label: 'Projet Externe'
    }
];

//Request
export const requestType = [
    {
        value: 'contact',
        label: 'Contact',
        reqOptions: [{value: "Personnes_DateCreation", label: "Personnes_DateCreation" }],
    }
];

export const allFilters = requestType[0].reqOptions;