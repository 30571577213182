import React from 'react';
/*import { Link } from 'react-router-dom';*/

import moment from 'moment';

import { toolTip, textSlice } from '../gj_modules/gj-strap/lib';

function formatDate(d) {
    return d ? moment(d).format("DD/MM/YY") : "";
}

function formatFloat(f) {
    if (f) {
        return f.toFixed(1) + " mois";
    } else {
        return "0.0 mois";
    }
}

function computeDifference(a, b) {
    if (a && b) {

        let result = a - b;

        return (
            <b style={(result > 0) ? {color:"red"} : null}>
                {formatFloat(result)}
            </b>
        )
    } else {
        return "0.0 mois";
    }
}

export default function phasesFormat(phases) {

    let array = [];

    if (phases && phases.length) {
        for (let i = 0; i < phases.length; i++) {

            const object = {
                id: phases[i].id,
                name: (toolTip(textSlice(phases[i].name, 8),
                    phases[i].name,
                    { placement: "right", trigger: "hover" })),

                cicanordlength: formatFloat(phases[i].cicanordLength),
                cicanorddatestart: formatDate(phases[i].cicanordDateStart),
                cicanorddateend: formatDate(phases[i].cicanordDateEnd),

                plannedlength: formatFloat(phases[i].plannedLength),
                planneddatestart: formatDate(phases[i].plannedDateStart),
                planneddateend: formatDate(phases[i].plannedDateEnd),

                differencea: computeDifference(phases[i].plannedLength, phases[i].cicanordLength),

                donelength: formatFloat(phases[i].doneLength),
                donedatestart: formatDate(phases[i].doneDateStart),
                donedateend: formatDate(phases[i].doneDateEnd),

                differenceb: computeDifference(phases[i].doneLength, phases[i].cicanordLength),

                comment: (toolTip(textSlice(phases[i].comment, 8),
                    phases[i].comment,
                    { placement: "left", trigger: "hover" }))
            }

            object.key = "phase" + phases[i].id
            object.index = i + 1;


            array.push(object);
        }
    }

    return array;

}