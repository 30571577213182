import {
    EPROJECTS_ALL,
    EPROJECTS_ALL_PENDING,
    EPROJECTS_ID,
    EPROJECTS_ID_PENDING,
    EPROJECTS_ADD,
    EPROJECTS_ADD_PENDING,
    EPROJECTS_UPDATE,
    EPROJECTS_UPDATE_PENDING,
    EPROJECTS_DELETE,
    EPROJECTS_DELETE_PENDING,
    EPROJECTS_ENTITIES_ADD,
    EPROJECTS_ENTITIES_ADD_PENDING,
    EPROJECTS_ENTITIES_DELETE,
    EPROJECTS_ENTITIES_DELETE_PENDING,
    EPROJECTS_CONTACT_ADD,
    EPROJECTS_CONTACT_ADD_PENDING,
    EPROJECTS_CONTACT_DELETE,
    EPROJECTS_CONTACT_DELETE_PENDING,
    EPROJECTS_RESET,
    EPROJECTS_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    error:'',
    eprojects:null,
    eproject:null,
    message:null,
    pending:false,
    pendingAdd:false,
    pendingDelete:false,
    pendingUpdate:false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case EPROJECTS_ALL_PENDING:
            return {
                ...state,
                pending:true
            }
        case EPROJECTS_ALL:
            let eprojects = action.payload.eprojects;
            eprojects['count'] = action.payload.eprojectsCount;
            return {
                ...state,
                eprojects: eprojects,
                error: '',
                message: action.payload.message,
                pending:false
            };
        case EPROJECTS_ADD_PENDING:
            return {
                ...state,
                pendingAdd:true
            }
        case EPROJECTS_ADD:
            return {
                ...state,
                pendingAdd:false
            }
        case EPROJECTS_DELETE_PENDING:
            return {
                ...state,
                pendingDelete:true
            }
        case EPROJECTS_DELETE:
            let newEprojects = state.eprojects;
            if(newEprojects !== null){
                newEprojects = state.eprojects.filter((eproject) => eproject.id !== Number(action.payload.id))
            }
            return {
                ...state,
                eprojects:newEprojects,
                pendingDelete:false,
                error: '',
                message: action.payload.message
            };

        /* ENTITIES */
        case EPROJECTS_ENTITIES_ADD_PENDING:
            return {
                ...state,
                eproject:{
                    ...state.eproject,
                    pendingEntitiesAdd:true
                }
            }
        case EPROJECTS_ENTITIES_ADD:
            let entities = state.eproject.entities;
            entities.push(action.payload.entity);
            return {
                ...state,
                eproject:{
                    ...state.eproject,
                    entities:entities,
                    pendingEntitiesAdd:false
                }
            }
        case EPROJECTS_ENTITIES_DELETE_PENDING:
            return {
                ...state,
                eproject:{
                    ...state.eproject,
                    pendingEntitiesDelete:true
                }
            }
        case EPROJECTS_ENTITIES_DELETE:
            return {
                ...state,
                eproject:{
                    ...state.eproject,
                    entities: state.eproject.entities.filter((entity) => entity.id !== Number(action.payload.id)),
                    pendingEntitiesDelete:false
                },
                error: '',
                message: action.payload.message
            };
        
        /* CONTACTS */
        case EPROJECTS_CONTACT_ADD_PENDING:
            return {
                ...state,
                eproject:{
                    ...state.eproject,
                    pendingContactAdd:true
                }
            }
        case EPROJECTS_CONTACT_ADD:
            let contacts = state.eproject.contacts;
            contacts.push(action.payload.contact);
            return {
                ...state,
                eproject:{
                    ...state.eproject,
                    contacts:contacts,
                    pendingContactAdd:false
                }
            }
        case EPROJECTS_CONTACT_DELETE_PENDING:
            return {
                ...state,
                eproject:{
                    ...state.eproject,
                    pendingContactDelete:true
                }
            }
        case EPROJECTS_CONTACT_DELETE:
            return {
                ...state,
                eproject:{
                    ...state.eproject,
                    contacts: state.eproject.contacts.filter((contact) => contact.id !== Number(action.payload.id)),
                    pendingContactDelete:false
                },
                error: '',
                message: action.payload.message
            };
    
        /* EPROJECT */ 
        case EPROJECTS_ID_PENDING:
            return{
                ...state,
                eproject:{
                    ...state.eproject,
                    pending:true
                }
            }
        case EPROJECTS_ID:
            let eproject = action.payload.eproject;
            eproject.pending = false;
            eproject.pendingEntitiesAdd = false;
            return{
                ...state,
                eproject:eproject,
                error:'',
                message:action.payload.message
            }
        case EPROJECTS_UPDATE_PENDING:
            return{
                ...state,
                eproject:{
                    ...state.eproject,
                    pendingUpdate:true,
                    message:''
                }
            }
        case EPROJECTS_UPDATE:
            //console.log("projects_update")
            return{
                ...state,
                eproject:{
                    ...state.eproject,
                    pendingUpdate:false,
                    message:'Eprojet modifié'
                }
            }
        case EPROJECTS_RESET:
            return {
                ...state,
                eprojects: null,
                eproject: null,
                message: null,
                error: null,
                pending:false,
                pendingAdd:false,
                pendingDelete:false,
                pendingUpdate:false
            };
        case EPROJECTS_ERROR:
            return { ...state, error: action.payload.message };
        default:
            return state;
    }
}
