import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactDOM from 'react-dom';
//npm
import moment from 'moment'
//gj-strap
import { TileContainer, Tile, TileHeader, TileBody, TileFooter } from '../../gj_modules/gj-strap/components/partials';
import { Panel, DataTable } from '../../gj_modules/gj-strap/components/form';
//import { ComplexAllMini } from '../../gj_modules/gj-strap/components/complex'
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
//import { Panel } from 'gj_modules/gj-strap/components/form';
import { /*returnFunction, processSubmit,  checkNull,*/ toolTip, textSlice, checkNull } from '../../gj_modules/gj-strap/lib';
//actions
import { resetActions, getActionsAll, addAction, deleteAction, updateAction, setStateAction } from '../../actions/actions';
import { getEntitiesAll, getEntityById } from '../../actions/entities';
import { getUsersAll } from '../../actions/user';
import { getContactsAll, getContactById } from '../../actions/contacts';
//Design
import { Button } from 'react-bootstrap';
//constants
//import { actionStatus } from '../../data/constants';
//current
//import { required, validateDate } from 'lib/validate';
//import { actionsFormat, actionsUpdatePanel } from '../../lib';
//import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

class EntityActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ActionId: null,
        };
    }

    componentDidMount() {
        const { resetActions, entity, getContactById, getUserById } = this.props;

        resetActions();
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    actionDetailsRender = () => {

        let action = null;

        if (this.props.entity.actions) {
            for (let e of this.props.entity.actions) {
                if (e.id === this.state.ActionId) {
                    action = e;
                }
            }
        }

        for (let i in action) {
            if (action[i] === "null" || action[i] === null || action[i] === "") {
                delete action[i];
            }
        }

        return (
            action ? (
                <React.Fragment>
                    {action.description && <p>{"Description: " + action.description}</p>}
                </React.Fragment>
            ) : null
        )
    }

    dataTableActionsRender = () => {
        const { contact, actions, updateContactEntity, deleteContactEntity, getEntitiesAll, getContactsAll, getUsersAll, updateAction, user, setStateAction } = this.props;

        const properties = [ // DataTable Headers
            { short: "name", width: "35%", category: "classic" },
            { short: "date", width: "35%", category: "classic" },
            { short: "actions", width: "30%", category: "actions" }
        ];

        //const filterSort = { type: "hidden", name: "sortUserId", value: "" + user.info.Utilisateurs_ID + "" }

        //const localGetActionsAll = this.props.getActionsAll;
        console.log(actions)
        const array = [];
        if (actions && actions.length) {
            for (let i = 0; i < actions.length; i++) {
                if (contact && contact.info) {
                    const object = {
                        id: actions[i].id,
                        name: (<Link to={"/sic/contact/" + contact.info.Personnes_ID}>{checkNull(Personnes.info.Personnes_Prenom) + " " + checkNull(Personnes.info.Personnes_Nom)}</Link>),
                        date: (<React.Fragment>
                            {
                                actions[i].dateTarget ?
                                    "Date: " + moment(actions[i].dateTarget).format("DD/MM/YYYY")
                                    :
                                    ""
                            }
                        </React.Fragment>),
                    };
                    object.key = actions[i].id
                    object.index = i + 1;

                    /*if (this.props.person && this.props.person.info && entities[i].Entites_ID === this.props.person.info.Entites_ID) {
                        object.style = { "border": "2px solid #4164be" }
                    }*/

                    array.push(object);
                }
            }
        }

        return (
            <DataTable small
                properties={properties}
                collection={array}
                noDataMessage={"Pas d'actions"}
                showAll={2}
                focus={{
                    id: this.state.ActionId,
                    class: "focusAction"
                }}
                clickAction={(e) => {
                    this.setState({
                        ActionId: e.id
                    })
                    //this.props.getActionById(e.id)
                }}
                editAction={(e) => {
                    let cacheUserIds = [];
                    let index = -1;
                    for (let i = 0; i < actions.length; i++) {
                        if (e === actions[i].id) {
                            index = i;
                        }
                    }

                    if (!entity.pendingActionsUpdate) {
                        modalFunction({
                            submit: (values) => {
                                if (!entity.pendingActionsUpdate) {
                                    //console.log(entities[index].PersonnesEntites_ID)
                                    //console.log("updateEntity", values)
                                    updateAction(actions[index].id, processSubmit(values)).then(
                                        function (response) {
                                            getActionsAll({ skip: 0, limit: 20, filter: [] });
                                        }
                                    );
                                }
                            },
                            initialValues: {
                                date: moment(actions[index].dateTarget).format("YYYY-MM-DD") || "",
                                hours: moment(actions[index].dateTarget).format("YYYY-MM-DD").substring(10) || "",
                                description: actions[index].description || "",
                                reminderType: actions[index].reminderType || ""
                            },
                            text: (
                                <Panel
                                    width={[3, 9]}
                                    inputs={[
                                        {
                                            short: "date", name: "Date", type: "datepicker", placeholder: "", data: {
                                                anytime: true,
                                                alldays: true
                                            }
                                        },
                                        {
                                            short: "hours", name: "Heure", type: "selecthour", data: {
                                                minTime: '00:00',
                                                maxTime: '24:00',
                                                step: 30
                                            }
                                        },
                                        { short: "description", name: "Description", type: "textarea", placeholder: "Action", validate: required() },
                                        {
                                            short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", data: {
                                                isMulti: false,
                                                isAsync: true,
                                                //defaultValue: { value:null, label:"", force:true },
                                                loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 5, "entities.entities", "Entites_ID", "Entites_Nom")
                                            }
                                        },
                                        {
                                            short: "contactId", name: "Contact", type: "select", placeholder: "Rechercher", data: {
                                                isMulti: false,
                                                isAsync: true,
                                                //defaultValue: { value:null, label:"", force:true },
                                                loadOptions: returnFunction(getContactsAll, "searchContactsByName", 5, "contacts.contacts", "Personnes_ID", ["Personnes_Prenom", "Personnes_Nom"])
                                            }
                                        },
                                        {
                                            short: "reminderType", name: "Type de rappel", type: "select", placeholder: "Type de rappel", validate: required(), data: {
                                                isMulti: false,
                                                options: reminderType
                                            }
                                        },
                                        {
                                            short: "userIds", name: "Utilisateurs", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                                isMulti: true,
                                                isAsync: true,
                                                forceLabel: true,
                                                cache: cacheUserIds,
                                                //defaultValue: { value:null, label:"", force:true },
                                                loadOptions: returnFunction(getUsersAll, "searchUsersByName", 5, "user.users", "Utilisateurs_ID", ["Utilisateurs_Prenom", "Utilisateurs_Nom"])
                                            }
                                        }
                                    ]}
                                />
                            ),
                            header: "Editer une Action",
                            state: "entities.entity.pendingActionsUpdate"
                        }, "popup")
                    }
                }}
                deleteAction={(e) => {
                    modalFunction({
                        btnFunction: () => {
                            console.log("function delete " + e)
                            deleteAction(e)
                        },
                        btnText: "Confirmer",
                        text: "Êtes-vous sûr ?",
                        header: "Supprimer une action",
                        state: "actions.pendingDelete"
                    }, "popup")
                }}
            />
        )
    }

    render() {
        const { contact, getActionsAll, /*addContactEntity,*/ idContact, /*person*/ } = this.props;

        return (
            <React.Fragment>
                {/* Actions */}
                <TileContainer>
                    <Tile size={"full"}>
                        <TileHeader
                            title="Actions"
                            border={true}
                            toolbar={<Button onClick={
                                () => {
                                    if (!entity.pendingContactsAdd) {
                                        modalFunction({
                                            submit: (values) => {
                                                /*values["contactId"] = Number(idContact);
                                                //console.log(values)
                                                if (!contact.pendingEntitiesAdd) {
                                                    console.log(values)
                                                    addContactEntity(values.entityId, values)
                                                }*/
                                            },
                                            /*text: (
                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        {
                                                            short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                //defaultValue: { value:null, label:"", force:true },
                                                                loadOptions: returnFunction(getActionsAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", "Entites_Nom")
                                                            }
                                                        }
                                                    ]}
                                                />
                                            ),*/
                                            header: "Ajouter une entité",
                                            state: "actions.pendingAdd"
                                        }, "popup")
                                    }
                                }
                            }>
                                {/*{contact.pendingEntitiesAdd ? "O" : "+"}*/}
                            </Button>}
                        />
                        <TileBody>
                            {this.dataTableActionsRender()}
                        </TileBody>
                        <TileFooter>
                            {this.actionDetailsRender()}
                            {/*person && person.info && person.info.Entites_Telephone && person.info.Entites_Telephone != null && person.info.Entites_Telephone !== "" ? <p>{"Téléphone : " + person.info.Entites_Telephone}</p> : ""*/}
                            {/*person.persons[0].PersonnesEntites_TelephoneProfessionel !== null && person.persons[0].PersonnesEntites_TelephoneProfessionel !== "" && person.persons[0].PersonnesEntites_TelephoneProfessionelDiffusable === true ? <p>{"Téléphone Professionnel : " + person.persons[0].PersonnesEntites_TelephoneProfessionel}</p> : ""*/}
                            {/*person.persons[0].PersonnesEntites_TelephonePortableProfessionel !== null && person.persons[0].PersonnesEntites_TelephonePortableProfessionel !== "" && person.persons[0].PersonnesEntites_TelephonePortableProfessionelDiffusable === true ? <p>{"Téléphone Professionnel Portable: " + person.persons[0].PersonnesEntites_TelephonePortableProfessionel}</p> : ""*/}
                            {/*person.persons[0].PersonnesEntites_FaxProfessionel !== null && person.persons[0].PersonnesEntites_FaxProfessionel !== "" && person.persons[0].PersonnesEntites_FaxProfessionelDiffusable === true ? <p>{"Téléphone Fax : " + person.persons[0].PersonnesEntites_FaxProfessionel}</p> : ""*/}
                        </TileFooter>
                    </Tile>
                </TileContainer>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getEntitiesAll,
            getUsersAll,
            getContactsAll,
            getActionsAll,
            addAction,
            deleteAction,
            updateAction,
            setStateAction,
            resetActions,
            getEntityById,
            getContactById,
            getActionsAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        contact: state.contacts.contact,
        entity: state.entities.entity,
        actions: state.actions
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EntityActions);