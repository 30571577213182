import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
//gj-strap
import { TileContainer, Tile, TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials';
import { DataTable } from '../../gj_modules/gj-strap/components/form';

class BusinessEngineers extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    dataTablePersonsRender = () => {

		const { business } = this.props;

		let engineers = [];

		for(let c of business.contacts){
			if(c.PersonnesEntites_IDEntites === 3910){

				let found = false;

				for(let e of engineers){
					if(e.Personnes_ID === c.Personnes_ID){
						found = true;
					}
				}

				if(!found){
					engineers.push(c)
				}
			}
		}

        const properties = [ // DataTable Headers
            { short: "name", width: "100%", category: "classic" }
        ];

        const array = [];

        if (engineers && engineers.length) {
            for (let i = 0; i < engineers.length; i++) {
                const object = {
                    id: engineers[i].Personnes_ID,
                    name: (<Link to={"/sic/contact/" + engineers[i].Personnes_ID}>{engineers[i].Personnes_Prenom+" "+engineers[i].Personnes_Nom}</Link>),
                };
                object.key = engineers[i].Personnes_ID;
                object.index = i + 1;
                array.push(object);
            }
        }

        return (
            <DataTable small
                noEditGlobal
                properties={properties}
                collection={array}
                noDataMessage={"Pas d'ingénieurs"}
            />
        )
    }

    render() {
        //const { contact } = this.props;

        return (
            <React.Fragment>
                {/* Entities */}
                <TileContainer>
                    <Tile size={"full"}>
                        <TileHeader
                            title="Ingénieurs Cicanord"
							border={true}
						/>
                        <TileBody>
                            {this.dataTablePersonsRender()}
                        </TileBody>
                    </Tile>
                </TileContainer>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        /*contact: state.contacts.contact*/
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessEngineers);