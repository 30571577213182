import React from 'react';
//import { Link } from 'react-router-dom';

import moment from 'moment';

import history from '../history';

import { toolTip, textSlice, labelByValue, checkNull } from '../gj_modules/gj-strap/lib';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'

//Action
import { setStateAction } from '../actions/actions';

import { default as store } from '../index'

import AvatarUser from '../components/subpartials/AvatarUser'
import { actionType } from '../data/constants';

export default function actionsFormat(actions, setStateActionVar) {

    let renderUsers = function(users, i){
        return users.map((u) => {
            return (
                <AvatarUser u={u} key={"actionuser_"+i+"_"+u.id} />
            );
        })
    }

    let clickStateAction = function(id, state) {

        //const { setProjectStateAction } = this.props;

        let finalState = 1;
        if (state === 1) {
            finalState = 0;
        }

        if(setStateActionVar === undefined){
            store.dispatch(setStateAction(id, {
                state: finalState
            }))
        }else{
            setStateActionVar(id, {
                state: finalState
            });
        }

    }

    let array = [];

    if (actions && actions.length) {
        for (let i = 0; i < actions.length; i++) {

            let finalStateIcon = faSquare;

            if (actions[i].state === "1" || actions[i].state === 1) {
                finalStateIcon = faCheckSquare;
            }

            let finalColor = "transparent";

            let difference = ((moment(actions[i].dateTarget) - moment()) / 3600000 / 24);

            if (difference < 8) {
                finalColor = "red";
            } else if (difference < 15) {
                finalColor = "orange";
            }

            let stateDate = "";

            if (actions[i].state) {
                stateDate = "Action accomplie le " + moment(actions[i].dateState).format("DD/MM/YYYY à HH:mm");
            }

            let category = null;
            let name = null;
            let code = null;
            let subid = null;

            if(actions[i].businessId){
                category = "business";
                name = actions[i].Affaires_Nom;
                code = actions[i].Affaires_Code;
                subid = actions[i].businessId;
            }else if(actions[i].projectId){
                category = "project";
                name = actions[i].Projects_name;
                code = actions[i].Projects_code;
                subid = actions[i].projectId;
            }

            let description = checkNull(labelByValue(actions[i].type, actionType))+" "+checkNull(actions[i].description);

            if(actions[i].description !== null && actions[i].description !== "" && actions[i].type){
                description = checkNull(labelByValue(actions[i].type, actionType))+" - "+checkNull(actions[i].description);
            }

            array.push({

                index: i + 1,

                key: ("action" + actions[i].id),

                original: actions[i],

                users: renderUsers(actions[i].userIds, i),

                id: actions[i].id,

                category:category,
                subname: name,
                code: code,
                subnameformat: (<div className="link" onClick={() => history.push("/sic/"+category+"/"+subid)}>{code}</div>),

                date: (
                    <React.Fragment>
                        <div style={{borderLeft:"4px solid "+finalColor, paddingLeft:"5px"}}>
                            {/*<div className="actionColorBar" style={{ backgroundColor: finalColor }}></div>*/}
                            {moment(actions[i].dateTarget).format("DD/MM/YYYY")}
                            <br />
                            {renderUsers(actions[i].userIds, i)}
                        </div>
                    </React.Fragment>
                ),

                //name: (<Link to={"/sic/entity/" + actions[i].Entites_ID}>{actions[i].Entites_Nom}</Link>),
                name:(<div className="link" onClick={() => history.push("/sic/entity/" + actions[i].Entites_ID)}>{actions[i].Entites_Nom}</div>),

                description: (toolTip(textSlice(description, 50),
                    description,
                    { placement: "left", trigger: "hover" })
                ),

                state: (actions[i].state ? 
                    toolTip(<FontAwesomeIcon className="actionCheck" onClick={() => clickStateAction(actions[i].id, actions[i].state)} icon={finalStateIcon} />,
                        stateDate,
                        { placement: "left", trigger: "hover" })
                    : <FontAwesomeIcon className="actionCheck" onClick={() => clickStateAction(actions[i].id, actions[i].state)} icon={finalStateIcon} />),

                
            });

            /*object.popover = {
                content:(
                    <React.Fragment>
                        <div>
                            <PanelDisplay
                                width={[3, 9]}
                                inputs={[
                                    { short: "description", name: "Description", type: "text", value: "Action" },
                                    { short: "description", name: "Description", type: "text", value: "Action" },
                                ]}
                            />
                        </div>
                    </React.Fragment>
                ),
                title:"Action",
                trigger:"hover", //hover click
                placement:"left"
            }*/
        }
    }

    return array;

}