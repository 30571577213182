import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//modules
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
//gj-strap
import { Panel } from '../../gj_modules/gj-strap/components/form';
import { returnFunction, processSubmit } from '../../gj_modules/gj-strap/lib';
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
import { TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials';
import { Form, Input } from '../../gj_modules/gj-strap/components/form';
//actions
import { getActivitiesAll } from '../../actions/activities';
import { getEntitiesAll } from '../../actions/entities';
import { getContactsAll } from '../../actions/contacts';
import { addBusinessesDirectory, updateBusinessesDirectory, deleteBusinessesDirectory, addBusinessesDirectoriesEntity, deleteBusinessesDirectoriesEntity, updateBusinessesDirectoriesEntity, addBusinessesDirectoriesEntitiesContact, updateBusinessesDirectoriesEntitiesContact, deleteBusinessesDirectoriesEntitiesContact } from '../../actions/businesses';
//current
import { required } from '../../lib/validate';
import { sortDirectories } from '../../lib';
import Tree from 'react-animated-tree'
//design
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit, faStar } from '@fortawesome/free-regular-svg-icons'
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons'
import "./BusinessDirectories.scss"

class CCategory extends Component {
    render(){
        const {
			category,
			businessState,
			handleHide,
			addBusinessesDirectory
        } = this.props;
        
        return(
            <Tree canHide visible onClick={(e) => handleHide({depth:"categories", id:category.value, value:e })} content={
                <div className="subdisplay">
                    {category.label} ({category.directories ? category.directories.length : 0})

                    {/*<div style={{display:'inline-block'}}><Checkbox name={"bla"} onClick={() => console.log("truc")} onChange={(e) => console.log(e)} /> </div>    */}                        

                    <Button
                        className="ml-3"
                        onClick={() => {
                            modalFunction({
                                submit: (values) => {
                                    values["businessId"] = businessState.info.Affaires_ID;
                                    values["categoryId"] = category.value;
                                    if (!businessState.pendingDirectoryAdd) {
                                        //console.log(processSubmit(values))
                                        addBusinessesDirectory(processSubmit(values))
                                    }
                                },
                                text: (
                                    <Panel
                                        width={[3, 9]}
                                        inputs={[
											{ short: "lot", name: "N° lot", type: "text", placeholder: "" },
                                            { short: "name", name: "Intitulé", type: "text", placeholder: "Intitulé" },
                                            /*{
                                                short: "activityId", name: "Activité", type: "select", placeholder: "Rechercher", data: {
                                                    isMulti: false,
													isAsync: true,
                                                    //defaultValue: { value:null, label:"", force:true },
                                                    loadOptions: returnFunction(getActivitiesAll, "searchActivitiesByName", 20, "activities.activities", "Activites_ID", "Activites_Valeur")
                                                }
                                            }*/
                                        ]}
                                    />
                                ),
                                header: "Ajouter une catégorie",
                                state: "businesses.business.pendingDirectoryAdd"
                            }, "popup")
                        }}
                        //title="Add category"
                    >
                    Ajouter catégorie
                    </Button>
                </div>
            }>
                {this.props.children}
            </Tree>
        )
    }
}

class CDirectory extends Component {
	render() {
		const {
			alreadyIn,
            directory,
			businessState,
			handleHide,
            getEntitiesAll,
            updateBusinessesDirectory,
            addBusinessesDirectory,
			deleteBusinessesDirectory,
			addBusinessesDirectoriesEntity
        } = this.props;
        
        let count = 0;

        if(directory.hasOwnProperty("entities")){
            count += directory.entities.length;
        }
        if(directory.hasOwnProperty("subdirectories")){
            count += directory.subdirectories.length;
		}
		
		let lotName = "";

		//if(directory.Annuaires_NumPartie === 4){
			if(directory.Annuaires_Numero){
				lotName = directory.Annuaires_Numero+" : "
			}
        //}

		return(
			<Tree /*open*/ canHide visible={directory.entities ? true : false || directory.subdirectories ? true : false} onClick={(e) => handleHide({depth:"directories", id:directory.Annuaires_ID, value:e })} content={
				<div className="subdisplay">
					{lotName}{directory.Annuaires_Intitule} ({count})
                    <Button
                        className="ml-3"
                        onClick={() => {
                            modalFunction({
                                submit: (values) => {
                                    if (!businessState.pendingDirectoryUpdate) {
                                        //console.log(processSubmit(values))
                                        updateBusinessesDirectory(directory.Annuaires_ID, processSubmit(values));
                                    }
                                },
                                initialValues: {
                                    lot: (directory.Annuaires_Numero) || "",
                                    intitule: (directory.Annuaires_Intitule) || ""
                                },
                                text: (
                                    <Panel
                                        width={[3, 9]}
                                        inputs={[
                                            { short: "lot", name: "N° lot", type: "text", placeholder: "" },
                                            { short: "intitule", name: "Intitulé", type: "text", placeholder: "" }
                                        ]}
                                    />
                                ),
                                header: "Editer une catégorie",
                                state: "businesses.business.pendingDirectoryUpdate"
                            }, "popup")
                        }}
                    >
                        Editer une catégorie
                    </Button>
					{!alreadyIn ? (
						<Button
						className="ml-3"
						onClick={() => {
							modalFunction({
								submit: (values) => {
									values["businessId"] = businessState.info.Affaires_ID;
									values["categoryId"] = directory.Annuaires_NumPartie;
									values["parentId"] = directory.Annuaires_ID;
									if (!businessState.pendingDirectoryAdd) {
										console.log(processSubmit(values))
										addBusinessesDirectory(processSubmit(values))
									}
								},
								text: (
									<Panel
										width={[3, 9]}
										inputs={[
											{ short: "lot", name: "N° lot", type: "text", placeholder: "" },
											{ short: "name", name: "Intitulé", type: "text", placeholder: "Intitulé" },
											/*{
												short: "activityId", name: "Activité", type: "select", placeholder: "Rechercher", data: {
													isMulti: false,
													isAsync: true,
													//defaultValue: { value:null, label:"", force:true },
													loadOptions: returnFunction(getActivitiesAll, "searchActivitiesByName", 20, "activities.activities", "Activites_ID", "Activites_Valeur")
												}
											}*/
										]}
									/>
								),
								header: "Ajouter une sous-catégorie",
								state: "businesses.business.pendingDirectoryAdd"
							}, "popup")
						}}
						>Ajouter une sous-catégorie</Button>
					) : null}
					<Button
						className="ml-3"
						onClick={() => {
							modalFunction({
								submit: (values) => {
									values["directoryId"] = directory.Annuaires_ID;
									values["add"] = true;
									if (!businessState.pendingDirectoriesEntitiesAdd) {
										//console.log(processSubmit(values))
										addBusinessesDirectoriesEntity(processSubmit(values))
									}
								},
								text: (
									<Panel
										width={[3, 9]}
										inputs={[
											{ short: "name", name: "Nom", type: "text", placeholder: "nom" },
                                            { short: "code", name: "Code", type: "text", placeholder: "code"},
                                            { short: "adressCity", name: "Ville", type: "text", placeholder: "ville"},
                                            { short: "adressPostalCode", name: "Code Postal", type: "text", placeholder: "code postal"},
                                            { short: "adressLines", name: "Adresse", type: "text", placeholder: "adresse"},
                                            { short: "postalBox", name: "Boîte postale", type: "text", placeholder: "boîte postale"},
                                            { short: "email", name: "Email", type: "text", placeholder: "email"},
                                            { short: "phone", name: "Téléphone", type: "text", placeholder: "0000000000"}
											/*{
												short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", validate: required(), data: {
													isMulti: false,
													isAsync: true,
													//defaultValue: { value:null, label:"", force:true },
													loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", "Entites_Nom")
												}
											}*/
										]}
									/>
								),
								header: "Créer et ajouter une entité",
								state: "businesses.business.pendingDirectoriesEntitiesAdd"
							}, "popup")
						}}
					>Créer et ajouter une entité</Button>
					<Button
						className="ml-3"
						onClick={() => {
							modalFunction({
								submit: (values) => {
									values["directoryId"] = directory.Annuaires_ID;
									if (!businessState.pendingDirectoriesEntitiesAdd) {
										//console.log(processSubmit(values))
										addBusinessesDirectoriesEntity(processSubmit(values))
									}
								},
								text: (
									<Panel
										width={[3, 9]}
										inputs={[
											{
												short: "entityId", hover: "address", name: "Entité", type: "select", placeholder: "Rechercher", validate: required(), data: {
													isMulti: false,
													isAsync: true,
													//defaultValue: { value:null, label:"", force:true },
													loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", ["Entites_Nom", "Entites_Adresse"])
												}
											}
										]}
									/>
								),
								header: "Ajouter une entité",
								state: "businesses.business.pendingDirectoriesEntitiesAdd"
							}, "popup")
						}}
					>Ajouter une entité</Button>
					<Button
						className="ml-3"
						onClick={() => {
							modalFunction({
								btnFunction: () => {
									console.log("function delete " + directory.Annuaires_ID)
									deleteBusinessesDirectory(directory.Annuaires_ID)
								},
								btnText: "Confirmer",
								text: "Êtes-vous sûr ?",
								header: "Supprimer une catégorie",
								state: "businesses.business.pendingDirectoryDelete"
							}, "popup")
						}}
					>
						Supprimer une catégorie
					</Button>
				</div>
			}>
				{this.props.children}
			</Tree>
		)
	}
}

class CEntity extends Component{
    render(){
        const {
			entity,
			businessState,
			handleHide,
            getContactsAll,
            addBusinessesDirectoriesEntitiesContact,
            deleteBusinessesDirectoriesEntity,
			updateBusinessesDirectoriesEntity
        } = this.props;
        
        return(
            <Tree canHide visible onClick={(e) => handleHide({depth:"entities", id:entity.Entites_ID, value:e })} content={
                <div className="subdisplay">
                    <Link to={"/sic/entity/" + entity.Entites_ID}>
                        {entity.Entites_Nom} ({entity.contacts ? entity.contacts.length : 0}) {(entity.AnnuairesEntites_Reference === "architect" || entity.AnnuairesEntites_Reference === "studyoffice") ? <FontAwesomeIcon icon={faStarSolid} /> : ""}
                    </Link>
					<Button
                        className="ml-3"
                        onClick={() => {
                            modalFunction({
                                submit: (values) => {
									values["Entites_ID"] = entity.Entites_ID;
                                    values["AnnuairesEntites_ID"] = entity.AnnuairesEntites_ID;
									values["add"] = true;
                                    if (!businessState.pendingDirectoriesEntitiesContactAdd) {
                                        //console.log(processSubmit(values))
                                        addBusinessesDirectoriesEntitiesContact(processSubmit(values));
                                    }
                                },
                                text: (
                                    <Panel
                                        width={[3, 9]}
                                        inputs={[
                                            { short: "functionfree", name: "Fonction libre", type: "text", placeholder: "Fonction libre" },
											{ short: "name", name: "Nom", type: "text", placeholder: "Nom", validate: required() },
											{ short: "surname", name: "Prénom", type: "text", placeholder: "Prénom", validate: required() },
                                            { short: "phonenb", name: "Téléphone", type: "text", placeholder: "0123456789", validate: required()},
                                            { short: "email", name: "Email", type: "text", placeholder: "email", validate: required()},
                                        ]}
                                    />
                                ),
                                header: "Créer et ajouter un contact",
                                state: "businesses.business.pendingDirectoriesEntitiesContactAdd"
                            }, "popup")
                        }}
                    >Créer et ajouter un contact</Button>
                    <Button
                        className="ml-3"
                        onClick={() => {
                            modalFunction({
                                submit: (values) => {
                                    values["AnnuairesEntites_ID"] = entity.AnnuairesEntites_ID;
                                    if (!businessState.pendingDirectoriesEntitiesContactAdd) {
                                        //console.log(processSubmit(values))
                                        addBusinessesDirectoriesEntitiesContact(processSubmit(values));
                                    }
                                },
                                text: (
                                    <Panel
                                        width={[3, 9]}
                                        inputs={[
                                            { short: "functionfree", name: "Fonction libre", type: "text", placeholder: "Fonction libre" },
                                            {
                                                short: "contactId", name: "Contact", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                                    isMulti: false,
													isAsync: true,
                                                    //defaultValue: { value:null, label:"", force:true },
                                                    loadOptions: returnFunction(
														getContactsAll,
														[
															{
																name:"searchContactsByName",
															},
															{
																name:"searchContactsByEntityId",
																value:entity.Entites_ID
															},
                                                            {
                                                                name:"searchContactsByStatus",
                                                                value: 1
                                                            }
														],
														20,
														"contacts.contacts",
														"Personnes_ID",
														["Personnes_Prenom", "Personnes_Nom"],
														true,
														-1
													)
                                                }
                                            }
                                        ]}
                                    />
                                ),
                                header: "Ajouter un contact",
                                state: "businesses.business.pendingDirectoriesEntitiesContactAdd"
                            }, "popup")
                        }}
                    >Ajouter un contact</Button>
                    <Button
                        className="ml-3"
                        onClick={() => {
                            modalFunction({
                                btnFunction: () => {
                                    console.log("function delete " + entity.AnnuairesEntites_ID)
                                    deleteBusinessesDirectoriesEntity(entity.AnnuairesEntites_ID)
                                },
                                btnText: "Confirmer",
                                text: "Êtes-vous sûr ?",
                                header: "Supprimer une entité",
                                state: "businesses.business.pendingDirectoriesEntitiesDelete"
                            }, "popup")
                        }}
                    >
                        Supprimer une entité
                    </Button>
                    <Button
                        className="ml-3"
                        onClick={() => {
                            modalFunction({                                                       

                                submit:(values) => {
                                    if(!businessState.pendingEntityUpdate){
                                        //console.log(processSubmit(values))
                                        updateBusinessesDirectoriesEntity(entity.AnnuairesEntites_ID, processSubmit(values)).then(function(){
                                            //console.log("refresh")
                                        })
                                    }
                                },
                                initialValues:{
                                    reference: entity.AnnuairesEntites_Reference
                                },
                                text:(
                                    <Panel
                                        width={[3, 9]}
                                        inputs={[
                                            {
                                                short: "reference", name: "Référence", type: "select", placeholder: "", data: {
                                                    isMulti: false,
                                                    options: [
                                                        {
                                                            value: 'architect',
                                                            label: 'Architecte'
                                                        },
                                                        {
                                                            value: 'studyoffice',
                                                            label: 'Bureau d\'étude'
                                                        }
                                                    ]
                                                }
                                            },
                                        ]}
                                    />
                                ),
                                header:"Modifier une référence",
                                state:"businesses.business.pendingEntityUpdate"
                            }, "popup")
                        }}
                    >
                        Modifier une référence
                    </Button>
                </div>
            }>
                {this.props.children}
            </Tree>
        )
    }
}

class CContact extends Component {
    render(){
        const {
			contact,
			businessState,
			handleHide,
			updateBusinessesDirectoriesEntitiesContact,
			deleteBusinessesDirectoriesEntitiesContact
		} = this.props;

        return(
            <Tree /*open*/ canHide visible onClick={(e) => handleHide({depth:"contacts", id:contact.Personnes_ID, value:e })} content={
                <div className="subdisplay">
                    <Link to={"/sic/contact/" + contact.Personnes_ID}>
                        {contact.Personnes_Prenom + " " + contact.Personnes_Nom.toUpperCase()}
                    </Link>
                    <Button
                        className="ml-3"
                        onClick={() => {
                            modalFunction({
                                submit: (values) => {
                                    if (!businessState.pendingDirectoriesEntitiesContactUpdate) {
                                        updateBusinessesDirectoriesEntitiesContact(contact.AnnuairesPersonnes_ID, processSubmit(values));
                                    }
                                },
                                initialValues: {
                                    functionfree: (contact.AnnuairesPersonnes_FonctionLibre) || "",
                                    phone: (contact.AnnuairesPersonnes_TelChantier) || "",
                                    fax: (contact.AnnuairesPersonnes_FaxChantier) || "",
                                    email: (contact.AnnuairesPersonnes_EmailChantier) || "",
                                    comment: (contact.AnnuairesPersonnes_Commentaire) || ""
                                },
                                text: (
                                    <Panel
                                        width={[3, 9]}
                                        inputs={[
                                            { short: "functionfree", name: "Fonction libre", type: "text", placeholder: "Fonction libre" },
                                            { short: "phone", name: "Téléphone", type: "text", placeholder: "0123456789" },
                                            { short: "fax", name: "Fax", type: "text", placeholder: "0123456789" },
                                            { short: "email", name: "Email", type: "text", placeholder: "Email" },
                                            { short: "comment", name: "Commentaire", type: "textarea", placeholder: "" }
                                        ]}
                                    />
                                ),
                                header: "Editer un contact",
                                state: "businesses.business.pendingDirectoriesEntitiesContactUpdate"
                            }, "popup")
                        }}
                    >
                        Editer un contact
                    </Button>
                    <Button
                        className="ml-3"
                        onClick={() => {
                            modalFunction({
                                btnFunction: () => {
                                    //console.log("function delete " + contact.AnnuairesPersonnes_ID)
                                    deleteBusinessesDirectoriesEntitiesContact(contact.AnnuairesPersonnes_ID)
                                },
                                btnText: "Confirmer",
                                text: "Êtes-vous sûr ?",
                                header: "Supprimer un contact",
                                state: "businesses.business.pendingDirectoriesEntitiesContactDelete"
                            }, "popup")
                        }}
                    >
                        Supprimer un contact
                    </Button>
                </div>
            } />
        )
    }
}

class BusinessDirectories extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            filters:{
                categories:{},
                directories:{},
                entities:{},
                contacts:{}
            }
        };
    }

    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    handleHide = (e) => {

        const { changeFilters } = this.props;

        var stateCopy = Object.assign({}, this.state);

        stateCopy.filters[e.depth][e.id] = e.value;

        this.setState(stateCopy);

        changeFilters && changeFilters(stateCopy.filters)

    }

    renderDirectories = (search) => {

        const { business, businessState, getEntitiesAll, getActivitiesAll, getContactsAll,
            addBusinessesDirectory, deleteBusinessesDirectory, updateBusinessesDirectory,
            addBusinessesDirectoriesEntity, deleteBusinessesDirectoriesEntity, updateBusinessesDirectoriesEntity,
            addBusinessesDirectoriesEntitiesContact, updateBusinessesDirectoriesEntitiesContact, deleteBusinessesDirectoriesEntitiesContact
        } = this.props;

        let sortFinal = sortDirectories(business);

        //console.log(sortFinal)

        //Do the search

        if(search !== null && search !== "" && search !== undefined){
            for(let category of sortFinal.categories){
                category.keep = false;
                if(category.directories){
                    for(let directory of category.directories){
                        directory.keep = false;
                        if(directory.entities){
                            for(let entity of directory.entities){
                                entity.keep = false;
                                if(entity.contacts){
                                    for(let contact of entity.contacts){
                                        contact.keep = false;
                                        if((contact.Personnes_Prenom && contact.Personnes_Prenom.toLowerCase().includes(search))
                                            || (contact.Personnes_Nom && contact.Personnes_Nom.toLowerCase().includes(search))
                                            || (entity.Entites_Nom && entity.Entites_Nom.toLowerCase().includes(search))
                                            || (directory.Annuaires_Intitule && directory.Annuaires_Intitule.toLowerCase().includes(search))
                                        ){
                                            contact.keep = true
                                            entity.keep = true
                                            directory.keep = true
                                            category.keep = true
                                        }
                                    }
                                    entity.contacts = entity.contacts.filter(contact => contact.keep === true);
                                }
                                if(entity.Entites_Nom.toLowerCase().includes(search) || directory.Annuaires_Intitule.toLowerCase().includes(search)){
                                    entity.keep = true
                                    directory.keep = true
                                    category.keep = true
                                }
                            }
                            directory.entities = directory.entities.filter(entity => entity.keep === true);
                        }
                        if(directory.Annuaires_Intitule.toLowerCase().includes(search)){
                            directory.keep = true
                            category.keep = true
                        }
                    }
                    category.directories = category.directories.filter(directory => directory.keep === true);
                }
            }
            sortFinal.categories = sortFinal.categories.filter(category => category.keep === true);
        }

        //End of the search

        return (
            <React.Fragment>
                <Form>
                {sortFinal.categories.map((category) => //Category
					<CCategory
						category={category}
						key={"cat_" + category.value}
						businessState={businessState}
						addBusinessesDirectory={this.props.addBusinessesDirectory}
						getActivitiesAll={getActivitiesAll}
						handleHide={this.handleHide}
					>
						{category.directories && category.directories.map((directory) => // Directories
							<CDirectory
								directory={directory}
								key={"dir_" + directory.Annuaires_ID}
								businessState={businessState}
								handleHide={this.handleHide}
								getEntitiesAll={getEntitiesAll}
								getActivitiesAll={getActivitiesAll}
								updateBusinessesDirectory={updateBusinessesDirectory}
								addBusinessesDirectory={addBusinessesDirectory}
								deleteBusinessesDirectory={deleteBusinessesDirectory}
								addBusinessesDirectoriesEntity={addBusinessesDirectoriesEntity}
							>
                                {directory.entities && React.Children.toArray(directory.entities.map((entity) => //Entities
									<CEntity
										entity={entity}
										businessState={businessState}
										handleHide={this.handleHide}
										getContactsAll={getContactsAll}
										addBusinessesDirectoriesEntitiesContact={addBusinessesDirectoriesEntitiesContact}
										deleteBusinessesDirectoriesEntity={deleteBusinessesDirectoriesEntity}
										updateBusinessesDirectoriesEntity={updateBusinessesDirectoriesEntity}
									>
                                        {entity.contacts && React.Children.toArray(entity.contacts.map((contact) => //Contact
											<CContact
												contact={contact}
												businessState={businessState}
												handleHide={this.handleHide}
												updateBusinessesDirectoriesEntitiesContact={updateBusinessesDirectoriesEntitiesContact}
												deleteBusinessesDirectoriesEntitiesContact={deleteBusinessesDirectoriesEntitiesContact}
											></CContact>
                                        ))}
                                    </CEntity>
                                ))}
                                {directory.subdirectories && React.Children.toArray(directory.subdirectories.map((subdirectory) => //subdirectories
									<CDirectory
										alreadyIn
										directory={subdirectory}
										key={"dir_" + subdirectory.Annuaires_ID}
										businessState={businessState}
										handleHide={this.handleHide}
										getEntitiesAll={getEntitiesAll}
										getActivitiesAll={getActivitiesAll}
										updateBusinessesDirectory={updateBusinessesDirectory}
										addBusinessesDirectory={addBusinessesDirectory}
										deleteBusinessesDirectory={deleteBusinessesDirectory}
										addBusinessesDirectoriesEntity={addBusinessesDirectoriesEntity}
									>
                                        {subdirectory.entities && React.Children.toArray(subdirectory.entities.map((entity) => //Entities
											<CEntity
												entity={entity}
												businessState={businessState}
												handleHide={this.handleHide}
												getContactsAll={getContactsAll}
												addBusinessesDirectoriesEntitiesContact={addBusinessesDirectoriesEntitiesContact}
												deleteBusinessesDirectoriesEntity={deleteBusinessesDirectoriesEntity}
												updateBusinessesDirectoriesEntity={updateBusinessesDirectoriesEntity}
											>
                                                {entity.contacts && React.Children.toArray(entity.contacts.map((contact) => //Contact
													<CContact
														contact={contact}
														businessState={businessState}
														handleHide={this.handleHide}
														updateBusinessesDirectoriesEntitiesContact={updateBusinessesDirectoriesEntitiesContact}
														deleteBusinessesDirectoriesEntitiesContact={deleteBusinessesDirectoriesEntitiesContact}
													></CContact>
                                                ))}
                                            </CEntity>
                                        ))}
                                    </CDirectory>
                                ))}
                            </CDirectory>
                        )}
                    </CCategory>
                )}
                </Form>
            </React.Fragment>
        )
    }

    searchChange = e => {
        this.setState({search: e.target.value});
    }

    render() {
        //const { businessState, getEntitiesAll, addBusinessesDirectoriesEntity, deleteBusinessesDirectoriesEntity } = this.props;

        const { search } = this.state;

        return (
            <React.Fragment>
                <TileHeader>
                    <Form key={"test02"} className={"w-100 my-3 "}>
                        <Input
                            type={"search"}
                            name={"test"}
                            placeholder={"Nom"}
                            onChange={this.searchChange}
                        ></Input>
                    </Form>
                </TileHeader>
                <TileBody className="pt-0">
                    {this.renderDirectories(search)}
                </TileBody>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getActivitiesAll,
            getEntitiesAll,
            getContactsAll,
			addBusinessesDirectory,
			updateBusinessesDirectory,
            deleteBusinessesDirectory,
            addBusinessesDirectoriesEntity,
            deleteBusinessesDirectoriesEntity,
            updateBusinessesDirectoriesEntity,
            addBusinessesDirectoriesEntitiesContact,
            updateBusinessesDirectoriesEntitiesContact,
            deleteBusinessesDirectoriesEntitiesContact
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        businessState: state.businesses.business
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessDirectories);