import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import FooterCicanord from '../partials/FooterCicanord';
import { Full, TileContainer, Tile, TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials';
import history from '../../history';

import moment from 'moment';

import { Form, Panel, PanelButton } from '../../gj_modules/gj-strap/components/form';
import { Row, Col, Button } from 'react-bootstrap';
import { Cover } from '../../gj_modules/gj-strap/components/partials';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons'

import { getEprojectById, resetEprojects, updateEprojectById, deleteEproject } from '../../actions/eprojects';
import { getEntitiesAll } from '../../actions/entities';

import { returnFunction, processSubmit, checkNull } from '../../gj_modules/gj-strap/lib';
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';

import { projectCategory } from '../../data/constants';

import EprojectEntities from './EprojectEntities';
import EprojectContacts from './EprojectContacts';

/*
import ProjectActions from './ProjectActions';
import ProjectInformations from './ProjectInformations';
*/

class Eproject extends Component {
    componentDidMount() {
        const { match, getEprojectById, resetEprojects } = this.props;
        const id = match && match.params && match.params.id;
        resetEprojects();
        if (id) {
            getEprojectById(match.params.id);
        }
    }

    componentDidUpdate(){
        //console.log("didUpdate");
    }

    submitEdit(values){
        const { match, updateEprojectById, eproject } = this.props;
        const id = match && match.params && match.params.id;
        //console.log(processSubmit(values));
        if(eproject && !eproject.pendingUpdate){
            updateEprojectById(id, processSubmit(values));
        }
    }

    render() {
        const { match, eproject, deleteEproject, getEntitiesAll } = this.props;
        const id = match && match.params && match.params.id;

        //console.log("PROJECT", project);

        let portlet = {
            title: "Chantiers externes",
            path: "/sic/eprojects",
            links: [
                //{ name: "Recherche", path: "/sic/eprojects" },
                { name: "Fiche chantier externe", path: "/sic/eproject/" + id }
            ]
        }

        if (eproject) {
            //portlet.links[1].name = "test"; //person.info.Personnes_Prenom+" "+person.info.Personnes_Nom;
        }

        return (
            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />

                {eproject && !eproject.pending ? (

                    <Full portlet={portlet}>
                        <TileContainer>
                            <Tile size={"full"}>
                                <TileBody>
                                    <Cover
                                        icon={faPencilRuler}
                                        title={eproject.info.name}
                                        subtitle={checkNull(eproject.info.department)+" "+checkNull(eproject.info.adressCity)}
                                    >
                                        Projet créé le {moment(eproject.info.dateCreate).format("DD/MM/YYYY")}<br />
                                        Dernière modification le {moment(eproject.info.dateUpdate).format("DD/MM/YYYY")}
                                    </Cover>
                                </TileBody>
                            </Tile>
                        </TileContainer>

                        <Row>
                            <Col lg="6" className="tile-col">

                                {/* Général */}
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileHeader title="Général" border={true} />
                                        <TileBody>

                                            <Form
                                                onSubmit={values => this.submitEdit(values)}
                                                initialValues={{
                                                    name: (eproject.info.name) || "",
                                                    adressCity: (eproject.info.adressCity) || "",
                                                    department: (eproject.info.department) || "",
                                                    adressLines: (eproject.info.adressLines) || "",
                                                    //entityId:Number(eproject.info.entityId),
                                                    moa: (eproject.info.moa) || "",
                                                    description: (eproject.info.description) || "",
                                                    category:eproject.info.category,
                                                }}
                                            >

                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        { short: "name", name: "Nom", type: "text", placeholder: "Nom du projet" },
                                                        { short: "adressCity", name: "Ville", type: "text", placeholder: "Ville" },
                                                        { short: "department", name: "Département", type: "text", placeholder: "00000" },
                                                        { short: "adressLines", name: "Adresse", type: "text", placeholder: "Rue" },
                                                        { short: "moa", name: "MOA", type: "text", placeholder: "MOA" },
                                                        /*{
                                                            short: "entityId", name: "MOA", type: "select", placeholder: "Test", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                defaultValue: { value:Number(eproject.info.entityId), label:eproject.info.entityLabel, force:true },
                                                                loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 5, "entities.entities", "Entites_ID", "Entites_Nom")
                                                            }
                                                        },*/
                                                        {
                                                            short: "category", name: "Catégorie", type: "select", placeholder: "Catégorie", data: {
                                                                isMulti: false,
                                                                options: projectCategory
                                                            }
                                                        },
                                                        {
                                                            short: "description", name: "Description", type: "textarea", placeholder: "Description", data:{ height:"90px" }
                                                        },
                                                        {
															short: "architect", name: "Architecte", type: "select", placeholder: "Rechercher", data: {
																isMulti: false,
																isAsync: true,
																defaultValue: eproject.architect ? { value:Number(eproject.architect.Entites_ID), label:eproject.architect.Entites_Nom, force:true } : null,
																loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", "Entites_Nom")
															}
														}
                                                    ]}
                                                />

                                                <PanelButton
                                                    pending={eproject.pendingUpdate}
                                                    message={eproject.message}
                                                />

                                            </Form>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>

                                {/* Delete */}
								<TileContainer>
									<Tile size={'full'}>
										<TileBody>
											<Button
                                                onClick={function(){
                                                    modalFunction({
                                                        btnFunction:() => {
                                                            deleteEproject(id).then(
                                                                function(response){
                                                                    history.push("/sic/eprojects")
                                                                }
                                                            );
                                                        },
                                                        btnText:"Confirmer",
                                                        text:"Êtes-vous sûr de supprimer ce eprojet ?",
                                                        header:"Supprimer un eprojet",
                                                        state:"eprojects.pendingDelete"
                                                    }, "popup")
                                                }}
                                                className="float-sm-right"
                                                variant="danger"
                                            >
												Supprimer ce eprojet
											</Button>
										</TileBody>
									</Tile>
								</TileContainer>
                            </Col>

                            <Col lg="6" className="tile-col">

                                {/* Intervenants */}
                                <EprojectEntities
                                    eproject={eproject}
                                    idProject={id}
                                />

                                {/* Contacts */}
                                <EprojectContacts
                                    eproject={eproject}
                                    idProject={id}
                                />
                            </Col>

                        </Row>

                    </Full>
                ) : (
                    <Full>
                        Chargement
                    </Full>
                )}
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetEprojects,
            getEprojectById,
            updateEprojectById,
            deleteEproject,
            getEntitiesAll,
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        eproject: state.eprojects.eproject
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Eproject);