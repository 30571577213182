import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
//gj-strap
import { TileContainer, Tile, TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials';
import { Panel, DataTable } from '../../gj_modules/gj-strap/components/form';
//actions
import { addProjectEntity, deleteProjectEntity, updateProjectEntity } from '../../actions/projects';
import { getEntitiesAll } from '../../actions/entities';
import { getContactsAll } from '../../actions/contacts';

import { returnFunction, processSubmit, labelByValue, checkNull } from '../../gj_modules/gj-strap/lib';
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
import { required } from '../../lib/validate';

import { projectType } from '../../data/constants';

//design
import { Button } from 'react-bootstrap';

class ProjectEntities extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    dataTableEntitiesRender = () => {
        const { project, deleteProjectEntity, updateProjectEntity, getEntitiesAll, getContactsAll } = this.props;

        const properties = [ // DataTable Headers
            { short: "type", width: "20%", category: "classic" },
            { short: "entity", width: "35%", category: "classic" },
            { short: "contact", width: "25%", category: "classic" },
            { short: "actions", width: "20%", category: "actions" }
        ];

        const arrayMOA = [];
        const arrayMOE = [];
        const arrayBET = [];
        const arrayEnterprise = [];
        const arrayDefault = [];

        if (project && project.entities && project.entities.length) {
            for (let i = 0; i < project.entities.length; i++) {
                const object = {
                    id: project.entities[i].id,
                    type: labelByValue(project.entities[i].type, projectType),
                    entity: (<Link to={"/sic/entity/" + project.entities[i].Entites_ID}>{project.entities[i].Entites_Nom}</Link>),
                    contact: (<Link to={"/sic/contact/" + project.entities[i].Personnes_ID}>{checkNull(project.entities[i].Personnes_Prenom) + " " + checkNull(project.entities[i].Personnes_Nom)}</Link>),
                };
                object.key = "entity" + project.entities[i].Entites_ID
                object.index = i + 1;
                switch (object.type) {
                    case 'MOA':
                        arrayMOA.push(object);
                        break;
                    case 'Architecte':
                        arrayMOE.push(object);
                        break;
                    case 'BET':
                        arrayBET.push(object);
                        break;
                    case 'Entreprise':
                        arrayEnterprise.push(object);
                        break;
                    default:
                        arrayDefault.push(object);
                        break;
                }
            }
        }

        const array = arrayMOA.concat(arrayMOE, arrayBET, arrayEnterprise, arrayDefault)

        return (
            <DataTable small
                noEditGlobal
                properties={properties}
                collection={array}
                noDataMessage={`Pas d'intervenants`}
                showAll={3}
                editAction={(e) => {

                    let index = -1;
                    for (let i = 0; i < project.entities.length; i++) {
                        if (e === project.entities[i].id) {
                            index = i;
                        }
                    }

                    modalFunction({
                        submit: (values) => {
                            if (!project.pendingEntitiesUpdate) {
                                //console.log(processSubmit(values))
                                updateProjectEntity(e, processSubmit(values))
                            }
                        },
                        initialValues: {
                            type: project.entities[index].type,
                            entityId: Number(project.entities[index].Entites_ID),
                            contactId: Number(project.entities[index].Personnes_ID),
                            /*reminderType:project.actions[index].reminderType,
                            userIds:finalUsersIds*/
                        },
                        text: (
                            <Panel
                                width={[3, 9]}
                                inputs={[
                                    {
                                        short: "type", name: "Type", type: "select", placeholder: "Type d'intervenant", validate: required(), data: {
                                            isMulti: false,
                                            options: projectType
                                        }
                                    },
                                    /*{ short: "type", name: "Nom", type: "text", placeholder: "Type d'intervenant" },*/
                                    {
                                        short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                            isMulti: false,
                                            isAsync: true,
                                            defaultValue: { value: Number(project.entities[index].Entites_ID), label: project.entities[index].Entites_Nom, force: true },
                                            loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", "Entites_Nom")
                                        }
                                    },
                                    {
                                        short: "contactId", name: "Contact", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                            isMulti: false,
                                            isAsync: true,
                                            defaultValue: { value: Number(project.entities[index].Personnes_ID), label: (checkNull(project.entities[index].Personnes_Prenom) + " " + checkNull(project.entities[index].Personnes_Nom)), force: true },
                                            loadOptions: returnFunction(getContactsAll, "searchContactsByName", 5, "contacts.contacts", "Personnes_ID", ["Personnes_Prenom", "Personnes_Nom"])
                                        }
                                    }
                                ]}
                            />
                        ),
                        header: "Modifier une entité",
                        state: "projects.project.pendingEntitiesUpdate"
                    }, "popup")
                }}
                deleteAction={(e) => {
                    modalFunction({
                        btnFunction: () => {
                            console.log("function delete " + e)
                            deleteProjectEntity(e)
                        },
                        btnText: "Confirmer",
                        text: "Êtes-vous sûr ?",
                        header: "Supprimer un intervenant",
                        state: "projects.project.pendingEntitiesDelete"
                    }, "popup")
                }}
            />
        )
    }

    render() {
        const { project, getEntitiesAll, getContactsAll, addProjectEntity, idProject } = this.props;

        return (
            <React.Fragment>
                {/* Intervenants */}
                <TileContainer>
                    <Tile size={"full"}>
                        <TileHeader
                            title="Intervenants"
                            border={true}
                            toolbar={<div>
                                <Button
                                    className="ml-3"
                                    onClick={() => {
                                        modalFunction({
                                            submit: (values) => {
                                                /*modalFunction({
                                                    submit: (contactValues) => {
                                                        */values["add"] = true;/*
                                                        values["contactname"] = contactValues.name;
                                                        values["contactsurname"] = contactValues.surname;
                                                        values["contactphonenb"] = contactValues.phonenb;
                                                        values["contactemail"] = contactValues.email;*/
                                                        if (!project.pendingEntitiesAdd/* && !project.pendingContactsAdd*/) {
                                                            //console.log(processSubmit(values))
                                                            addProjectEntity(idProject, values);
                                                        }
                                                    /*},
                                                    text: <Panel
                                                        width={[3, 9]}
                                                        inputs={[
                                                            { short: "name", name: "Nom", type: "text", placeholder: "Nom", validate: required() },
                                                            { short: "surname", name: "Prénom", type: "text", placeholder: "Prénom", validate: required() },
                                                            { short: "phonenb", name: "Téléphone", type: "text", placeholder: "0123456789", validate: required() },
                                                            { short: "email", name: "Email", type: "text", placeholder: "email", validate: required() }
                                                        ]}
                                                    />,
                                                    header: "Créer et ajouter un contact",
                                                    state: "projects.project.pendingContactsAdd"
                                                }, "popup");*/
                                            },
                                            text: (
                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        { short: "name", name: "Nom", type: "text", placeholder: "nom" },
                                                        { short: "code", name: "Code", type: "text", placeholder: "code", validate: required() },
                                                        { short: "adressCity", name: "Ville", type: "text", placeholder: "ville" },
                                                        { short: "adressPostalCode", name: "Code Postal", type: "text", placeholder: "code postal" },
                                                        { short: "adressLines", name: "Adresse", type: "text", placeholder: "adresse" },
                                                        { short: "postalBox", name: "Boîte postale", type: "text", placeholder: "boîte postale" },
                                                        { short: "email", name: "Email", type: "text", placeholder: "email" },
                                                        { short: "phone", name: "Téléphone", type: "text", placeholder: "0000000000" },
                                                        { short: "type", name: "Type", type: "select", placeholder: "Type d'intervenant", validate: required(), data: {
                                                            isMulti: false,
                                                            options: projectType
                                                        }}
                                                        /*{
                                                            short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                //defaultValue: { value:null, label:"", force:true },
                                                                loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", "Entites_Nom")
                                                            }
                                                        }*/
                                                    ]}
                                                />
                                            ),
                                            header: "Créer et ajouter une entité",
                                            state: "projects.project.pendingEntitiesAdd"
                                        }, "popup")
                                    }}
                                >Créer et ajouter</Button>
                                <Button className="ml-3"
                                    onClick={
                                        () => {
                                            if (!project.pendingEntitiesAdd) {
                                                modalFunction({
                                                    submit: (values) => {
                                                        if (!project.pendingEntitiesAdd) {
                                                            addProjectEntity(idProject, values)
                                                        }
                                                    },
                                                    text: (
                                                        <Panel
                                                            width={[3, 9]}
                                                            inputs={[
                                                                {
                                                                    short: "type", name: "Type", type: "select", placeholder: "Type d'intervenant", validate: required(), data: {
                                                                        isMulti: false,
                                                                        options: projectType
                                                                    }
                                                                },
                                                                /*{ short: "type", name: "Nom", type: "text", placeholder: "Type d'intervenant" },*/
                                                                {
                                                                    short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                                                        isMulti: false,
                                                                        isAsync: true,
                                                                        //defaultValue: { value:null, label:"", force:true },
                                                                        loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", "Entites_Nom")
                                                                    }
                                                                },
                                                                {
                                                                    short: "contactId", name: "Contact", type: "select", placeholder: "Rechercher", data: {
                                                                        isMulti: false,
                                                                        isAsync: true,
                                                                        //defaultValue: { value:null, label:"", force:true },
                                                                        loadOptions: returnFunction(getContactsAll, "searchContactsByName", 5, "contacts.contacts", "Personnes_ID", ["Personnes_Prenom", "Personnes_Nom"])
                                                                    }
                                                                }
                                                            ]}
                                                        />
                                                    ),
                                                    header: "Ajouter un intervenant",
                                                    state: "projects.project.pendingEntitiesAdd"
                                                }, "popup")
                                            }
                                        }
                                    }>
                                    {project.pendingEntitiesAdd ? "O" : "+"}
                                </Button></div>}
                        />
                        <TileBody>
                            {this.dataTableEntitiesRender()}
                        </TileBody>
                    </Tile>
                </TileContainer>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getEntitiesAll,
            getContactsAll,
            addProjectEntity,
            deleteProjectEntity,
            updateProjectEntity
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        project: state.projects.project
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectEntities);