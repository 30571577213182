import {
    EMPLOYEES_ALL,
    EMPLOYEES_ALL_PENDING,
    EMPLOYEES_ADD,
    EMPLOYEES_ADD_PENDING,
    EMPLOYEES_DELETE,
    EMPLOYEES_DELETE_PENDING,
    //EMPLOYEES_ERROR,
    EMPLOYEES_RESET
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    contacts: null,
    contact: null,
    message: null,
    pending: false,
    pendingAdd: false,
    pendingDelete: false,
    pendingUpdate: false,
    pendingUpdateVIP: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case EMPLOYEES_ALL_PENDING:
            return {
                ...state,
                pending: true
            }
        case EMPLOYEES_ALL:
            let employees = action.payload.employees;
            employees['count'] = action.payload.employeesCount;
            return {
                ...state,
                employees: employees,
                error: '',
                pending: false,
                message: action.payload.message
            };
        case EMPLOYEES_ADD:
            return {
                ...state,
                error: '',
                message: action.payload.message,
                pendingAdd: false
            };
        case EMPLOYEES_ADD_PENDING:
            return {
                ...state,
                pendingAdd: true
            }
        case EMPLOYEES_DELETE:
            employees = state.employees;
            if (employees !== null) {
                employees = state.employees.filter((employee) => employee.Utilisateurs_ID !== Number(action.payload.id))
            }
            return {
                ...state,
                error: '',
                employees: employees,
                message: action.payload.message,
                pendingDelete: false
            };
        case EMPLOYEES_DELETE_PENDING:
            return {
                ...state,
                pendingDelete: true
            }
        case EMPLOYEES_RESET:
            return {
                ...state,
                employees: null,
                employee:null,
                message: null,
                error: '',
                pending: false,
                pendingDelete: false,
                pendingAdd: false,
                pendingUpdate: false,
                pendingUpdatePassword: false
            };
        default:
            return state;
    }
}
