import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';

import { TileContainer, Tile, TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials';

import { Panel } from '../../gj_modules/gj-strap/components/form';
import DataTable from '../../gj_modules/gj-strap/components/form/DataTable';
import { Button } from 'react-bootstrap';

import { addEprojectEntity, deleteEprojectEntity } from '../../actions/eprojects';
import { getEntitiesAll } from '../../actions/entities';

import { returnFunction } from '../../gj_modules/gj-strap/lib';
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
import { labelByValue } from '../../gj_modules/gj-strap/lib'

import { projectType } from '../../data/constants';

import { required } from '../../lib/validate';

class EprojectEntities extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    dataTableEntitiesRender = () => {
        const { eproject, deleteEprojectEntity } = this.props;

        const properties = [ // DataTable Headers
            { short: "type", width: "30%", category: "classic" },
            { short: "name", width: "40%", category: "classic" },
            { short: "actions", width: "30%", category: "actions" }
        ];

        const array = [];

        if (eproject && eproject.entities && eproject.entities.length) {
            for (let i = 0; i < eproject.entities.length; i++) {
                const object = {
                    id: eproject.entities[i].id,
                    type: labelByValue(eproject.entities[i].type, projectType),
                    name: (<Link to={"/sic/entity/" + eproject.entities[i].Entites_ID} className="mx-3">{eproject.entities[i].Entites_Nom}</Link>),
                };
                object.key = "entity" + eproject.entities[i].Entites_ID
                object.index = i + 1;
                array.push(object);
            }
        }

        return (
            <DataTable small
                properties={properties}
                collection={array}
                noDataMessage={`Pas d'intervenants`}
                showAll={3}
                deleteAction={(e) => {
                    modalFunction({
                        btnFunction: () => {
                            console.log("function delete " + e)
                            deleteEprojectEntity(e)
                        },
                        btnText: "Confirmer",
                        text: "Êtes-vous sûr ?",
                        header: "Supprimer un intervenant",
                        state: "eprojects.eproject.pendingEntitiesDelete"
                    }, "popup")
                }}
            />
        )
    }

    render() {
        const { eproject, getEntitiesAll, addEprojectEntity, idProject } = this.props;

        return (
            <React.Fragment>
                {/* Intervenants */}
                <TileContainer>
                    <Tile size={"full"}>
                        <TileHeader
                            title="Intervenants"
                            border={true}
                            toolbar={<Button onClick={
                                () => {
                                    if (!eproject.pendingEntitiesAdd) {
                                        modalFunction({
                                            submit: (values) => {
                                                if (!eproject.pendingEntitiesAdd) {
                                                    console.log(values)
                                                    addEprojectEntity(idProject, values)
                                                }
                                            },
                                            text: (
                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        {
                                                            short: "type", name: "Type", type: "select", placeholder: "Type d'intervenant", validate: required(), data: {
                                                                isMulti: false,
                                                                options: projectType
                                                            }
                                                        },
                                                        /*{ short: "type", name: "Nom", type: "text", placeholder: "Type d'intervenant" },*/
                                                        {
                                                            short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                //defaultValue: { value:null, label:"", force:true },
                                                                loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", "Entites_Nom")
                                                            }
                                                        }
                                                    ]}
                                                />
                                            ),
                                            header: "Ajouter un intervenant",
                                            state: "eprojects.eproject.pendingEntitiesAdd"
                                        }, "popup")
                                    }
                                }
                            }>
                                {eproject.pendingEntitiesAdd ? "O" : "+"}
                            </Button>}
                        />
                        <TileBody>
                            {this.dataTableEntitiesRender()}
                        </TileBody>
                    </Tile>
                </TileContainer>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getEntitiesAll,
            addEprojectEntity,
            deleteEprojectEntity
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        eproject: state.eprojects.eproject
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EprojectEntities);