import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//cicanord
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
//gj-strap
import { FullCenter, Tile, TileContainer, TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials';
import { Form, Panel, PanelButton } from '../../gj_modules/gj-strap/components/form';
//design
//actions
import { resetUsers, getUserById, updateUserPreferences } from '../../actions/user';

import { processSubmit } from '../../gj_modules/gj-strap/lib';
import { userFrequency } from '../../data/constants';

class Preferences extends Component {
    componentDidMount() {
        const { resetUsers, getUserById, auth } = this.props;
        resetUsers()
        getUserById(auth.user.id);
    }

    submitEdit(values) {
        const { user, updateUserPreferences } = this.props;
        if(user && !user.pendingUpdate){
            updateUserPreferences(processSubmit(values));
        }
    }

    render() {

        const { user } = this.props;

        return (
            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />

                {user.user && !user.pending ? (

                    <FullCenter>
                        <TileContainer>
                            <Tile size={"full"}>
                                <TileHeader
                                    border={true}
                                    title={"Préférences"}
                                >
                                </TileHeader>
                                <TileBody>
                                    <Form
                                        onSubmit={values => this.submitEdit(values)}
                                        initialValues={{
                                            emailReminderFrequency: user.user.info.Utilisateurs_emailReminderFrequency,
                                        }}
                                    >

                                        <Panel
                                            width={[3, 9]}
                                            inputs={[
                                                {
                                                    short: "emailReminderFrequency", name: "Fréquence d'envoi des emails", type: "select", placeholder: "", data: {
                                                        isMulti: false,
                                                        options: userFrequency
                                                    }
                                                },
                                            ]}
                                        />

                                        <PanelButton
                                            pending={user.pendingUpdate}
                                            message={user.message}
                                        />


                                    </Form>
                                </TileBody>
                            </Tile>
                        </TileContainer>
                    </FullCenter>

                ) : (
                        <FullCenter>
                            Chargement
                        </FullCenter>
                    )}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetUsers,
            getUserById,
            updateUserPreferences
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
        auth: state.auth
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Preferences);
