//react
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
//gj-strap
import MenuAside from '../../gj_modules/gj-strap/components/partials/MenuAside';
//actions
import { logoutUser } from '../../actions/auth';
//data
import { navLinks } from '../../data/menu';
import { findRoleByValue } from '../../lib';

class MenuLeft extends Component {

    render(){
        const { menu, currentUser } = this.props;
        
        return (
            <MenuAside
                navlinks={navLinks[menu]}
                rank={findRoleByValue(currentUser.role).rank}
            />
        );
    }
}

/*function MenuLeft(props) {
    const { currentUser } = this.props;
    return (
        <MenuAside
            navlinks={navLinks[props.menu]}
        />
    );
}*/

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logoutUser
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        currentUser: state.auth.user
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuLeft);