//react
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactDOM from 'react-dom';
//npm
import moment from 'moment'
//gj-strap
import { ComplexAllMini } from '../../gj_modules/gj-strap/components/complex'
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
import { Panel } from '../../gj_modules/gj-strap/components/form';
import { returnFunction, processSubmit, /*checkNull,*/ toolTip, textSlice } from '../../gj_modules/gj-strap/lib';
//actions
import { resetActions, getActionsAll, addAction, deleteAction, updateAction, setStateAction } from '../../actions/actions';
import { getEntitiesAll } from '../../actions/entities';
import { getUsersAll } from '../../actions/user';
import { getContactsAll } from '../../actions/contacts';
//constants
import { /*actionType,*/ reminderType } from '../../data/constants';
//current
import { required, validateDate } from '../../lib/validate';
import { actionsFormat, actionsUpdatePanel } from '../../lib';
//design
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

class BusinessActions extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

	EventDetail = ({ event, el }) => {
        const content = (
            <React.Fragment>
                <div className="float-left">{event.extendedProps.actionFormat.users}</div>

                {
                    toolTip(
                        textSlice(event.extendedProps.actionFormat.original.description, 15),
                        event.extendedProps.actionFormat.original.description,
                        { placement: "top", trigger: "hover" }
                    )
                }
                
            </React.Fragment>
        )
        //console.log(event.extendedProps.actionFormat.date)
        ReactDOM.render(content, el);
        return el;
    };

    render() {

        const { actions, business, getEntitiesAll, getUsersAll, getContactsAll, addAction, deleteAction, updateAction, setStateAction } = this.props;

        const properties = [ // DataTable Headers
            { short: "date", name: "Date", width: "20%", category: "classic" },
            { short: "name", width: "25%", category: "classic" },
            { short: "state", width: "5%", category: "classic" },
            { short: "description", width: "30%", category: "classic" },
            { short: "actions", width: "20%", category: "actions" }
        ];

        const filterSort = { type: "hidden", name: "sortBusinessId", value:""+business.info.Affaires_ID+"" }

        const localGetActionsAll = this.props.getActionsAll;

        const filters = [
            { type: "search", size: 75, name: "searchActionsByDescription", placeholder: "Recherche par description" },
            filterSort,
            {
                type: "button",
                size: 25,
                name: "",
                title: "Ajouter",
                icon: faPlusCircle,
                fn: () => {
                    let cacheUserIds = [];

                    if (!actions.pendingAdd) {
                        modalFunction({
                            submit: (values) => {
                                if (!actions.pendingAdd) {
                                    //console.log(values)
                                    addAction("business", business.info.Affaires_ID, processSubmit(values)).then(
                                        function(response){
                                            localGetActionsAll({ skip:0, limit:20, filter: { sortBusinessId:business.info.Affaires_ID } });
                                        }
                                        //Reload page
                                    );
                                }
                            },
                            text: (
                                <Panel
                                    width={[3, 9]}
                                    inputs={[
                                        {
                                            short: "date", name: "Date", type: "datepicker", placeholder: "", validate: validateDate(), data: {
                                                anytime: true,
                                                alldays: true
                                            }
                                        },
                                        { short: "hours", name: "Heure", type: "selecthour", data:{
                                            minTime:'00:00',
                                            maxTime:'24:00',
                                            step:30
                                        } },
                                        { short: "description", name: "Description", type: "textarea", placeholder: "Action", validate: required() },
                                        {
                                            short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                                isMulti: false,
                                                isAsync: true,
                                                //defaultValue: { value:null, label:"", force:true },
                                                loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 5, "entities.entities", "Entites_ID", "Entites_Nom")
                                            }
                                        },
                                        {
                                            short: "contactId", name: "Contact", type: "select", placeholder: "Rechercher", data: {
                                                isMulti: false,
                                                isAsync: true,
                                                //defaultValue: { value:null, label:"", force:true },
                                                loadOptions: returnFunction(getContactsAll, "searchContactsByName", 5, "contacts.contacts", "Personnes_ID", ["Personnes_Prenom", "Personnes_Nom"])
                                            }
                                        },
                                        /*{
                                            short: "type", name: "Type", type: "select", placeholder: "Type", data: {
                                                isMulti: false,
                                                options: actionType
                                            }
                                        },*/
                                        {
                                            short: "reminderType", name: "Type de rappel", type: "select", placeholder: "Type de rappel", validate: required(), data: {
                                                isMulti: false,
                                                options: reminderType
                                            }
                                        },
                                        {
                                            short: "userIds", name: "Utilisateurs", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                                isMulti: true,
                                                isAsync: true,
                                                forceLabel: true,
                                                cache: cacheUserIds,
                                                //defaultValue: { value:null, label:"", force:true },
                                                loadOptions: returnFunction(getUsersAll, "searchUsersByName", 5, "user.users", "Utilisateurs_ID", ["Utilisateurs_Prenom", "Utilisateurs_Nom"])
                                            }
                                        }
                                    ]}
                                />
                            ),
                            header: "Ajouter une action",
                            state: "actions.pendingAdd"
                        }, "popup")
                    }
                }
            }
        ]

        let array = [];

        array = actionsFormat(actions.actions, setStateAction)

        return (
            <React.Fragment>
                <ComplexAllMini
					mode={["list", "calendar"]}
                    getElementsAll={getActionsAll}
                    resetElements={resetActions}
                    properties={properties}
                    filters={filters}
                    collection={array}
                    elements={actions.actions}
                    noDataMessage={"Pas d'actions"}
                    pending={actions.pending}
                    deleteAction={(e) => {
                        modalFunction({
                            btnFunction: () => {
                                //deleteBusinesses(e)
                                deleteAction(e);
                            },
                            btnText: "Confirmer",
                            text: "Êtes-vous sûr ?",
                            header: "Supprimer une action",
                            state: "actions.pendingDelete"
                        }, "popup")
                    }}
                    editAction={(id) => {
                        actionsUpdatePanel(
                            id,
                            this.props.actions.actions,
                            actions.pendingUpdate,
                            "actions.pendingUpdate",
                            updateAction,
                            getEntitiesAll,
                            getContactsAll,
                            getUsersAll
                        )
                    }}
					calendarEventFunction={(a) => {
						let temp = {
							title:a.original.description,
							date:moment(a.original.dateTarget).format("YYYY-MM-DD"),
							actionFormat: a
						}
						return temp
					}}
					calendarEventRenderFunction={this.EventDetail}
                />
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getEntitiesAll,
            getUsersAll,
            getContactsAll,
            getActionsAll,
            addAction,
            deleteAction,
            updateAction,
            setStateAction
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        actions: state.actions
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessActions);