import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import HeaderTop from '../partials/HeaderTop'
import FooterCicanord from '../partials/FooterCicanord'

import { FullCenter } from '../../gj_modules/gj-strap/components/partials/'
import { MenuLink } from '../../gj_modules/gj-strap/components/display/'

import { CardDeck } from 'react-bootstrap'
import { faDatabase, faChartBar, faNetworkWired } from '@fortawesome/free-solid-svg-icons'

class Home extends Component {
    componentDidMount() {
        //const { getUserSelf } = this.props;
        //getUserSelf();
    }

    render() {
        //const { user } = this.props;
        return (
            <React.Fragment>
                <HeaderTop menu={"home"} />
                <FullCenter>
                    <CardDeck className="my-5">
                        <MenuLink title="SIC" icon={faDatabase} notifications={"3"} path="/profile" />
                        <MenuLink title="PYTHAGORE" notifications={"5"} icon={faChartBar} />
                        <MenuLink title="INTRANET" notifications={"2"} icon={faNetworkWired} />
                    </CardDeck>
                </FullCenter>
                <FooterCicanord/>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            //getUserSelf
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        //user: state.user
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
