import { getData, postData, putData, deleteData } from './index';
import {
    BUSINESSESPHASES_ALL,
    BUSINESSESPHASES_ALL_PENDING,
    BUSINESSESPHASES_ADD,
    BUSINESSESPHASES_ADD_PENDING,
    BUSINESSESPHASES_UPDATE,
    BUSINESSESPHASES_UPDATE_PENDING,
    BUSINESSESPHASES_DELETE,
    BUSINESSESPHASES_DELETE_PENDING,
    BUSINESSESPHASES_RESET,
    BUSINESSESPHASES_ERROR
} from './types';

import { dataToUrl } from '../lib'

export function resetBusinessesPhases() {
	return dispatch => dispatch({ type: BUSINESSESPHASES_RESET });
}

export function getBusinessesPhasesAll(data) {
    const url = '/businessesphases/all'+dataToUrl(data);
    return dispatch => getData(BUSINESSESPHASES_ALL, BUSINESSESPHASES_ALL_PENDING, BUSINESSESPHASES_ERROR, true, url, dispatch, data);
}

export function addBusinessesPhases(id, data) {
    const url = '/businessesphases/add/'+id;
    return dispatch => postData(BUSINESSESPHASES_ADD, BUSINESSESPHASES_ADD_PENDING, BUSINESSESPHASES_ERROR, true, url, dispatch, data);
}

export function updateBusinessesPhasesById(id, data) {
    const url = '/businessesphases/update/'+id;
    return dispatch => putData(BUSINESSESPHASES_UPDATE, BUSINESSESPHASES_UPDATE_PENDING, BUSINESSESPHASES_ERROR, true, url, dispatch, data);
}

export function deleteBusinessesPhases(id) {
    const url = '/businessesphases/delete/'+id;
    return dispatch => deleteData(BUSINESSESPHASES_DELETE, BUSINESSESPHASES_DELETE_PENDING, BUSINESSESPHASES_ERROR, true, url, dispatch, null);
}