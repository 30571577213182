import { getData, postData, deleteData } from './index';
import {
    EPROJECTS_ALL,
    EPROJECTS_ALL_PENDING,
    EPROJECTS_ID,
    EPROJECTS_ID_PENDING,
    EPROJECTS_ADD,
    EPROJECTS_ADD_PENDING,
    EPROJECTS_UPDATE,
    EPROJECTS_UPDATE_PENDING,
    EPROJECTS_DELETE,
    EPROJECTS_DELETE_PENDING,
    EPROJECTS_ENTITIES_ADD,
    EPROJECTS_ENTITIES_ADD_PENDING,
    EPROJECTS_ENTITIES_DELETE,
    EPROJECTS_ENTITIES_DELETE_PENDING,
    EPROJECTS_CONTACT_ADD,
    EPROJECTS_CONTACT_ADD_PENDING,
    EPROJECTS_CONTACT_DELETE,
    EPROJECTS_CONTACT_DELETE_PENDING,
    EPROJECTS_RESET,
    EPROJECTS_ERROR
} from './types';

export function getEprojectsAll(data) {
    const url = '/eprojects/all';
    return dispatch => postData(EPROJECTS_ALL, EPROJECTS_ALL_PENDING, EPROJECTS_ERROR, true, url, dispatch, data);
}

export function resetEprojects() {
	return dispatch => dispatch({ type: EPROJECTS_RESET });
}


//Eproject

export function getEprojectById(id) {
    const url = '/eprojects/'+id;
    return dispatch => getData(EPROJECTS_ID, EPROJECTS_ID_PENDING, EPROJECTS_ERROR, true, url, dispatch);
}

export function addEproject(data) {
    const url = '/eprojects/add';
    return dispatch => postData(EPROJECTS_ADD, EPROJECTS_ADD_PENDING, EPROJECTS_ERROR, true, url, dispatch, data);
}

export function updateEprojectById(id, data) {
    const url = '/eprojects/update/'+id;
    return dispatch => postData(EPROJECTS_UPDATE, EPROJECTS_UPDATE_PENDING, EPROJECTS_ERROR, true, url, dispatch, data);
}

export function deleteEproject(id) {
    const url = '/eprojects/delete/'+id;
    return dispatch => deleteData(EPROJECTS_DELETE, EPROJECTS_DELETE_PENDING, EPROJECTS_ERROR, true, url, dispatch, null);
}


//Entity Eproject

export function addEprojectEntity(id, data) {
    const url = '/eprojects/addentity/'+id;
    return dispatch => postData(EPROJECTS_ENTITIES_ADD, EPROJECTS_ENTITIES_ADD_PENDING, EPROJECTS_ERROR, true, url, dispatch, data);
}

export function deleteEprojectEntity(id) {
    const url = '/eprojects/deleteentity/'+id;
    return dispatch => deleteData(EPROJECTS_ENTITIES_DELETE, EPROJECTS_ENTITIES_DELETE_PENDING, EPROJECTS_ERROR, true, url, dispatch, null);
}

//Contact Eproject

export function addEprojectContact(id, data) {
    const url = '/eprojects/addcontact/'+id;
    return dispatch => postData(EPROJECTS_CONTACT_ADD, EPROJECTS_CONTACT_ADD_PENDING, EPROJECTS_ERROR, true, url, dispatch, data);
}

export function deleteEprojectContact(id) {
    const url = '/eprojects/deletecontact/'+id;
    return dispatch => deleteData(EPROJECTS_CONTACT_DELETE, EPROJECTS_CONTACT_DELETE_PENDING, EPROJECTS_ERROR, true, url, dispatch, null);
}