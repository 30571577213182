import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactDOM from 'react-dom';
//npm
import moment from 'moment'
//gj-strap
import { ComplexAllMini } from '../../gj_modules/gj-strap/components/complex'
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
//import { Panel } from 'gj_modules/gj-strap/components/form';
import { /*returnFunction, processSubmit,  checkNull,*/ toolTip, textSlice } from '../../gj_modules/gj-strap/lib';
//actions
import { resetActions, getActionsAll, addAction, deleteAction, updateAction, setStateAction } from '../../actions/actions';
import { getEntitiesAll } from '../../actions/entities';
import { getUsersAll } from '../../actions/user';
import { getContactsAll } from '../../actions/contacts';
//constants
import { actionStatus } from '../../data/constants';
//current
//import { required, validateDate } from 'lib/validate';
import { actionsFormat, actionsUpdatePanel } from '../../lib';
//design
//import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

class UserActions extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    EventDetail = ({ event, el }) => {
        const content = (
            <React.Fragment>
                <div className="float-left">{event.extendedProps.actionFormat.users}</div>

                {
                    toolTip(
                        textSlice(event.extendedProps.actionFormat.original.description, 15),
                        event.extendedProps.actionFormat.original.description,
                        { placement: "top", trigger: "hover" }
                    )
                }
                
            </React.Fragment>
        )
        //console.log(event.extendedProps.actionFormat.date)
        ReactDOM.render(content, el);
        return el;
    };

    render() {

        const { actions, user, getEntitiesAll, getUsersAll, getContactsAll, deleteAction, updateAction, setStateAction } = this.props;

        const properties = [ // DataTable Headers
            { short: "date", name: "Date", width: "15%", category: "classic" },
            { short: "subnameformat", name: "Code", width: "10%", category: "classic" },
            { short: "name", width: "25%", category: "classic" },
            { short: "state", width: "5%", category: "classic" },
            { short: "description", width: "30%", category: "classic" },
            { short: "actions", width: "15%", category: "actions" }
        ];

        const filterSort = { type: "hidden", name: "sortUserId", value:""+user.info.Utilisateurs_ID+"" }

        //const localGetActionsAll = this.props.getActionsAll;

        const filters = [
            { type: "search", size: 50, name: "searchActionsByDescription", placeholder: "Recherche par description" },
            { type:"select", size:50, name:"searchActionsByStatus", placeholder:"Trier", options:actionStatus },
            filterSort
        ]

        let array = [];

        array = actionsFormat(actions.actions, setStateAction)

        return (
            <React.Fragment>
                <ComplexAllMini
                    mode={["list", "calendar"]}
                    getElementsAll={getActionsAll}
                    resetElements={resetActions}
                    properties={properties}
                    filters={filters}
                    collection={array}
                    elements={actions.actions}
                    noDataMessage={"Pas d'actions"}
                    pending={actions.pending}
                    deleteAction={(e) => {
                        modalFunction({
                            btnFunction: () => {
                                //deleteBusinesses(e)
                                deleteAction(e);
                            },
                            btnText: "Confirmer",
                            text: "Êtes-vous sûr ?",
                            header: "Supprimer une action",
                            state: "actions.pendingDelete"
                        }, "popup")
                    }}
                    editAction={(id) => {
                        actionsUpdatePanel(
                            id,
                            this.props.actions.actions,
                            actions.pendingUpdate,
                            "actions.pendingUpdate",
                            updateAction,
                            getEntitiesAll,
                            getContactsAll,
                            getUsersAll
                        )
                    }}
                    calendarEventFunction={(a) => {
						let temp = {
							title:a.original.description,
							date:moment(a.original.dateTarget).format("YYYY-MM-DD"),
							actionFormat: a
						}
						return temp
					}}
					calendarEventRenderFunction={this.EventDetail}
                />
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getEntitiesAll,
            getUsersAll,
            getContactsAll,
            getActionsAll,
            addAction,
            deleteAction,
            updateAction,
            setStateAction
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        actions: state.actions
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserActions);