// Auth Actions
export const AUTH_USER = 'auth_user',
    UNAUTH_USER = 'unauth_user',
    AUTH_ERROR = 'auth_error',
    AUTH_CLEAR = 'auth_clear',
    AUTH_PENDING = 'auth_pending',
    FORGOT_PASSWORD_REQUEST = 'forgot_password_request',
    RESET_PASSWORD_REQUEST = 'reset_password_request',
    PROTECTED_TEST = 'protected_test';

// User Actions
export const UPDATE_NOTIFICATION = 'update_notification',
    GET_USER_SELF = 'get_user_self',
    GET_USER = 'get_user',
    EXISTING_USER = 'existing_user',
    ADD_USER = 'add_user',
    RESET_USER = 'reset_user',
    USER_ERROR = 'user_error',
    SET_PREFERENCE = 'set_preference',
    EDIT_USER_REQUEST = 'edit_user_request',
    EDIT_USER_SUCCESS = 'edit_user_success',
    EDIT_USER_ERROR = 'edit_user_error',
    USERS_ALL = 'users_all',
    USERS_ALL_PENDING = 'users_all_pending',
    USERS_ADD = 'users_add',
    USERS_ADD_PENDING = 'users_add_pending',
    USERS_UPDATE = 'users_update',
    USERS_UPDATE_PENDING = 'users_update_pending',
    USERS_DELETE = 'users_delete',
    USERS_DELETE_PENDING = "users_delete_pending",
    USERS_UPDATE_PASSWORD = 'users_update_password',
    USERS_UPDATE_PASSWORD_PENDING = 'users_update_password_pending',
    USERS_RESET = 'users_reset';

// Search Actions
export const SEARCH_ALL = 'search_all',
    SEARCH_ALL_PENDING = 'search_all_pending',
    SEARCH_RESET = 'search_reset',
    SEARCH_ERROR = 'search_error';

// Request Actions
export const REQUEST_ALL = 'request_all',
    REQUEST_ALL_PENDING = 'request_all_pending',
    REQUEST_RESET = 'request_reset',
    REQUEST_ERROR = 'request_error';

// Contacts Actions
export const CONTACTS_ALL = 'contacts_all',
    CONTACTS_ALL_PENDING = 'contacts_all_pending',
    CONTACTS_ADD = 'contacts_add',
    CONTACTS_ADD_PENDING = 'contacts_add_pending',
    CONTACTS_UPDATE = 'contacts_update',
    CONTACTS_UPDATE_PENDING = 'contacts_update_pending',
    CONTACTS_DELETE = 'contacts_delete',
    CONTACTS_DELETE_PENDING = 'contacts_delete_pending',
    CONTACTS_ID = 'contacts_id',
    CONTACTS_ID_PENDING = 'contacts_id_pending',
    CONTACT_ENTITIES_ADD = 'contacts_entities_add',
    CONTACT_ENTITIES_ADD_PENDING = 'contacts_entities_add_pending',
    CONTACT_ENTITIES_UPDATE = 'contacts_entities_update',
    CONTACT_ENTITIES_UPDATE_PENDING = 'contacts_entities_update_pending',
    CONTACT_ENTITIES_DELETE = 'contacts_entities_delete',
    CONTACT_ENTITIES_DELETE_PENDING = 'contacts_entities_delete_pending',
    CONTACT_HISTO_ADD = 'contacts_histo_add',
    CONTACT_HISTO_ADD_PENDING = 'contacts_histo_add_pending',
    CONTACT_HISTO_UPDATE = 'contacts_histo_update',
    CONTACT_HISTO_UPDATE_PENDING = 'contacts_histo_update_pending',
    CONTACT_HISTO_DELETE = 'contacts_histo_delete',
    CONTACT_HISTO_DELETE_PENDING = 'contacts_histo_delete_pending',
    CONTACT_VIP_ADD = 'contacts_vip_add',
    CONTACT_VIP_ADD_PENDING = 'contacts_vip_add_pending',
    CONTACT_VIP_DELETE = 'contacts_vip_delete',
    CONTACT_VIP_DELETE_PENDING = 'contacts_vip_delete_pending',
    CONTACTS_VIP_UPDATE = 'contacts_vip_update',
    CONTACTS_VIP_UPDATE_PENDING = 'contacts_vip_update_pending',
    CONTACTS_RESET = 'contacts_reset',
    CONTACTS_ERROR = 'contacts_error';

// Employees Actions
export const EMPLOYEES_ALL = 'employees_all',
    EMPLOYEES_ALL_PENDING = 'employees_all_pending',
    EMPLOYEES_ERROR = 'employees_error',
    EMPLOYEES_RESET = 'employees_reset',
    EMPLOYEES_ADD = 'employees_add',
    EMPLOYEES_ADD_PENDING = 'employees_add_pending',
    EMPLOYEES_DELETE = 'employees_delete',
    EMPLOYEES_DELETE_PENDING = 'employees_delete_pending';

// Businesses Actions
export const BUSINESSES_ALL = 'businesses_all',
    BUSINESSES_ALL_PENDING = 'businesses_all_pending',
    BUSINESSES_ID = 'businesses_id',
    BUSINESSES_ID_PENDING = 'businesses_id_pending',
    BUSINESSES_ADD = 'businesses_add',
    BUSINESSES_ADD_PENDING = 'businesses_add_pending',
    BUSINESSES_UPDATE = 'businesses_update',
    BUSINESSES_UPDATE_PENDING = 'businesses_update_pending',
    BUSINESSES_DELETE = 'businesses_delete',
    BUSINESSES_DELETE_PENDING = 'businesses_delete_pending',
    BUSINESSES_ACTIONS_ADD = 'businesses_actions_add',
    BUSINESSES_ACTIONS_ADD_PENDING = 'businesses_actions_add_pending',
    BUSINESSES_ACTIONS_UPDATE = 'businesses_actions_update',
    BUSINESSES_ACTIONS_UPDATE_PENDING = 'businesses_actions_update_pending',
    BUSINESSES_ACTIONS_STATE_UPDATE = 'businesses_actions_state_update',
    BUSINESSES_ACTIONS_STATE_UPDATE_PENDING = 'businesses_actions_state_update_pending',
    BUSINESSES_ACTIONS_DELETE = 'businesses_actions_delete',
    BUSINESSES_ACTIONS_DELETE_PENDING = 'businesses_actions_delete_pending',
    BUSINESSES_DIRECTORIES_ADD = 'businesses_directories_add',
    BUSINESSES_DIRECTORIES_ADD_PENDING = 'businesses_directories_add_pending',
    BUSINESSES_DIRECTORIES_UPDATE = 'businesses_directories_update',
    BUSINESSES_DIRECTORIES_UPDATE_PENDING = 'businesses_directories_update_pending',
    BUSINESSES_DIRECTORIES_DELETE = 'businesses_directories_delete',
    BUSINESSES_DIRECTORIES_DELETE_PENDING = 'businesses_directories_delete_pending',
    BUSINESSES_DIRECTORIES_ENTITIES_ADD = 'businesses_directories_entities_add',
    BUSINESSES_DIRECTORIES_ENTITIES_ADD_PENDING = 'businesses_directories_entities_add_pending',
    BUSINESSES_DIRECTORIES_ENTITIES_DELETE = 'businesses_directories_entities_delete',
    BUSINESSES_DIRECTORIES_ENTITIES_DELETE_PENDING = 'businesses_directories_entities_delete_pending',
    BUSINESSES_DIRECTORIES_ENTITIES_UPDATE = 'businesses_directories_entities_update',
    BUSINESSES_DIRECTORIES_ENTITIES_UPDATE_PENDING = 'businesses_directories_entities_update_pending',
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_ADD = 'businesses_directories_entities_contacts_add',
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_ADD_PENDING = 'businesses_directories_entities_contacts_add_pending',
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_UPDATE = 'businesses_directories_entities_contacts_update',
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_UPDATE_PENDING = 'businesses_directories_entities_contacts_update_pending',
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_DELETE = 'businesses_directories_entities_contacts_delete',
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_DELETE_PENDING = 'businesses_directories_entities_contacts_delete_pending',
    BUSINESSES_REFERENCES_ALL = 'businesses_references_all',
    BUSINESSES_REFERENCES_ALL_PENDING = 'businesses_references_all_pending',
    BUSINESSES_REFERENCES_RESET = 'businesses_references_reset',
    BUSINESSES_RESET = 'businesses_reset',
    BUSINESSES_ERROR = 'businesses_error';

// BusinessesPhases Actions
export const BUSINESSESPHASES_ALL = 'businessesphases_all',
    BUSINESSESPHASES_ALL_PENDING = 'businessesphases_all_pending',
    BUSINESSESPHASES_ADD = 'businessesphases_add',
    BUSINESSESPHASES_ADD_PENDING = 'businessesphases_add_pending',
    BUSINESSESPHASES_UPDATE = 'businessesphases_update',
    BUSINESSESPHASES_UPDATE_PENDING = 'businessesphases_update_pending',
    BUSINESSESPHASES_DELETE = 'businessesphases_delete',
    BUSINESSESPHASES_DELETE_PENDING = 'businessesphases_delete_pending',
    BUSINESSESPHASES_RESET = 'businessesphases_reset',
    BUSINESSESPHASES_ERROR = 'businessesphases_error';

// Entities Actions
export const ENTITIES_ALL = 'entities_all',
    ENTITIES_ALL_PENDING = 'entities_all_pending',
    ENTITIES_ID = 'entities_id',
    ENTITIES_ID_PENDING = 'entities_id_pending',
    ENTITIES_ADD = 'entities_add',
    ENTITIES_ADD_PENDING = 'entities_add_pending',
    ENTITIES_UPDATE = 'entities_update',
    ENTITIES_UPDATE_PENDING = 'entities_update_pending',
    ENTITIES_DELETE = 'entities_delete',
    ENTITIES_DELETE_PENDING = 'entities_delete_pending',
    ENTITIES_CONTACT_ADD = 'entities_contact_add',
    ENTITIES_CONTACT_ADD_PENDING = 'entities_contact_add_pending',
    ENTITIES_CONTACT_UPDATE = 'entities_contact_update',
    ENTITIES_CONTACT_UPDATE_PENDING = 'entities_contact_update_pending',
    ENTITIES_CONTACT_DELETE = 'entities_contact_delete',
    ENTITIES_CONTACT_DELETE_PENDING = 'entities_contact_delete_pending',
	ENTITIES_ACTIVITY_ADD = 'entities_activity_add',
	ENTITIES_ACTIVITY_ADD_PENDING = 'entities_activity_add_pending',
	ENTITIES_ACTIVITY_DELETE = 'entities_activity_delete',
	ENTITIES_ACTIVITY_DELETE_PENDING = 'entities_activity_delete_pending',
    ENTITIES_RESET = 'entities_reset',
    ENTITIES_ERROR = 'entities_error';

// Projects Actions
export const PROJECTS_ALL = 'projects_all',
    PROJECTS_ALL_PENDING = 'projects_all_pending',
    PROJECTS_ID = 'projects_id',
    PROJECTS_ID_PENDING = 'projects_id_pending',
    PROJECTS_ADD = 'projects_add',
    PROJECTS_ADD_PENDING = 'projects_add_pending',
    PROJECTS_UPDATE = 'projects_update',
    PROJECTS_UPDATE_PENDING = 'projects_update_pending',
    PROJECTS_DELETE = 'projects_delete',
    PROJECTS_DELETE_PENDING = 'projects_delete_pending',
    PROJECTS_ENTITIES_ADD = 'projects_entities_add',
    PROJECTS_ENTITIES_ADD_PENDING = 'projects_entities_add_pending',
    PROJECTS_ENTITIES_UPDATE = 'projects_entities_update',
    PROJECTS_ENTITIES_UPDATE_PENDING = 'projects_entities_update_pending',
    PROJECTS_ENTITIES_DELETE = 'projects_entities_delete',
    PROJECTS_ENTITIES_DELETE_PENDING = 'projects_entities_delete_pending',
    PROJECTS_ACTIONS_ADD = 'projects_actions_add',
    PROJECTS_ACTIONS_ADD_PENDING = 'projects_actions_add_pending',
    PROJECTS_ACTIONS_UPDATE = 'projects_actions_update',
    PROJECTS_ACTIONS_UPDATE_PENDING = 'projects_actions_update_pending',
    PROJECTS_ACTIONS_STATE_UPDATE = 'projects_actions_state_update',
    PROJECTS_ACTIONS_STATE_UPDATE_PENDING = 'projects_actions_state_update_pending',
    PROJECTS_ACTIONS_DELETE = 'projects_actions_delete',
    PROJECTS_ACTIONS_DELETE_PENDING = 'projects_actions_delete_pending',
    PROJECTS_INFORMATIONS_ADD = 'projects_informations_add',
    PROJECTS_INFORMATIONS_ADD_PENDING = 'projects_informations_add_pending',
    PROJECTS_INFORMATIONS_UPDATE = 'projects_informations_update',
    PROJECTS_INFORMATIONS_UPDATE_PENDING = 'projects_informations_update_pending',
    PROJECTS_INFORMATIONS_DELETE = 'projects_informations_delete',
    PROJECTS_INFORMATIONS_DELETE_PENDING = 'projects_informations_delete_pending',
    PROJECTS_RESET = 'projects_reset',
    PROJECTS_ERROR = 'projects_error';

// Eprojects Actions
export const EPROJECTS_RESET = 'eprojects_reset',
    EPROJECTS_ALL = 'eprojects_all',
    EPROJECTS_ALL_PENDING = 'eprojects_all_pending',
    EPROJECTS_ID = 'eprojects_id',
    EPROJECTS_ID_PENDING = 'eprojects_id_pending',
    EPROJECTS_ADD = 'eprojects_add',
    EPROJECTS_ADD_PENDING = 'eprojects_add_pending',
    EPROJECTS_UPDATE = 'eprojects_update',
    EPROJECTS_UPDATE_PENDING = 'eprojects_update_pending',
    EPROJECTS_DELETE = 'eprojects_delete',
    EPROJECTS_DELETE_PENDING = 'eprojects_delete_pending',
    EPROJECTS_ENTITIES_ADD = 'eprojects_entities_add',
    EPROJECTS_ENTITIES_ADD_PENDING = 'eprojects_entities_add_pending',
    EPROJECTS_ENTITIES_DELETE = 'eprojects_entities_delete',
    EPROJECTS_ENTITIES_DELETE_PENDING = 'eprojects_entities_delete_pending',
    EPROJECTS_CONTACT_ADD = 'eprojects_contact_add',
    EPROJECTS_CONTACT_ADD_PENDING = 'eprojects_contact_add_pending',
    EPROJECTS_CONTACT_DELETE = 'eprojects_contact_delete',
    EPROJECTS_CONTACT_DELETE_PENDING = 'eprojects_contact_delete_pending',
    EPROJECTS_ERROR = 'eprojects_error';


// Actions
export const ACTIONS_ERROR = 'actions_error',
    ACTIONS_RESET = 'actions_reset',
    ACTIONS_ALL = 'actions_all',
    ACTIONS_ALL_PENDING = 'actions_all_pending',
    ACTIONS_ADD = 'actions_add',
    ACTIONS_ADD_PENDING = 'actions_add_pending',
    ACTIONS_UPDATE = 'actions_update',
    ACTIONS_UPDATE_PENDING = 'actions_update_pending',
    ACTIONS_DELETE = 'actions_delete',
    ACTIONS_DELETE_PENDING = 'actions_delete_pending',
    ACTIONS_UPDATE_STATE = 'actions_update_state',
    ACTIONS_UPDATE_STATE_PENDING = 'actions_update_state_pending',
    ACTIONS_ADD_DEFAULT = 'actions_add_default',
    ACTIONS_ADD_DEFAULT_PENDING = 'actions_add_default_pending'

// Activities
export const ACTIVITIES_ERROR = 'activities_error',
    ACTIVITIES_RESET = 'activities_reset',
    ACTIVITIES_ALL = 'activities_all',
    ACTIVITIES_ALL_PENDING = 'activities_all_pending',
    ACTIVITIES_ADD = 'activities_add',
    ACTIVITIES_ADD_PENDING = 'activities_add_pending',
    ACTIVITIES_UPDATE = 'activities_update',
    ACTIVITIES_UPDATE_PENDING = 'activities_update_pending',
    ACTIVITIES_DELETE = 'activities_delete',
    ACTIVITIES_DELETE_PENDING = 'activities_delete_pending';

// Sectors
export const SECTORS_ERROR = 'sectors_error',
    SECTORS_RESET = 'sectors_reset',
    SECTORS_ALL = 'sectors_all',
    SECTORS_ALL_PENDING = 'sectors_all_pending',
    SECTORS_ADD = 'sectors_add',
    SECTORS_ADD_PENDING = 'sectors_add_pending',
    SECTORS_UPDATE = 'sectors_update',
    SECTORS_UPDATE_PENDING = 'sectors_update_pending',
    SECTORS_DELETE = 'sectors_delete',
    SECTORS_DELETE_PENDING = 'sectors_delete_pending';

// Notification Actions
export const FETCH_NOTIFICATION = 'fetch_notification',
    VIEWED_NOTIFICATION = 'viewed_notification',
    GET_SOME_NOTIFICATION = 'get_some_notification',
    NOTIFICATION_ERROR = 'notification_error';
