import {
    SECTORS_ALL,
    SECTORS_ALL_PENDING,
    SECTORS_ADD,
    SECTORS_ADD_PENDING,
    SECTORS_UPDATE,
    SECTORS_UPDATE_PENDING,
    SECTORS_DELETE,
    SECTORS_DELETE_PENDING,
    SECTORS_ERROR,
    SECTORS_RESET
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    sectors: null,
    sector: null,
    message: null,
    pending: false,
    pendingAdd: false,
    pendingDelete: false,
    pendingUpdate: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SECTORS_ALL_PENDING:
            return {
                ...state,
                pending: true
            }
        case SECTORS_ALL:
            let sectors = action.payload.sectors;
            sectors['count'] = action.payload.sectorsCount;
            return {
                ...state,
                sectors: sectors,
                error: '',
                message: action.payload.message,
                pending: false
            };
        case SECTORS_ADD_PENDING:
            return {
                ...state,
                pendingAdd: true
            }
        case SECTORS_ADD:
            return {
                ...state,
                error: '',
                message: action.payload.message,
                pendingAdd: false
            };
        case SECTORS_UPDATE_PENDING:
            return {
                ...state,
                pendingUpdate: true
            }
        case SECTORS_UPDATE:
            sectors = state.sectors;
            for(let i=0; i<sectors.length; i++){
                if(sectors[i].Secteurs_ID === Number(action.payload.id)){
                    sectors[i] = Object.assign(sectors[i], action.payload.sector);
                }
            }
            return {
                ...state,
                sectors:sectors,
                error: '',
                message: action.payload.message,
                pendingUpdate: false
            };
        case SECTORS_DELETE_PENDING:
            return {
                ...state,
                pendingDelete: true
            }
        case SECTORS_DELETE:
            sectors = state.sectors;
            if(sectors !== null){
                sectors = state.sectors.filter((sector) => sector.Secteurs_ID !== Number(action.payload.id))
            }
            return {
                ...state,
                error: '',
                sectors:sectors,
                message: action.payload.message,
                pendingDelete: false
            };
        case SECTORS_RESET:
            return {
                ...state,
                sectors: null,
                sector: null,
                message: null,
                error: null,
                pending:false,
                pendingAdd:false,
                pendingDelete:false,
                pendingUpdate:false
            };
        case SECTORS_ERROR:
            return { ...state, error: action.payload.message };
        default:
            return state;
    }
}
