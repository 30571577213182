import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//gj-strap
import { modalFunction, TileContainer, Tile, TileBody } from '../../gj_modules/gj-strap/components/partials';
import { Panel } from '../../gj_modules/gj-strap/components/form';
import { returnFunction, processSubmit /*, checkNull, toolTip, textSlice*/ } from '../../gj_modules/gj-strap/lib';
//actions
import { addActionsDefault } from '../../actions/actions';
import { getUsersAll } from '../../actions/user';
//current
import { required } from '../../lib/validate';
import Rank from '../functions/Rank'
//design
import { Button } from 'react-bootstrap';

class BusinessActionsShortcut extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    render() {

        const { actions, business, getUsersAll, addActionsDefault } = this.props;

        return (
            <React.Fragment>
                {/* Delete */}
                <Rank need={"admin"}>
                    <TileContainer>
                        <Tile size={'full'}>
                            <TileBody>
                                <Button
                                    onClick={function () {

                                        let cacheUserIds = [];

                                        modalFunction({
                                            submit: (values) => {
                                                if (!actions.pendingDefault) {
                                                    console.log(processSubmit(values))

                                                    addActionsDefault(business.info.Affaires_ID, processSubmit(values));

                                                    /*addAction("business", business.info.Affaires_ID, processSubmit(values)).then(
                                                        function(response){
                                                            localGetActionsAll({ skip:0, limit:20, filter: { sortBusinessId:business.info.Affaires_ID } });
                                                        }
                                                        //Reload page
                                                    );*/
                                                }
                                            },
                                            text: (
                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        { short: "checkboxphotos", name: "Photos", type: "checkbox", placeholder: "" },
                                                        { short: "cvupdate", name: "Mise à jour CV", type: "checkbox", placeholder: "" },
                                                        { short: "presentationupdate", name: "Dossier présentation", type: "checkbox", placeholder: "" },
                                                        { short: "tablereferences", name: "Tableau des références", type: "checkbox", placeholder: "" },
                                                        { short: "website", name: "Site internet", type: "checkbox", placeholder: "" },
                                                        { short: "opqibi", name: "OPQIBI", type: "checkbox", placeholder: "" },
                                                        { short: "certifcapacity", name: "Certificat de capacité", type: "checkbox", placeholder: "" },
                                                        {
                                                            short: "userId", name: "Utilisateur", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                forceLabel: true,
                                                                cache: cacheUserIds,
                                                                //defaultValue: { value:null, label:"", force:true },
                                                                loadOptions: returnFunction(getUsersAll, "searchUsersByName", 5, "user.users", "Utilisateurs_ID", ["Utilisateurs_Prenom", "Utilisateurs_Nom"])
                                                            }
                                                        }
                                                    ]}
                                                />
                                            ),
                                            header: "Ajouter des actions par défaut",
                                            state: "actions.pendingDefault"
                                        }, "popup")
                                    }}
                                    className="float-sm-right"
                                >
                                    Ajouter des actions par défaut
                        </Button>
                            </TileBody>
                        </Tile>
                    </TileContainer>
                </Rank>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUsersAll,
            addActionsDefault
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        actions: state.actions
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessActionsShortcut);