import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//import { Link } from 'react-router-dom';

import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import FooterCicanord from '../partials/FooterCicanord';
import { FullCenter, TileContainer, Tile, TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials';
import history from '../../history';

import moment from 'moment';

import { Form, Panel, PanelButton } from '../../gj_modules/gj-strap/components/form';
//import DataTable from '../../gj_modules/gj-strap/components/form/DataTable';
import { Row, Col, Button } from 'react-bootstrap';
import { Cover } from '../../gj_modules/gj-strap/components/partials';
import { faBuilding } from '@fortawesome/free-solid-svg-icons'

import { getManualData, savePdf } from '../../actions/index';

import { resetEntities, getEntityById, updateEntityById, deleteEntity } from '../../actions/entities';
import { getActionsAll } from '../../actions/actions';

import { modalFunction } from '../../gj_modules/gj-strap/components/partials';

import { processSubmit } from '../../gj_modules/gj-strap/lib';

import EntityContacts from './EntityContacts';
import EntityActivities from './EntityActivities';
import EntityActions from './EntityActions';

import SubProjects from '../subpartials/SubProjects';

//design
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons'


export const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

class Entity extends Component {
    componentDidMount() {
        const { match, getEntityById, resetEntities } = this.props;
        const id = match && match.params && match.params.id;
        resetEntities();
        if (id) {
            getEntityById(match.params.id);
        }
    }

    submitEdit(values) {
        const { match, entity, updateEntityById } = this.props;
        const id = match && match.params && match.params.id;
        console.log(values);
        if (entity && !entity.pendingUpdate) {
            updateEntityById(id, processSubmit(values));
        }
    }

    render() {
        const { match, entity, deleteEntity, actions } = this.props;
        const id = match && match.params && match.params.id;

        //console.log("ENTITY", entity);

        console.log(actions)
        let portlet = {
            title: "Entites",
            path: "/sic/entities",
            links: [
                //{ name: "Recherche", path: "/sic/entities" },
                { name: "Fiche entité", path: "/sic/entity/" + id }
            ],
            actions: [
                {
                    title: "Télécharger la fiche",
                    icon: faDownload,
                    //variant:"success",
                    function: function () {
                        getManualData(true, '/entities/' + id + '/print').then(response => {
                            let pdf = atob(response.response.data.pdf);
                            savePdf(pdf, entity.info.pdfTitle + ".pdf");
                        });
                    }
                },
                {
                    title: "Imprimer",
                    icon: faPrint,
                    function: function () {
                        //history.push("/sic/project/"+id+"/print");
                        window.open("" + (REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "") + "/sic/entity/" + id + "/print", '_blank');
                    }
                }
            ]
        }

        if (entity && !entity.pending) {
            //portlet.links[0].name = "test"; //person.info.Personnes_Prenom+" "+person.info.Personnes_Nom;
        }

        return (
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />

                {entity && !entity.pending ? (

                    <FullCenter portlet={portlet}>
                        <TileContainer>
                            <Tile size={"full"}>
                                <TileBody>
                                    <Cover
                                        icon={faBuilding}
                                        title={entity.info.Entites_Nom}
                                        subtitle={(entity.info.Entites_CodePostal ? entity.info.Entites_CodePostal : "") + " " + (entity.info.Entites_Ville ? entity.info.Entites_Ville : "")}
                                    >
                                        Entité créée le {moment(entity.info.Entites_DateCreation).format("DD/MM/YYYY")}<br />
                                        Dernière modification le {moment(entity.info.Entites_DateModification).format("DD/MM/YYYY")}
                                    </Cover>
                                </TileBody>
                            </Tile>
                        </TileContainer>

                        <Row>
                            <Col lg="6" className="tile-col">
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileHeader title="Général" border={true} />
                                        <TileBody>

                                            <Form
                                                onSubmit={values => this.submitEdit(values)}
                                                initialValues={{
                                                    name: (entity.info.Entites_Nom) || "",
                                                    code: (entity.info.Entites_Code) || "",
                                                    adressCity: (entity.info.Entites_Ville) || "",
                                                    adressPostalCode: (entity.info.Entites_CodePostal) || "",
                                                    adressLines: (entity.info.Entites_Adresse) || "",
                                                    email: (entity.info.Entites_Email) || "",
                                                    postalBox: (entity.info.Entites_BoitePostal) || "",
                                                    phone: (entity.info.Entites_Telephone) || "",
                                                    fax: (entity.info.Entites_Fax) || "",
                                                    website: (entity.info.Entites_SiteWeb) || "",
                                                    comment: (entity.info.Entites_Commentaire) || ""
                                                    /*entityId:Number(project.info.entityId),
                                                    description: (project.info.description) || "",
                                                    planningPreviewStudies: (project.info.planningPreviewStudies) || "",
                                                    planningPreviewWork: (project.info.planningPreviewWork) || ""*/
                                                }}
                                            >

                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        { short: "name", name: "Nom", type: "text", placeholder: "Nom de l'entité" },
                                                        { short: "code", name: "Code", type: "text", placeholder: "Code de l'entité" },
                                                        { short: "adressCity", name: "Ville", type: "text", placeholder: "Ville" },
                                                        { short: "adressPostalCode", name: "Code postal", type: "text", placeholder: "00000" },
                                                        { short: "adressLines", name: "Adresse", type: "text", placeholder: "Rue" },
                                                        { short: "postalBox", name: "Boîte postale", type: "text", placeholder: "Boîte postale"},
                                                        { short: "email", name: "Email", type: "text", placeholder: "Email" },
                                                        { short: "phone", name: "Téléphone", type: "text", placeholder: "0000000000" },
                                                        { short: "fax", name: "Fax", type: "text", placeholder: "" },
                                                        { short: "website", name: "Site web", type: "text", placeholder: "http://" },
                                                        { short: "comment", name: "Commentaire", type: "textarea", placeholder: "...", data: { height: "90px" } }
                                                        /*{
                                                            short: "entityId", name: "MOA", type: "select", placeholder: "Test", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                defaultValue: { value:Number(project.info.entityId), label:project.info.entityLabel, force:true },
                                                                loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 5, "entities.entities")
                                                            }
                                                        },
                                                        { short: "description", name: "Description", type: "textarea", placeholder: "Description", data:{ height:"90px" } },
                                                        { short: "planningPreviewStudies", name: "Planning des études", type: "textarea", placeholder: "Planning prévisionnel des études", data:{ height:"90px" } },
                                                        { short: "planningPreviewWork", name: "Planning des travaux", type: "textarea", placeholder: "Planning prévisionnel des travaux", data:{ height:"90px" } }
                                                    */
                                                    ]}
                                                />

                                                <PanelButton
                                                    pending={entity.pendingUpdate}
                                                    message={entity.message}
                                                />

                                            </Form>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>

                                {/* Delete */}
                                <TileContainer>
                                    <Tile size={'full'}>
                                        <TileBody>
                                            <Button
                                                onClick={function () {
                                                    modalFunction({
                                                        btnFunction: () => {
                                                            deleteEntity(id).then(
                                                                function (response) {
                                                                    history.push("/sic/entities")
                                                                }
                                                            );
                                                        },
                                                        btnText: "Confirmer",
                                                        text: "Êtes-vous sûr de supprimer cette entité ?",
                                                        header: "Supprimer une entité",
                                                        state: "entities.pendingDelete"
                                                    }, "popup")
                                                }}
                                                className="float-sm-right"
                                                variant="danger"
                                            >
                                                Supprimer cette entité
                                            </Button>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>

                            </Col>

                            <Col lg="6" className="tile-col">
                                <EntityContacts
                                    contacts={entity.persons}
                                    idEntity={id}
                                />

                                <EntityActivities
                                    activities={entity.activities}
                                    idEntity={id}
                                />

                                {/* Projects */}
                                <SubProjects
                                    projects={entity.projects}
                                    idProject={id}
                                />

                                {/* Actions */}
                                {/*<EntityActions
                                    actions={entity.actions}
                                    idContact={id}
                                            />*/}
                            </Col>

                        </Row>

                    </FullCenter>
                ) : (
                    <FullCenter>
                        Chargement
                    </FullCenter>
                )}
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetEntities,
            getEntityById,
            updateEntityById,
            deleteEntity,
            getActionsAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        entity: state.entities.entity,
        actions: state.actions
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Entity);