import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export default function (ComposedComponent) {
    class Authentication extends Component {
        static contextTypes = {
            router: PropTypes.object
        };

        UNSAFE_componentWillMount() {
            if (!this.props.auth) {
                this.props.history.push('/');
            }
        }

        UNSAFE_componentWillUpdate(nextProps) {
            if (!nextProps.auth) {
                this.props.history.push('/');
            }
        }

        render() {
            return this.props.auth && <ComposedComponent {...this.props} />;
        }
    }

    function mapStateToProps(state) {
        return { auth: state.auth.authenticated && state.auth.user };
    }

    return connect(mapStateToProps)(Authentication);
}
