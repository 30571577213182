import {
    BUSINESSES_ALL,
    BUSINESSES_ALL_PENDING,
    BUSINESSES_ID,
    BUSINESSES_ID_PENDING,
    BUSINESSES_ADD,
    BUSINESSES_ADD_PENDING,
    BUSINESSES_UPDATE,
    BUSINESSES_UPDATE_PENDING,
    BUSINESSES_DELETE,
    BUSINESSES_DELETE_PENDING,
    BUSINESSES_DIRECTORIES_ADD,
    BUSINESSES_DIRECTORIES_ADD_PENDING,
    BUSINESSES_DIRECTORIES_UPDATE,
    BUSINESSES_DIRECTORIES_UPDATE_PENDING,
    BUSINESSES_DIRECTORIES_DELETE,
    BUSINESSES_DIRECTORIES_DELETE_PENDING,
    BUSINESSES_DIRECTORIES_ENTITIES_ADD,
    BUSINESSES_DIRECTORIES_ENTITIES_ADD_PENDING,
    BUSINESSES_DIRECTORIES_ENTITIES_DELETE,
    BUSINESSES_DIRECTORIES_ENTITIES_DELETE_PENDING,
    BUSINESSES_DIRECTORIES_ENTITIES_UPDATE,
    BUSINESSES_DIRECTORIES_ENTITIES_UPDATE_PENDING,
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_ADD,
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_ADD_PENDING,
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_UPDATE,
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_UPDATE_PENDING,
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_DELETE,
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_DELETE_PENDING,
    BUSINESSES_REFERENCES_ALL,
    BUSINESSES_REFERENCES_ALL_PENDING,
    BUSINESSES_REFERENCES_RESET,
    BUSINESSES_RESET,
    BUSINESSES_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    businesses: null,
    business: null,
    references: null,
    message: null,
    pending: false,
    pendingAdd: false,
    pendingDelete: false,
    pendingUpdate: false,
    pendingReferences: false,
    pendingEntityUpdate: false,
    pendingDirectoryUpdate: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case BUSINESSES_ALL_PENDING:
            return {
                ...state,
                pending: true
            }
        case BUSINESSES_ALL:
            let businesses = action.payload.businesses;
            businesses['count'] = action.payload.businessesCount;
            return {
                ...state,
                businesses: businesses,
                error: '',
                message: action.payload.message,
                pending: false
            };
        case BUSINESSES_ID_PENDING:
            return {
                ...state,
                business: {
                    ...state.business,
                    pending: true
                }
            }
        case BUSINESSES_ID:
            let business = action.payload.business;
            business.pending = false;
            //business.pendingEntitiesAdd = false;
            return {
                ...state,
                business: business,
                error: '',
                message: action.payload.message
            }
        case BUSINESSES_ADD_PENDING:
            return {
                ...state,
                pendingAdd: true
            }
        case BUSINESSES_ADD:
            return {
                ...state,
                pendingAdd: false
            }
        case BUSINESSES_UPDATE_PENDING:
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingUpdate: true,
                    message: ''
                }
            }
        case BUSINESSES_UPDATE:
            //console.log("projects_update")
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingUpdate: false,
                    message: 'Affaire modifiée'
                }
            }

        case BUSINESSES_DELETE_PENDING:
            return {
                ...state,
                pendingDelete: true
            }
        case BUSINESSES_DELETE:
            let newBusinesses = state.businesses;
            if (newBusinesses !== null) {
                newBusinesses = state.businesses.filter((business) => business.id !== Number(action.payload.id))
            }
            return {
                ...state,
                businesses: newBusinesses,
                pendingDelete: false,
                error: '',
                message: action.payload.message
            };
        case BUSINESSES_DIRECTORIES_ADD_PENDING:
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoryAdd: true
                }
            }
        case BUSINESSES_DIRECTORIES_ADD:

            let newDirectoriesAdd = state.business.directories;
            newDirectoriesAdd.push(action.payload.directory)

            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoryAdd: false,
                    directories: newDirectoriesAdd
                }
            }
        case BUSINESSES_DIRECTORIES_UPDATE_PENDING:
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoryUpdate: true
                }
            }
        case BUSINESSES_DIRECTORIES_UPDATE:
			let newDirectoriesUpdate = state.business.directories;
			for (let i = 0; i < newDirectoriesUpdate.length; i++) {
				if (newDirectoriesUpdate[i].Annuaires_ID === Number(action.payload.directory.Annuaires_ID)) {
                    newDirectoriesUpdate[i] = action.payload.directory;
                }
			}
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoryUpdate: false,
					directories: newDirectoriesUpdate
                }
            }
        case BUSINESSES_DIRECTORIES_DELETE_PENDING:
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoryDelete: true
                }
            }
        case BUSINESSES_DIRECTORIES_DELETE:
            let newDirectories = state.business.directories;
            if (newDirectories !== null) {
                newDirectories = state.business.directories.filter((directory) => directory.Annuaires_ID !== Number(action.payload.id))
            }
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoryDelete: false,
                    directories: newDirectories
                }
            }
        case BUSINESSES_DIRECTORIES_ENTITIES_ADD_PENDING:
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoriesEntitiesAdd: true
                }
            }
        case BUSINESSES_DIRECTORIES_ENTITIES_ADD:

            let newDirectoriesEntityAdd = state.business.entities;
            newDirectoriesEntityAdd.push(action.payload.entity)

            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoriesEntitiesAdd: false,
                    entities: newDirectoriesEntityAdd
                }
            }
        case BUSINESSES_DIRECTORIES_ENTITIES_DELETE_PENDING:
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoriesEntitiesDelete: true
                }
            }
        case BUSINESSES_DIRECTORIES_ENTITIES_DELETE:
            let newEntities = state.business.entities;
            if (newEntities !== null) {
                newEntities = state.business.entities.filter((entity) => entity.AnnuairesEntites_ID !== Number(action.payload.id))
            }
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoriesEntitiesDelete: false,
                    entities: newEntities
                }
            }

        case BUSINESSES_DIRECTORIES_ENTITIES_UPDATE_PENDING:
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingEntityUpdate: true
                }
            }
        case BUSINESSES_DIRECTORIES_ENTITIES_UPDATE:

            /*let newDirectoriesEntityAdd = state.business.entities;
            newDirectoriesEntityAdd.push(action.payload.entity)*/

            let newEntities2 = state.business.entities;

            for (let ne of newEntities2) {
                if (ne.AnnuairesEntites_ID === Number(action.payload.id)) {
                    ne.AnnuairesEntites_Reference = action.payload.reference;
                }
            }

            return {
                ...state,
                business: {
                    ...state.business,
                    pendingEntityUpdate: false,
                    entities: newEntities2
                    //entities: newDirectoriesEntityAdd
                }
            }
        case BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_ADD_PENDING:
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoriesEntitiesContactAdd: true
                }
            }
        case BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_ADD:

            let newDirectoriesEntitiesContactAdd = state.business.contacts;
            newDirectoriesEntitiesContactAdd.push(action.payload.contact)

            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoriesEntitiesContactAdd: false,
                    contacts: newDirectoriesEntitiesContactAdd
                }
            }
        case BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_UPDATE_PENDING:
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoriesEntitiesContactUpdate: true
                }
            }
        case BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_UPDATE:

            let contactsAnnuaire = state.business.contacts;
            for (let i = 0; i < contactsAnnuaire.length; i++) {
                if (contactsAnnuaire[i].AnnuairesPersonnes_ID === Number(action.payload.contact.AnnuairesPersonnes_ID)) {
                    for (var key in action.payload.contact) {
                        if (contactsAnnuaire[i].hasOwnProperty(key)) {
                            contactsAnnuaire[i][key] = action.payload.contact[key]
                        }
                    }
                }
            }

            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoriesEntitiesContactUpdate: false,
                    contacts: contactsAnnuaire
                }
            }
        case BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_DELETE_PENDING:
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoriesEntitiesContactDelete: true
                }
            }
        case BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_DELETE:
            let newContacts = state.business.contacts;
            if (newContacts !== null) {
                newContacts = state.business.contacts.filter((contact) => contact.AnnuairesPersonnes_ID !== Number(action.payload.id))
            }
            return {
                ...state,
                business: {
                    ...state.business,
                    pendingDirectoriesEntitiesContactDelete: false,
                    contacts: newContacts
                }
            }

        //References

        case BUSINESSES_REFERENCES_ALL_PENDING:
            return {
                ...state,
                pendingReferences: true
            }
        case BUSINESSES_REFERENCES_ALL:
            let references = action.payload.businesses;
            references['count'] = action.payload.businessesCount;
            return {
                ...state,
                references: references,
                error: '',
                message: action.payload.message,
                pendingReferences: false
            };
        case BUSINESSES_REFERENCES_RESET:
            return {
                ...state,
                references: null,
                message: null,
                error: null
            };

        case BUSINESSES_RESET:
            return {
                ...state,
                businesses: null,
                business: null,
                message: null,
                error: null,
                pending: false,
                pendingAdd: false,
                pendingDelete: false,
                pendingUpdate: false,
                pendingReferences: false,
                pendingEntityUpdate: false
            };
        case BUSINESSES_ERROR:
            return { ...state, error: action.payload.message };
        default:
            return state;
    }
}
