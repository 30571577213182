import {
    CONTACTS_ALL,
    CONTACTS_ALL_PENDING,
    CONTACTS_ID,
    CONTACTS_ID_PENDING,
    CONTACTS_ADD,
    CONTACTS_ADD_PENDING,
    CONTACTS_UPDATE,
    CONTACTS_UPDATE_PENDING,
    CONTACTS_DELETE,
    CONTACTS_DELETE_PENDING,
    CONTACT_VIP_ADD,
    CONTACT_VIP_ADD_PENDING,
    CONTACT_VIP_DELETE,
    CONTACT_VIP_DELETE_PENDING,
    CONTACT_ENTITIES_ADD,
    CONTACT_ENTITIES_ADD_PENDING,
    CONTACT_ENTITIES_UPDATE,
    CONTACT_ENTITIES_UPDATE_PENDING,
    CONTACT_ENTITIES_DELETE,
    CONTACT_ENTITIES_DELETE_PENDING,
    CONTACT_HISTO_ADD,
    CONTACT_HISTO_ADD_PENDING,
    CONTACT_HISTO_UPDATE,
    CONTACT_HISTO_UPDATE_PENDING,
    CONTACT_HISTO_DELETE,
    CONTACT_HISTO_DELETE_PENDING,
    CONTACTS_VIP_UPDATE,
    CONTACTS_VIP_UPDATE_PENDING,
    CONTACTS_RESET,
    CONTACTS_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    contacts: null,
    contact: null,
    message: null,
    pending: false,
    pendingAdd: false,
    pendingDelete: false,
    pendingVIPAdd: false,
    pendingVIPDelete: false,
    pendingUpdate: false,
    pendingUpdateVIP: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CONTACTS_ALL_PENDING:
            return {
                ...state,
                pending: true
            }
        case CONTACTS_ALL:
            let contacts = action.payload.contacts;
            contacts['count'] = action.payload.contactsCount;
            return {
                ...state,
                contacts: contacts,
                error: '',
                pending: false,
                message: action.payload.message
            };
        case CONTACTS_ADD_PENDING:
            return {
                ...state,
                pendingAdd: true
            }
        case CONTACTS_ADD:
            return {
                ...state,
                pendingAdd: false
            }
        case CONTACTS_DELETE_PENDING:
            return {
                ...state,
                pendingDelete: true
            }
        case CONTACTS_DELETE:
            let newContacts = state.contacts;
            if (newContacts !== null) {
                newContacts = state.contacts.filter((contact) => contact.Personnes_ID !== Number(action.payload.id))
            }
            return {
                ...state,
                contacts: newContacts,
                pendingDelete: false,
                error: '',
                message: action.payload.message
            };
        case CONTACTS_ID_PENDING:
            return {
                ...state,
                contact: {
                    ...state.project,
                    pending: true
                }
            }
        case CONTACTS_ID:
            let contact = action.payload.contact;
            contact.pending = false;
            return {
                ...state,
                contact: contact,
                error: '',
                message: action.payload.message
            }
        case CONTACTS_UPDATE_PENDING:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    pendingUpdate: true,
                    message: ''
                }
            }
        case CONTACTS_UPDATE:
            //console.log("contacts_update")
            return {
                ...state,
                contact: {
                    ...state.contact,
                    pendingUpdate: false,
                    message: 'Contact modifié'
                }
            }



        /* ENTITIES */
        case CONTACT_ENTITIES_ADD_PENDING:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    pendingEntitiesAdd: true
                }
            }
        case CONTACT_ENTITIES_ADD:
            let entities = state.contact.entites;
            entities.push(action.payload.entity);
            return {
                ...state,
                contact: {
                    ...state.contact,
                    entites: entities,
                    pendingEntitiesAdd: false
                }
            }
        case CONTACT_ENTITIES_DELETE_PENDING:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    pendingEntitiesDelete: true
                }
            }
        case CONTACT_ENTITIES_DELETE:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    entites: state.contact.entites.filter((entity) => entity.PersonnesEntites_ID !== Number(action.payload.id)),
                    pendingEntitiesDelete: false
                },
                error: '',
                message: action.payload.message
            };
        case CONTACT_ENTITIES_UPDATE_PENDING:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    pendingEntitiesUpdate: true
                }
            }
        case CONTACT_ENTITIES_UPDATE:
            entities = state.contact.entites;
            for (let i = 0; i < entities.length; i++) {
                if (entities[i].PersonnesEntites_ID === Number(action.payload.contact.PersonnesEntites_ID)) {
                    entities[i] = action.payload.contact;
                }
            }
            return {
                ...state,
                contact: {
                    ...state.contact,
                    entites: entities,
                    pendingEntitiesUpdate: false
                }
            }


        /* Histo */


        case CONTACT_HISTO_ADD_PENDING:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    pendinghistoAdd: true
                }
            }
        case CONTACT_HISTO_ADD:
            let histo = state.contact.histo;
            histo.push(action.payload.histo);
            return {
                ...state,
                contact: {
                    ...state.contact,
                    histo: histo,
                    pendinghistoAdd: false
                }
            }
        case CONTACT_HISTO_DELETE_PENDING:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    pendingHistoDelete: true
                }
            }
        case CONTACT_HISTO_DELETE:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    histo: state.contact.histo.filter((h) => h.id !== Number(action.payload.id)),
                    pendingHistoDelete: false
                },
                error: '',
                message: action.payload.message
            };
        case CONTACT_HISTO_UPDATE_PENDING:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    pendingHistoUpdate: true
                }
            }
        case CONTACT_HISTO_UPDATE:
            histo = state.contact.histo;
            for (let i = 0; i < histo.length; i++) {
                if (histo[i].id === Number(action.payload.histo.id)) {
                    histo[i] = action.payload.histo;
                }
            }
            return {
                ...state,
                contact: {
                    ...state.contact,
                    histo: histo,
                    pendingHistoUpdate: false
                }
            }


        /* VIP */
        case CONTACT_VIP_ADD_PENDING:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    pendingVIPAdd: true
                }
            }
        case CONTACT_VIP_ADD:
            let vip = state.contact.vip;
            if(action.payload.hasOwnProperty("already")){

            }else{
                vip.push(action.payload.vip);
            }
            return {
                ...state,
                contact: {
                    ...state.contact,
                    vip: vip,
                    pendingVIPAdd: false
                }
            }
        case CONTACT_VIP_DELETE_PENDING:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    pendingVIPDelete: true
                }
            }
        case CONTACT_VIP_DELETE:
            let newVIP = state.contact.vip;
            if (newVIP !== null) {
                newVIP = state.contact.vip.filter((employee) => employee.PersonnesContactsVIP_IDSalaries !== Number(action.payload.idSalarie))
            }
            return {
                ...state,
                contact: {
                    ...state.contact,
                    vip: newVIP,
                    pendingVIPDelete: false
                }
            }
        case CONTACTS_VIP_UPDATE_PENDING:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    pendingUpdateVIP: true
                }
            }
        case CONTACTS_VIP_UPDATE:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    pendingUpdateVIP: false
                }
            }
        case CONTACTS_RESET:
            return {
                ...state,
                contacts: null,
                contact: null,
                message: null,
                error: null,
                pending: false,
                pendingAdd: false,
                pendingDelete: false,
                pendingUpdate: false,
                pendingUpdateVIP: false
            };
        case CONTACTS_ERROR:
            return { ...state, error: action.payload.message };
        default:
            return state;
    }
}
