import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
//import moment from 'moment';
//import history from '../../history';

import { getManualData, savePdf } from '../../actions/index';

//design
import { faPrint, faDownload } from '@fortawesome/free-solid-svg-icons'
//gj-strap
import Full from '../../gj_modules/gj-strap/components/partials/Full';
import { ComplexAll } from '../../gj_modules/gj-strap/components/complex'
import { returnFunction /*, checkNull, toolTip, textSlice*/ } from '../../gj_modules/gj-strap/lib';
//import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
//import Spinner from '../../gj_modules/gj-strap/components/display/Spinner';
//import { Form, Checkbox } from '../../gj_modules/gj-strap/components/form';

import FooterCicanord from '../partials/FooterCicanord';

//import { labelByValue } from '../../gj_modules/gj-strap/lib';

//action
import { resetBusinessesReferences, getBusinessesReferencesAll } from '../../actions/businesses'
import { getBusinessesAll } from '../../actions/businesses';

//import { required } from 'lib/validate';

export const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

class Checkboxdisplay extends Component {
    render(){
        const { name, value, onChange, check } = this.props;
        return (
            <input
                type="checkbox"
                name={name}
                value={value}
                checked={check}
                onChange={onChange}
            />
        )
    }
}

window.console.logSync = (...args) => {
    try {
      args = args.map((arg) => JSON.parse(JSON.stringify(arg)));
      console.log(...args);
    } catch (error) {
      console.log('Error trying to console.logSync()', ...args);
    }
};

class BusinessesReferencesPrintSearch extends Component {

    defaultRef = {
        e: true,
        o: true,
        y: true,
        aw: true,
        ap: true,
        s: true,
        d: true,
        m: true,
        sp: true,
        mo: true,
        pp: true,
        a: true,
        do: true
    };

    constructor() {
        super();
        this.state = {
            check : {},
            baseBusinesses: { value:null, label:"", force:true }
        }
    }

    componentDidMount() {
        let data = returnFunction(getBusinessesAll, "searchBusinessesByCode", 5, "businesses.businesses", "Affaires_ID", []);
        data(5, getBusinessesAll).then(value => {
            console.log(value);
        });
    }

    changeVar = function(e, id) {
        /*console.log("change")
        console.log(e.target.name)
        console.log(e.target.checked)
        console.log(test)*/
        console.log(this.state.check);
        console.log(id);

        let copy = Object.assign(e);

        let checkOld = Object.assign(this.state.check);

        if(!checkOld.hasOwnProperty(id)){
            checkOld[id] =  Object.assign({}, this.defaultRef);
        }

        if(checkOld[id][copy.target.name]){
            checkOld[id][copy.target.name] = false;
        }else{
            checkOld[id][copy.target.name] = true;
        }
        this.setState({check : checkOld});
    }

    changeVarColumn = function(ee, id) { 
        /*This function sets all ckeck values of the buttons in the name column to the same value.
        This function is designed to be used as the onChange value for a checkBox.

        Args:   ee - Data passed through when called through onChange call
                name - The name of the column that's being changed*/

        let checkOld = Object.assign(this.state.check);                                         //Getting state check values for futur modifications 
        console.log(this.state)
        if(!checkOld.hasOwnProperty(id)){
            checkOld[id] = Object.assign({}, this.defaultRef);
        }
        if(checkOld[id][ee.target.name]){
            checkOld[id][ee.target.name] = false;
        }else{
            checkOld[id][ee.target.name] = true;
        }
        for (let ref in this.props.businesses.references) {                                                             //Going through all references Currently selected
            if (!checkOld.hasOwnProperty(this.props.businesses.references[ref].Affaires_ID)) {                          //Checking if the id exists in the state check value
                checkOld[this.props.businesses.references[ref].Affaires_ID] = Object.assign({}, this.defaultRef);       //adding the id withe the default value
            }
            if (checkOld[id][ee.target.name])                                                               //Setting the checkBox of the current ref to the
                checkOld[this.props.businesses.references[ref].Affaires_ID][ee.target.name] = true;         //value of the name column
            else                                                                                            //
                checkOld[this.props.businesses.references[ref].Affaires_ID][ee.target.name] = false;        //
        }
        this.setState({check : checkOld});                                              //Setting the state check value to the newly modified value
    }

    check = function(id, field) {
        let checkOld = Object.assign(this.state.check)

        if(checkOld.hasOwnProperty(id)){
            return checkOld[id][field];
        }else{
            checkOld[id] = this.defaultRef;
            return true;
        }
    }

    computeHidden = function(searchBusinesses) {
        let final = "";
        let first = false;
        
        for(let sb of searchBusinesses.split("-")){
            if(first){
                final += "-";
            }else{
                first = true;
            }

            final += ""+sb+"";

            if(this.state.check.hasOwnProperty(sb)){
                //Check all
                let temp = [];
                for (const [key, value] of Object.entries(this.state.check[sb])) {
                    if(value){
                        temp.push(key)
                    }
                }
                //console.log(temp);
                final += "_"+temp.join("i");
            }

        }

        return final;
    }

    renderTd = (e) => {
        return {
            key: e.id + "" + Math.random(),
            code: e.Affaires_Code,
            e: <Checkboxdisplay name={"e"} check={this.check(e.Affaires_ID, "e")} onChange={(ee) => this.changeVar(ee, e.Affaires_ID)} />,
            o: <Checkboxdisplay name={"o"} check={this.check(e.Affaires_ID, "o")} onChange={(ee) => this.changeVar(ee, e.Affaires_ID)} />,
            y: <Checkboxdisplay name={"y"} check={this.check(e.Affaires_ID, "y")} onChange={(ee) => this.changeVar(ee, e.Affaires_ID)} />,
            aw: <Checkboxdisplay name={"aw"} check={this.check(e.Affaires_ID, "aw")} onChange={(ee) => this.changeVar(ee, e.Affaires_ID)} />,
            ap: <Checkboxdisplay name={"ap"} check={this.check(e.Affaires_ID, "ap")} onChange={(ee) => this.changeVar(ee, e.Affaires_ID)} />,
            s: <Checkboxdisplay name={"s"} check={this.check(e.Affaires_ID, "s")} onChange={(ee) => this.changeVar(ee, e.Affaires_ID)} />,
            d: <Checkboxdisplay name={"d"} check={this.check(e.Affaires_ID, "d")} onChange={(ee) => this.changeVar(ee, e.Affaires_ID)} />,
            m: <Checkboxdisplay name={"m"} check={this.check(e.Affaires_ID, "m")} onChange={(ee) => this.changeVar(ee, e.Affaires_ID)} />,
            sp: <Checkboxdisplay name={"sp"} check={this.check(e.Affaires_ID, "sp")} onChange={(ee) => this.changeVar(ee, e.Affaires_ID)} />,
            mo: <Checkboxdisplay name={"mo"} check={this.check(e.Affaires_ID, "mo")} onChange={(ee) => this.changeVar(ee, e.Affaires_ID)} />,
            pp: <Checkboxdisplay name={"pp"} check={this.check(e.Affaires_ID, "pp")} onChange={(ee) => this.changeVar(ee, e.Affaires_ID)} />,
            a: <Checkboxdisplay name={"a"} check={this.check(e.Affaires_ID, "a")} onChange={(ee) => this.changeVar(ee, e.Affaires_ID)} />,
            do: <Checkboxdisplay name={"do"} check={this.check(e.Affaires_ID, "do")} onChange={(ee) => this.changeVar(ee, e.Affaires_ID)} />,
        }
    }

    render() {
        //const { businessesphases } = this.props;

        const { getBusinessesAll } = this.props;

        let cacheUserIds = [];

        const properties = [ // DataTable Headers
            { short: "code", name: "Code", width: "6%"},
            {short: "e", name:<React.Fragment>
                <Checkboxdisplay name={"e"} check={this.check("header", "e")} onChange={(ee) => this.changeVarColumn(ee, "header")}/> Externe
            </React.Fragment>, width: "6%"},
            { short: "o", name:<React.Fragment>
                <Checkboxdisplay name={"o"} check={this.check("header", "o")} onChange={(ee) => this.changeVarColumn(ee, "header")}/> En avant
            </React.Fragment> , width: "8%",},
            { short: "y", name: <React.Fragment>
            <Checkboxdisplay   name={"y"} check={this.check("header", "y")} onChange={(ee) => this.changeVarColumn(ee, "header")}/> Année
        </React.Fragment> , width: "8%" },
            { short: "aw", name: <React.Fragment>
            <Checkboxdisplay   name={"aw"} check={this.check("header", "aw")} onChange={(ee) => this.changeVarColumn(ee, "header")}/> Montant Travaux
        </React.Fragment>, width: "8%" },
            { short: "ap", name: <React.Fragment>
            <Checkboxdisplay   name={"ap"} check={this.check("header", "ap")} onChange={(ee) => this.changeVarColumn(ee, "header")}/> Montant prestation
        </React.Fragment>, width: "8%" },
            { short: "s", name: <React.Fragment>
            <Checkboxdisplay   name={"s"} check={this.check("header", "s")} onChange={(ee) => this.changeVarColumn(ee, "header")}/> Surface
        </React.Fragment>, width: "8%" },
            { short: "d", name: <React.Fragment>
            <Checkboxdisplay   name={"d"} check={this.check("header", "d")} onChange={(ee) => this.changeVarColumn(ee, "header")}/> Délai Execution
        </React.Fragment>, width: "8%" },
            { short: "m", name: <React.Fragment>
            <Checkboxdisplay   name={"m"} check={this.check("header", "m")} onChange={(ee) => this.changeVarColumn(ee, "header")}/> Mission
        </React.Fragment>, width: "8%" },
            { short: "sp", name: <React.Fragment>
            <Checkboxdisplay   name={"sp"} check={this.check("header", "sp")} onChange={(ee) => this.changeVarColumn(ee, "header")}/> Spécificités
        </React.Fragment>, width: "8%" },
            { short: "mo", name: <React.Fragment>
            <Checkboxdisplay   name={"mo"} check={this.check("header", "mo")} onChange={(ee) => this.changeVarColumn(ee, "header")}/> Maître d'ouvrage
        </React.Fragment>, width: "8%" },
            { short: "pp", name: <React.Fragment>
            <Checkboxdisplay   name={"pp"} check={this.check("header", "pp")} onChange={(ee) => this.changeVarColumn(ee, "header")}/> P/P
        </React.Fragment>, width: "8%" },
            { short: "a", name: <React.Fragment>
            <Checkboxdisplay   name={"a"} check={this.check("header", "a")} onChange={(ee) => this.changeVarColumn(ee, "header")}/> Architecte
        </React.Fragment>, width: "8%" },
            { short: "do", name: <React.Fragment>
            <Checkboxdisplay   name={"do"} check={this.check("header", "do")} onChange={(ee) => this.changeVarColumn(ee, "header")}/> Bureaux d'Etudes
        </React.Fragment>, width: "8%" },
        ];

        const filters = [
            /*{ type: "search", size: 25, name: "searchBusinessesByCode", placeholder: "Recherche par code" },
            { type: "search", size: 25, name: "searchBusinessesByName", placeholder: "Recherche par nom" },*/
            {type: "hidden", name:"from", value:"printbusinessesreferences"},
            {
                short:"searchBusinesses",
                type: "select", size: 50, name: "searchBusinesses", placeholder: "Codes affaire", data: {
                    isMulti: true,
                    isAsync: true,
                    forceLabel: true,
                    cache: cacheUserIds,
                    //defaultValue: this.state.baseBusinesses,
                    loadOptions: returnFunction(getBusinessesAll, "searchBusinessesByCode", 5, "businesses.businesses", "Affaires_ID", ["Affaires_Code"])
                }
            },
            {
                type: "button",
                size: 25,
                name: "",
                title: "Imprimer les références",
                icon: faPrint,
                fn: (filters) => {
                    if(filters && filters.searchBusinesses){
                        
                        let final = this.computeHidden(filters.searchBusinesses);

                        window.open(""+(REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "")+"/sic/businessesreferencesprint/"+final, '_blank');
                    }
                }
            },
            {
                type: "button",
                size: 25,
                name: "",
                title: "Télécharger les références",
                icon: faDownload,
                fn: (filters) => {
                    if(filters && filters.searchBusinesses){

                        let final = this.computeHidden(filters.searchBusinesses);
                        console.log(final)

                        getManualData(true, '/businesses/print/'+final).then(response => {
                            let pdf = atob(response.response.data.pdf);
                            savePdf(pdf, response.response.data.data.title+".pdf");
                        });
                    }
                }
            }
        ]

        const portlet = {
            title: "Affaires",
            links: [
                { name: "Recherche", path: "/sic/businesses" }
            ]
        }

        //console.log(this.props.businesses)

        return (
            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />
                <Full portlet={portlet}>
                    <ComplexAll
                        noEditGlobal
                        getElementsAll={getBusinessesReferencesAll}
                        resetElements={resetBusinessesReferences}
                        elements={this.props.businesses.references}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        noDataMessage={"Pas de références"}
                        deleteAction={(e) => {
                            console.log("delete")
                        }}
                        editAction={(id) => (console.log("edit"))}
                        /*dataTableClass={"datatablespecial"}*/
                    />
                </Full>
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetBusinessesReferences,
            getBusinessesReferencesAll,
            getBusinessesAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        businesses: state.businesses,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessesReferencesPrintSearch);
