import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import history from '../../history';

import { resetEprojects, getEprojectsAll, addEproject, deleteEproject } from '../../actions/eprojects'

import { Panel } from '../../gj_modules/gj-strap/components/form';
//import Spinner from '../../gj_modules/gj-strap/components/display/Spinner';
import FullCenter from '../../gj_modules/gj-strap/components/partials/FullCenter';
import FooterCicanord from '../partials/FooterCicanord';

import { ComplexAll } from '../../gj_modules/gj-strap/components/complex'
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
import { labelByValue } from '../../gj_modules/gj-strap/lib'

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { projectCategory } from '../../data/constants';
import { required } from '../../lib/validate';

class Eprojects extends Component {
    componentDidMount() {
    }

    renderTd = (e) => {
        return {
            id: e.id,
            key: e.id,
            name: e.name,
            city: e.adressCity,
            postcode: e.adressPostalCode,
            costpreview: e.costPreview,
            category: labelByValue(e.category, projectCategory)
        }
    }

    render() {

        const { eprojects, addEproject, deleteEproject } = this.props;

        const properties = [
            { short: "id", width: "6%", category: "id" },
            { short: "name", name: "Nom", width: "20%", category: "classic" },
            { short: "city", name: "Ville", width: "10%", category: "classic" },
            { short: "postcode", name: "CP", width: "10%", category: "classic" },
            { short: "costpreview", name: "Coût", width: "13%", category: "classic" },
            { short: "category", name: "Catégorie", width: "15%", category: "classic" },
            { short: "actions", name: "", width: "6%", category: "actions" }
        ];

        const filters = [
            { type: "search", size: 40, name: "searchEprojectsByName", placeholder: "Recherche par nom" },
            { type: "select", size: 40, name: "searchEprojectsByCategory", placeholder: "Catégorie", options: projectCategory },
            {
                type: "button",
                size: 20,
                name: "",
                title: "Ajouter un eprojet",
                icon: faPlusCircle,
                fn: () => {
                    modalFunction({
                        submit: (values) => {
                            if (!eprojects.pendingAdd) {
                                addEproject(values).then(
                                    function (response) {
                                        history.push("/sic/eproject/" + response.response.data.eprojects)
                                    }
                                );
                            }
                        },
                        text: (
                            <Panel
                                width={[3, 9]}
                                inputs={[
                                    { short: "name", name: "Nom", type: "text", placeholder: "Nom du eprojet", validate: required() },
                                ]}
                            />
                        ),
                        header: "Ajouter un eprojet",
                        state: "eprojects.pendingAdd"
                    }, "popup")
                }
            }
        ]

        const portlet = {
            title: "Chantiers externes",
            links: [
                { name: "Recherche", path: "/sic/eprojects" }
            ]
        }

        return (
            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        getElementsAll={getEprojectsAll}
                        resetElements={resetEprojects}
                        elements={this.props.eprojects.eprojects}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        pending={this.props.eprojects.pending}
                        noDataMessage={`Pas de eprojet`}
                        deleteAction={(e) => {
                            modalFunction({
                                btnFunction: () => {
                                    deleteEproject(e)
                                },
                                btnText: "Confirmer",
                                text: "Êtes-vous sûr ?",
                                header: "Supprimer un eprojet",
                                state: "eprojects.pendingDelete"
                            }, "popup")
                        }}
                        viewAction={(id) => (history.push("/sic/eproject/" + id))}
                    />
                </FullCenter>
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetEprojects,
            getEprojectsAll,
            addEproject,
            deleteEproject
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        eprojects: state.eprojects,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Eprojects);
