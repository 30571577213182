import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//gj-strap
import { DataTable } from '../../gj_modules/gj-strap/components/form';
import { TileBody } from '../../gj_modules/gj-strap/components/partials';
import history from '../../history';
//import { Panel } from 'gj_modules/gj-strap/components/form';
//import { returnFunction, processSubmit /*, checkNull, toolTip, textSlice*/ } from 'gj_modules/gj-strap/lib';
//actions

class UserBusinesses extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    render() {

        const { user } = this.props;

		const properties = [ // DataTable Headers
			{ short: "code", width: "20%", category: "classic" },
            { short: "name", width: "80%", category: "classic" },
        ];

        //const localGetActionsAll = this.props.getActionsAll;

        let array = [];
		
		for(let b of user.businesses){
            if (b.Affaires_ID !== null) {
                array.push({
                    id:b.Affaires_ID,
                    key:"Affaire_"+b.Affaires_ID,
                    code:b.Affaires_Code,
                    name:b.Affaires_Nom
                })
            }
		}

        return (
            <React.Fragment>
				<TileBody>				
					<DataTable small
						clickAction={(e) => {
							history.push("/sic/business/"+e.id)
						}}
						properties={properties}
						collection={array}
						noDataMessage={"Pas d'affaires"}
					/>
				</TileBody>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        user: state.user.user
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserBusinesses);