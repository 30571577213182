import React, { Component } from 'react';

//import { Link } from 'react-router-dom';

import history from '../../history';

import { toolTip } from '../../gj_modules/gj-strap/lib';

class AvatarUser extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }
    render() {
        const { u } = this.props;

        return (
            <React.Fragment>
                <div className="userAvatar link" onClick={() => history.push("/sic/user/" + u.userId)}>
                    <div className="userCircle" style={{ backgroundColor: "#" + u.color }}>
                        {toolTip(
                            u.login.charAt(0).toUpperCase(),
                            u.surname + " " + u.name,
                            { placement: "top", trigger: "hover" })}
                    </div>
                </div>
                {/*<Link to={"/sic/user/" + u.userId}>
                    <div className="userCircle" style={{ backgroundColor: "#" + u.color }}>
                        {toolTip(
                            u.login.charAt(0).toUpperCase(),
                            u.name + " " + u.surname,
                            { placement: "top", trigger: "hover" })}
                    </div>
                        </Link>*/}
            </React.Fragment>
        );
    }
};

export default AvatarUser;