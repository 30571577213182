import {
    REQUEST_ALL,
    REQUEST_ALL_PENDING,
    REQUEST_ERROR,
    REQUEST_RESET
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    requests: null,
    message: null,
    pending: false
}

export default function (state = INITIAL_STATE, request) {
    switch (request.type) {
        case REQUEST_ALL_PENDING:
            return {
                ...state,
                pending: true
            }
        case REQUEST_ALL:
            let requests = request.payload.requests;
            requests['count'] = request.payload.requestsCount;
            return {
                ...state,
                requests: requests,
                error: '',
                message: request.payload.message,
                pending: false
            };
        case REQUEST_RESET:
            return {
                ...state,
                requests: null,
                message: null,
                error: null,
                pending:false
            };
        case REQUEST_ERROR:
            return { ...state, error: request.payload.message };
        default:
            return state;
    }
}
