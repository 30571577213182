import { combineReducers } from 'redux';
import authReducer from './auth_reducer';
import userReducer from './user_reducer';
import contactsReducer from './contacts_reducer';
import businessesReducer from './businesses_reducer';
import businessesphasesReducer from './businessesphases_reducer';
import entitiesReducer from './entities_reducer';
import projectsReducer from './projects_reducer';
import eprojectsReducer from './eprojects_reducer';
import actionsReducer from './actions_reducer';
import activitiesReducer from './activities_reducer';
import sectorsReducer from './sectors_reducer';
import searchReducer from './search_reducer';
import notificationReducer from './notification_reducer';
import employees_reducer from './employees_reducer';
import requestsReducer from './requests_reducer';

const reducers = combineReducers({
    auth: authReducer,
    user: userReducer,
    contacts: contactsReducer,
    employees: employees_reducer,
    businesses: businessesReducer,
    businessesphases: businessesphasesReducer,
    entities: entitiesReducer,
    projects: projectsReducer,
    eprojects: eprojectsReducer,
    actions: actionsReducer,
    activities: activitiesReducer,
    sectors: sectorsReducer,
    searchs: searchReducer,
    notifications: notificationReducer,
    requests: requestsReducer
});

export default reducers;
