import { getData, postData, putData, deleteData } from './index';
import {
    SECTORS_ALL,
    SECTORS_ALL_PENDING,
    SECTORS_ADD,
    SECTORS_ADD_PENDING,
    SECTORS_UPDATE,
    SECTORS_UPDATE_PENDING,
    SECTORS_DELETE,
    SECTORS_DELETE_PENDING,
    SECTORS_ERROR,
    SECTORS_RESET
} from './types';

import { dataToUrl } from '../lib'

export function resetSectors() {
	return dispatch => dispatch({ type: SECTORS_RESET });
}

export function getSectorsAll(data) {
    const url = '/sectors/all'+dataToUrl(data);
    return dispatch => getData(SECTORS_ALL, SECTORS_ALL_PENDING, SECTORS_ERROR, true, url, dispatch);
}

export function addSector(data) {
    const url = '/sectors/add';
    return dispatch => postData(SECTORS_ADD, SECTORS_ADD_PENDING, SECTORS_ERROR, true, url, dispatch, data);
}

export function updateSector(id, data) {
    const url = '/sectors/update/'+id;
    return dispatch => putData(SECTORS_UPDATE, SECTORS_UPDATE_PENDING, SECTORS_ERROR, true, url, dispatch, data);
}

export function deleteSector(id) {
    const url = '/sectors/delete/'+id;
    return dispatch => deleteData(SECTORS_DELETE, SECTORS_DELETE_PENDING, SECTORS_ERROR, true, url, dispatch);
}
