import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router";
//import filter from 'lodash/filter'
import { navLinks } from '../../data/menu'
import Footer from '../../gj_modules/gj-strap/components/partials/Footer'

class FooterCicanord extends Component {

    render() {
        const { menu, /*currentUser*/ } = this.props;
        // const notifs = this.countNotification();

        return (
            <Footer navLinks={navLinks[menu]} align={"right"}>Cicanord 2019</Footer>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            // logoutUser,
            // getNotificationByUser
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        // notification: state.notifications.notification,
        // currentUser: state.auth.user
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FooterCicanord));