import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';

import { TileContainer, Tile, TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials';

import DataTable from '../../gj_modules/gj-strap/components/form/DataTable';

import { labelByValue } from '../../gj_modules/gj-strap/lib'

import { projectCategory, projectType } from '../../data/constants';

import { Popover, OverlayTrigger } from 'react-bootstrap'

class SubProjects extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    dataTableProjectsRender = () => {
        const { projects } = this.props;

        const properties = [ // DataTable Headers
            { short: "postalcode", width: "20%", category: "classic" },
            { short: "name", width: "60%", category: "classic" },
            { short: "type", width: "20%", category: "classic" }
        ];

        let isSomething = false;

        const cats = {};

        const array = [];

        Object.entries(projects).forEach(([key, project]) => {
            let projects = project.slice();

            let link = "eproject";

            if (key === "projects") {
                link = "project";
            } else if (key === "businesses") {
                link = "business";
            }

            if (projects && projects.length) {
                for (let i = 0; i < projects.length; i++) {

                    //let type = "";

                    let finalColor = "#a5b6e0";

                    if (key === "eprojects") {
                        //type = ("(E)");
                        finalColor = "red";
                    }else if(key === "businesses"){
                        finalColor = "#3959a9";
                    }
                    //console.log(projects[i])
                    const object = {
                        id: projects[i].id,
                        postalcode: (
                            <React.Fragment>
                                <div className="projectColorBar" style={{ backgroundColor: finalColor }}></div>
                                {projects[i].Projects_adressPostalCode}
                            </React.Fragment>
                        ),
                        name: (<Link to={"/sic/" + link + "/" + projects[i].Projects_id}>{projects[i].Projects_name}</Link>),
                        type: labelByValue(projects[i].type, projectType),
                    };

                    object.key = link + "-" + projects[i].Projects_id
                    object.index = i + 1;
                    array.push(object);

                    if (cats[projects[i].Projects_category] === undefined) {
                        cats[projects[i].Projects_category] = [];
                    }

                    cats[projects[i].Projects_category].push(object);
                    isSomething = true;
                }
            }

        });

        let subLabelByValue = function(keyName){
            let finalLabel = labelByValue(keyName, projectCategory);

            if(finalLabel === "null" || finalLabel === "undefined"){
                finalLabel = "Autre";
            }

            return finalLabel;
        }

        return (
            <React.Fragment>
                {isSomething ? (
                    Object.keys(cats).map((keyName, i) => (
                        <React.Fragment key={"test" + i}>
                            <b className="projectsReference">{subLabelByValue(keyName)}</b>
                            <DataTable small
                                properties={properties}
                                collection={cats[keyName]}
                                showAll = {3}
                                refresh = {true}
                                //noDataMessage = {`Pas de projets`}
                            />
                        </React.Fragment>
                    ))
                ) : (
                        <div className="my-3 text-center">Pas de projets</div>
                    )}
            </React.Fragment>
        )
    }

    render() {
        //const {} = this.props;

        const popover = (
            <Popover id="popover-basic">
                {/*<Popover.Title as="h3">Popover right</Popover.Title>*/}
                <Popover.Content>
                    <div className="projectColorBarLegend m-2 p-2" style={{ borderLeftColor: "#3959a9" }}>Affaire Cicanord</div>
                    <div className="projectColorBarLegend m-2 p-2" style={{ borderLeftColor: "#a5b6e0" }}>Projet Cicanord</div>
                    <div className="projectColorBarLegend m-2 p-2" style={{ borderLeftColor: "red" }}>Chantiers externes</div>
              </Popover.Content>
            </Popover>
        );

        return (
            <React.Fragment>
                {/* Intervenants */}
                <TileContainer>
                    <Tile size={"full"}>
                        <TileHeader
                            title="Projets / Affaires"
                            border={true}
                            toolbar={
                                <OverlayTrigger trigger="hover" placement="left" overlay={popover}>
                                    <div className="pl-2">Légende</div>
                                </OverlayTrigger>
                            }
                        />
                        <TileBody>
                            {this.dataTableProjectsRender()}
                        </TileBody>
                    </Tile>
                </TileContainer>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        //eproject: state.eprojects.eproject
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubProjects);