//constants
import { directoriesPart } from '../data/constants';

export default function sortDirectories(business){

    let businessOutput = JSON.parse(JSON.stringify(business));

    //Sort contacts in entities
    let index = 0;
    for (let contact of business.contacts) {
        for (let i = 0; i < businessOutput.entities.length; i++) {
            if (businessOutput.entities[i].AnnuairesEntites_ID === contact.AnnuairesPersonnes_IDAnnuairesEntites) {
                if (!businessOutput.entities[i].hasOwnProperty("contacts")) {
                    businessOutput.entities[i].contacts = [];
                }
                contact.index = index;
                index++;
                businessOutput.entities[i].contacts.push(contact);
            }
        }
    }

    //Sort entities in directories
    index = 0;
    for (let entity of businessOutput.entities) {
        for (let i = 0; i < businessOutput.directories.length; i++) {
            if (businessOutput.directories[i].Annuaires_ID === entity.AnnuairesEntites_IDAnnuaires) {
                if (!businessOutput.directories[i].hasOwnProperty("entities")) {
                    businessOutput.directories[i].entities = [];
                }
                entity.index = index;
                index++;
                businessOutput.directories[i].entities.push(entity);
            }
        }
    }

    //Sort directories in directories
    index = 0;
    for (let directory of businessOutput.directories) {
        if (directory.Annuaires_Niveau === 1) {
            for (let i = 0; i < businessOutput.directories.length; i++) {
                if(directory.Annuaires_IDParent === businessOutput.directories[i].Annuaires_ID){
                    if (!businessOutput.directories[i].hasOwnProperty("subdirectories")) {
                        businessOutput.directories[i].subdirectories = [];
                    }
                    directory.index = index;
                    index++;
                    businessOutput.directories[i].subdirectories.push(directory);
                }
            }
        }
    }

    businessOutput.categories = [];

    for (let d of directoriesPart) {
        businessOutput.categories.push({
            label: d.label,
            value: d.value,
            directories: []
        })
    }

    //Sort directories in categories
    index = 0;
    for (let directory of businessOutput.directories) {
        for (let i = 0; i < businessOutput.categories.length; i++) {
            if (businessOutput.categories[i].value === directory.Annuaires_NumPartie && directory.Annuaires_Niveau === 0) {
                businessOutput.categories[i].directories.push(directory);
            }
        }
    }

    return businessOutput;
}