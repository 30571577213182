import { faAddressBook, faFolderOpen, /*faBuilding,*/ faUser, faSun, faEdit, faBuilding/*, faPaperPlane*/ } from '@fortawesome/free-regular-svg-icons'
import { faSearch, faPrint, faList } from '@fortawesome/free-solid-svg-icons'
import { roles } from './constants'

export const navLinks = {
    sic:[
        /*{
            path:"/home",
            name:"Accueil",
            mobile:true,
            desktop:true,
            icon:faHome
        },*/
        {
            type:"category",
            id:"directories",
            path:"/sic/search",
            name:"Annuaire",
            mobile:true,
            desktop:true,
            icon:faSearch,
            links:[
                {
                    path:"/sic/search",
                    name:"Général",
                    mobile:true,
                    desktop:true,
                    icon:faAddressBook
                },
                {
                    path:"/sic/contacts",
                    name:"Contacts",
                    mobile:true,
                    desktop:true,
                    icon:faAddressBook
                },
                {
                    path:"/sic/entities",
                    name:"Entités",
                    mobile:true,
                    desktop:true,
                    icon:faBuilding
                },
            ]
        },
        {
            path:"/sic/businesses",
            name:"Affaires",
            mobile:true,
            desktop:true,
            icon:faFolderOpen
        },
        {
            path:"/sic/projects",
            name:"Projets",
            mobile:true,
            desktop:true,
            icon:faEdit
        },
        {
            path:"/sic/eprojects",
            name:"Chantiers externes",
            mobile:true,
            desktop:true,
            icon:faEdit
        },
        {
            path:"/sic/actions",
            name:"Actions",
            mobile:true,
            desktop:true,
            icon:faList,
            rank:roles.ROLE_ADMIN.rank
        },
        {
            path:"/sic/users",
            name:"Utilisateurs",
            mobile:true,
            desktop:true,
            icon:faUser,
            rank:roles.ROLE_ADMIN.rank
        },
        {
            type:"category",
            id:"utilities",
            name:"Utilitaires",
            desktop:true,
            icon:faSun,
            links:[
                {
                    path:"/sic/businessesphasesprintsearch",
                    name:"Phases des missions",
                    mobile:true,
                    desktop:true,
                    icon:faPrint
                },
                {
                    path:"/sic/businessesreferencesprintsearch",
                    name:"Références",
                    mobile:true,
                    desktop:true,
                    icon:faPrint
                }
            ]
        },
        {
            type:"category",
            id:"category",
            name:"Catégories",
            desktop:true,
            icon:faSun,
            links:[
                {
                    path:"/sic/employees",
                    name:"Salariés",
                    mobile:true,
                    desktop:true,
                    icon:faUser
                },
                {
                    path:"/sic/activities",
                    name:"Activités",
                    mobile:true,
                    desktop:true,
                    icon:faEdit
                },
                {
                    path:"/sic/sectors",
                    name:"Secteurs",
                    mobile:true,
                    desktop:true,
                    icon:faEdit
                }
            ]
        },
        /*{
            path:"/dashboard",
            name:"Dashboard",
            mobile:true,
            desktop:true,
            icon:faPaperPlane
        },*/
        {
            path:"/sic/preferences",
            name:"Préfèrences",
            mobile:true,
            desktop:true,
            icon:faSun
        },
    ]
}