import {
    ACTIVITIES_ALL,
    ACTIVITIES_ALL_PENDING,
    ACTIVITIES_ADD,
    ACTIVITIES_ADD_PENDING,
    ACTIVITIES_UPDATE,
    ACTIVITIES_UPDATE_PENDING,
    ACTIVITIES_DELETE,
    ACTIVITIES_DELETE_PENDING,
    ACTIVITIES_ERROR,
    ACTIVITIES_RESET
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    activities: null,
    activity: null,
    message: null,
    pending: false,
    pendingAdd: false,
    pendingDelete: false,
    pendingUpdate: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ACTIVITIES_ALL_PENDING:
            return {
                ...state,
                pending: true
            }
        case ACTIVITIES_ALL:
            let activities = action.payload.activities;
            activities['count'] = action.payload.activitiesCount;
            return {
                ...state,
                activities: activities,
                error: '',
                message: action.payload.message,
                pending: false
            };
        case ACTIVITIES_ADD_PENDING:
            return {
                ...state,
                pendingAdd: true
            }
        case ACTIVITIES_ADD:
            return {
                ...state,
                error: '',
                message: action.payload.message,
                pendingAdd: false
            };
        case ACTIVITIES_UPDATE_PENDING:
            return {
                ...state,
                pendingUpdate: true
            }
        case ACTIVITIES_UPDATE:
            activities = state.activities;
            for(let i=0; i<activities.length; i++){
                if(activities[i].Activites_ID === Number(action.payload.id)){
                    activities[i] = Object.assign(activities[i], action.payload.activity);
                }
            }
            return {
                ...state,
                activities:activities,
                error: '',
                message: action.payload.message,
                pendingUpdate: false
            };
        case ACTIVITIES_DELETE_PENDING:
            return {
                ...state,
                pendingDelete: true
            }
        case ACTIVITIES_DELETE:
            activities = state.activities;
            if(activities !== null){
                activities = state.activities.filter((activity) => activity.Activites_ID !== Number(action.payload.id))
            }
            return {
                ...state,
                error: '',
                activities:activities,
                message: action.payload.message,
                pendingDelete: false
            };
        case ACTIVITIES_RESET:
            return {
                ...state,
                activities: null,
                activity: null,
                message: null,
                error: null,
                pending:false,
                pendingAdd:false,
                pendingDelete:false,
                pendingUpdate:false
            };
        case ACTIVITIES_ERROR:
            return { ...state, error: action.payload.message };
        default:
            return state;
    }
}
