import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

export default function toolTip(value, tooltipValue, data) {
    return (
        <OverlayTrigger
            container={document.body}
            placement={data.placement}
            trigger={data.trigger}
            overlay={
                <Tooltip>
                    {tooltipValue}
                </Tooltip>
            }
        >
            <div>{value}</div>
        </OverlayTrigger>
    )
}