import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
//gj-strap
import { TileContainer, Tile, TileHeader, TileBody, TileTabs } from '../../gj_modules/gj-strap/components/partials';
import { Panel, DataTable } from '../../gj_modules/gj-strap/components/form';
//actions
import { addEntityContact, updateEntityContact, deleteEntityContact } from '../../actions/entities';
import { addContact, getContactsAll } from '../../actions/contacts';
//current
import { processSubmit, returnFunction/*, processSubmit, labelByValue, checkNull*/ } from '../../gj_modules/gj-strap/lib';
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
//import { required } from 'lib/validate';
import { required } from '../../lib/validate';
//design
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown, faHandPaper } from '@fortawesome/free-solid-svg-icons'

class EntityContacts extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    dataTablePersonsRender = () => {
        const { entity, contacts, updateEntityContact, deleteEntityContact } = this.props;

        const properties = [ // DataTable Headers
            { short: "name", width: "35%", category: "classic" },
            { short: "freefunction", width: "35%", category: "classic" },
            { short: "actions", width: "30%", category: "actions" }
        ];

        const array = [];

        if (contacts && contacts.length) {
            for (let i = 0; i < contacts.length; i++) {
                const object = {
                    id: contacts[i].PersonnesEntites_ID,
                    name: (<Link to={"/sic/contact/" + contacts[i].Personnes_ID}>
                        {contacts[i].Personnes_Prenom !== null ? contacts[i].Personnes_Prenom + " " : ""}
                        {contacts[i].Personnes_Nom !== null ? contacts[i].Personnes_Nom.toUpperCase() : ""}
                        {contacts[i].Personnes_Vip ? <FontAwesomeIcon className="way" icon={faCrown} /> : ""}
                        {contacts[i].Personnes_Prospect ? <FontAwesomeIcon className="way" icon={faHandPaper} /> : ""}
                    </Link>),
                    freefunction: contacts[i].PersonnesEntites_FonctionLibre,
                };
                object.key = contacts[i].Personnes_ID
                object.index = i + 1;
                array.push(object);
            }
        }

        return (
            <DataTable small
                noEditGlobal
                properties={properties}
                collection={array}
                noDataMessage={"Pas de collaborateurs"}
                showAll={3}
                editAction={(e) => {
                    let index = -1;
                    for (let i = 0; i < contacts.length; i++) {
                        if (e === contacts[i].PersonnesEntites_ID) {
                            index = i;
                        }
                    }

                    if (!entity.pendingContactsUpdate) {
                        modalFunction({
                            submit: (values) => {
                                if (!entity.pendingContactsUpdate) {
                                    console.log(values)
                                    updateEntityContact(e, values)
                                }
                            },
                            initialValues: {
                                freefunction: (contacts[index].PersonnesEntites_FonctionLibre) || "",
                                phonemobile: (contacts[index].PersonnesEntites_TelephonePortableProfessionel) || "",
                                phonemobiledisplay: contacts[index].PersonnesEntites_TelephonePortableProfessionelDiffusable,
                                phone: (contacts[index].PersonnesEntites_TelephoneProfessionel) || "",
                                phonedisplay: contacts[index].PersonnesEntites_TelephoneProfessionelDiffusable,
                                fax: (contacts[index].PersonnesEntites_FaxProfessionel) || "",
                                faxdisplay: contacts[index].PersonnesEntites_FaxProfessionelDiffusable,
                            },
                            text: (
                                <Panel
                                    width={[3, 9]}
                                    inputs={[
                                        {
                                            short: "freefunction", name: "Fonction libre", type: "text", placeholder: "Fonction"
                                        },
                                        {
                                            short: "phonemobile", name: "Téléphone portable", type: "text", placeholder: "0123456789"
                                        },
                                        {
                                            short: "phonemobiledisplay", name: "Diffusable", type: "checkbox", placeholder: ""
                                        },
                                        {
                                            short: "phone", name: "Téléphone", type: "text", placeholder: "0123456789"
                                        },
                                        {
                                            short: "phonedisplay", name: "Diffusable", type: "checkbox", placeholder: ""
                                        },
                                        {
                                            short: "fax", name: "Fax", type: "text", placeholder: "0123456789"
                                        },
                                        {
                                            short: "faxdisplay", name: "Diffusable", type: "checkbox", placeholder: ""
                                        }
                                    ]}
                                />
                            ),
                            header: "Editer un collaborateur",
                            state: "entities.entity.pendingContactsUpdate"
                        }, "popup")
                    }
                }}
                deleteAction={(e) => {
                    modalFunction({
                        btnFunction: () => {
                            //console.log("function delete " + e)
                            deleteEntityContact(e)
                        },
                        btnText: "Confirmer",
                        text: "Êtes-vous sûr ?",
                        header: "Supprimer un collaborateur",
                        state: "entities.entity.pendingContactsDelete"
                    }, "popup")
                }}
            />
        )
    }

    /*switchTab = (tab) => {
        const { match } = this.props;
        const id = match && match.params && match.params.id;

        switch (tab) {
            case "createContact":
                console.log("display create contact")
                break;
            case "addContact":
                console.log("display add contact")
                break;
            default:
                break;
        }
    }*/

    render() {
        const { entity, getContactsAll, addEntityContact, idEntity } = this.props;

        return (
            <React.Fragment>
                {/* Contacts */}
                <TileContainer>
                    <Tile size={"full"}>
                        <TileHeader
                            title="Collaborateurs"
                            border={true}
                            toolbar={<div>
                                <Button className="ml-3"
                                    onClick={() => {
                                        if (!entity.pendingContactsAdd) {
                                            modalFunction({
                                                submit: (values) => {
                                                    values["Entites_ID"] = entity.Entites_ID;
                                                    values["AnnuairesEntites_ID"] = entity.AnnuairesEntites_ID;
                                                    values["add"] = true;
                                                    if (!entity.pendingContactsAdd) {
                                                        //console.log(processSubmit(values))
                                                        addEntityContact(idEntity, values);
                                                    }
                                                },
                                                text: (
                                                    <Panel
                                                        width={[3, 9]}
                                                        inputs={[
                                                            { short: "functionfree", name: "Fonction libre", type: "text", placeholder: "Fonction libre" },
                                                            { short: "name", name: "Nom", type: "text", placeholder: "Nom", validate: required() },
                                                            { short: "surname", name: "Prénom", type: "text", placeholder: "Prénom", validate: required() },
                                                            { short: "phonenb", name: "Téléphone", type: "text", placeholder: "0123456789", validate: required() },
                                                            { short: "email", name: "Email", type: "text", placeholder: "email", validate: required() },
                                                        ]}
                                                    />
                                                ),
                                                header: "Créer et ajouter un contact",
                                                state: "entities.entity.pendingContactsAdd"
                                            }, "popup")
                                        }
                                    }}
                                >{entity.pendingContactsAdd ? "O" : "créer et ajouter"}</Button>
                                <Button className="ml-3"
                                onClick={() => {
                                    if (!entity.pendingContactsAdd) {
                                        modalFunction({
                                            submit: (values) => {
                                                if (!entity.pendingContactsAdd) {
                                                    //console.log(values)
                                                    addEntityContact(idEntity, values)
                                                }
                                            },
                                            /*text: (
                                                <TileTabs defaultActiveKey={create} onSelect={this.switchTab} transition={false} id="tabs-bussiness">
                                                    <TileTab eventKey="createContact" title="Créer un contact">
                                                        <BusinessActions
                                                            business={business}
                                                        />
                                                    </TileTab>
                                                </TileTabs>
                                            ),*/
                                            text: (
                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        {
                                                            short: "contactId", name: "Contact", type: "select", placeholder: "Rechercher", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                //defaultValue: { value:null, label:"", force:true },
                                                                loadOptions: returnFunction(getContactsAll, "searchContactsByName", 5, "contacts.contacts", "Personnes_ID", ["Personnes_Prenom", "Personnes_Nom"])
                                                            }
                                                        }
                                                    ]}
                                                />
                                            ),
                                            header: "Ajouter un collaborateur",
                                            state: "entities.entity.pendingContactsAdd"
                                        }, "popup")
                                    }
                                }
                                }>
                                    {entity.pendingContactsAdd ? "O" : "+"}
                                </Button>
                            </div>
                            }
                        />
                        <TileBody>
                            {this.dataTablePersonsRender()}
                        </TileBody>
                    </Tile>
                </TileContainer>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getContactsAll,
            addEntityContact,
            updateEntityContact,
            deleteEntityContact
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        entity: state.entities.entity
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EntityContacts);