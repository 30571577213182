import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import moment from 'moment';
//import history from '../../history';

import { getManualData, savePdf } from '../../actions/index';

import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver'

//design
import { faPrint, faDownload } from '@fortawesome/free-solid-svg-icons'
//gj-strap
import Full from '../../gj_modules/gj-strap/components/partials/Full';
import { ComplexAll } from '../../gj_modules/gj-strap/components/complex'
import { returnFunction /*, checkNull, toolTip, textSlice*/ } from '../../gj_modules/gj-strap/lib';
//import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
//import Spinner from '../../gj_modules/gj-strap/components/display/Spinner';
//import { Panel } from '../../gj_modules/gj-strap/components/form';

import { FullCenter } from '../../gj_modules/gj-strap/components/partials';

import FooterCicanord from '../partials/FooterCicanord';

//import { labelByValue } from '../../gj_modules/gj-strap/lib';

//action
import { resetRequests, getRequestsAll } from '../../actions/requests';
import { resetBusinesses } from '../../actions/businesses'
import { getBusinessesAll } from '../../actions/businesses';

import { requestType, allFilters } from '../../data/constants';
import Select from 'react-select/lib/Select';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import { required } from 'lib/validate';

export const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

class BusinessesPhasesPrintSearch extends Component {
    componentDidMount() {
    }

    renderTd = (e) => {

        let typeFinal = "";

        switch (e.type) {
            case "contact":
                typeFinal = "Contact"
                break;
            case "business":
                typeFinal = "Affaire"
                break;
            default:
                break;
        }
        let vip = <FontAwesomeIcon className="actionCheck" icon={faSquare} />
        if (e.vip !== undefined && e.vip === true) {
            vip = <FontAwesomeIcon className="actionCheck" icon={faCheckSquare} />
        }
        let prospect = <FontAwesomeIcon className="actionCheck" icon={faSquare} />
        if (e.prospect !== undefined && e.prospect === true) {
            prospect = <FontAwesomeIcon className="actionCheck" icon={faCheckSquare} />
        }

        return {
            id: e.id,
            key: "request_" + e.type + "_" + e.id, //+"_"+e.Entites_Nom,
            typefinal: typeFinal,
            type: e.type,
            name: e.name1 + " " + e.name2,
            entity: e.entity,
            code: e.code,
            fonction: e.fonction,
            email: e.email,
            vip: vip,
            prospect: prospect
        }
    }

    render() {
        //const { searchs } = this.props;
        const { requests } = this.props;

        let pageDefault = {
            current: 1,
            skip: 0,
            limit: 200,
            totalSize: 0,
            go: false
        };

        const properties = [
            { short: "typefinal", name: "Type", width: "10%", category: "classic" },
            { short: "code", name: "Code", width: "10%", category: "classic"},
            { short: "name", name: "Nom", width: "10%", category: "classic" },
            { short: "entity", name: "Entité", width: "10%", category: "classic"},
            { short: "fonction", name: "Fontion", width: "10%", category: "classic"},
            { short: "email", name: "Email", width: "10%", category:"classic"},
            { short: "vip", name: "VIP", width: "10%", category: "classic"},
            { short: "prospect", name: "Prospect", width: "10%", category: "classic"}
        ];

        const filters = [
            /*{ type: "search", size: 75, name: "searchText", placeholder: "Recherche par nom" },
            { type: "select", size: 25, name: "searchType", placeholder: "Type", options: searchType },*/
            {
                type: "button",
                size: 25,
                name: "",
                title: "Télécharger en format Excel",
                icon: faDownload,
                fn: () => {
                    console.log(requests)
                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    const ws = XLSX.utils.json_to_sheet(requests.requests);
                    const wb = { Sheets: { 'data': ws}, SheetNames: ['data']};
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
                    const data = new Blob([excelBuffer], {type: fileType});

                    FileSaver.saveAs(data, 'Requête.xlsx');
                }
            }
        ]

        let portlet = {
            title: "Recherche",
            links: [
                { name: "Recherche", path: "/sic/businessesphasesprintsearch" }
            ]
        }

        if (this.props.requests.requests) {
            for (let i = 0; i < this.props.requests.requests.length; i++) {
                if (this.props.requests.requests[i] && this.props.requests.requests[i].name1 === null)
                    this.props.requests.requests[i].name1 = "";
                if (this.props.requests.requests[i] && this.props.requests.requests[i].name2 === null)
                    this.props.requests.requests[i].name2 = "";
            }
        }
        return (

            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        getElementsAll={getRequestsAll}
                        resetElements={resetRequests}
                        elements={this.props.requests.requests}
                        properties={properties}
                        filters={filters}
                        page={pageDefault}
                        renderTd={this.renderTd}
                        pending={this.props.requests.pending}
                        noDataMessage={"Pas de résultat"}
                        /*clickAction={(e) => {
                            switch (e.type) {
                                case "contact":
                                    history.push("/sic/contact/" + e.id)
                                    break;
                                case "entity":
                                    history.push("/sic/entity/" + e.id)
                                    break;
                                case "business":
                                    history.push("/sic/business/" + e.id)
                                    break;
                                case "project":
                                    history.push("/sic/project/" + e.id)
                                    break;
                                case "eproject":
                                    history.push("/sic/eproject/" + e.id)
                                    break;
                                default:
                                    break;
                            }
                        }}*/
                    />
                </FullCenter>
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetRequests,
            getRequestsAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        requests: state.requests,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessesPhasesPrintSearch);
