import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import moment from 'moment';
import history from '../../history';
import { TileContainer, Tile, TileHeader, TileBody, TileTabs, TileTab } from '../../gj_modules/gj-strap/components/partials/';

import { resetEntities, getEntitiesAll, addEntity, deleteEntity } from '../../actions/entities'
//import Spinner from 'gj_modules/gj-strap/components/display/Spinner';
import { Form, Panel, PanelButton } from '../../gj_modules/gj-strap/components/form';
import FullCenter from '../../gj_modules/gj-strap/components/partials/FullCenter';
import FooterCicanord from '../partials/FooterCicanord';

import { ComplexAll } from '../../gj_modules/gj-strap/components/complex'

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { modalFunction } from '../../gj_modules/gj-strap/components/partials';

import { required } from '../../lib/validate';

class Entities extends Component {
    componentDidMount() {

    }

    renderTd = (e) => {
        return {
            id: e.Entites_ID,
            key: e.Entites_ID,
            code: e.Entites_Code,
            name: e.Entites_Nom,
            date: moment(e.Entites_DateCreation).format("DD/MM/YYYY")
        }
    }

    render() {
        let pageDefault={
            current: 1,
            skip: 0,
            limit: 200,
            totalSize: 0,
            go: false
        };

        const { entities, addEntity, deleteEntity } = this.props;

        const properties = [
            { short: "code", name: "Code", width: "35%", orderby: true },
            { short: "name", name: "Name", width: "40%", orderby: true, orderbydefault: true, orderbywaydefault: "asc" },
            { short: "date", name: "Date", width: "14%", orderby: true },
            { short: "actions", name: "Actions", width: "11%", category: "actions" }
        ];

        const filters = [
            { type: "search", size: 40, name: "searchEntitiesByCode", placeholder: "Recherche par code" },
            { type: "search", size: 40, name: "searchEntitiesByName", placeholder: "Recherche par nom" },
            {
                type: "button",
                size: 20,
                name: "",
                title: "Ajouter une entité",
                icon: faPlusCircle,
                fn: () => {
                    modalFunction({
                        submit: (values) => {
                            if (!entities.entities.pendingAdd) {
                                //console.log(values)
                                addEntity(values).then(
                                    function (response) {
                                        history.push("/sic/entity/" + response.response.data.entity)
                                    }
                                );
                            }
                        },
                        text: (
                            <Panel
                                width={[3, 9]}
                                inputs={[
                                    { short: "name", name: "Nom", type: "text", placeholder: "Nom", validate: required() }
                                ]}
                            />
                        ),
                        header: "Ajouter une entité",
                        state: "entities.pendingAdd"
                    }, "popup")
                }
            }
        ]

        let portlet = {
            title: "Entites",
            links: [
                { name: "Recherche", path: "/sic/entities" }
            ]
        }

        return (
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        getElementsAll={getEntitiesAll}
                        resetElements={resetEntities}
                        elements={this.props.entities.entities}
                        properties={properties}
                        pending={this.props.entities.pending}
                        filters={filters}
                        page={pageDefault}
                        renderTd={this.renderTd}
                        noDataMessage={"Pas d'entité"}
                        deleteAction={(e) => {
                            modalFunction({
                                btnFunction: () => {
                                    console.log(deleteEntity(e))
                                },
                                btnText: "Confirmer",
                                text: "Êtes-vous sûr ?",
                                header: "Supprimer une entité",
                                state: "entities.pendingDelete"
                            }, "popup")
                        }}
                        viewAction={(id) => (history.push("/sic/entity/" + id))}
                    />
                </FullCenter>
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetEntities,
            getEntitiesAll,
            addEntity,
            deleteEntity
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        entities: state.entities,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Entities);
