import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//gj-strap
import { ComplexAllMini } from '../../gj_modules/gj-strap/components/complex'
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
import { Panel } from '../../gj_modules/gj-strap/components/form';
import { processSubmit /*, checkNull, toolTip, textSlice*/ } from '../../gj_modules/gj-strap/lib';
import { required } from '../../gj_modules/gj-strap/lib/validate';
//actions
import { resetBusinessesPhases, getBusinessesPhasesAll, addBusinessesPhases, deleteBusinessesPhases, updateBusinessesPhasesById } from '../../actions/businessesPhases';
//constants
//import { reminderType } from 'data/constants';
//current
import { phasesFormat } from '../../lib';
//design
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

class BusinessPhases extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    render() {

        const { businessesPhasesState, business, addBusinessesPhases, deleteBusinessesPhases, updateBusinessesPhasesById } = this.props;

        let styleBackground = { backgroundColor: "#EEEEEE", borderBottom: "1px solid #EEEEEE" }
        //let styleBackground2 = {backgroundColor:"#FDFDFD", borderBottom:"1px solid #FDFDFD"}

        const properties = [ // DataTable Headers
            { short: "name", width: "5%", category: "classic" },

            { short: "cicanordlength", width: "4%", category: "classic", style: [styleBackground] },
            { short: "cicanorddatestart", width: "4%", category: "classic" },
            { short: "cicanorddateend", width: "4%", category: "classic" },

            { short: "plannedlength", width: "4%", category: "classic", style: [styleBackground] },
            { short: "planneddatestart", width: "4%", category: "classic" },
            { short: "planneddateend", width: "4%", category: "classic" },

            { short: "differencea", width: "4%", category: "classic" },

            { short: "donelength", width: "4%", category: "classic", style: [styleBackground] },
            { short: "donedatestart", width: "4%", category: "classic" },
            { short: "donedateend", width: "4%", category: "classic" },

            { short: "differenceb", width: "4%", category: "classic" },

            { short: "comment", width: "5%", category: "classic" },
            { short: "actions", width: "5%", category: "actions" }
        ];

        const filterSort = { type: "hidden", name: "sortBusinessId", value: "" + business.info.Affaires_ID + "" }

        const localGetBusinessesPhasesAll = this.props.getBusinessesPhasesAll;

        const filters = [
            { type: "search", size: 75, name: "searchBusinessesByCode", placeholder: "Recherche par code" },
            filterSort,
            {
                type: "button",
                size: 25,
                name: "",
                title: "Ajouter",
                icon: faPlusCircle,
                fn: () => {
                    //let cacheUserIds = [];

                    if (!businessesPhasesState.pendingAdd) {
                        modalFunction({
                            submit: (values) => {
                                if (!businessesPhasesState.pendingAdd) {
                                    console.log(business.info.Affaires_ID)
                                    console.log(values)
                                    addBusinessesPhases(business.info.Affaires_ID, processSubmit(values)).then(
                                        function (response) {
                                            localGetBusinessesPhasesAll({ skip: 0, limit: 20, filter: { sortBusinessId: business.info.Affaires_ID } });
                                        }
                                    );
                                }
                            },
                            text: (
                                <Panel
                                    width={[3, 9]}
                                    inputs={[
                                        { short: "name", name: "Nom", type: "text", placeholder: "Nom", validate: required() },
                                        { short: "cicanordLength", name: "Durée contractuelle", type: "text", placeholder: "1.5" },
                                        {
                                            short: "cicanordDate", name: "Contractuelles", type: "daterange", placeholder: "", data: {
                                                anytime: true,
                                                alldays: true
                                            }
                                        },
                                        { short: "plannedLength", name: "Durée prévue", type: "text", placeholder: "1.5" },
                                        {
                                            short: "plannedDate", name: "Prévues", type: "daterange", placeholder: "", data: {
                                                anytime: true,
                                                alldays: true
                                            }
                                        },
                                        { short: "doneLength", name: "Durée réalisée", type: "text", placeholder: "1.5" },
                                        {
                                            short: "doneDate", name: "Réalisées", type: "daterange", placeholder: "", data: {
                                                anytime: true,
                                                alldays: true
                                            }
                                        },
                                        { short: "comment", name: "Commentaires", type: "textarea", placeholder: "Commentaires" },
                                    ]}
                                />
                            ),
                            header: "Ajouter une phase",
                            state: "businessesphases.pendingAdd"
                        }, "popup")
                    }
                }
            }
        ]

        let array = phasesFormat(businessesPhasesState.businessesphases)

        //array = actionsFormat(actions.actions, setStateAction)

        return (
            <React.Fragment>
                <ComplexAllMini
                    getElementsAll={getBusinessesPhasesAll}
                    resetElements={resetBusinessesPhases}
                    properties={properties}
                    filters={filters}
                    collection={array}
                    elements={businessesPhasesState.businessesphases}
                    noDataMessage={"Pas de phases"}
                    pending={businessesPhasesState.pending}
                    deleteAction={(e) => {
                        modalFunction({
                            btnFunction: () => {
                                deleteBusinessesPhases(e).then(
                                    function (response) {
                                        localGetBusinessesPhasesAll({ skip: 0, limit: 20, filter: { sortBusinessId: business.info.Affaires_ID } });
                                    }
                                )
                            },
                            btnText: "Confirmer",
                            text: "Êtes-vous sûr ?",
                            header: "Supprimer une phase",
                            state: "businessesphases.pendingDelete"
                        }, "popup")
                    }}
                    editAction={(id) => {

                        let phases = businessesPhasesState.businessesphases;

                        let index = -1;
                        for (let i = 0; i < phases.length; i++) {
                            if (id === phases[i].id) {
                                index = i;
                            }
                        }

                        modalFunction({
                            submit: (values) => {
                                if (!businessesPhasesState.pendingUpdate) {
                                    updateBusinessesPhasesById(id, processSubmit(values))
                                }
                            },
                            initialValues: {
                                name: (phases[index].name) || "",
                                cicanordLength: phases[index].cicanordLength,
                                cicanordDate: { startDate: phases[index].cicanordDateStart, endDate: phases[index].cicanordDateEnd },
                                plannedLength: phases[index].plannedLength,
                                plannedDate: { startDate: phases[index].plannedDateStart, endDate: phases[index].plannedDateEnd },
                                doneLength: phases[index].doneLength,
                                doneDate: { startDate: phases[index].doneDateStart, endDate: phases[index].doneDateEnd },
                                comment: (phases[index].comment) || ""
                            },
                            text: (
                                <Panel
                                    width={[3, 9]}
                                    inputs={[
                                        { short: "name", name: "Nom", type: "text", placeholder: "Nom", validate: required() },
                                        { short: "cicanordLength", name: "Durée contractuelle", type: "text", placeholder: "1.5" },
                                        {
                                            short: "cicanordDate", name: "Contractuelles", type: "daterange", placeholder: "", data: {
                                                anytime: true,
                                                alldays: true
                                            }
                                        },
                                        { short: "plannedLength", name: "Durée prévue", type: "text", placeholder: "1.5" },
                                        {
                                            short: "plannedDate", name: "Prévues", type: "daterange", placeholder: "", data: {
                                                anytime: true,
                                                alldays: true
                                            }
                                        },
                                        { short: "doneLength", name: "Durée réalisée", type: "text", placeholder: "1.5" },
                                        {
                                            short: "doneDate", name: "Réalisées", type: "daterange", placeholder: "", data: {
                                                anytime: true,
                                                alldays: true
                                            }
                                        },
                                        { short: "comment", name: "Commentaires", type: "textarea", placeholder: "Commentaires" },
                                    ]}
                                />
                            ),
                            header: "Modifier une phase",
                            state: "businessesphases.pendingUpdate"
                        }, "popup")

                    }}
                />
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetBusinessesPhases,
            getBusinessesPhasesAll,
            addBusinessesPhases,
            updateBusinessesPhasesById,
            deleteBusinessesPhases
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        //businessState: state.businesses.business
        businessesPhasesState: state.businessesphases
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessPhases);