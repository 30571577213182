import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getManualData } from '../../actions/index';

import Print from '../partials/Print'

class BusinessDirectoryPrint extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, pdf: null };
    }

    componentDidMount() {
        const { match } = this.props;
        const id = match && match.params && match.params.id;

        if (id) {
            console.log(this.props.location.search)
            getManualData(true, '/businesses/' + id + '/print/directories' + this.props.location.search).then(response => {
                let pdf = atob(response.response.data.pdf);

                this.setState({
                    loading: false,
                    pdf: pdf
                });
            });
        }
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    render() {
        const { loading, pdf } = this.state;

        return (
            <React.Fragment>
                <Print loading={loading} pdf={pdf}></Print>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessDirectoryPrint);