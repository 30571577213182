export default function textSlice(value, length) {
    if(value == null){
        return "";
    }else{
        if(value.length > length){
            return value.slice(0, length)+"...";
        }else{
            return value;
        }
    }
}