import { getData, postData, putData, deleteData } from './index';
import {
    ACTIVITIES_ALL,
    ACTIVITIES_ALL_PENDING,
    ACTIVITIES_ADD,
    ACTIVITIES_ADD_PENDING,
    ACTIVITIES_UPDATE,
    ACTIVITIES_UPDATE_PENDING,
    ACTIVITIES_DELETE,
    ACTIVITIES_DELETE_PENDING,
    ACTIVITIES_ERROR,
    ACTIVITIES_RESET
} from './types';

import { dataToUrl } from '../lib'

export function resetActivities() {
	return dispatch => dispatch({ type: ACTIVITIES_RESET });
}

export function getActivitiesAll(data) {
    const url = '/activities/all'+dataToUrl(data);
    return dispatch => getData(ACTIVITIES_ALL, ACTIVITIES_ALL_PENDING, ACTIVITIES_ERROR, true, url, dispatch);
}

export function addActivity(data) {
    const url = '/activities/add';
    return dispatch => postData(ACTIVITIES_ADD, ACTIVITIES_ADD_PENDING, ACTIVITIES_ERROR, true, url, dispatch, data);
}

export function updateActivity(id, data) {
    const url = '/activities/update/'+id;
    return dispatch => putData(ACTIVITIES_UPDATE, ACTIVITIES_UPDATE_PENDING, ACTIVITIES_ERROR, true, url, dispatch, data);
}

export function deleteActivity(id) {
    const url = '/activities/delete/'+id;
    return dispatch => deleteData(ACTIVITIES_DELETE, ACTIVITIES_DELETE_PENDING, ACTIVITIES_ERROR, true, url, dispatch);
}
