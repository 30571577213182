import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import FooterCicanord from '../partials/FooterCicanord';

import history from '../../history';
import moment from 'moment';

import { Full, TileContainer, Tile, TileHeader, TileBody, Cover, modalFunction } from '../../gj_modules/gj-strap/components/partials';
import { Form, Panel, PanelButton } from '../../gj_modules/gj-strap/components/form';
import { Row, Col, Button } from 'react-bootstrap';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons'
import { required } from '../../lib/validate';

import { getManualData, savePdf } from '../../actions/index';

import { getProjectById, resetProjects, updateProjectById, deleteProject } from '../../actions/projects';
import { getEntitiesAll } from '../../actions/entities';
import { getContactsAll } from '../../actions/contacts';
import { getUsersAll } from '../../actions/user'

import { returnFunction, processSubmit, checkNull } from '../../gj_modules/gj-strap/lib';

import { projectCategory, projectStatusType } from '../../data/constants';

import ProjectEntities from './ProjectEntities';
import ProjectActions from './ProjectActions';
import ProjectInformations from './ProjectInformations';

import { addBusinesses } from '../../actions/businesses'

import { addEproject } from '../../actions/eprojects';

import Rank from '../functions/Rank'

export const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

class Project extends Component {
    componentDidMount() {
        const { match, getProjectById, resetProjects } = this.props;
        const id = match && match.params && match.params.id;
        resetProjects();
        if (id) {
            getProjectById(match.params.id);
        }
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    submitEdit(values) {
        const { match, updateProjectById, project } = this.props;
        const id = match && match.params && match.params.id;
        //console.log(processSubmit(values));
        if (project && !project.pendingUpdate) {
            updateProjectById(id, processSubmit(values));
        }
    }

    render() {
        const { match, project, deleteProject, getContactsAll, getUsersAll, businesses, addBusinesses, eprojects, addEproject } = this.props;
        const id = match && match.params && match.params.id;

        //console.log("PROJECT", project);

        let portlet = {
            title: "Projets",
            path: "/sic/projects",
            links: [
                //{ name: "Recherche", path: "/sic/projects" },
                { name: "Fiche projet", path: "/sic/project/" + id }
            ],
            actions: [
                {
                    title: "Télécharger la fiche",
                    //variant:"success",
                    function: function () {
                        getManualData(true, '/projects/' + id + '/print').then(response => {
                            console.log.apply(response.response.data);
                            let pdf = atob(response.response.data.pdf);
                            savePdf(pdf, project.info.pdfTitle + ".pdf");
                        });
                    }
                },
                {
                    title: "Imprimer",
                    function: function () {
                        //history.push("/sic/project/"+id+"/print");
                        window.open("" + (REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "") + "/sic/project/" + id + "/print", '_blank');
                    }
                }
            ]
        }
        return (
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />

                {project && !project.pending ? (

                    <Full portlet={portlet}>
                        <TileContainer>
                            <Tile size={"full"}>
                                <TileBody>
                                    <Cover
                                        icon={faPencilRuler}
                                        title={project.info.name}
                                        subtitle={checkNull(project.info.department) + " " + checkNull(project.info.adressCity)}
                                    >
                                        Projet créé le {moment(project.info.dateCreate).format("DD/MM/YYYY")}<br />
                                        Dernière modification le {moment(project.info.dateUpdate).format("DD/MM/YYYY")}
                                    </Cover>
                                </TileBody>
                            </Tile>
                        </TileContainer>

                        <Row>
                            <Col lg="6" className="tile-col">

                                {/* Général */}
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileHeader title="Général" border={true} />
                                        <TileBody>

                                            <Form
                                                onSubmit={values => this.submitEdit(values)}
                                                initialValues={{
                                                    name: (project.info.name) || "",
                                                    code: (project.info.code) || "",
                                                    statusType: project.info.statusType,
                                                    adressCity: (project.info.adressCity) || "",
                                                    department: (project.info.department) || "",
                                                    adressLines: (project.info.adressLines) || "",
                                                    //entityId:Number(project.info.entityId),
                                                    userId: Number(project.info.userId),
                                                    moa: (project.info.moa) || "",
                                                    costPreview: (project.info.costPreview) || "",
                                                    contactId: Number(project.info.contactId),
                                                    description: (project.info.description) || "",
                                                    category: project.info.category,
                                                    planningPreviewStudies: (project.info.planningPreviewStudies) || "",
                                                    planningPreviewWork: (project.info.planningPreviewWork) || "",
                                                }}
                                            >

                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        { short: "name", name: "Nom", type: "text", placeholder: "Nom du projet" },
                                                        { short: "code", name: "Numéro de projet", type: "text", placeholder: "Numéro de projet" },
                                                        {
                                                            short: "statusType", name: "Statut", type: "select", placeholder: "", data: {
                                                                isMulti: false,
                                                                options: projectStatusType
                                                            }
                                                        },
                                                        { short: "adressCity", name: "Ville", type: "text", placeholder: "Ville" },
                                                        { short: "department", name: "Département", type: "text", placeholder: "00000" },
                                                        { short: "adressLines", name: "Adresse", type: "text", placeholder: "Rue" },
                                                        { short: "moa", name: "MOA", type: "text", placeholder: "MOA" },
                                                        { short: "costPreview", name: "Coût", type: "text", placeholder: "Coût des travaux" },
                                                        /*{
                                                            short: "entityId", name: "MOA", type: "select", placeholder: "Entité", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                defaultValue: { value:Number(project.info.entityId), label:project.info.entityLabel, force:true },
                                                                loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 5, "entities.entities", "Entites_ID", "Entites_Nom")
                                                            }
                                                        },*/
                                                        {
                                                            short: "contactId", name: "Contact MOA", type: "select", placeholder: "Contact", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                defaultValue: { value: Number(project.info.contactId), label: (checkNull(project.info.Personnes_Prenom) + " " + checkNull(project.info.Personnes_Nom)), force: true },
                                                                loadOptions: returnFunction(getContactsAll, "searchContactsByName", 5, "contacts.contacts", "Personnes_ID", ["Personnes_Prenom", "Personnes_Nom"])
                                                            }
                                                        },
                                                        {
                                                            short: "userId", name: "Intervenant Cicanord", type: "select", placeholder: "Utilisateur", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                defaultValue: { value: Number(project.info.userId), label: project.info.userLabel, force: true },
                                                                loadOptions: returnFunction(getUsersAll, "searchUsersByName", 5, "user.users", "Utilisateurs_ID", ["Utilisateurs_Prenom", "Utilisateurs_Nom"])
                                                            }
                                                        },
                                                        {
                                                            short: "category", name: "Catégorie", type: "select", placeholder: "Catégorie", data: {
                                                                isMulti: false,
                                                                options: projectCategory
                                                            }
                                                        },
                                                        { short: "description", name: "Description", type: "textarea", placeholder: "Description", data: { height: "90px" } },
                                                        { short: "planningPreviewStudies", name: "Planning des études", type: "textarea", placeholder: "Planning prévisionnel des études", data: { height: "90px" } },
                                                        { short: "planningPreviewWork", name: "Planning des travaux", type: "textarea", placeholder: "Planning prévisionnel des travaux", data: { height: "90px" } }
                                                    ]}
                                                />

                                                <PanelButton
                                                    pending={project.pendingUpdate}
                                                    message={project.message}
                                                />

                                            </Form>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>

                                {/* Eproject Convert */}
                                <Rank need={"admin"}>
                                    <TileContainer>
                                        <Tile size={'full'}>
                                            <TileBody>
                                                <Button onClick={function () {
                                                    modalFunction({
                                                        submit: (values) => {
                                                            if (!eprojects.pendingAdd) {
                                                                console.log(processSubmit(values));
                                                                addEproject(values).then(
                                                                    function (response) {
                                                                        history.push("/sic/eproject/" + response.response.data.eprojects)
                                                                    }
                                                                );
                                                                deleteProject(id, values);
                                                            }
                                                        },
                                                        initialValues: {
                                                            name: (project.info.name) || "",
                                                            adressCity: (project.info.adressCity) || "",
                                                            adressPostalCode: (project.info.adressPostalCode) || "",
                                                            adressLines: (project.info.adressLines) || "",
                                                            moa: (project.info.moa) || "",
                                                            category: (project.info.category) || "",
                                                            description: (project.info.description) || "",
                                                            idProject: project.info.id,
                                                        },
                                                        text: (
                                                            <Panel
                                                                width={[2, 9]}
                                                                inputs={[
                                                                    { short: "idProject", type: "hidden" },
                                                                    { short: "name", name: "Nom", type: "text", placeholder: "Nom", validate: required() },
                                                                    { short: "adressCity", name: "Ville", type: "text", placeholder: "Ville"},
                                                                    { short: "department", name: "Département", type: "text", placeholder: "00000"},
                                                                    { short: "adressLines", name: "Adresse", type: "text", placeholder: "Adresse"},
                                                                    { short: "moa", name: "MOA", type: "text", placeholder: "MOA"},
                                                                    { short: "category", name: "Catégorie", type: "select", placeholder: "Catégorie", data: {
                                                                        isMulti: false,
                                                                        options: projectCategory
                                                                    }},
                                                                    {
                                                                        short: "description", name: "Description", type: "textarea", placeholder: "Description", data:{ height:"90px" }
                                                                    },
                                                                ]}
                                                            />
                                                        ),
                                                        header: "Convertir en projet externe",
                                                        state: "eprojects.pendingAdd"
                                                    }, "popup")
                                                }}
                                                    className="float-sm-right">
                                                    Convertir en projet externe
                                                </Button>
                                            </TileBody>
                                        </Tile>
                                    </TileContainer>
                                </Rank>

                                {/* Business Convert */}
                                <Rank need={"admin"}>
                                    <TileContainer>
                                        <Tile size={'full'}>
                                            <TileBody>
                                                <Button
                                                    onClick={function () {
                                                        modalFunction({
                                                            submit: (values) => {
                                                                if (!businesses.pendingAdd) {
                                                                    console.log(processSubmit(values))
                                                                    addBusinesses(values).then(
                                                                        function (response) {
                                                                            history.push("/sic/business/" + response.response.data.business)
                                                                        }
                                                                    );
                                                                    deleteProject(id, values);
                                                                }
                                                            },
                                                            initialValues: {
                                                                name: (project.info.name) || "",
                                                                idProject: project.info.id
                                                                /*description: (project.informations[index].description) || "",
                                                                date:moment(project.informations[index].dateTarget).format("YYYY-MM-DD"),
                                                                entityId:Number(project.informations[index].Entites_ID),
                                                                path: (project.informations[index].path) || "",*/
                                                            },
                                                            text: (
                                                                <Panel
                                                                    width={[3, 9]}
                                                                    inputs={[
                                                                        { short: "idProject", type: "hidden" },
                                                                        { short: "name", name: "Nom", type: "text", placeholder: "Name", validate: required() },
                                                                        { short: "code", name: "Code", type: "text", placeholder: "Code", validate: required() },
                                                                    ]}
                                                                />
                                                            ),
                                                            header: "Convertir en affaire",
                                                            state: "businesses.pendingAdd"
                                                        }, "popup")
                                                    }}
                                                    className="float-sm-right"
                                                >
                                                    Convertir en affaire
                                                </Button>
                                            </TileBody>
                                        </Tile>
                                    </TileContainer>
                                </Rank>

                                {/* Delete */}
                                <Rank need={"admin"}>
                                    <TileContainer>
                                        <Tile size={'full'}>
                                            <TileBody>
                                                <Button
                                                    onClick={function () {
                                                        modalFunction({
                                                            btnFunction: () => {
                                                                deleteProject(id).then(
                                                                    function (response) {
                                                                        history.push("/sic/projects")
                                                                    }
                                                                );
                                                            },
                                                            btnText: "Confirmer",
                                                            text: "Êtes-vous sûr de supprimer ce projet ?",
                                                            header: "Supprimer un projet",
                                                            state: "projects.pendingDelete"
                                                        }, "popup")
                                                    }}
                                                    className="float-sm-right"
                                                    variant="danger"
                                                >
                                                    Supprimer ce projet
                                                </Button>
                                            </TileBody>
                                        </Tile>
                                    </TileContainer>
                                </Rank>
                            </Col>

                            <Col lg="6" className="tile-col">

                                {/* Intervenants */}
                                <ProjectEntities
                                    project={project}
                                    idProject={id}
                                />

                                {/* Actions */}
                                <ProjectActions
                                    project={project}
                                    idProject={id}
                                />

                                {/* Informations */}
                                <ProjectInformations
                                    project={project}
                                    idProject={id}
                                />
                            </Col>

                        </Row>

                    </Full>
                ) : (
                    <Full>
                        Chargement
                    </Full>
                )}
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetProjects,
            getProjectById,
            updateProjectById,
            deleteProject,
            getEntitiesAll,
            getContactsAll,
            getUsersAll,
            addBusinesses,
            addEproject
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        project: state.projects.project,
        businesses: state.businesses,
        eprojects: state.eprojects
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Project);