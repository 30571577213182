import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
//gj-strap
import { TileContainer, Tile, TileHeader, TileBody, TileFooter } from '../../gj_modules/gj-strap/components/partials';
import { Panel, DataTable } from '../../gj_modules/gj-strap/components/form';
//actions
import { addContactEntity, updateContactEntity, deleteContactEntity } from '../../actions/contacts';
import { getEntitiesAll, getEntityById, resetEntities } from '../../actions/entities';

import { returnFunction/*, processSubmit, labelByValue, checkNull*/ } from '../../gj_modules/gj-strap/lib';
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
import { required } from '../../lib/validate';
import moment from 'moment';

//design
import { Button } from 'react-bootstrap';
import './ContactEntities.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

class ContactEntities extends Component {

	constructor(props) {
		super(props);
		this.state = {
			entityId: null
		};
	}

	componentDidMount() {
		const { match, resetEntities, getEntityById } = this.props;
        const id = match && match.params && match.params.id;

        resetEntities();

        if (id) {
            getEntityById(match.params.id);
        }


		/*if (this.props.contact.entites) {
			this.setState({
				entityId: this.props.contact.entites[0].PersonnesEntites_IDEntites
			})
			//this.props.getEntityById(this.props.contact.entites[0].PersonnesEntites_IDEntites)
		}*/
	}

	componentDidUpdate() {
		//console.log("didUpdate");
	}

	entityDetailsRender = () => {

		let entity = null;

		if (this.props.contact.entites) {
			for (let e of this.props.contact.entites) {
				if (e.Entites_ID === this.state.entityId) {
					entity = e;
				}
			}
		}

		for (let i in entity) {
			if (entity[i] === "null" || entity[i] === null || entity[i] === "") {
				delete entity[i];
			}
		}

		return (
			entity ? (
				<React.Fragment>
					{entity.PersonnesEntites_Archivage && <p>{"Archivage: " + entity.PersonnesEntites_Archivage}</p>}
					{entity.PersonnesEntites_DateEntree && <p>{"Date d'Entrée: " + moment(entity.PersonnesEntites_DateEntree).format("DD/MM/YYYY")}</p>}
					{entity.PersonnesEntites_DateSommeil && <p>{"Date de sommeil: " + moment(entity.PersonnesEntites_DateSommeil).format("DD/MM/YYYY")}</p>}
					{entity.PersonnesEntites_DateSortie && <p>{"Date de Sortie: " + moment(entity.PersonnesEntites_DateSortie).format("DD/MM/YYYY")}</p>}
					{entity.PersonnesEntites_FonctionLibre && <p>{"Fonction Libre: " + entity.PersonnesEntites_FonctionLibre}</p>}
					{/*entity.PersonnesEntites_ID && <p>{"ID: " + entity.PersonnesEntites_ID}</p>*/}
					{/*entity.PersonnesEntites_IDEntites && <p>{"IDEntites: " + entity.PersonnesEntites_IDEntites}</p>*/}
					{/*entity.PersonnesEntites_IDPersonnes && <p>{"IDPersonnes: " + entity.PersonnesEntites_IDPersonnes}</p>*/}
					{entity.PersonnesEntites_Salarie && <p>{"Salarié: " + entity.PersonnesEntites_Salarie}</p>}
					{entity.PersonnesEntites_Sommeil && <p>{"Sommeil: " + entity.PersonnesEntites_Sommeil}</p>}
					{/*entity.PersonnesEntites_SommeilPersonnes_ID && <p>{"SommeilPersonnes_ID: " + entity.PersonnesEntites_SommeilPersonnes_ID}</p>*/}
					{/**/}
					{entity.PersonnesEntites_Status ? <p>{"Statut: " + entity.PersonnesEntites_Status}</p> : ""}
					{
						entity.PersonnesEntites_TelephonePortableProfessionel &&
						<p>
							{"Téléphone Professionnel Portable: " + entity.PersonnesEntites_TelephonePortableProfessionel +
								" (Diffusable: "}
							{entity.PersonnesEntites_TelephonePortableProfessionelDiffusable ?
								<React.Fragment><FontAwesomeIcon icon={faCheck} /></React.Fragment>
								:
								<React.Fragment><FontAwesomeIcon icon={faTimes} /></React.Fragment>}
							{")"}
						</p>

					}

					{
						entity.PersonnesEntites_TelephoneProfessionel &&
						<p>
							{"Téléphone Professionnel: " + entity.PersonnesEntites_TelephoneProfessionel +
								" (Diffusable: "}
							{entity.PersonnesEntites_TelephoneProfessionelDiffusable ?
								<React.Fragment><FontAwesomeIcon icon={faCheck} /></React.Fragment>
								:
								<React.Fragment><FontAwesomeIcon icon={faTimes} /></React.Fragment>}
							{")"}
						</p>
					}

					{
						entity.PersonnesEntites_EmailProfessionel &&
						<p>{"Email Professionnel: " + entity.PersonnesEntites_EmailProfessionel +
							" (Diffusable: "}
							{entity.PersonnesEntites_EmailProfessionelDiffusable ?
								<React.Fragment><FontAwesomeIcon icon={faCheck} /></React.Fragment>
								:
								<React.Fragment><FontAwesomeIcon icon={faTimes} /></React.Fragment>}
							{")"}
						</p>

					}

					{
						entity.PersonnesEntites_FaxProfessionel &&
						<p>{"Fax Professionnel: " + entity.PersonnesEntites_FaxProfessionel +
							" (Diffusable: "}
							{entity.PersonnesEntites_FaxProfessionelDiffusable ?
								<React.Fragment><FontAwesomeIcon icon={faCheck} /></React.Fragment>
								:
								<React.Fragment><FontAwesomeIcon icon={faTimes} /></React.Fragment>}
							{")"}
						</p>

					}

					{entity.PersonnesEntites_TelephoneSecretaire && <p>{"Téléphone Secrétaire: " + entity.PersonnesEntites_TelephoneSecretaire}</p>}

					{entity.PersonnesEntites_dateCreate && <p>{"Créé le: " + moment(entity.PersonnesEntites_dateCreate).format("DD/MM/YYYY à HH:MM:SS")}</p>}
					{entity.PersonnesEntites_dateUpdate && <p>{"Modifié le: " + moment(entity.PersonnesEntites_dateUpdate).format("DD/MM/YYYY à HH:MM:SS")}</p>}
				</React.Fragment>
			) : null
		)
	}

	dataTablePersonsRender = () => {

		const { contact, entities, updateContactEntity, deleteContactEntity } = this.props;

		const properties = [ // DataTable Headers
			{ short: "name", width: "35%", category: "classic" },
			{ short: "freefunction", width: "35%", category: "classic" },
			{ short: "actions", width: "30%", category: "actions" }
		];

		const array = [];

		if (entities && entities.length) {
			for (let i = 0; i < entities.length; i++) {
				const object = {
					id: entities[i].Entites_ID,
					name: (<Link to={"/sic/entity/" + entities[i].Entites_ID}>{entities[i].Entites_Nom}</Link>),
					freefunction: (<React.Fragment>
						{entities[i].PersonnesEntites_FonctionLibre}
						<br />
						{
							entities[i].PersonnesEntites_DateSortie ?
								moment(entities[i].PersonnesEntites_DateEntree).format("DD/MM/YYYY") + " - " + moment(entities[i].PersonnesEntites_DateSortie).format("DD/MM/YYYY")
								:
								"Depuis " + moment(entities[i].PersonnesEntites_DateEntree).format("DD/MM/YYYY")
						}
					</React.Fragment>),
				};
				object.key = entities[i].Entites_ID
				object.index = i + 1;

				/*if (this.props.person && this.props.person.info && entities[i].Entites_ID === this.props.person.info.Entites_ID) {
					object.style = { "border": "2px solid #4164be" }
				}*/

				array.push(object);
			}
		}

		return (
			<DataTable small
				properties={properties}
				collection={array}
				noDataMessage={"Pas d'entité(s)"}
				showAll={3}
				focus={{
					id: this.state.entityId,
					class: "focusEntity"
				}}
				clickAction={(e) => {
					this.setState({
						entityId: e.id
					})
					//this.props.getEntityById(e.id)
				}}
				editAction={(e) => {
					let index = -1;
					for (let i = 0; i < entities.length; i++) {
						if (e === entities[i].Entites_ID) {
							index = i;
						}
					}

					if (!contact.pendingEntitiesUpdate) {
						modalFunction({
							submit: (values) => {
								if (!contact.pendingEntitiesUpdate) {
									//console.log(entities[index].PersonnesEntites_ID)
									//console.log("updateEntity", values)
									updateContactEntity(entities[index].PersonnesEntites_ID, values)
								}
							},
							initialValues: {
								freefunction: (entities[index].PersonnesEntites_FonctionLibre) || "",
								phonemobile: (entities[index].PersonnesEntites_TelephonePortableProfessionel) || "",
								phonemobiledisplay: entities[index].PersonnesEntites_TelephonePortableProfessionelDiffusable,
								phone: (entities[index].PersonnesEntites_TelephoneProfessionel) || "",
								phonedisplay: entities[index].PersonnesEntites_TelephoneProfessionelDiffusable,
								fax: (entities[index].PersonnesEntites_FaxProfessionel) || "",
								faxdisplay: entities[index].PersonnesEntites_FaxProfessionelDiffusable,
								dateenter: moment(entities[index].PersonnesEntites_DateEntree).format("YYYY-MM-DD") || "",
								dateleaving: moment(entities[index].PersonnesEntites_DateSortie).format("YYYY-MM-DD") || "",
							},
							text: (
								<Panel
									width={[3, 9]}
									inputs={[
										{
											short: "freefunction", name: "Fonction libre", type: "text", placeholder: "Fonction"
										},
										{
											short: "phonemobile", name: "Téléphone portable", type: "text", placeholder: "0123456789"
										},
										{
											short: "phonemobiledisplay", name: "Diffusable", type: "checkbox", placeholder: ""
										},
										{
											short: "phone", name: "Téléphone", type: "text", placeholder: "0123456789"
										},
										{
											short: "phonedisplay", name: "Diffusable", type: "checkbox", placeholder: ""
										},
										{
											short: "fax", name: "Fax", type: "text", placeholder: "0123456789"
										},
										{
											short: "faxdisplay", name: "Diffusable", type: "checkbox", placeholder: ""
										},
										{
											short: "dateenter", name: "Date de début", type: "datesimple"
										},
										{
											short: "dateleaving", name: "Date de fin", type: "datesimple"
										}
									]}
								/>
							),
							header: "Editer un contact",
							state: "contacts.contact.pendingEntitiesUpdate"
						}, "popup")
					}
				}}
				deleteAction={(e) => {

					let index = -1;
					for (let i = 0; i < entities.length; i++) {
						if (e === entities[i].Entites_ID) {
							index = i;
						}
					}

					modalFunction({
						btnFunction: () => {
							//console.log("function delete " + entities[index].PersonnesEntites_ID)
							deleteContactEntity(entities[index].PersonnesEntites_ID)
						},
						btnText: "Confirmer",
						text: "Êtes-vous sûr ?",
						header: "Supprimer une entité",
						state: "contacts.contact.pendingEntitiesDelete"
					}, "popup")
				}}
			/>
		)
	}

	render() {
		const { contact, getEntitiesAll, addContactEntity, idContact, /*person*/ } = this.props;

		return (
			<React.Fragment>
				{/* Entities */}
				<TileContainer>
					<Tile size={"full"}>
						<TileHeader
							title="Entités"
							border={true}
							toolbar={<Button onClick={
								() => {
									if (!contact.pendingEntitiesAdd) {
										modalFunction({
											submit: (values) => {
												values["contactId"] = Number(idContact);
												//console.log(values)
												if (!contact.pendingEntitiesAdd) {
													console.log(values)
													addContactEntity(values.entityId, values)
												}
											},
											text: (
												<Panel
													width={[3, 9]}
													inputs={[
														{
															short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", validate: required(), data: {
																isMulti: false,
																isAsync: true,
																//defaultValue: { value:null, label:"", force:true },
																loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", "Entites_Nom")
															}
														}
													]}
												/>
											),
											header: "Ajouter une entité",
											state: "contacts.contact.pendingEntitiesAdd"
										}, "popup")
									}
								}
							}>
								{contact.pendingEntitiesAdd ? "O" : "+"}
							</Button>}
						/>
						<TileBody>
							{this.dataTablePersonsRender()}
						</TileBody>
						<TileFooter>
							{this.entityDetailsRender()}
							{/*person && person.info && person.info.Entites_Telephone && person.info.Entites_Telephone != null && person.info.Entites_Telephone !== "" ? <p>{"Téléphone : " + person.info.Entites_Telephone}</p> : ""*/}
							{/*person.persons[0].PersonnesEntites_TelephoneProfessionel !== null && person.persons[0].PersonnesEntites_TelephoneProfessionel !== "" && person.persons[0].PersonnesEntites_TelephoneProfessionelDiffusable === true ? <p>{"Téléphone Professionnel : " + person.persons[0].PersonnesEntites_TelephoneProfessionel}</p> : ""*/}
							{/*person.persons[0].PersonnesEntites_TelephonePortableProfessionel !== null && person.persons[0].PersonnesEntites_TelephonePortableProfessionel !== "" && person.persons[0].PersonnesEntites_TelephonePortableProfessionelDiffusable === true ? <p>{"Téléphone Professionnel Portable: " + person.persons[0].PersonnesEntites_TelephonePortableProfessionel}</p> : ""*/}
							{/*person.persons[0].PersonnesEntites_FaxProfessionel !== null && person.persons[0].PersonnesEntites_FaxProfessionel !== "" && person.persons[0].PersonnesEntites_FaxProfessionelDiffusable === true ? <p>{"Téléphone Fax : " + person.persons[0].PersonnesEntites_FaxProfessionel}</p> : ""*/}
						</TileFooter>
					</Tile>
				</TileContainer>
			</React.Fragment>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			getEntitiesAll,
			addContactEntity,
			updateContactEntity,
			deleteContactEntity,
			getEntityById,
			resetEntities
		},
		dispatch
	);
}

function mapStateToProps(state) {
	return {
		//person: state.entities.entity,
		contact: state.contacts.contact
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ContactEntities);