import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { registerUser } from '../../../actions/auth';
import { resetUser } from '../../../actions/user';
import { validateEmail, required, equalsField } from '../../../lib/validate';
import { Form, Input } from '../../../gj_modules/gj-strap/components/form';
import omit from 'lodash/omit';
import HeaderTop from '../../partials/HeaderTop';
import FullCard from '../../../gj_modules/gj-strap/components/partials/FullCard';

class Register extends Component {
    componentDidMount() {
        this.props.resetUser();
    }

    register = values => {
        this.props.registerUser(omit(values, ['confirmPassword']));
    };

    render() {
        return (
            <React.Fragment>
                <HeaderTop />

                <FullCard title="Je m'inscris">
                    <Form onSubmit={this.register} className="w-100">
                        <Input
                            type="text"
                            name="firstName"
                            label="Votre prénom"
                            placeholder="prénom"
                            validate={required()}
                        />
                        <Input
                            type="text"
                            name="lastName"
                            label="Votre nom de famille"
                            placeholder="nom"
                            validate={required()}
                        />
                        <Input
                            type="email"
                            name="email"
                            label="Votre Email"
                            placeholder="exemple@mail.com"
                            validate={validateEmail()}
                        />
                        <Input
                            type="password"
                            name="password"
                            label="Votre mot de passe"
                            placeholder="mot de passe"
                            validate={required()}
                        />
                        <Input
                            type="password"
                            name="confirmPassword"
                            label="Confirmation de votre mot de passe"
                            placeholder="entrer à nouveau le mot de passe"
                            validate={equalsField('password', 'Mot de passe différent')}
                        />
                        <p className="warn center">{this.props.errorMessage}</p>
                        <div className="d-table m-auto py-4">
                            <Button variant="primary" size="lg" type="submit">S'inscrire</Button>
                        </div>
                        <div className="signup">
                            <span style={{ color: '#B0BAC9' }}>
                                Déjà inscrit ?
                            </span>{' '}
                            <Link to="/" className="signup-link">
                                Se connecter
                            </Link>
                        </div>
                    </Form>
                </FullCard>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ registerUser, resetUser }, dispatch);
}

function mapStateToProps(state) {
    return {
        errorMessage: state.user.error,
        message: state.user.message
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Register);
