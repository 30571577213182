import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//gj-strap
import { TileContainer, Tile, TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials/';
import { Panel, DataTable } from '../../gj_modules/gj-strap/components/form';
//actions
import { updateContactVIP, addContactVIP, deleteContactVIP } from '../../actions/contacts'
import { getContactsAll, getContactById } from '../../actions/contacts';
import { getEmployeesAll } from '../../actions/employees';
//current
import { returnFunction } from '../../gj_modules/gj-strap/lib';
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
import moment from 'moment';
//design
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown, faHandPaper } from '@fortawesome/free-solid-svg-icons'

class ContactVIP extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    dataTableVIPRender = () => {
        const { contact, deleteContactVIP } = this.props;

        const properties = [ // DataTable Headers
            { short: "name", width: "35%", category: "classic" },
            { short: "freefunction", width: "35%", category: "classic" },
            { short: "actions", width: "30%", category: "actions" }
        ];

        const array = [];

        if (contact.vip && contact.vip.length) {
            for (let i = 0; i < contact.vip.length; i++) {
                const object = {
                    id: contact.vip[i].Personnes_ID,
                    name: (<Link to={"/sic/contact/" + contact.vip[i].Personnes_ID}>{contact.vip[i].Personnes_Prenom + " " + contact.vip[i].Personnes_Nom.toUpperCase()}{contact.vip[i].Personnes_Vip ? <FontAwesomeIcon className="way" icon={faCrown} /> : ""}{contact.vip[i].Personnes_Prospect ? <FontAwesomeIcon className="way" icon={faHandPaper} /> : ""}</Link>),
                    freefunction: (<React.Fragment>
                        {contact.vip[i].PersonnesEntites_FonctionLibre}
                        <br /><br />
                        {
                            contact.vip[i].PersonnesEntites_DateSortie ?
                                moment(contact.vip[i].PersonnesEntites_DateEntree).format("DD/MM/YYYY") + " - " + moment(contact.vip[i].PersonnesEntites_DateSortie).format("DD/MM/YYYY")
                                :
                                "Depuis " + moment(contact.vip[i].PersonnesEntites_DateEntree).format("DD/MM/YYYY")
                        }
                    </React.Fragment>),
                };
                object.key = contact.vip[i].Personnes_ID
                object.index = i + 1;
                array.push(object);
            }
        }

        return (
            <DataTable small
                noEditGlobal
                properties={properties}
                collection={array}
                noDataMessage={"Pas de référent"}
                showAll={3}
                deleteAction={(e) => {

                    let index = -1;
                    for (let i = 0; i < contact.vip.length; i++) {
                        if (e === contact.vip[i].Personnes_ID) {
                            index = i;
                        }
                    }

                    modalFunction({
                        btnFunction: () => {
                            //console.log("function delete " + employees[index].PersonnesEntites_ID)
                            deleteContactVIP(contact.vip[index].PersonnesContactsVIP_IDPersonnes, contact.vip[index].PersonnesContactsVIP_IDSalaries)
                        },
                        btnText: "Confirmer",
                        text: "Êtes-vous sûr ?",
                        header: "Supprimer une entité",
                        state: "contacts.contact.pendingVIPDelete"
                    }, "popup")
                }}
            />
        )
    }

    render() {

        const { contact, getEmployeesAll, addContactVIP } = this.props;

        //let vip = false;
        /*let salarieId = null;
        let salarieName = null;

        if(contact && contact.vip && contact.vip.length > 0){
            //vip = true;
            salarieId = contact.vip[0].PersonnesContactsVIP_IDSalaries;
            salarieName = contact.vip[0].Personnes_Prenom+" "+contact.vip[0].Personnes_Nom
        }*/

        return (
            <React.Fragment>
                <TileContainer>
                    <Tile size={"full"}>
                        <TileHeader
                            title="Collaborateurs Cicanord référents"
                            border={true}
                            toolbar={<Button onClick={
                                () => {
                                    if (!contact.pendingVIPAdd) {
                                        modalFunction({
                                            submit: (values) => {
                                                values["Personnes_ID"] = Number(contact.info.Personnes_ID);
                                                //console.log(values)
                                                if (!contact.pendingVIPAdd) {
                                                    console.log(values)
                                                    //getEmployeesAll();
                                                    addContactVIP(values.Personnes_ID, values)
                                                }
                                            },
                                            text: (
                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        {
                                                            short: "Salarie_ID", name: "Salarié référent", type: "select", placeholder: "", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                loadOptions: returnFunction(
                                                                    getEmployeesAll,
                                                                    [
                                                                        {
                                                                            name: "searchContactsByName",
                                                                            value: ""
                                                                        }
                                                                    ],
                                                                    20,
                                                                    "employees.employees",
                                                                    "Salarie_ID",
                                                                    ["Personnes_Prenom", "Personnes_Nom", "Entites_Nom"])
                                                            }
                                                        }
                                                    ]}
                                                />
                                            ),
                                            header: "Ajouter un référent",
                                            state: "contacts.contact.pendingVIPAdd"
                                        }, "popup")
                                    }
                                }
                            }>
                                {contact.pendingVIPAdd ? "O" : "+"}
                            </Button>}
                        />
                        <TileBody>
                            {this.dataTableVIPRender()}
                        </TileBody>
                    </Tile>
                </TileContainer>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getContactsAll,
            getEmployeesAll,
            getContactById,
            updateContactVIP,
            addContactVIP,
            deleteContactVIP
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        contact: state.contacts.contact
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactVIP);