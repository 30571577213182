import { postData, putData, getData, deleteData } from './index';
import {
    CONTACTS_ALL,
    CONTACTS_ALL_PENDING,
    CONTACTS_ID,
    CONTACTS_ID_PENDING,
    CONTACTS_ADD,
    CONTACTS_ADD_PENDING,
    CONTACTS_UPDATE,
    CONTACTS_UPDATE_PENDING,
    CONTACTS_DELETE,
    CONTACTS_DELETE_PENDING,
    CONTACT_ENTITIES_ADD,
    CONTACT_ENTITIES_ADD_PENDING,
    CONTACT_ENTITIES_UPDATE,
    CONTACT_ENTITIES_UPDATE_PENDING,
    CONTACT_ENTITIES_DELETE,
    CONTACT_ENTITIES_DELETE_PENDING,
    CONTACT_VIP_ADD,
    CONTACT_VIP_ADD_PENDING,
    CONTACT_VIP_DELETE,
    CONTACT_VIP_DELETE_PENDING,
    CONTACT_HISTO_ADD,
    CONTACT_HISTO_ADD_PENDING,
    CONTACT_HISTO_UPDATE,
    CONTACT_HISTO_UPDATE_PENDING,
    CONTACT_HISTO_DELETE,
    CONTACT_HISTO_DELETE_PENDING,
    CONTACTS_VIP_UPDATE,
    CONTACTS_VIP_UPDATE_PENDING,
    CONTACTS_RESET,
    CONTACTS_ERROR
} from './types';

import { dataToUrl } from '../lib'

export function getContactsAll(data) {
    const url = '/contacts/all'+dataToUrl(data);
    return dispatch => getData(CONTACTS_ALL, CONTACTS_ALL_PENDING, CONTACTS_ERROR, true, url, dispatch);
}

export function getContactById(id) {
    const url = '/contacts/'+id;
    return dispatch => getData(CONTACTS_ID, CONTACTS_ID_PENDING, CONTACTS_ERROR, true, url, dispatch);
}

export function addContact(data) {
    const url = '/contacts/add';
    return dispatch => postData(CONTACTS_ADD, CONTACTS_ADD_PENDING, CONTACTS_ERROR, true, url, dispatch, data);
}

export function updateContactById(id, data) {
    const url = '/contacts/update/'+id;
    return dispatch => postData(CONTACTS_UPDATE, CONTACTS_UPDATE_PENDING, CONTACTS_ERROR, true, url, dispatch, data);
}

export function deleteContact(id) {
    const url = '/contacts/delete/'+id;
    return dispatch => deleteData(CONTACTS_DELETE, CONTACTS_DELETE_PENDING, CONTACTS_ERROR, true, url, dispatch, null);
}

//Entity

export function addContactEntity(id, data) {
    const url = '/entities/addcontact/'+Number(id);
    return dispatch => postData(CONTACT_ENTITIES_ADD, CONTACT_ENTITIES_ADD_PENDING, CONTACTS_ERROR, true, url, dispatch, data);
}

export function updateContactEntity(id, data) {
    const url = '/entities/updatecontact/'+id;
    return dispatch => putData(CONTACT_ENTITIES_UPDATE, CONTACT_ENTITIES_UPDATE_PENDING, CONTACTS_ERROR, true, url, dispatch, data);
}

export function deleteContactEntity(id) {
    const url = '/entities/deletecontact/'+id;
    return dispatch => deleteData(CONTACT_ENTITIES_DELETE, CONTACT_ENTITIES_DELETE_PENDING, CONTACTS_ERROR, true, url, dispatch, null);
}

//Histo

export function addContactHisto(id, data) {
    const url = '/contacts/histo/add/'+Number(id);
    return dispatch => postData(CONTACT_HISTO_ADD, CONTACT_HISTO_ADD_PENDING, CONTACTS_ERROR, true, url, dispatch, data);
}

export function updateContactHisto(id, data) {
    const url = '/contacts/histo/update/'+id;
    return dispatch => putData(CONTACT_HISTO_UPDATE, CONTACT_HISTO_UPDATE_PENDING, CONTACTS_ERROR, true, url, dispatch, data);
}

export function deleteContactHisto(id) {
    const url = '/contacts/histo/delete/'+id;
    return dispatch => deleteData(CONTACT_HISTO_DELETE, CONTACT_HISTO_DELETE_PENDING, CONTACTS_ERROR, true, url, dispatch, null);
}

//VIP

export function addContactVIP(id, data) {
    console.log("action add !", id, data)
    const url = '/contacts/addvip/'+id; //Todo : change
    console.log("url :", url)
    return dispatch => postData(CONTACT_VIP_ADD, CONTACT_VIP_ADD_PENDING, CONTACTS_ERROR, true, url, dispatch, data);
}

export function deleteContactVIP(id, data) {
    const url = '/contacts/deletevip/'+id+'/'+data; //Todo : change
    console.log("url :", url)
    return dispatch => deleteData(CONTACT_VIP_DELETE, CONTACT_VIP_DELETE_PENDING, CONTACTS_ERROR, true, url, dispatch);
}

export function updateContactVIP(id, data) {
    const url = '/contacts/updatevip/'+id;
    return dispatch => putData(CONTACTS_VIP_UPDATE, CONTACTS_VIP_UPDATE_PENDING, CONTACTS_ERROR, true, url, dispatch, data);
}

export function resetContacts() {
	return dispatch => dispatch({ type: CONTACTS_RESET });
}