import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import FooterCicanord from '../partials/FooterCicanord';
import { Full, TileContainer, Tile, TileHeader, TileBody, TileTabs, TileTab } from '../../gj_modules/gj-strap/components/partials';
import history from '../../history';

import moment from 'moment';

import { Form, Panel, PanelButton } from '../../gj_modules/gj-strap/components/form';
import { Row, Col, Button } from 'react-bootstrap';
import { Cover } from '../../gj_modules/gj-strap/components/partials';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons'

import { resetBusinesses, getBusinessesById, updateBusinessesById, deleteBusinesses } from '../../actions/businesses';
import { getEntitiesAll } from '../../actions/entities';
//import { getUsersAll } from '../../actions/user';

import { returnFunction, processSubmit, checkNull } from '../../gj_modules/gj-strap/lib';
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';

import { projectCategory, businessStatusType, businessSector } from '../../data/constants';

import BusinessDirectories from './BusinessDirectories'
import BusinessActions from './BusinessActions'
import BusinessPhases from './BusinessPhases'
import BusinessActionsShortcut from './BusinessActionsShortcut'
//design
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons'
//current
import Rank from '../functions/Rank'
import { getManualData, savePdf } from '../../actions/index';
import BusinessEngineers from './BusinessEngineers'

export const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

class Business extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filters: {}
        };
    }

    componentDidMount() {
        const { match, getBusinessesById, resetBusinesses } = this.props;
        const id = match && match.params && match.params.id;
        resetBusinesses();
        if (id) {
            getBusinessesById(match.params.id);
        }
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    changeFilters = (e) => {
        var stateCopy = Object.assign({}, this.state);

        stateCopy.filters = Object.assign({}, e);

        this.setState(stateCopy);
    }

    submitEdit(values) {
        const { match, updateBusinessesById, business } = this.props;
        const id = match && match.params && match.params.id;
        //console.log(processSubmit(values));
        if (business && !business.pendingUpdate) {
            updateBusinessesById(id, processSubmit(values));
        }
    }

    switchTab = (tab) => {
        const { match } = this.props;
        const id = match && match.params && match.params.id;

        switch (tab) {
            case "phases":
                window.history.replaceState(null, null, "" + (REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "") + "/sic/business/" + id + "/phases")
                break;
            case "calendar":
                window.history.replaceState(null, null, "" + (REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "") + "/sic/business/" + id + "/calendar")
                break;
            case "directories":
                window.history.replaceState(null, null, "" + (REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "") + "/sic/business/" + id + "/directories")
                break;
            case "actions":
                window.history.replaceState(null, null, "" + (REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "") + "/sic/business/" + id)
                break;
            default:
                break;
        }
    }

    searchMake = () => {
        const { filters } = this.state

        let search = "";
        let first = false;

        for (let [short] of Object.entries(filters)) {

            let array = Object.entries(filters[short]);

            if (array.length > 0) {

                const sortedArray = array.filter(word => word[1] === false);

                if (sortedArray.length > 0) {
                    if (first === false) {
                        first = true;
                        search = "?" + short + "=";
                    } else {
                        search += "&" + short + "=";
                    }

                    let finalArray = [];

                    //const finalArray = array.filter(word => word[1] === false);

                    for (let [short2] of sortedArray) {
                        finalArray.push(short2)
                    }

                    search += finalArray.join('-');
                }

            }
        }

        return search;

    }

    render() {
        const { match, business, deleteBusinesses, getEntitiesAll/*, getUsersAll*/ } = this.props
        const id = match && match.params && match.params.id;

        const newFunc = this.searchMake;

        //console.log("PROJECT", project);

        let defaultTab = "actions";
        if (this.props.location.pathname.includes("directories")) {
            defaultTab = "directories";
        } else if (this.props.location.pathname.includes("calendar")) {
            defaultTab = "calendar";
        } else if (this.props.location.pathname.includes("phases")) {
            defaultTab = "phases";
        }

        let portlet = {
            title: "Affaires",
            path: "/sic/businesses",
            links: [
                //{ name: "Recherche", path: "/sic/businesses" },
                { name: "Fiche affaire", path: "/sic/business/" + id }
            ],
            actions: [
                {
                    title: "Imprimer",
                    icon:faPrint,
                    function: function () {
                        //history.push("/sic/project/"+id+"/print");
                        window.open("" + (REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "") + "/sic/business/" + id + "/print", '_blank');
                    }
                },
                {
                    title: "Télécharger",
                    icon:faDownload,
                    //variant:"success",
                    function: function () {
                        getManualData(true, '/businesses/' + id + '/print').then(response => {
                            let pdf = atob(response.response.data.pdf);
                            savePdf(pdf, business.info.pdfTitle + ".pdf");
                        });
                    }
                },
                {
                    title: "Télécharger l'annuaire",
                    icon:faDownload,
                    //variant:"success",
                    function: function () {
                        getManualData(true, '/businesses/' + id + '/print/directories' + newFunc()).then(response => {
                            let pdf = atob(response.response.data.pdf);
                            savePdf(pdf, business.info.pdfTitle + ".pdf");
                        });
                    }
                },
                {
                    title: "Imprimer l'annuaire",
                    icon:faPrint,
                    function: function () {
                        //history.push("/sic/project/"+id+"/print");
                        window.open("" + (REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "") + "/sic/business/" + id + "/print/directories" + newFunc(), '_blank');
                    }
                }
            ]
        }

        return (
            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />

                {business && !business.pending ? (

                    <Full portlet={portlet}>

                        <Row>
                            <Col lg="8" className="tile-col">

                                {/* Header */}
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileBody>
                                            <Cover
                                                icon={faPencilRuler}
                                                title={business.info.Affaires_Nom}
                                                subtitle={checkNull(business.info.Affaires_CodePostal) + " " + checkNull(business.info.Affaires_Ville)}
                                            >
                                                Affaire créée le {moment(business.info.Affaires_DateCreation).format("DD/MM/YYYY")}<br />
                                                Dernière modification le {moment(business.info.Affaires_DateModification).format("DD/MM/YYYY")}
                                            </Cover>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>

                                {/* Tabs */}
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileTabs defaultActiveKey={defaultTab} onSelect={this.switchTab} transition={false} id="tabs-bussiness">
                                            <TileTab eventKey="actions" title="Actions">
                                                <BusinessActions
                                                    business={business}
                                                />
                                            </TileTab>
                                            <TileTab eventKey="directories" title="Annuaires">
                                                {/* Directories */}
                                                <BusinessDirectories
                                                    business={business}
                                                    changeFilters={this.changeFilters}
                                                />
                                            </TileTab>
                                            <TileTab eventKey="phases" title="Phases de la mission">
                                                {/* Phases */}
                                                <BusinessPhases
                                                    business={business}
                                                />
                                            </TileTab>
                                        </TileTabs>
                                    </Tile>
                                </TileContainer>
                            </Col>

                            <Col lg="4" className="tile-col">

								<BusinessEngineers
									business={business}
								/>

                                {/* Général */}
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileHeader title="Général" border={true} />
                                        <TileBody>

                                            <Form
                                                onSubmit={values => this.submitEdit(values)}
                                                initialValues={{
                                                    name: (business.info.Affaires_Nom) || "",
                                                    code: (business.info.Affaires_Code) || "",
                                                    category: business.info.Affaires_Category,
                                                    adressCity: (business.info.Affaires_Ville) || "",
                                                    adressPostalCode: (business.info.Affaires_CodePostal) || "",
                                                    adressLines: (business.info.Affaires_Adresse) || "",
                                                    email: (business.info.Affaires_Email) || "",
                                                    entityId: Number(business.info.Affaires_IDEntiteMO),
                                                    clientId: Number(business.info.Affaires_IDEntiteClient),
                                                    //engineerUserId:Number(business.info.Affaires_EngineerUserId),
                                                    mission: (business.info.Affaires_DescriptifMission) || "",
                                                    globalCostFixed: (business.info.Affaires_CostGlobalFixed) || "",
                                                    costGlobal: (business.info.Affaires_CostGlobal) || "",
                                                    missionCostFixed: (business.info.Affaires_CostMissionFixed) || 0,
                                                    costMission: (business.info.Affaires_CostMission) || "",
                                                    statusType: (business.info.Affaires_StatusType) || "",
                                                    details: (business.info.Affaires_Details) || "",
                                                    detailsShort: (business.info.Affaires_DetailsShort) || "",
                                                    surfaceFixed: (business.info.Affaires_SurfaceFixed) || "",
                                                    surface: (business.info.Affaires_Surface) || "",
                                                    sector: (business.info.Affaires_Sector) || "",
                                                    yearsFixed: (business.info.Affaires_YearsFixed) ||0,
                                                    years: (business.info.Affaires_Years) || "",
                                                    durationFixed: (business.info.Affaires_DurationFixed) || 0,
                                                    duration: (business.info.Affaires_Duration) || "",
                                                    specificities: (business.info.Affaires_Specificities) || "",
                                                    label: (business.info.Affaires_Label) || ""
                                                }}
                                            >

                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        { short: "name", name: "Nom", type: "text", placeholder: "Nom de l'affaire" },
                                                        { short: "code", name: "Code", type: "text", placeholder: "Code de l'affaire" },
                                                        {
                                                            short: "category", name: "Catégorie", type: "select", placeholder: "Catégorie", data: {
                                                                isMulti: false,
                                                                options: projectCategory
                                                            }
                                                        },
                                                        { short: "adressCity", name: "Ville", type: "text", placeholder: "Ville" },
                                                        { short: "adressPostalCode", name: "Code postal", type: "text", placeholder: "00000" },
                                                        { short: "adressLines", name: "Adresse", type: "text", placeholder: "Rue" },
                                                        { short: "email", name: "Email", type: "text", placeholder: "Email" },
                                                        {
                                                            short: "entityId", name: "MOA", type: "select", placeholder: "", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                defaultValue: { value: Number(business.info.Affaires_IDEntiteMO), label: business.info.EntiteMO_name, force: true },
                                                                loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", "Entites_Nom")
                                                            }
                                                        },
                                                        {
                                                            short: "clientId", name: "Client", type: "select", placeholder: "", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                defaultValue: { value: Number(business.info.Affaires_IDEntiteClient), label: business.info.Entites_Nom, force: true },
                                                                loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", "Entites_Nom")
                                                            }
                                                        },
                                                        /*{
                                                            short: "engineerUserId", name: "Ingénieur Cicanord", type: "select", placeholder: "Utilisateur", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                defaultValue: { value:Number(business.info.Affaires_EngineerUserId), label:business.info.userLabel, force:true },
                                                                loadOptions: returnFunction(getUsersAll, "searchUsersByName", 5, "user.users", "Utilisateurs_ID", ["Utilisateurs_Prenom", "Utilisateurs_Nom"])
                                                            }
                                                        },*/
                                                        { short: "mission", name: "Mission", type: "textarea", placeholder: "Mission", data: { height: "90px" } },
                                                        { short: "globalCostFixed", name: "Montant fixe", type: "checkbox"},
                                                        { short: "costGlobal", name: "Montant des travaux", type: "text", placeholder: "" },
                                                        { short: "missionCostFixed", name: "Montant fixe", type: "checkbox"},
                                                        { short: "costMission", name: "Montant de la mission", type: "text", placeholder: "" },
                                                        {
                                                            short: "statusType", name: "Statut", type: "select", placeholder: "", data: {
                                                                isMulti: false,
                                                                options: businessStatusType
                                                            }
                                                        },
                                                        {
                                                            short: "sector", name: "Secteur", type: "select", placeholder: "", data: {
                                                                isMulti: false,
                                                                options: businessSector
                                                            }
                                                        },
                                                        { short: "details", name: "Présentation détaillée", type: "textarea", placeholder: "" },
                                                        { short: "detailsShort", name: "Présentation sommaire", type: "textarea", placeholder: "" },
                                                        { short: "surfaceFixed", name: "Surface fixe", type: "checkbox"},
                                                        { short: "surface", name: "Surface", type: "text", placeholder: "" },
                                                        { short: "specificities", name: "Spécificités", type: "textarea", placeholder: "" },
                                                        { short: "label", name: "Label", type: "text", placeholder: "" },
                                                        { short: "durationFixed", name: "Délais fixes", type: "checkbox"},
                                                        { short: "duration", name: "Délai d'exécution", type: "text", placeholder: "xx mois" },
                                                        { short: "yearsFixed", name: "Années fixes", type: "checkbox"},
                                                        { short: "years", name: "Années", type: "text", placeholder: "20xx-20xx" },
                                                    ]}
                                                />

                                                <PanelButton
                                                    pending={business.pendingUpdate}
                                                    message={business.message}
                                                />

                                            </Form>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>

                                {/* Business Add default actions */}
                                <BusinessActionsShortcut
                                    business={business}
                                />

                                {/* Delete */}
                                <Rank need={"admin"}>
                                    <TileContainer>
                                        <Tile size={'full'}>
                                            <TileBody>
                                                <Button
                                                    onClick={function () {
                                                        modalFunction({
                                                            btnFunction: () => {
                                                                deleteBusinesses(id).then(
                                                                    function (response) {
                                                                        history.push("/sic/businesses")
                                                                    }
                                                                );
                                                            },
                                                            btnText: "Confirmer",
                                                            text: "Êtes-vous sûr de supprimer cette affaire ?",
                                                            header: "Supprimer une affaire",
                                                            state: "businesses.pendingDelete"
                                                        }, "popup")
                                                    }}
                                                    className="float-sm-right"
                                                    variant="danger"
                                                >
                                                    Supprimer cette affaire
                                        </Button>
                                            </TileBody>
                                        </Tile>
                                    </TileContainer>
                                </Rank>
                            </Col>

                        </Row>

                    </Full>
                ) : (
                        <Full>
                            Chargement
                        </Full>
                    )}
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetBusinesses,
            getBusinessesById,
            updateBusinessesById,
            deleteBusinesses,
            //getUsersAll,
            getEntitiesAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        business: state.businesses.business
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Business);