import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//gj-strap
import { FullCenter } from '../../gj_modules/gj-strap/components/partials/';
import { TileContainer, Tile, TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials/';
import { Avatar } from '../../gj_modules/gj-strap/components/display/';
import { Form, Panel, PanelButton } from '../../gj_modules/gj-strap/components/form';
//cicanord
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import FooterCicanord from '../partials/FooterCicanord';
import { civility } from '../../data/constants';
//bootstrap
import { Media, Row, Col } from 'react-bootstrap';
//actions
import { resetContacts, getContactById, updateContactById } from '../../actions/contacts';

//import { Link } from 'react-router-dom';
import moment from 'moment';

import { processSubmit } from '../../gj_modules/gj-strap/lib';
import ContactEntities from './ContactEntities';
import SubProjects from '../subpartials/SubProjects';
import ContactVIP from './ContactVIP'
import ContactActions from './ContactActions';

class Contact extends Component {
    componentWillMount() {
        const { match, resetContacts, getContactById } = this.props;
        const id = match && match.params && match.params.id;

        resetContacts();

        if (id) {
            getContactById(match.params.id);
        }
    }

    submitEdit(values){
        const { match, contact, updateContactById } = this.props;
        const id = match && match.params && match.params.id;
        console.log(processSubmit(values));
        if(contact && !contact.pendingUpdate){
            updateContactById(id, processSubmit(values));
        }
    }

    render() {
        const { match, contact } = this.props;
        const id = match && match.params && match.params.id;
        console.log(contact)
        //console.log("Contact", contact);

        let portlet = {
            title:"Contacts",
            path:"/sic/contacts",
            links:[
                //{name:"Recherche", path:"/sic/contacts"},
                {name:"contact inconnu", path:"/sic/contact/"+id}
            ]
        }

        if(contact && !contact.pending){
            portlet.links[0].name = contact.info.Personnes_Prenom+" "+contact.info.Personnes_Nom;
        }
        return (
            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />

                {contact && !contact.pending ? (

                    <FullCenter portlet={portlet}>
                        <TileContainer>
                            <Tile size={"full"}>
                                <TileBody>
                                    <Row>
                                        <Col sm="6">
                                            <Media>
                                                <Avatar />
                                                <Media.Body>
                                                    <h3>{contact.info.Personnes_Prenom} {contact.info.Personnes_Nom.toUpperCase()}</h3>
                                                    { contact.entites.length > 0 ? (
                                                        <p className="my-0">{contact.entites[0].Entites_Nom}</p>
                                                    ) : null }
                                                </Media.Body>
                                            </Media>
                                        </Col>
                                        <Col sm="6" className="text-right">
                                            Contact ajouté le {moment(contact.info.Personnes_DateCreation).format("DD/MM/YYYY")}<br />
                                            Dernière modification le {moment(contact.info.Personnes_DateModification).format("DD/MM/YYYY")}
                                        </Col>
                                    </Row>
                                </TileBody>
                            </Tile>
                        </TileContainer>

                        <Row>
                            <Col lg="6" className="tile-col">
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileHeader title="Général" border={true} />
                                        <TileBody>

                                            <Form
                                                onSubmit={values => this.submitEdit(values)}
                                                initialValues={{
                                                    civility: contact.info.Personnes_IDCivilite,
													prospect: contact.info.Personnes_Prospect,
													vip: contact.info.Personnes_Vip,
                                                    name: contact.info.Personnes_Nom || "",
                                                    surname: contact.info.Personnes_Prenom || "",
                                                    phonemobile: contact.info.Personnes_TelephonePortablePrive || "",
                                                    phonemobiledisplay: contact.info.Personnes_TelephonePortablePriveDiffusable,
                                                    email: contact.info.Personnes_EmailPrivee || "",
                                                    emaildisplay: contact.info.Personnes_EmailPriveeDiffusable,
                                                    comment: contact.info.Personnes_Commentaire || "",
													assistantphone: contact.info.Personnes_AssistantTelephone || "",
													assistantemail: contact.info.Personnes_AssistantEmail || "",
                                                }}
                                            >

                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        {
                                                            short: "civility", name: "Civilité", type: "select", placeholder: "", data: {
                                                                isMulti: false,
                                                                options: civility
                                                            }
														},
														{ short: "vip", name: "Vip", type: "checkbox", placeholder: "" },
                                                        { short: "prospect", name: "Prospect", type: "checkbox", placeholder: "" },
                                                        { short: "name", name: "Nom", type: "text", placeholder: "Nom" },
                                                        { short: "surname", name: "Prénom", type: "text", placeholder: "Prénom" },
                                                        { short: "phonemobile", name: "Téléphone portable", type: "text", placeholder: "0123456789" },
                                                        /*{
                                                            short: "phonemobiledisplay", name: "Diffusable", type: "checkbox", placeholder: ""
                                                        },*/
                                                        { short: "email", name: "Email", type: "text", placeholder: "" },
                                                        {
                                                            short: "emaildisplay", name: "Diffusable", type: "checkbox", placeholder: ""
                                                        },
														{ short: "assistantphone", name: "Téléphone portable assistant", type: "text", placeholder: "0123456789" },
                                                        { short: "assistantemail", name: "Email assistant", type: "text", placeholder: "" },
                                                        { short: "comment", name: "Commentaire", type: "textarea", placeholder: "" },
                                                    ]}
                                                />

                                                <PanelButton
                                                    pending={contact.pendingUpdate}
                                                    message={contact.message}
                                                />
                                            </Form>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>
                            </Col>

                            <Col lg="6" className="tile-col">
                            
                                {/* Entities */}
                                <ContactEntities
                                    entities={contact.entites}
                                    idContact={id}
                                />

                                {/* Projects */}
                                <SubProjects
                                    projects={contact.projects}
                                    idProject={id}
                                />

                                {/* ContactVIP */}
                                <ContactVIP

                                />

                                {/*<ContactActions
                                    actions={contact.actions}
                                    idContact={id}
                                                />*/}

                                {/* ContactHistory 
                                <ContactHistory
                                    histo={contact.histo}
                                    idContact={id}
                                />*/}
                            </Col>

                        </Row>
                        
                    </FullCenter>
                ) : (
                    <FullCenter>
                        Chargement
                    </FullCenter>
                )}
                <FooterCicanord menu={"sic"}/>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetContacts,
            getContactById,
            updateContactById
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        contact:state.contacts.contact
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact);