import {
    GET_USER,
    GET_USER_SELF,
    RESET_USER,
    USER_ERROR,
    USERS_ALL,
    USERS_ALL_PENDING,
    USERS_ADD,
    USERS_ADD_PENDING,
    USERS_UPDATE,
    USERS_UPDATE_PENDING,
    USERS_DELETE,
    USERS_DELETE_PENDING,
    USERS_UPDATE_PASSWORD,
    USERS_UPDATE_PASSWORD_PENDING,
    USERS_RESET
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    user: null,
    users: null,
    notification: null,
    message: null,
    pending: false,
    pendingDelete: false,
    pendingAdd: false,
    pendingUpdate: false,
    pendingUpdatePassword: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case USERS_ALL_PENDING:
            return {
                ...state,
                pending: true
            }
        case USERS_ALL:
            let users = action.payload.users;
            users['count'] = action.payload.usersCount;
            return {
                ...state,
                users: users,
                error: '',
                pending: false,
                message: action.payload.message
            };
        case USERS_RESET:
            return {
                ...state,
                users: null,
                user:null,
                message: null,
                error: '',
                pending: false,
                pendingDelete: false,
                pendingAdd: false,
                pendingUpdate: false,
                pendingUpdatePassword: false
            };
        case GET_USER:
            //let notification = action.payload.users[0].preference.notification;
            return {
                ...state,
                user: action.payload.user,
                error: '',
                message: action.payload.message,
                pending: false
            };
        case GET_USER_SELF:
            return {
                ...state,
                user: action.payload.user,
                error: '',
                message: action.payload.message
            };
        case USERS_ADD_PENDING:
            return {
                ...state,
                pendingAdd: true
            }
        case USERS_ADD:
            return {
                ...state,
                error: '',
                message: action.payload.message,
                pendingAdd: false
            };
        case USERS_UPDATE_PENDING:
            return {
                ...state,
                pendingUpdate: true
            }
        case USERS_UPDATE:
            return {
                ...state,
                error: '',
                message: action.payload.message,
                pendingUpdate: false
            };
        case USERS_DELETE_PENDING:
            return {
                ...state,
                pendingDelete: true
            }
        case USERS_DELETE:
            users = state.users;
            if (users !== null) {
                users = state.users.filter((user) => user.Utilisateurs_ID !== Number(action.payload.id))
            }
            return {
                ...state,
                error: '',
                users: users,
                message: action.payload.message,
                pendingDelete: false
            };
        case USERS_UPDATE_PASSWORD_PENDING:
            return {
                ...state,
                pendingUpdatePassword: true
            }
        case USERS_UPDATE_PASSWORD:
            return {
                ...state,
                message: action.payload.message,
                pendingUpdatePassword: false
            }
        case RESET_USER:
            return { ...state, user: null, pending: null, message: '', error: '' };
        case USER_ERROR:
            return { ...state, error: action.payload.message };
        default:
            return state;
    }
}
