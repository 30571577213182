import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getForgotPasswordToken, clearAuth } from '../../../actions/auth';
import { Form, Input } from '../../../gj_modules/gj-strap/components/form';
import { validateEmail } from '../../../lib/validate';
import { Button } from 'react-bootstrap';
import history from '../../../history';
import HeaderTop from '../../partials/HeaderTop';
import FullCard from '../../../gj_modules/gj-strap/components/partials/FullCard';

class ForgotPassword extends Component {

    UNSAFE_componentWillMount() {
        this.props.clearAuth()
        if (this.props.authenticated) {
            history.push('/home');
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.authenticated) {
            history.push('/home');
        }
    }

    submit = values => {
        this.props.getForgotPasswordToken(values);
    };

    renderAlert() {
        if (this.props.errorMessage) {
            return (
                <div>
                    <p className="alert alert-danger">{this.props.errorMessage}</p>
                </div>
            );
        }
    }

    renderMessage() {
        return (
            <div>
                <p className="alert alert-success">{this.props.message}</p>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTop />

                <FullCard title="J'ai oublié mon mot de passe">
                    <Form onSubmit={this.submit} className="w-100">
                        <Input
                            name="email"
                            type="email"
                            label="Votre Email"
                            placeholder="exemple@mail.com"
                            validate={validateEmail()}
                        />

                        {this.renderAlert()}
                        
                        {this.props.message ?
                            this.renderMessage() :
                            <div className="d-table m-auto py-4">
                                <Button variant="primary" size="lg" type="submit" className="">Réinitialiser le mot de passe</Button>
                            </div>
                        }
                    </Form>
                </FullCard>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        errorMessage: state.auth.error,
        message: state.auth.message,
        authenticated: state.auth.authenticated,
    };
}

export default connect(mapStateToProps, { getForgotPasswordToken, clearAuth })(ForgotPassword);
