export default function labelByValue(value, options) {
    let label = null;
    options.forEach(option => {
        if(option.value === value){
            label = option.label;
        }
    });

    if(label){
        return label;
    }else{
        return value;
    }
}