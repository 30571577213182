import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { getUser } from '../../actions/user';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import moment from 'moment';

import { getSectorsAll, addSector, resetSectors, deleteSector, updateSector } from '../../actions/sectors'
//import Spinner from '../../gj_modules/gj-strap/components/display/Spinner';
import { Panel } from '../../gj_modules/gj-strap/components/form';
import FullCenter from '../../gj_modules/gj-strap/components/partials/FullCenter';
import FooterCicanord from '../partials/FooterCicanord';

import { ComplexAll } from '../../gj_modules/gj-strap/components/complex'

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { modalFunction } from '../../gj_modules/gj-strap/components/partials';

import { required } from '../../lib/validate';

import { directoriesPart } from '../../data/constants';
import { labelByValue, processSubmit,  } from '../../gj_modules/gj-strap/lib';

class Sectors extends Component {
    componentDidMount() {
    }

    renderTd = (e) => {
        return {
            id: e.Secteurs_ID,
            key: e.Secteurs_ID + "_" + e.Secteurs_Valeur,
            name: e.Secteurs_Valeur,
            directory: labelByValue(e.Secteurs_IDGAnnuaires, directoriesPart),
            date: moment(e.Secteurs_dateUpdate).format("DD/MM/YYYY")
        }
    }

    render() {
        const { sectors, addSector, updateSector, deleteSector } = this.props;

        const localGetSectorAll = this.props.getSectorsAll;

        const properties = [
            { short: "id", width: "6%", category: "id" },
            { short: "name", name: "Nom", width: "18%", category: "classic" },
            { short: "directory", name: "Annuaire", width: "18%", category: "classic" },
            { short: "date", name: "Date", width: "13%", category: "classic" },
            { short: "actions", width: "11%", name: "", category: "actions" }
        ];

        const filters = [
            { type: "search", size: 30, name: "searchSectorsByName", placeholder: "Recherche par nom" },
            {
                type: "button",
                size: 20,
                name: "",
                title: "Ajouter un secteur",
                icon: faPlusCircle,
                fn: () => {
                    modalFunction({
                        submit: (values) => {
                            if (!sectors.pendingAdd) {
                                console.log(values)
                                addSector(values).then(
                                    function (response) {
                                        localGetSectorAll({ skip:0, limit:20, filters });
                                        //history.push("/sic/contact/"+response.response.data.contacts)
                                    }
                                );
                            }
                        },
                        text: (
                            <Panel
                                width={[3, 9]}
                                inputs={[
                                    { short: "name", name: "Nom", type: "text", placeholder: "Nom", validate: required() },
                                    {
                                        short: "idDirectory", name: "Type", type: "select", placeholder: "Type", data: {
                                            isMulti: false,
                                            options: directoriesPart
                                        }
                                    }
                                ]}
                            />
                        ),
                        header: "Ajouter un secteur",
                        state: "sectors.pendingAdd"
                    }, "popup")
                }
            }
        ]

        let portlet = {
            title: "Secteurs",
            links: [
                { name: "Recherche", path: "/sic/sectors" }
            ]
        }

        return (

            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        getElementsAll={getSectorsAll}
                        resetElements={resetSectors}
                        elements={this.props.sectors.sectors}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        pending={this.props.sectors.pending}
                        noDataMessage={"Pas de secteur"}
                        deleteAction={(e) => {
                            modalFunction({
                                btnFunction: () => {
                                    deleteSector(e)
                                },
                                btnText: "Confirmer",
                                text: "Êtes-vous sûr ?",
                                header: "Supprimer un secteur",
                                state: "sectors.pendingDelete"
                            }, "popup")
                        }}
                        editAction={(id) => {

                            let index = -1;
                            for (let i = 0; i < sectors.sectors.length; i++) {
                                if (id === sectors.sectors[i].Secteurs_ID) {
                                    index = i;
                                }
                            }

                            modalFunction({
                                submit: (values) => {
                                    if (!sectors.pendingUpdate) {
                                        //console.log(processSubmit(values))
                                        updateSector(id, processSubmit(values))
                                    }
                                },
                                initialValues: {
                                    name: (sectors.sectors[index].Secteurs_Valeur) || "",
                                    idDirectory: sectors.sectors[index].Secteurs_IDGAnnuaires
                                },
                                text: (
                                    <Panel
                                        width={[3, 9]}
                                        inputs={[
                                            { short: "name", name: "Nom", type: "text", placeholder: "Action", validate: required() },
                                            {
                                                short: "idDirectory", name: "Type", type: "select", placeholder: "Type", data: {
                                                    isMulti: false,
                                                    //defaultValue: { value:project.actions[index].reminderType, label:"test", force:true },
                                                    options: directoriesPart
                                                }
                                            }
                                        ]}
                                    />
                                ),
                                header: "Modifier un secteur",
                                state: "sectors.pendingUpdate"
                            }, "popup")
                        }}
                    />
                </FullCenter>
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSectorsAll,
            addSector,
            updateSector,
            deleteSector,
            resetSectors
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        sectors: state.sectors,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Sectors);
