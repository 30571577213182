import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router";
//import filter from 'lodash/filter'

class Print extends Component {

    render() {
        const { loading, pdf } = this.props;

        let url = null;

        if(pdf !== null){
            var binaryLen = pdf.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = pdf.charCodeAt(i);
                bytes[i] = ascii;
            }

            var blob = new Blob([bytes], { type: "application/pdf" });
            url = window.URL.createObjectURL(blob);
        }

        return (
            <React.Fragment>
                {loading ? "Chargement" : (
                    <iframe title="iframe" className="iframePrint" src={url}></iframe>
                )}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            // logoutUser,
            // getNotificationByUser
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        // notification: state.notifications.notification,
        // currentUser: state.auth.user
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Print));