import { getData, postData, putData, deleteData } from './index';
import {
    ACTIONS_ALL,
    ACTIONS_ALL_PENDING,
    ACTIONS_ADD,
    ACTIONS_ADD_PENDING,
    ACTIONS_UPDATE,
    ACTIONS_UPDATE_PENDING,
    ACTIONS_DELETE,
    ACTIONS_DELETE_PENDING,
    ACTIONS_UPDATE_STATE,
    ACTIONS_UPDATE_STATE_PENDING,
    ACTIONS_ADD_DEFAULT,
    ACTIONS_ADD_DEFAULT_PENDING,
    ACTIONS_ERROR,
    ACTIONS_RESET,
} from './types';

import { dataToUrl } from '../lib'

export function resetActions() {
	return dispatch => dispatch({ type: ACTIONS_RESET });
}

export function getActionsAll(data) {
    const url = '/actions/all'+dataToUrl(data);
    return dispatch => getData(ACTIONS_ALL, ACTIONS_ALL_PENDING, ACTIONS_ERROR, true, url, dispatch);
}

export function addAction(type, id, data) {
    const url = '/actions/add/'+type+'/'+id;
    return dispatch => postData(ACTIONS_ADD, ACTIONS_ADD_PENDING, ACTIONS_ERROR, true, url, dispatch, data);
}

export function updateAction(id, data) {
    const url = '/actions/update/'+id;
    return dispatch => putData(ACTIONS_UPDATE, ACTIONS_UPDATE_PENDING, ACTIONS_ERROR, true, url, dispatch, data);
}

export function addActionsDefault(id, data) {
    const url = '/actions/adddefault/'+id;
    return dispatch => postData(ACTIONS_ADD_DEFAULT, ACTIONS_ADD_DEFAULT_PENDING, ACTIONS_ERROR, true, url, dispatch, data);
}


export function deleteAction(id) {
    const url = '/actions/delete/'+id;
    return dispatch => deleteData(ACTIONS_DELETE, ACTIONS_DELETE_PENDING, ACTIONS_ERROR, true, url, dispatch);
}

export function setStateAction(id, data) {
    const url = '/actions/setState/'+id;
    return dispatch => postData(ACTIONS_UPDATE_STATE, ACTIONS_UPDATE_STATE_PENDING, ACTIONS_ERROR, true, url, dispatch, data);
}