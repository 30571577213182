import {
    AUTH_USER,
    UNAUTH_USER,
    AUTH_ERROR,
    AUTH_CLEAR,
    AUTH_PENDING,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST,
    PROTECTED_TEST,
    EDIT_USER_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    message: '',
    authenticated: false,
    pending:false
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTH_USER:
            return {
                ...state,
                error: '',
                message: '',
                authenticated: true,
                user: action.user,
                pending:false
            };
        case UNAUTH_USER:
            return {
                ...state,
                authenticated: false,
                user: null,
                error: action.payload,
                pending:false
        };
        case AUTH_PENDING:
            return {
                ...state,
                pending:true
            }
        case AUTH_ERROR:
            return {
                ...state,
                error: action.payload,
                pending:false
            };
        case AUTH_CLEAR:
            return {
                ...state,
                error: '',
                message: '',
                pending:false
            };
        case FORGOT_PASSWORD_REQUEST:
            return { ...state, message: action.payload.message };
        case RESET_PASSWORD_REQUEST:
            return { ...state, message: action.payload.message };
        case PROTECTED_TEST:
            return { ...state, content: action.payload.message };
        case EDIT_USER_SUCCESS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    firstName: action.requestData.firstName,
                    lastName: action.requestData.lastName,
                    email: action.requestData.email
                }
            };
        }
        default:
            return state;
    }
}
