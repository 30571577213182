import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//gj-strap
import { TileContainer, Tile, TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials';
import { Panel, DataTable } from '../../gj_modules/gj-strap/components/form';
//actions
import { addEntityActivity, deleteEntityActivity } from '../../actions/entities';
import { getActivitiesAll } from '../../actions/activities'
//current
import { returnFunction/*, processSubmit, labelByValue, checkNull*/ } from '../../gj_modules/gj-strap/lib';
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
//import { required } from 'lib/validate';
//design
import { Button } from 'react-bootstrap';

class EntityActivities extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    dataTablePersonsRender = () => {
        const { activities, deleteEntityActivity } = this.props;

        const properties = [ // DataTable Headers
            { short: "sector", width: "35%", category: "classic" },
            { short: "activity", width: "35%", category: "classic" },
            { short: "actions", width: "30%", category: "actions" }
        ];

        const array = [];

        if (activities && activities.length) {
            for (let i = 0; i < activities.length; i++) {
                const object = {
                    id: activities[i].SecteursActivites_ID,
                    sector: activities[i].Secteurs_Valeur,
                    activity: activities[i].Activites_Valeur
                };
                object.key = activities[i].SecteursActivites_ID
                object.index = i + 1;
                array.push(object);
            }
        }

        return (
            <DataTable small
                noEditGlobal
                properties={properties}
                collection={array}
                noDataMessage={"Pas d'activité"}
                showAll={3}
                deleteAction={(e) => {
                    modalFunction({
                        btnFunction: () => {
                            //console.log("function delete " + e)
                            deleteEntityActivity(e)
                        },
                        btnText: "Confirmer",
                        text: "Êtes-vous sûr ?",
                        header: "Supprimer une activité",
                        state: "entities.pendingActivityDelete"
                    }, "popup")
                }}
            />
        )
    }

    render() {
        const { entities, addEntityActivity, idEntity } = this.props;

        return (
            <React.Fragment>
                {/* Contacts */}
                <TileContainer>
                    <Tile size={"full"}>
                        <TileHeader
                            title="Secteur / Activité"
                            border={true}
                            toolbar={<Button onClick={
                                () => {
                                    if (!entities.pendingActivityAdd) {
                                        modalFunction({
                                            submit: (values) => {
                                                if (!entities.pendingActivityAdd) {
                                                    console.log(values)
                                                    addEntityActivity(idEntity, values)
                                                }
                                            },
                                            text: (
                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        {
                                                            short: "activityId", name: "Activité", type: "select", placeholder: "Rechercher", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                //defaultValue: { value:null, label:"", force:true },
                                                                loadOptions: returnFunction(getActivitiesAll, "searchActivitiesByName", 5, "activities.activities", "Activites_ID", ["Activites_Valeur"])
                                                            }
                                                        }
                                                    ]}
                                                />
                                            ),
                                            header: "Ajouter une activité",
                                            state: "entities.pendingActivityAdd"
                                        }, "popup")
                                    }
                                }
                            }>
                                {entities.pendingActivityAdd ? "O" : "+"}
                            </Button>}
                        />
                        <TileBody>
                            {this.dataTablePersonsRender()}
                        </TileBody>
                    </Tile>
                </TileContainer>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addEntityActivity,
            deleteEntityActivity,
            getActivitiesAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        entities: state.entities
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EntityActivities);