import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';

import { TileContainer, Tile, TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials';

import { Panel } from '../../gj_modules/gj-strap/components/form';
import DataTable from '../../gj_modules/gj-strap/components/form/DataTable';
import { Button } from 'react-bootstrap';

import { addEprojectContact, deleteEprojectContact } from '../../actions/eprojects';
import { getContactsAll } from '../../actions/contacts';

import { returnFunction } from '../../gj_modules/gj-strap/lib';
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
//import { labelByValue } from '../../gj_modules/gj-strap/lib'

//import { projectType } from 'data/constants';

import { required } from '../../lib/validate';

class EprojectContacts extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    dataTableContactsRender = () => {
        const { eproject, deleteEprojectContact } = this.props;

        const properties = [ // DataTable Headers
            /*{ short: "type", width: "30%", category: "classic" },*/
            { short: "name", width: "40%", category: "classic" },
            { short: "actions", width: "30%", category: "actions" }
        ];

        const array = [];

        if (eproject && eproject.contacts && eproject.contacts.length) {
            for (let i = 0; i < eproject.contacts.length; i++) {
                const object = {
                    id: eproject.contacts[i].id,
                    //type: labelByValue(eproject.entities[i].type, projectType),
                    name: (<Link to={"/sic/contact/" + eproject.contacts[i].Personnes_ID} className="mx-3">{eproject.contacts[i].Personnes_Prenom} {eproject.contacts[i].Personnes_Nom}</Link>),
                };
                object.key = "contact" + eproject.contacts[i].Personnes_ID
                object.index = i + 1;
                array.push(object);
            }
        }

        return (
            <DataTable small
                properties={properties}
                collection={array}
                noDataMessage={`Pas de contact`}
                showAll={3}
                deleteAction={(e) => {
                    modalFunction({
                        btnFunction: () => {
                            //console.log("function delete "+e)
                            deleteEprojectContact(e)
                        },
                        btnText: "Confirmer",
                        text: "Êtes-vous sûr ?",
                        header: "Supprimer un contact",
                        state: "eprojects.eproject.pendingContactDelete"
                    }, "popup")
                }}
            />
        )
    }

    render() {
        const { eproject, getContactsAll, addEprojectContact, idProject } = this.props;

        return (
            <React.Fragment>
                {/* Intervenants */}
                <TileContainer>
                    <Tile size={"full"}>
                        <TileHeader
                            title="Contacts"
                            border={true}
                            toolbar={<Button onClick={
                                () => {
                                    if (!eproject.pendingContactAdd) {
                                        modalFunction({
                                            submit: (values) => {
                                                if (!eproject.pendingContactAdd) {
                                                    //console.log(values)
                                                    addEprojectContact(idProject, values)
                                                }
                                            },
                                            text: (
                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        /*{
                                                            short: "type", name: "Type", type: "select", placeholder: "Type d'intervenant", validate:required(), data: {
                                                                isMulti: false,
                                                                options: projectType
                                                            }
                                                        },*/
                                                        /*{ short: "type", name: "Nom", type: "text", placeholder: "Type d'intervenant" },*/
                                                        {
                                                            short: "contactId", name: "Contact", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                //defaultValue: { value:null, label:"", force:true },
                                                                loadOptions: returnFunction(getContactsAll, "searchContactsByName", 5, "contacts.contacts", "Personnes_ID", ["Personnes_Prenom", "Personnes_Nom"])
                                                            }
                                                        }
                                                    ]}
                                                />
                                            ),
                                            header: "Ajouter un contact",
                                            state: "eprojects.eproject.pendingContactAdd"
                                        }, "popup")
                                    }
                                }
                            }>
                                {eproject.pendingContactAdd ? "O" : "+"}
                            </Button>}
                        />
                        <TileBody>
                            {this.dataTableContactsRender()}
                        </TileBody>
                    </Tile>
                </TileContainer>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getContactsAll,
            addEprojectContact,
            deleteEprojectContact
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        eproject: state.eprojects.eproject
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EprojectContacts);