import {
    SEARCH_ALL,
    SEARCH_ALL_PENDING,
    SEARCH_ERROR,
    SEARCH_RESET
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    searchs: null,
    message: null,
    pending: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SEARCH_ALL_PENDING:
            return {
                ...state,
                pending: true
            }
        case SEARCH_ALL:
            let searchs = action.payload.searchs;
            searchs['count'] = action.payload.searchsCount;
            return {
                ...state,
                searchs: searchs,
                error: '',
                message: action.payload.message,
                pending: false
            };
        case SEARCH_RESET:
            return {
                ...state,
                searchs: null,
                message: null,
                error: null,
                pending:false
            };
        case SEARCH_ERROR:
            return { ...state, error: action.payload.message };
        default:
            return state;
    }
}
