import {
    PROJECTS_ALL,
    PROJECTS_ALL_PENDING,
    PROJECTS_ID,
    PROJECTS_ID_PENDING,
    PROJECTS_ADD,
    PROJECTS_ADD_PENDING,
    PROJECTS_UPDATE,
    PROJECTS_UPDATE_PENDING,
    PROJECTS_DELETE,
    PROJECTS_DELETE_PENDING,
    PROJECTS_ENTITIES_ADD,
    PROJECTS_ENTITIES_ADD_PENDING,
    PROJECTS_ENTITIES_UPDATE,
    PROJECTS_ENTITIES_UPDATE_PENDING,
    PROJECTS_ENTITIES_DELETE,
    PROJECTS_ENTITIES_DELETE_PENDING,
    PROJECTS_ACTIONS_ADD,
    PROJECTS_ACTIONS_ADD_PENDING,
    PROJECTS_ACTIONS_UPDATE,
    PROJECTS_ACTIONS_UPDATE_PENDING,
    PROJECTS_ACTIONS_STATE_UPDATE,
    PROJECTS_ACTIONS_STATE_UPDATE_PENDING,
    PROJECTS_ACTIONS_DELETE,
    PROJECTS_ACTIONS_DELETE_PENDING,
    PROJECTS_INFORMATIONS_ADD,
    PROJECTS_INFORMATIONS_ADD_PENDING,
    PROJECTS_INFORMATIONS_UPDATE,
    PROJECTS_INFORMATIONS_UPDATE_PENDING,
    PROJECTS_INFORMATIONS_DELETE,
    PROJECTS_INFORMATIONS_DELETE_PENDING,
    PROJECTS_RESET,
    PROJECTS_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    error:'',
    projects:null,
    project:null,
    message:null,
    pending:false,
    pendingAdd:false,
    pendingDelete:false,
    pendingUpdate:false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case PROJECTS_ALL_PENDING:
            return {
                ...state,
                pending:true
            }
        case PROJECTS_ALL:
            let projects = action.payload.projects;
            projects['count'] = action.payload.projectsCount;
            return {
                ...state,
                projects: projects,
                error: '',
                message: action.payload.message,
                pending:false
            };
        case PROJECTS_ADD_PENDING:
            return {
                ...state,
                pendingAdd:true
            }
        case PROJECTS_ADD:
            return {
                ...state,
                pendingAdd:false
            }
        case PROJECTS_DELETE_PENDING:
            return {
                ...state,
                pendingDelete:true
            }
        case PROJECTS_DELETE:
            let newProjects = state.projects;
            if(newProjects !== null){
                newProjects = state.projects.filter((project) => project.id !== Number(action.payload.id))
            }
            return {
                ...state,
                projects:newProjects,
                pendingDelete:false,
                error: '',
                message: action.payload.message
            };

        /* ENTITIES */
        case PROJECTS_ENTITIES_ADD_PENDING:
            return {
                ...state,
                project:{
                    ...state.project,
                    pendingEntitiesAdd:true
                }
            }
        case PROJECTS_ENTITIES_ADD:
            let entities = state.project.entities;
            entities.push(action.payload.entity);
            return {
                ...state,
                project:{
                    ...state.project,
                    entities:entities,
                    pendingEntitiesAdd:false
                }
            }
        case PROJECTS_ENTITIES_DELETE_PENDING:
            return {
                ...state,
                project:{
                    ...state.project,
                    pendingEntitiesDelete:true
                }
            }
        case PROJECTS_ENTITIES_DELETE:
            return {
                ...state,
                project:{
                    ...state.project,
                    entities: state.project.entities.filter((entity) => entity.id !== Number(action.payload.id)),
                    pendingEntitiesDelete:false
                },
                error: '',
                message: action.payload.message
            };

        case PROJECTS_ENTITIES_UPDATE_PENDING:
            return {
                ...state,
                project:{
                    ...state.project,
                    pendingEntitiesUpdate:true
                }
            }
        case PROJECTS_ENTITIES_UPDATE:
            entities = state.project.entities;
            for(let i=0; i<entities.length; i++){
                if(entities[i].id === Number(action.payload.id)){
                    entities[i] = Object.assign(action.payload.entity, action[i]);
                }
            }
            return {
                ...state,
                project:{
                    ...state.project,
                    entities:entities,
                    pendingEntitiesUpdate:false
                }
            }
        
        /* ACTIONS */
        case PROJECTS_ACTIONS_ADD_PENDING:
            return {
                ...state,
                project:{
                    ...state.project,
                    pendingActionsAdd:true
                }
            }
        case PROJECTS_ACTIONS_ADD:
            let actions = state.project.actions;
            actions.push(action.payload.action);
            return {
                ...state,
                project:{
                    ...state.project,
                    actions:actions,
                    pendingActionsAdd:false
                }
            }
        case PROJECTS_ACTIONS_DELETE_PENDING:
            return {
                ...state,
                project:{
                    ...state.project,
                    pendingActionsDelete:true
                }
            }
        case PROJECTS_ACTIONS_DELETE:
            return {
                ...state,
                project:{
                    ...state.project,
                    actions: state.project.actions.filter((a) => a.id !== Number(action.payload.id)),
                    pendingActionsDelete:false
                },
                error: '',
                message: action.payload.message
            };
        case PROJECTS_ACTIONS_UPDATE_PENDING:
            return {
                ...state,
                project:{
                    ...state.project,
                    pendingActionsUpdate:true
                }
            }
        case PROJECTS_ACTIONS_UPDATE:
            actions = state.project.actions;
            for(let i=0; i<actions.length; i++){
                if(actions[i].id === Number(action.payload.id)){
                    actions[i] = Object.assign(action.payload.action, action[i]);
                }
            }
            return {
                ...state,
                project:{
                    ...state.project,
                    actions:actions,
                    pendingActionsUpdate:false
                }
            }
        
        case PROJECTS_ACTIONS_STATE_UPDATE_PENDING:
            return {
                ...state,
                project:{
                    ...state.project,
                    pendingActionsUpdate:true
                }
            }
        case PROJECTS_ACTIONS_STATE_UPDATE:
            actions = state.project.actions;
            for(let i=0; i<actions.length; i++){
                if(actions[i].id === Number(action.payload.id)){
                    actions[i].state = action.payload.state;
                }
            }
            return {
                ...state,
                project:{
                    ...state.project,
                    actions:actions,
                    pendingActionsUpdate:false
                }
            }
        


        /* INFORMATIONS */
        case PROJECTS_INFORMATIONS_ADD_PENDING:
            return {
                ...state,
                project:{
                    ...state.project,
                    pendingInformationsAdd:true
                }
            }
        case PROJECTS_INFORMATIONS_ADD:
            let informations = state.project.informations;
            informations.push(action.payload.information);
            return {
                ...state,
                project:{
                    ...state.project,
                    informations:informations,
                    pendingInformationsAdd:false
                }
            }
        case PROJECTS_INFORMATIONS_DELETE_PENDING:
            return {
                ...state,
                project:{
                    ...state.project,
                    pendingInformationsDelete:true
                }
            }
        case PROJECTS_INFORMATIONS_DELETE:
            return {
                ...state,
                project:{
                    ...state.project,
                    informations: state.project.informations.filter((information) => information.id !== Number(action.payload.id)),
                    pendingInformationsDelete:false
                },
                error: '',
                message: action.payload.message
            };
        
        case PROJECTS_INFORMATIONS_UPDATE_PENDING:
            return {
                ...state,
                project:{
                    ...state.project,
                    pendingInformationsUpdate:true
                }
            }
        case PROJECTS_INFORMATIONS_UPDATE:
            informations = state.project.informations;
            for(let i=0; i<informations.length; i++){
                if(informations[i].id === Number(action.payload.id)){
                    informations[i] = Object.assign(action.payload.information, action[i]);
                }
            }
            return {
                ...state,
                project:{
                    ...state.project,
                    actions:actions,
                    pendingInformationsUpdate:false
                }
            }
        
        /* PROJECT */ 
        case PROJECTS_ID_PENDING:
            return{
                ...state,
                project:{
                    ...state.project,
                    pending:true
                }
            }
        case PROJECTS_ID:
            let project = action.payload.project;
            project.pending = false;
            project.pendingEntitiesAdd = false;
            return{
                ...state,
                project:project,
                error:'',
                message:action.payload.message
            }
        case PROJECTS_UPDATE_PENDING:
            return{
                ...state,
                project:{
                    ...state.project,
                    pendingUpdate:true,
                    message:''
                }
            }
        case PROJECTS_UPDATE:
            //console.log("projects_update")
            return{
                ...state,
                project:{
                    ...state.project,
                    pendingUpdate:false,
                    message:'Projet modifié'
                }
            }
        case PROJECTS_RESET:
            return {
                ...state,
                projects: null,
                project: null,
                message: null,
                error: null,
                pending:false,
                pendingAdd:false,
                pendingDelete:false,
                pendingUpdate:false
            };
        case PROJECTS_ERROR:
            return { ...state, error: action.payload.message };
        default:
            return state;
    }
}
