import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactDOM from 'react-dom';
//npm
import moment from 'moment'
//import { getUser } from '../../actions/user';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
//gj-strap
import { returnFunction, /*checkNull, */toolTip, textSlice } from '../../gj_modules/gj-strap/lib';
import { Panel } from '../../gj_modules/gj-strap/components/form';
//actions
import { resetActions, getActionsAll, addAction, updateAction, deleteAction, setStateAction, /*updateAction*/ } from '../../actions/actions'
import { getEntitiesAll } from '../../actions/entities';
import { getUsersAll } from '../../actions/user';
import { getContactsAll } from '../../actions/contacts';
//import Spinner from '../../gj_modules/gj-strap/components/display/Spinner';
import Full from '../../gj_modules/gj-strap/components/partials/Full';
import FooterCicanord from '../partials/FooterCicanord';
import { ComplexAll } from '../../gj_modules/gj-strap/components/complex'
import { faDownload, faPlusCircle, faPrint } from '@fortawesome/free-solid-svg-icons'
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
//current
import { actionsFormat, actionsUpdatePanel, dataToUrl } from '../../lib';
import { required, validateDate } from '../../lib/validate';
import { actionStatus } from '../../data/constants';
import { processSubmit /*, labelByValue*/ } from '../../gj_modules/gj-strap/lib';
import { getManualData, savePdf } from '../../actions/index';
//constants
import { /*actionType,*/ reminderType } from '../../data/constants';

export const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

class Actions extends Component {
    componentDidMount() {
    }

    EventDetail = ({ event, el }) => {
        const content = (
            <React.Fragment>
                <div className="float-left">{event.extendedProps.actionFormat.users}</div>

                {
                    toolTip(
                        textSlice(event.extendedProps.actionFormat.original.Entites_Nom, 15),
                        event.extendedProps.actionFormat.original.Entites_Nom,
                        { placement: "top", trigger: "hover" }
                    )
                }
                
            </React.Fragment>
        )
        //console.log(event.extendedProps.actionFormat.date)
        ReactDOM.render(content, el);
        return el;
    };

    render() {
        const { actions, addAction, updateAction, setStateAction, getEntitiesAll, getContactsAll, getUsersAll, deleteAction } = this.props;

        const properties = [ // DataTable Headers
            { short: "date", name: "Date", width: "20%", category: "classic" },
            { short: "subnameformat", name: "Code", width: "10%", category: "classic" },
            { short: "name", name: "", width: "25%", category: "classic" },
            { short: "state", name:"Statut", width: "5%", category: "classic" },
            { short: "description", name: "Description", width: "30%", category: "classic" },
            { short: "actions", name: "", width: "20%", category: "actions" }
        ];

        let cacheUserIds = [];

        const filters = [
            {
                short:"sortUserId",
                type: "select", size: 20, name: "sortUserId", placeholder: "Utilisateurs", data: {
                    isMulti: true,
                    isAsync: true,
                    forceLabel: true,
                    cache: cacheUserIds,
                    //defaultValue: { value:null, label:"", force:true },
                    loadOptions: returnFunction(getUsersAll,"searchUsersByName", 5, "user.users", "Utilisateurs_ID", ["Utilisateurs_Prenom", "Utilisateurs_Nom"])
                }
            },
            { type: "search", size: 20, name: "searchActionsByDescription", placeholder: "Recherche par description" },
			{ type:"select", size:20, name:"searchActionsByStatus", placeholder:"Trier", options:actionStatus },
			{
                type: "button",
                size: 20,
                name: "",
                title: "Ajouter",
                icon: faPlusCircle,
                fn: () => {
                    let cacheUserIds = [];

                    if (!actions.pendingAdd) {
                        modalFunction({
                            submit: (values) => {
                                if (!actions.pendingAdd) {
                                    console.log(processSubmit(values))
                                    addAction("actions", null, processSubmit(values)).then(
										function(response){
											getActionsAll({ skip:0, limit:20, filter:[]});
										}
                                    );
                                }
                            },
                            text: (
                                <Panel
                                    width={[3, 9]}
                                    inputs={[
                                        {
                                            short: "date", name: "Date", type: "datepicker", placeholder: "", validate: validateDate(), data: {
                                                anytime: true,
                                                alldays: true
                                            }
                                        },
                                        { short: "hours", name: "Heure", type: "selecthour", data:{
                                            minTime:'00:00',
                                            maxTime:'24:00',
                                            step:30
                                        } },
                                        { short: "description", name: "Description", type: "textarea", placeholder: "Action", validate: required() },
                                        {
                                            short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", data: {
                                                isMulti: false,
                                                isAsync: true,
                                                //defaultValue: { value:null, label:"", force:true },
                                                loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 5, "entities.entities", "Entites_ID", "Entites_Nom")
                                            }
                                        },
                                        {
                                            short: "contactId", name: "Contact", type: "select", placeholder: "Rechercher", data: {
                                                isMulti: false,
                                                isAsync: true,
                                                //defaultValue: { value:null, label:"", force:true },
                                                loadOptions: returnFunction(getContactsAll, "searchContactsByName", 5, "contacts.contacts", "Personnes_ID", ["Personnes_Prenom", "Personnes_Nom"])
                                            }
                                        },
                                        /*{
                                            short: "type", name: "Type", type: "select", placeholder: "Type", data: {
                                                isMulti: false,
                                                options: actionType
                                            }
                                        },*/
                                        {
                                            short: "reminderType", name: "Type de rappel", type: "select", placeholder: "Type de rappel", validate: required(), data: {
                                                isMulti: false,
                                                options: reminderType
                                            }
                                        },
                                        {
                                            short: "userIds", name: "Utilisateurs", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                                isMulti: true,
                                                isAsync: true,
                                                forceLabel: true,
                                                cache: cacheUserIds,
                                                //defaultValue: { value:null, label:"", force:true },
                                                loadOptions: returnFunction(getUsersAll, "searchUsersByName", 5, "user.users", "Utilisateurs_ID", ["Utilisateurs_Prenom", "Utilisateurs_Nom"])
                                            }
                                        }
                                    ]}
                                />
                            ),
                            header: "Ajouter une action",
                            state: "actions.pendingAdd"
                        }, "popup")
                    }
                }
            },
            {
                type: "button",
                size: 10,
                name: "",
                title: "Imprimer",
                icon: faPrint,
                fn: (filters) => {
                    let filtersFinal = dataToUrl(filters).split("?")[1];
                    if(filters){
                        window.open(""+(REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "")+"/sic/actionsprint/"+filtersFinal, '_blank');
                    }
                }
            },
            {
                type: "button",
                size: 10,
                name: "",
                title: "Télécharger",
                icon: faDownload,
                fn: (filters) => {
                    if(filters){
                        let filtersFinal = dataToUrl(filters).split("?")[1];
                        getManualData(true, '/actions/print/'+filtersFinal).then(response => {
                            let pdf = atob(response.response.data.pdf);
                            savePdf(pdf, response.response.data.data.title+".pdf");
                        });
                    }
                }
            }
        ]

        let portlet = {
            title: "Actions",
            links: [
                { name: "Recherche", path: "/sic/actions" }
            ]
        }

        let array = [];

        array = actionsFormat(actions.actions, setStateAction)

        return (

            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />
                <Full portlet={portlet}>
                    <ComplexAll
                        mode={["list", "calendar"]}
                        noEditGlobal
                        getElementsAll={getActionsAll}
                        resetElements={resetActions}
                        elements={actions.actions}
                        properties={properties}
                        filters={filters}
                        collection={array}
                        pending={this.props.actions.pending}
                        noDataMessage={"Pas d'actions"}
                        deleteAction={(e) => {
                            modalFunction({
                                btnFunction: () => {
                                    deleteAction(e);
                                },
                                btnText: "Confirmer",
                                text: "Êtes-vous sûr ?",
                                header: "Supprimer une action",
                                state: "actions.pendingDelete"
                            }, "popup")
						}}
						editAction={(id) => {
                            console.log(this.props.actions.actions)
							actionsUpdatePanel(
								id,
								this.props.actions.actions,
								actions.pendingUpdate,
								"actions.pendingUpdate",
								updateAction,
								getEntitiesAll,
								getContactsAll,
								getUsersAll
							)
						}}
                        calendarEventFunction={(a) => {
                            let temp = {
                                title:a.original.Entites_Nom,
                                date:moment(a.original.dateTarget).format("YYYY-MM-DD"),
                                actionFormat: a
                            }
                            return temp
                        }}
                        calendarEventRenderFunction={this.EventDetail}
                    />
                </Full>
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getEntitiesAll,
            getUsersAll,
            getContactsAll,
            resetActions,
			getActionsAll,
			addAction,
			updateAction,
            deleteAction,
            setStateAction,
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        actions: state.actions,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Actions);
