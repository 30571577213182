import { getData } from './index';
import {
    REQUEST_ALL,
    REQUEST_ALL_PENDING,
    REQUEST_ERROR,
    REQUEST_RESET
} from './types';

import { dataToUrl } from '../lib'

export function resetRequests() {
	return dispatch => dispatch({ type: REQUEST_RESET });
}

export function getRequestsAll(data) {
    const url = '/request/all'+dataToUrl(data);
    return dispatch => getData(REQUEST_ALL, REQUEST_ALL_PENDING, REQUEST_ERROR, true, url, dispatch);
}