import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';

import { TileContainer, Tile, TileHeader, TileBody } from '../../gj_modules/gj-strap/components/partials';

import moment from 'moment';

import { Panel } from '../../gj_modules/gj-strap/components/form';
import DataTable from '../../gj_modules/gj-strap/components/form/DataTable';
import { Button } from 'react-bootstrap';

import { addProjectInformation, deleteProjectInformation, updateProjectInformation } from '../../actions/projects';
import { getEntitiesAll } from '../../actions/entities';

import { returnFunction, processSubmit, toolTip, textSlice } from '../../gj_modules/gj-strap/lib';
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';

import { required, validateDate } from '../../lib/validate';

class ProjectInformations extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    dataTableInformationsRender = () => {
        const { project, deleteProjectInformation, updateProjectInformation } = this.props;

        const properties = [ // DataTable Headers
            { short: "date", width: "20%", category: "classic" },
            { short: "name", width: "25%", category: "classic" },
            { short: "description", width: "30%", category: "classic" },
            { short: "user", width: "5%", category: "classic" },
            { short: "actions", width: "20%", category: "actions" }
        ];

        const array = [];

        if (project && project.informations && project.informations.length) {
            for (let i = 0; i < project.informations.length; i++) {
                const object = {
                    id: project.informations[i].id,
                    date: moment(project.informations[i].dateTarget).format("DD/MM/YYYY"),
                    name: (<Link to={"/sic/entity/" + project.informations[i].Entites_ID} className="mx-3">{project.informations[i].Entites_Nom}</Link>),
                    description: (toolTip(textSlice(project.informations[i].description, 50),
                        project.informations[i].description,
                        { placement: "left", trigger: "hover" })
                    ),
                    user: (<Link to={"/sic/user/" + project.informations[i].userId} key={"information" + i + "user" + project.informations[i].userId}>
                        <div className="userCircle" style={{ backgroundColor: "#" + project.informations[i].Utilisateurs_color }}>
                            {toolTip(
                                project.informations[i].Utilisateurs_Login.charAt(0).toUpperCase(),
                                project.informations[i].Utilisateurs_Prenom + " " + project.informations[i].Utilisateurs_Nom,
                                { placement: "left", trigger: "hover" })}
                        </div>
                    </Link>)
                };
                object.key = "information" + project.informations[i].id
                object.index = i + 1;
                array.push(object);
            }
        }

        return (
            <DataTable small
                noEditGlobal
                properties={properties}
                collection={array}
                noDataMessage={"Pas d'informations"}
                showAll={3}
                editAction={(e) => {
                    let index = -1;
                    for (let i = 0; i < project.informations.length; i++) {
                        if (e === project.informations[i].id) {
                            index = i;
                        }
                    }

                    modalFunction({
                        submit: (values) => {
                            if (!project.pendingInformationsUpdate) {
                                /*console.log(processSubmit(values))
                                console.log(e);*/
                                updateProjectInformation(e, processSubmit(values))
                            }
                        },
                        initialValues: {
                            description: (project.informations[index].description) || "",
                            date: moment(project.informations[index].dateTarget).format("YYYY-MM-DD"),
                            entityId: Number(project.informations[index].Entites_ID),
                            path: (project.informations[index].path) || "",
                        },
                        text: (
                            <Panel
                                width={[3, 9]}
                                inputs={[
                                    { short: "description", name: "Description", type: "textarea", placeholder: "Information", validate: required() },
                                    {
                                        short: "date", name: "Date", type: "datepicker", placeholder: "", validate: validateDate(), data: {
                                            anytime: true,
                                            alldays: true
                                        }
                                    },
                                    {
                                        short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                            isMulti: false,
                                            isAsync: true,
                                            defaultValue: { value: Number(project.informations[index].Entites_ID), label: project.informations[index].Entites_Nom, force: true },
                                            loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", "Entites_Nom")
                                        }
                                    },
                                    { short: "path", name: "Fichiers", type: "textarea", placeholder: "\\\\chemin\\fichier", validate: required() },
                                ]}
                            />
                        ),
                        header: "Modifier une information",
                        state: "projects.project.pendingInformationsUpdate"
                    }, "popup")
                }}
                deleteAction={(e) => {
                    modalFunction({
                        btnFunction: () => {
                            console.log("function delete " + e)
                            deleteProjectInformation(e)
                        },
                        btnText: "Confirmer",
                        text: "Êtes-vous sûr ?",
                        header: "Supprimer une information",
                        state: "projects.project.pendingInformationsDelete"
                    }, "popup")
                }}
            />
        )
    }

    render() {
        const { project, getEntitiesAll, addProjectInformation, idProject } = this.props;

        return (
            <React.Fragment>
                {/* Informations */}
                <TileContainer>
                    <Tile size={"full"}>
                        <TileHeader
                            title="Informations"
                            border={true}
                            toolbar={<Button onClick={
                                () => {
                                    if (!project.pendingInformationsAdd) {
                                        modalFunction({
                                            submit: (values) => {
                                                if (!project.pendingInformationsAdd) {
                                                    console.log(values)
                                                    addProjectInformation(idProject, values)
                                                }
                                            },
                                            text: (
                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        { short: "description", name: "Description", type: "textarea", placeholder: "Information", validate: required() },
                                                        {
                                                            short: "date", name: "Date", type: "datepicker", placeholder: "", validate: validateDate(), data: {
                                                                anytime: true,
                                                                alldays: true
                                                            }
                                                        },
                                                        {
                                                            short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", data: {
                                                                isMulti: false,
                                                                isAsync: true,
                                                                //defaultValue: { value:null, label:"", force:true },
                                                                loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", "Entites_Nom")
                                                            }
                                                        },
                                                        { short: "path", name: "Fichiers", type: "textarea", placeholder: "\\\\chemin\\fichier", validate: required() },
                                                    ]}
                                                />
                                            ),
                                            header: "Ajouter une information",
                                            state: "projects.project.pendingInformationsAdd"
                                        }, "popup")
                                    }
                                }
                            }>
                                {project.pendingInformationsAdd ? "O" : "+"}
                            </Button>}
                        />
                        <TileBody>
                            {this.dataTableInformationsRender()}
                        </TileBody>
                    </Tile>
                </TileContainer>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getEntitiesAll,
            addProjectInformation,
            deleteProjectInformation,
            updateProjectInformation
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        project: state.projects.project
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectInformations);