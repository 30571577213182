import React from 'react'
import ReactDOM from 'react-dom'
import cookies from 'universal-cookie';
import App from './App'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import reduxThunk from 'redux-thunk'

import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/lib/integration/react'
import storage from 'redux-persist/lib/storage'

import { AUTH_USER } from './actions/types';

import reducers from './reducers/index'

const persistConfig = {
    key: 'root',
    storage: storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

const createStoreWidthMiddleware = applyMiddleware(reduxThunk)(createStore)
const store = createStoreWidthMiddleware(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
const persistor = persistStore(store)

const cookie = new cookies();
const token = cookie.get('token');

if (token) {
    // Update application state. User has token and is probably authenticated
    store.dispatch({ type: AUTH_USER });
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading="loading..." persistor={persistor}>
            <App/>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

export default store;