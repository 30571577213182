import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//import { findRoleByValue } from '../../lib';

class Rank extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
    }

    render() {
        const { children, currentUser, need, current } = this.props;

        return (
            <React.Fragment>
                {(currentUser.role === need) ? children : null}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        currentUser: state.auth.user,
        current: state.current
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rank);