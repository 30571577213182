import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { getUser } from '../../actions/user';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import moment from 'moment';
//import history from '../../history';

import { getActivitiesAll, addActivity, resetActivities, deleteActivity, updateActivity } from '../../actions/activities'
import { getSectorsAll } from '../../actions/sectors'
//import Spinner from '../../gj_modules/gj-strap/components/display/Spinner';
import { Panel } from '../../gj_modules/gj-strap/components/form';
import FullCenter from '../../gj_modules/gj-strap/components/partials/FullCenter';
import FooterCicanord from '../partials/FooterCicanord';

import { ComplexAll } from '../../gj_modules/gj-strap/components/complex'

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
import { returnFunction, processSubmit } from '../../gj_modules/gj-strap/lib';

import { required } from '../../lib/validate';

//import { directoriesPart } from 'data/constants';


class Activities extends Component {
    componentDidMount(){
    }

    renderTd = (e) => {
        return {
            id: e.Activites_ID,
            key: e.Activites_ID+"_"+e.Activites_Valeur,
            name: e.Activites_Valeur,
            sector: e.Secteurs_Valeur,
            date: moment(e.Activites_dateUpdate).format("DD/MM/YYYY")
        }
    }

    render(){
        const { activities, addActivity, deleteActivity, getSectorsAll, updateActivity } = this.props;

        const localGetActivitiesAll = this.props.getActivitiesAll;

        const properties = [
            { short: "id", width: "6%", category: "id"},
            { short: "name", name:"Nom", width: "18%", category: "classic" },
            { short: "sector", name:"Secteur", width: "18%", category: "classic" },
            { short: "date", name:"Date", width: "13%", category: "classic" },
            { short: "actions", width: "11%", name: "", category: "actions" }
        ];

        const filters = [
            { type:"search", size:30, name:"searchActivitiesByName", placeholder:"Recherche par nom" },
            /*{ type:"search", size:30, name:"searchContactsByEntity", placeholder:"Recherche par entreprise" },
            { type:"select", size:20, name:"searchContactsProspect", placeholder:"Prospect ?", options:contactType },*/
            {
                type:"button",
                size:20,
                name:"",
                title:"Ajouter une activité",
                icon:faPlusCircle,
                fn:() => {
                    modalFunction({
                        submit:(values) => {
                            if(!activities.pendingAdd){
                                //console.log(processSubmit(values))
                                addActivity(processSubmit(values)).then(
                                    function(response){
                                        localGetActivitiesAll({ skip:0, limit:20, filters });
                                        //history.push("/sic/contact/"+response.response.data.contacts)
                                    }
                                );
                            }
                        },
                        text:(
                            <Panel
                                width={[3, 9]}
                                inputs={[
                                    { short: "name", name: "Nom", type: "text", placeholder: "Nom", validate:required() },
                                    {
                                        short: "idSector", name: "Secteur", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                            isMulti: false,
                                            isAsync: true,
                                            //defaultValue: { value:null, label:"", force:true },
                                            loadOptions: returnFunction(getSectorsAll, "searchSectorsByName", 5, "sectors.sectors", "Secteurs_ID", "Secteurs_Valeur")
                                        }
                                    },
                                ]}
                            />
                        ),
                        header:"Ajouter une activité",
                        state:"activities.pendingAdd"
                    }, "popup")
                }
            }
        ]

        let portlet = {
            title:"Activités",
            links:[
                {name:"Recherche", path:"/sic/activities"}
            ]
        }
            
        return(

            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        getElementsAll={getActivitiesAll}
                        resetElements={resetActivities}
                        elements={this.props.activities.activities}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        pending={this.props.activities.pending}
                        noDataMessage = {"Pas d'acitivités"}
                        deleteAction = {(e) => {
                            modalFunction({
                                btnFunction:() => {
                                    deleteActivity(e)
                                },
                                btnText:"Confirmer",
                                text:"Êtes-vous sûr ?",
                                header:"Supprimer une activité",
                                state:"activities.pendingDelete"
                            }, "popup")
                        }}
                        editAction = {(id) => {
                            let index = -1;
                            for (let i = 0; i < activities.activities.length; i++) {
                                if (id === activities.activities[i].Activites_ID) {
                                    index = i;
                                }
                            }

                            modalFunction({
                                submit: (values) => {
                                    if (!activities.pendingUpdate) {
                                        //console.log(processSubmit(values))
                                        updateActivity(id, processSubmit(values))
                                    }
                                },
                                initialValues: {
                                    name: (activities.activities[index].Activites_Valeur) || "",
                                    idSector: Number(activities.activities[index].Secteurs_ID)
                                },
                                text: (
                                    <Panel
                                        width={[3, 9]}
                                        inputs={[
                                            { short: "name", name: "Nom", type: "text", placeholder: "Action", validate: required() },
                                            {
                                                short: "idSector", name: "Secteur", type: "select", placeholder: "Rechercher", validate: required(), data: {
                                                    isMulti: false,
                                                    isAsync: true,
                                                    defaultValue: { value: Number(activities.activities[index].Secteurs_ID), label: activities.activities[index].Secteurs_Valeur, force: true },
                                                    loadOptions: returnFunction(getSectorsAll, "searchSectorsByName", 5, "sectors.sectors", "Secteurs_ID", "Secteurs_Valeur")
                                                }
                                            },
                                        ]}
                                    />
                                ),
                                header: "Modifier une activité",
                                state: "activities.pendingUpdate"
                            }, "popup")
                        }}
                    />
                </FullCenter>
                <FooterCicanord menu={"sic"}/>
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getActivitiesAll,
            addActivity,
            deleteActivity,
            resetActivities,
            getSectorsAll,
            updateActivity
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        activities: state.activities,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Activities);
