import React from 'react';

import moment from 'moment';

import { Panel } from '../gj_modules/gj-strap/components/form';

import { returnFunction, processSubmit, checkNull } from '../gj_modules/gj-strap/lib';
import { modalFunction } from '../gj_modules/gj-strap/components/partials';

import { /*actionType,*/ reminderType } from '../data/constants';

import { required, validateDate } from './validate';

export default function actionsUpdatePanel(e, actions, pendingUpdate, absolutePendingUpdate, updateAction, getEntitiesAll, getContactsAll, getUsersAll) {

    let index = -1;
    for (let i = 0; i < actions.length; i++) {
        if (e === actions[i].id) {
            index = i;
        }
    }

    let cacheUserIds = [];
    let finalUsersIds = [];

    for (let userIds of actions[index].userIds) {
        cacheUserIds.push({
            value: userIds.userId,
            label: userIds.login,
            //key:userIds.login
        })
        finalUsersIds.push(
            //userIds.userId
            {
                value: userIds.userId,
                label: userIds.login,
                force: true
            });
    }

    modalFunction({
        submit: (values) => {
            if (!pendingUpdate) {
                //console.log(processSubmit(values))
                updateAction(e, processSubmit(values))
            }
        },
        initialValues: {
            description: (actions[index].description) || "",
            date: moment(actions[index].dateTarget).format("YYYY-MM-DD"),
            entityId: Number(actions[index].Entites_ID),
            contactId: Number(actions[index].Personnes_ID),
            type: actions[index].type,
            reminderType: actions[index].reminderType,
            userIds: finalUsersIds,
            hours:moment(actions[index].dateTarget).format("HH:MM"),
        },
        text: (
            <Panel
                width={[3, 9]}
                inputs={[
                    {
                        short: "date", name: "Date", type: "datepicker", placeholder: "", validate: validateDate(), data: {
                            anytime: true,
                            alldays: true
                        }
                    },
                    { short: "hours", name: "Heure", type: "selecthour", data:{
                        minTime:'00:00',
                        maxTime:'24:00',
                        step:30
                    } },
                    { short: "description", name: "Description", type: "textarea", placeholder: "Action", validate: required() },
                    {
                        short: "entityId", name: "Entité", type: "select", placeholder: "Rechercher", data: {
                            isMulti: false,
                            isAsync: true,
                            defaultValue: { value: Number(actions[index].Entites_ID), label: actions[index].Entites_Nom, force: true },
                            loadOptions: returnFunction(getEntitiesAll, "searchEntitiesByName", 20, "entities.entities", "Entites_ID", "Entites_Nom")
                        }
                    },
                    {
                        short: "contactId", name: "Contact", type: "select", placeholder: "Rechercher", data: {
                            isMulti: false,
                            isAsync: true,
                            defaultValue: { value: Number(actions[index].Personnes_ID), label: (checkNull(actions[index].Personnes_Prenom) + " " + checkNull(actions[index].Personnes_Nom)), force: true },
                            loadOptions: returnFunction(getContactsAll, "searchContactsByName", 20, "contacts.contacts", "Personnes_ID", ["Personnes_Prenom", "Personnes_Nom"])
                        }
                    },
                    /*{
                        short: "type", name: "Type", type: "select", placeholder: "Type", data: {
                            isMulti: false,
                            //defaultValue: { value:project.actions[index].reminderType, label:"test", force:true },
                            options: actionType
                        }
                    },*/
                    {
                        short: "reminderType", name: "Type de rappel", type: "select", placeholder: "Type de rappel", validate: required(), data: {
                            isMulti: false,
                            //defaultValue: { value:project.actions[index].reminderType, label:"test", force:true },
                            options: reminderType
                        }
                    },
                    {
                        short: "userIds", name: "Utilisateurs", type: "select", placeholder: "Rechercher", validate: required(), data: {
                            isMulti: true,
                            isAsync: true,
                            forceLabel: true,
                            cache: cacheUserIds,
                            //defaultValue: { value:null, label:"", force:true },
                            loadOptions: returnFunction(getUsersAll, "searchUsersByName", 5, "user.users", "Utilisateurs_ID", ["Utilisateurs_Prenom", "Utilisateurs_Nom"])
                        }
                    }
                ]}
            />
        ),
        header: "Modifier une action",
        state: ""+absolutePendingUpdate+""
    }, "popup")
}