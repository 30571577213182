import { getData, postData, putData, deleteData } from './index';
import {
    BUSINESSES_ALL,
    BUSINESSES_ALL_PENDING,
    BUSINESSES_ID,
    BUSINESSES_ID_PENDING,
    BUSINESSES_ADD,
    BUSINESSES_ADD_PENDING,
    BUSINESSES_UPDATE,
    BUSINESSES_UPDATE_PENDING,
    BUSINESSES_DELETE,
    BUSINESSES_DELETE_PENDING,
    BUSINESSES_DIRECTORIES_ADD,
    BUSINESSES_DIRECTORIES_ADD_PENDING,
    BUSINESSES_DIRECTORIES_UPDATE,
    BUSINESSES_DIRECTORIES_UPDATE_PENDING,
    BUSINESSES_DIRECTORIES_DELETE,
    BUSINESSES_DIRECTORIES_DELETE_PENDING,
    BUSINESSES_DIRECTORIES_ENTITIES_ADD,
    BUSINESSES_DIRECTORIES_ENTITIES_ADD_PENDING,
    BUSINESSES_DIRECTORIES_ENTITIES_DELETE,
    BUSINESSES_DIRECTORIES_ENTITIES_DELETE_PENDING,
    BUSINESSES_DIRECTORIES_ENTITIES_UPDATE,
    BUSINESSES_DIRECTORIES_ENTITIES_UPDATE_PENDING,
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_ADD,
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_ADD_PENDING,
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_UPDATE,
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_UPDATE_PENDING,
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_DELETE,
    BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_DELETE_PENDING,
    BUSINESSES_REFERENCES_ALL,
    BUSINESSES_REFERENCES_ALL_PENDING,
    BUSINESSES_REFERENCES_RESET,
    BUSINESSES_RESET,
    BUSINESSES_ERROR
} from './types';

import { dataToUrl } from '../lib'

export function resetBusinesses() {
	return dispatch => dispatch({ type: BUSINESSES_RESET });
}

export function getBusinessesAll(data) {
    const url = '/businesses/all'+dataToUrl(data);
    return dispatch => getData(BUSINESSES_ALL, BUSINESSES_ALL_PENDING, BUSINESSES_ERROR, true, url, dispatch);
}

export function getBusinessesById(id) {
    const url = '/businesses/'+id;
    return dispatch => getData(BUSINESSES_ID, BUSINESSES_ID_PENDING, BUSINESSES_ERROR, true, url, dispatch);
}

export function addBusinesses(data) {
    const url = '/businesses/add';
    return dispatch => postData(BUSINESSES_ADD, BUSINESSES_ADD_PENDING, BUSINESSES_ERROR, true, url, dispatch, data);
}

export function updateBusinessesById(id, data) {
    const url = '/businesses/update/'+id;
    return dispatch => postData(BUSINESSES_UPDATE, BUSINESSES_UPDATE_PENDING, BUSINESSES_ERROR, true, url, dispatch, data);
}

export function deleteBusinesses(id) {
    const url = '/businesses/delete/'+id;
    return dispatch => deleteData(BUSINESSES_DELETE, BUSINESSES_DELETE_PENDING, BUSINESSES_ERROR, true, url, dispatch, null);
}

//Directories

export function addBusinessesDirectory(data) {
    const url = '/businesses/directory/add';
    return dispatch => postData(BUSINESSES_DIRECTORIES_ADD, BUSINESSES_DIRECTORIES_ADD_PENDING, BUSINESSES_ERROR, true, url, dispatch, data);
}

export function updateBusinessesDirectory(id, data) {
    const url = '/businesses/directory/update/'+id;
    return dispatch => putData(BUSINESSES_DIRECTORIES_UPDATE, BUSINESSES_DIRECTORIES_UPDATE_PENDING, BUSINESSES_ERROR, true, url, dispatch, data);
}

export function deleteBusinessesDirectory(id) {
    const url = '/businesses/directory/delete/'+id;
    return dispatch => deleteData(BUSINESSES_DIRECTORIES_DELETE, BUSINESSES_DIRECTORIES_DELETE_PENDING, BUSINESSES_ERROR, true, url, dispatch, null);
}

export function addBusinessesDirectoriesEntity(data) {
    const url = '/businesses/directories/entity/add';
    return dispatch => postData(BUSINESSES_DIRECTORIES_ENTITIES_ADD, BUSINESSES_DIRECTORIES_ENTITIES_ADD_PENDING, BUSINESSES_ERROR, true, url, dispatch, data);
}

export function deleteBusinessesDirectoriesEntity(id) {
    const url = '/businesses/directories/entity/delete/'+id;
    return dispatch => deleteData(BUSINESSES_DIRECTORIES_ENTITIES_DELETE, BUSINESSES_DIRECTORIES_ENTITIES_DELETE_PENDING, BUSINESSES_ERROR, true, url, dispatch, null);
}

export function updateBusinessesDirectoriesEntity(id, data) {
    const url = '/businesses/directories/entity/update/'+id;
    return dispatch => postData(BUSINESSES_DIRECTORIES_ENTITIES_UPDATE, BUSINESSES_DIRECTORIES_ENTITIES_UPDATE_PENDING, BUSINESSES_ERROR, true, url, dispatch, data);
}

export function addBusinessesDirectoriesEntitiesContact(data) {
    const url = '/businesses/directories/entities/contact/add';
    return dispatch => postData(BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_ADD, BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_ADD_PENDING, BUSINESSES_ERROR, true, url, dispatch, data);
}

export function updateBusinessesDirectoriesEntitiesContact(id, data) {
    const url = '/businesses/directories/entities/contact/update/'+id;
    return dispatch => putData(BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_UPDATE, BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_UPDATE_PENDING, BUSINESSES_ERROR, true, url, dispatch, data);
}

export function deleteBusinessesDirectoriesEntitiesContact(id) {
    const url = '/businesses/directories/entities/contact/delete/'+id;
    return dispatch => deleteData(BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_DELETE, BUSINESSES_DIRECTORIES_ENTITIES_CONTACTS_DELETE_PENDING, BUSINESSES_ERROR, true, url, dispatch, null);
}

//References

export function resetBusinessesReferences() {
	return dispatch => dispatch({ type: BUSINESSES_REFERENCES_RESET });
}

export function getBusinessesReferencesAll(data) {
    const url = '/businesses/all'+dataToUrl(data);
    return dispatch => getData(BUSINESSES_REFERENCES_ALL, BUSINESSES_REFERENCES_ALL_PENDING, BUSINESSES_ERROR, true, url, dispatch);
}