import React from 'react';
import ReactSelect from 'react-select';
import ReactAsyncSelect from 'react-select/lib/Async';
import { wrapField } from './Form';

const Select = props => {
	const { options, name, isMulti, input, meta, isAsync, label, onChange, cache, forceLabel, noForm , styles, defaultValue } = props;
    var { categoryOptions } = props;
    const SelectComponent = isAsync ? ReactAsyncSelect : ReactSelect;
    const pushNew = (o) => {
        let find = false;
        for(let c of cache){
            if(c.label === o.label){
                find = true;
            }
            /*if(o.value.hasOwnProperty("type")){
                if(o.value.type === "Feature"){

                }
            }*/
        }
        if(!find){
            cache.push(o);
        }
    };
	let value;
	// attention, avoir une valeur initiale dans Async select ne fonctionne pas car on n'a pas accès à la liste des options...
	// une solution dans ce cas est d'utiliser `defaultValue`, mais il faudra alors fournir les props `getOptionValue` et `getOptionLabel`
	if (!isAsync) {
		if (isMulti) value = (input.value || []).map(v => (options || []).find(opt => opt.value === v));
		else value = (options || []).find(opt => opt.value === input.value);
	} else if (isMulti) value = input.value;
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<SelectComponent
				className={`select ${meta && meta.error && meta.touched ? 'error' : ''} mb-3`}
				classNamePrefix="select"
				//placeholder="Sélectionner"
				noOptionsMessage={input => 'Aucune correspondance'}
				{...props}
				name={name}
                value={value}
				styles={styles}
				cacheOptions
				defaultOptions={defaultValue}
				onChange={value => {
					// normalement on doit retourner `value.value`,
					// mais pour que ça fonctionne avec `defaultValue`, on retourne `value` directement
					// car dans ce cas `value.value` n'existe pas
                    if(!forceLabel){
                        value = value && (isMulti ? value.map(v => v.value || v) : value.value || value);
                    }
                    if(!noForm){
                        input.onChange(value);
                    }
                    console.log(value);
                    onChange && onChange(value);
				}}
				//onInputChange={(newValue) => { console.log(newValue) }}
                options={options}
                getOptionLabel={option => {
                    if (option.label){
                        if(isAsync && isMulti && cache){
                            pushNew(option);
                        }
                        if (categoryOptions !== undefined && option.reqOptions)
                            categoryOptions = option.reqOptions;
                        return option.label;
                    }else{
                        if(isAsync && isMulti && cache){
                            //console.log(cache)
                            //console.log(option)
                            for(let c of cache){
                                if(c.value.hasOwnProperty("type")){
                                    if(c.value.type === "Feature"){
                                        if(option.hasOwnProperty("type")){
                                            if(option.type === "Feature"){
                                                if(option.properties.id === c.value.properties.id){
                                                    return c.label;
                                                }
                                            }
                                        }
                                    }
                                }
                                if(c.value === option){
                                    return c.label;
                                }
                            }
                        }
                    }
                    /*else{
                        console.log("nolabel");
                        return "nolabel"
                    }*/
                }}
			/>
			{meta && meta.error && meta.touched && <p className="form-input-error-message">{meta.error}</p>}
		</div>
	);
};

export default wrapField(Select);
