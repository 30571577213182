import { getData, postData, putData, deleteData} from './index';
import {
    USER_ERROR,
    RESET_USER,
    UPDATE_NOTIFICATION,
    GET_USER,
    GET_USER_SELF,
    SET_PREFERENCE,
    EDIT_USER_REQUEST,
    EDIT_USER_SUCCESS,
    EDIT_USER_ERROR,
    USERS_ALL,
    USERS_ALL_PENDING,
    USERS_ADD,
    USERS_ADD_PENDING,
    USERS_UPDATE,
    USERS_UPDATE_PENDING,
    USERS_DELETE,
    USERS_DELETE_PENDING,
    USERS_UPDATE_PASSWORD,
    USERS_UPDATE_PASSWORD_PENDING,
    USERS_RESET
} from './types';

import { dataToUrl } from '../lib'
import history from '../history';

export function addUser(data) {
    const url = '/user/add';
    return dispatch => postData(USERS_ADD, USERS_ADD_PENDING, USER_ERROR, true, url, dispatch, data);
}

export function getUserById(id) {
    const url = '/user/'+id;
    return dispatch => getData(GET_USER, null, USER_ERROR, true, url, dispatch);
}

export function getUsersAll(data) {
    const url = '/user/all'+dataToUrl(data);
    return dispatch => getData(USERS_ALL, USERS_ALL_PENDING, USER_ERROR, true, url, dispatch);
}

export function resetUsers() {
    return dispatch =>
        dispatch({
            type: USERS_RESET
        });
}

export function updateNotification(bool, id, preference) {
    const url = '/user/notification/' + id;
    return dispatch => {
        dispatch({
            type: SET_PREFERENCE,
            preference,
            value: bool
        });
        return postData(UPDATE_NOTIFICATION, null, USER_ERROR, true, url, dispatch, {
            notification: bool,
            preference: preference
        });
    };
}

export function resetUser() {
    return dispatch =>
        dispatch({
            type: RESET_USER
        });
}

export function getUserSelf() {
    const url = '/user/self';
    return dispatch => getData(GET_USER_SELF, null, USER_ERROR, true, url, dispatch);
}

export function getUser(userId) {
    const url = '/user/specific/' + userId;
    return dispatch => getData(GET_USER, null, USER_ERROR, true, url, dispatch);
}

export function updateUser(id, data){
    const url = '/user/update/'+id;
    return dispatch => putData(USERS_UPDATE, USERS_UPDATE_PENDING, USER_ERROR, true, url, dispatch, data);
}

export function updateUserPreferences(data){
    const url = '/user/updatepreferences';
    return dispatch => putData(USERS_UPDATE, USERS_UPDATE_PENDING, USER_ERROR, true, url, dispatch, data);
}

export function deleteUser(id) {
    const url = '/user/delete/'+id;
    return dispatch => deleteData(USERS_DELETE, USERS_DELETE_PENDING, USER_ERROR, true, url, dispatch);
}

export function updateUserPassword(id, data){
    const url = '/user/updatepassword/'+id;
    return dispatch => putData(USERS_UPDATE_PASSWORD, USERS_UPDATE_PASSWORD_PENDING, USER_ERROR, true, url, dispatch, data);
}

export function editUserRequest(userId, payload, redirect) {
    const url = '/user/edit/' + userId;
    return dispatch => {
        dispatch({ type: EDIT_USER_REQUEST, userId, payload });

        return putData(
            EDIT_USER_SUCCESS,
            EDIT_USER_ERROR,
            true,
            url,
            dispatch,
            payload
        ).then(r => {
            if (r.response && redirect) history.push(redirect);
        });
    };
}
