import { getData, postData } from './index';
import { FETCH_NOTIFICATION, VIEWED_NOTIFICATION, GET_SOME_NOTIFICATION, NOTIFICATION_ERROR } from './types';

//= ===============================
// Notification
//= ===============================

export function fetchNotification() {
    const url = '/notification';
    return dispatch => getData(FETCH_NOTIFICATION, null, NOTIFICATION_ERROR, true, url, dispatch);
}

export function viewedNotification(obj) {
    const url = '/notification/viewed';
    return dispatch => postData(VIEWED_NOTIFICATION, null, NOTIFICATION_ERROR, true, url, dispatch, obj);
}

export function getNotificationByUser(data) {
    const url = '/notification/user';
    return dispatch => postData(GET_SOME_NOTIFICATION, null, NOTIFICATION_ERROR, true, url, dispatch, data);
}
