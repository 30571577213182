import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetPassword, clearAuth } from '../../../actions/auth';
import { Form, Input } from '../../../gj_modules/gj-strap/components/form';
import { required, equalsField } from '../../../lib/validate';
import { Container, Row } from 'react-bootstrap';
import history from '../../../history';
import HeaderTop from '../../partials/HeaderTop';
class ResetPassword extends Component {
    UNSAFE_componentWillMount() {
        this.props.clearAuth();
        if (this.props.authenticated) {
            history.push('/home');
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.authenticated) {
            history.push('/home');
        }
    }

    submit = values => {
        const resetToken = this.props.match.params.resetToken;
        const password = values.password;
        this.props.resetPassword(resetToken, { password });
    }

    renderAlert() {
        if (this.props.errorMessage) {
            return (
                <div className="alert alert-danger">
                    {this.props.errorMessage}
                </div>
            );
        }
    }

    renderMessage() {
        return (
            <React.Fragment>
                <div className="alert alert-success">
                    {this.props.message}
                </div>
                <div className="d-table m-auto py-4">
                    <Link to="/" className="signup-link">
                        <span className="px-4">Retour à la page de connexion</span>
                    </Link>
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <HeaderTop />
                <Container fluid>
                    <div className="content" id="step1">
                        <Row>
                            <div className="col-lg-12 col-xl-12">
                                <h2 id="title1">
                                    <span>Réinitialiser mon mot de passe</span>
                                </h2>
                            </div>

                            <div
                                id="container1-step1"
                                className="col-sm-12 col-md-5 col-xl-3"
                            >
                                <Form onSubmit={this.submit} className="w-100">
                                    <Input
                                        type="password"
                                        name="password"
                                        label="Nouveau mot de passe"
                                        placeholder="mot de passe"
                                        validate={required()}
                                    />
                                    <Input
                                        type="password"
                                        name="confirmPassword"
                                        label="Confirmez votre mot de passe"
                                        placeholder="entrer à nouveau le mot de passe"
                                        validate={equalsField('password', 'Mot de passe différent')}
                                    />

                                    {this.renderAlert()}
                                    
                                    {this.props.message ?
                                        this.renderMessage() :
                                        <div className="d-table m-auto py-4">
                                            <button id="btn-solid" type="submit" className="btn btn-outline">
                                                <span className="px-4">Changer le mot de passe</span>
                                            </button>
                                        </div>
                                    }
                                </Form>
                            </div>
                        </Row>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        errorMessage: state.auth.error,
        message: state.auth.message,
        authenticated: state.auth.authenticated
    };
}

export default connect(mapStateToProps, { resetPassword, clearAuth })(ResetPassword);
