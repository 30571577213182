import { getData } from './index';
import {
    SEARCH_ALL,
    SEARCH_ALL_PENDING,
    SEARCH_ERROR,
    SEARCH_RESET
} from './types';

import { dataToUrl } from '../lib'

export function resetSearch() {
	return dispatch => dispatch({ type: SEARCH_RESET });
}

export function getSearchAll(data) {
    const url = '/search/all'+dataToUrl(data);
    return dispatch => getData(SEARCH_ALL, SEARCH_ALL_PENDING, SEARCH_ERROR, true, url, dispatch);
}