import { getData, postData, deleteData } from './index';
import {
    EMPLOYEES_ALL,
    EMPLOYEES_ALL_PENDING,
    EMPLOYEES_ADD,
    EMPLOYEES_ADD_PENDING,
    EMPLOYEES_DELETE,
    EMPLOYEES_DELETE_PENDING,
    EMPLOYEES_ERROR,
    EMPLOYEES_RESET
} from './types';

import { dataToUrl } from '../lib'

export function addEmployee(data) {
    const url = '/employee/add';
    return dispatch => postData(EMPLOYEES_ADD, EMPLOYEES_ADD_PENDING, EMPLOYEES_ERROR, true, url, dispatch, data);
}

export function getEmployeesAll(data) {
    const url = '/employees/all'+dataToUrl(data);
    return dispatch => getData(EMPLOYEES_ALL, EMPLOYEES_ALL_PENDING, EMPLOYEES_ERROR, true, url, dispatch);
}

export function deleteEmployee(id) {
    const url = '/employee/delete/'+id;
    return dispatch => deleteData(EMPLOYEES_DELETE, EMPLOYEES_DELETE_PENDING, EMPLOYEES_ERROR, true, url, dispatch);
}

export function resetEmployees() {
    return dispatch =>
        dispatch({
            type: EMPLOYEES_RESET
        });
}