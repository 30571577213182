import React from 'react';
import HeaderTop from '../partials/HeaderTop';
import Full from '../../gj_modules/gj-strap/components/partials/Full';

export default () => (
    <React.Fragment>
        <HeaderTop />
        <Full>
            <h1 id="title1" className="mt-5">
                <span>404 - Page Not Found</span>
            </h1>
            <h3 id="title1">
                <span>Désolé, la page que vous cherchez n'existe pas !</span>
            </h3>
        </Full>
    </React.Fragment>
)

// export default NotFoundPage;