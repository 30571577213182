import { getData, postData, deleteData } from './index';
import {
    PROJECTS_ALL,
    PROJECTS_ALL_PENDING,
    PROJECTS_ID,
    PROJECTS_ID_PENDING,
    PROJECTS_ADD,
    PROJECTS_ADD_PENDING,
    PROJECTS_UPDATE,
    PROJECTS_UPDATE_PENDING,
    PROJECTS_DELETE,
    PROJECTS_DELETE_PENDING,
    PROJECTS_ENTITIES_ADD,
    PROJECTS_ENTITIES_ADD_PENDING,
    PROJECTS_ENTITIES_UPDATE,
    PROJECTS_ENTITIES_UPDATE_PENDING,
    PROJECTS_ENTITIES_DELETE,
    PROJECTS_ENTITIES_DELETE_PENDING,
    PROJECTS_ACTIONS_ADD,
    PROJECTS_ACTIONS_ADD_PENDING,
    PROJECTS_ACTIONS_UPDATE,
    PROJECTS_ACTIONS_UPDATE_PENDING,
    PROJECTS_ACTIONS_STATE_UPDATE,
    PROJECTS_ACTIONS_STATE_UPDATE_PENDING,
    PROJECTS_ACTIONS_DELETE,
    PROJECTS_ACTIONS_DELETE_PENDING,
    PROJECTS_INFORMATIONS_ADD,
    PROJECTS_INFORMATIONS_ADD_PENDING,
    PROJECTS_INFORMATIONS_UPDATE,
    PROJECTS_INFORMATIONS_UPDATE_PENDING,
    PROJECTS_INFORMATIONS_DELETE,
    PROJECTS_INFORMATIONS_DELETE_PENDING,
    PROJECTS_RESET,
    PROJECTS_ERROR
} from './types';

import { dataToUrl } from '../lib'

export function resetProjects() {
	return dispatch => dispatch({ type: PROJECTS_RESET });
}

export function getProjectsAll(data) {
    const url = '/projects/all'+dataToUrl(data);
    return dispatch => getData(PROJECTS_ALL, PROJECTS_ALL_PENDING, PROJECTS_ERROR, true, url, dispatch);
}

//Project

export function getProjectById(id) {
    const url = '/projects/'+id;
    return dispatch => getData(PROJECTS_ID, PROJECTS_ID_PENDING, PROJECTS_ERROR, true, url, dispatch);
}

export function addProject(data) {
    const url = '/projects/add';
    return dispatch => postData(PROJECTS_ADD, PROJECTS_ADD_PENDING, PROJECTS_ERROR, true, url, dispatch, data);
}

export function updateProjectById(id, data) {
    const url = '/projects/update/'+id;
    return dispatch => postData(PROJECTS_UPDATE, PROJECTS_UPDATE_PENDING, PROJECTS_ERROR, true, url, dispatch, data);
}

export function deleteProject(id) {
    const url = '/projects/delete/'+id;
    return dispatch => deleteData(PROJECTS_DELETE, PROJECTS_DELETE_PENDING, PROJECTS_ERROR, true, url, dispatch, null);
}



//Entity Project

export function addProjectEntity(id, data) {
    const url = '/projects/addentity/'+id;
    return dispatch => postData(PROJECTS_ENTITIES_ADD, PROJECTS_ENTITIES_ADD_PENDING, PROJECTS_ERROR, true, url, dispatch, data);
}

export function deleteProjectEntity(id) {
    const url = '/projects/deleteentity/'+id;
    return dispatch => deleteData(PROJECTS_ENTITIES_DELETE, PROJECTS_ENTITIES_DELETE_PENDING, PROJECTS_ERROR, true, url, dispatch, null);
}

export function updateProjectEntity(id, data) {
    const url = '/projects/updateentity/'+id;
    return dispatch => postData(PROJECTS_ENTITIES_UPDATE, PROJECTS_ENTITIES_UPDATE_PENDING, PROJECTS_ERROR, true, url, dispatch, data);
}


//Action Project

export function addProjectAction(id, data) {
    const url = '/actions/add/project/'+id;
    return dispatch => postData(PROJECTS_ACTIONS_ADD, PROJECTS_ACTIONS_ADD_PENDING, PROJECTS_ERROR, true, url, dispatch, data);
}

export function deleteProjectAction(id) {
    const url = '/projects/deleteaction/'+id;
    return dispatch => deleteData(PROJECTS_ACTIONS_DELETE, PROJECTS_ACTIONS_DELETE_PENDING, PROJECTS_ERROR, true, url, dispatch, null);
}

export function updateProjectAction(id, data) {
    const url = '/projects/updateaction/'+id;
    return dispatch => postData(PROJECTS_ACTIONS_UPDATE, PROJECTS_ACTIONS_UPDATE_PENDING, PROJECTS_ERROR, true, url, dispatch, data);
}

export function setProjectStateAction(id, data) {
    const url = '/actions/setState/'+id;
    return dispatch => postData(PROJECTS_ACTIONS_STATE_UPDATE, PROJECTS_ACTIONS_STATE_UPDATE_PENDING, PROJECTS_ERROR, true, url, dispatch, data);
}


//Information Project

export function addProjectInformation(id, data) {
    const url = '/projects/addinformation/'+id;
    return dispatch => postData(PROJECTS_INFORMATIONS_ADD, PROJECTS_INFORMATIONS_ADD_PENDING, PROJECTS_ERROR, true, url, dispatch, data);
}

export function deleteProjectInformation(id) {
    const url = '/projects/deleteinformation/'+id;
    return dispatch => deleteData(PROJECTS_INFORMATIONS_DELETE, PROJECTS_INFORMATIONS_DELETE_PENDING, PROJECTS_ERROR, true, url, dispatch, null);
}

export function updateProjectInformation(id, data) {
    const url = '/projects/updateinformation/'+id;
    return dispatch => postData(PROJECTS_INFORMATIONS_UPDATE, PROJECTS_INFORMATIONS_UPDATE_PENDING, PROJECTS_ERROR, true, url, dispatch, data);
}