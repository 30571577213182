import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import FooterCicanord from '../partials/FooterCicanord';

import FullCenter from '../../gj_modules/gj-strap/components/partials/FullCenter';
import { ComplexAll } from '../../gj_modules/gj-strap/components/complex'
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';

import moment from 'moment';
import history from '../../history';

import { resetEmployees, getEmployeesAll, addEmployee, deleteEmployee } from '../../actions/employees'

class Employees extends Component {
    componentDidMount() {
    }

    renderTd = (e) => {
        return {
            id:e.Salarie_ID,
            key: e.Salarie_ID,
            name: e.Personnes_Nom,
            surname: e.Personnes_Prenom,
            date: e.Salarie_DateEntree ? moment(e.Salarie_DateEntree).format("DD/MM/YYYY - hh:mm") : ""
        }
    }

    render() {
        const { deleteEmployee } = this.props;

        const properties = [
            { short: "name", name: "Nom", width: "18%" },
            { short: "surname", name: "Prénom", width: "16%" },
            { short: "date", name: "Date", width: "13%" },
            /*{ short: "actions", name: "", width: "9%", category: "actions" }*/
        ];

        const filters = [
            { type: "search", size: 75, name: "searchContactsByName", placeholder: "Recherche par nom" },
            /*{
                type: "button",
                size: 25,
                name: "",
                title: "Ajouter",
                icon: faPlusCircle,
                fn: () => {
                    modalFunction({
                        submit: (values) => {
                            if (!employees.pendingAdd) {
                                console.log(values)
                                addEmployee(values).then(
                                    function (response) {
                                        history.push("/sic/employee/" + response.response.data.employee)
                                    }
                                );
                            }
                        },
                        text: (
                            <Panel
                                width={[3, 9]}
                                inputs={[
                                    { short: "login", name: "Login", type: "text", placeholder: "Login", validate: required() },
                                    { short: "surname", name: "Prénom", type: "text", placeholder: "Prénom", validate: required() },
                                    { short: "name", name: "Nom", type: "text", placeholder: "Nom", validate: required() }
                                ]}
                            />
                        ),
                        header: "Ajouter un salarié",
                        state: "employees.pendingAdd"
                    }, "popup")
                }
            }*/
        ]

        let portlet = {
            title: "Salariés",
            links: [
                { name: "Recherche", path: "/sic/employees" }
            ]
        }

        return (

            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        getElementsAll={getEmployeesAll}
                        resetElements={resetEmployees}
                        elements={this.props.employees.employees}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        pending={this.props.employees.pending}
                        noDataMessage={`Pas de salariés`}
                        deleteAction={(e) => {
                            modalFunction({
                                btnFunction: () => {
                                    deleteEmployee(e);
                                    console.log("delete " + e)
                                },
                                btnText: "Confirmer",
                                text: "Êtes-vous sûr ?",
                                header: "Supprimer un salarié",
                                state: "employee.pendingDelete"
                            }, "popup")
                        }}
                        editAction={(id) => (history.push("/sic/employee/" + id))}
                    />
                </FullCenter>
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getEmployeesAll,
            resetEmployees,
            addEmployee,
            deleteEmployee
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        employees: state.employees,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Employees);
