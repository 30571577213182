import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Ionicon from 'react-ionicons';
import HeaderTop from '../../partials/HeaderTop';
import { Container, Row } from 'react-bootstrap';

class AccountValidation extends Component {
    state = {
        ok : null
    }

    componentDidMount() {
        const { match } = this.props;
        const isEdit = match && match.params && match.params.token;
        if (isEdit) {
            axios
                .post(process.env.REACT_APP_API_URL + '/auth/account-validation/' + match.params.token)
                .then( response => this.setState({ ok : response.message }))
                .catch(error => error);
        }
    }

    render() {
        return (
                <React.Fragment>
                    <HeaderTop />
                    <Container fluid>
                        <div className="content" id="step1">
                            <Row>
                                <div className="col-lg-12 col-xl-12">
                                    <h2 id="title1">
                                        <span className="icon">
                                            <Ionicon
                                                icon="md-checkmark-circle-outline"
                                                fontSize="100px"
                                                color="#58D8A4"
                                            />
                                        </span>
                                    </h2>
                                    <h2 id="title1">
                                        <span>Votre compte est maintenant validé.</span>
                                    </h2>
                                </div>

                                <div
                                    id="container1-step1"
                                    className="col-sm-12 col-md-5 col-xl-3"
                                >
                                    <p className="text-center">Rendez-vous sur la page de connexion pour accéder à l'application.</p>
                                    <div className="d-table m-auto py-4">
                                        <Link id="btn-solid" className="btn btn-outline" to="/">
                                            <span className="px-4">Se connecter</span>
                                        </Link>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Container>
                </React.Fragment>
        );
    }
}

export default AccountValidation;
