import {
    ACTIONS_ALL,
    ACTIONS_ALL_PENDING,
    ACTIONS_ADD,
    ACTIONS_ADD_PENDING,
    ACTIONS_UPDATE,
    ACTIONS_UPDATE_PENDING,
    ACTIONS_DELETE,
    ACTIONS_DELETE_PENDING,
    ACTIONS_UPDATE_STATE,
    ACTIONS_UPDATE_STATE_PENDING,
    ACTIONS_ADD_DEFAULT,
    ACTIONS_ADD_DEFAULT_PENDING,
    ACTIONS_ERROR,
    ACTIONS_RESET
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    actions: null,
    action: null,
    message: null,
    pending: false,
    pendingAdd: false,
    pendingDelete: false,
    pendingUpdate: false,
    pendingDefault: false,
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ACTIONS_ALL_PENDING:
            return {
                ...state,
                pending: true
            }
        case ACTIONS_ALL:
            let actions = action.payload.actions;
            actions['count'] = action.payload.actionsCount;
            return {
                ...state,
                actions: actions,
                error: '',
                message: action.payload.message,
                pending: false
            };
        case ACTIONS_ADD_PENDING:
            return {
                ...state,
                pendingAdd: true
            }
        case ACTIONS_ADD:
            return {
                ...state,
                error: '',
                message: action.payload.message,
                pendingAdd: false
            };
        case ACTIONS_UPDATE_PENDING:
            return {
                ...state,
                pendingUpdate: true
            }
        case ACTIONS_UPDATE:
            actions = state.actions;
            for (let i = 0; i < actions.length; i++) {
                if (actions[i].id === Number(action.payload.id)) {
                    actions[i] = Object.assign(actions[i], action.payload.action);
                }
            }
            return {
                ...state,
                actions: actions,
                error: '',
                message: action.payload.message,
                pendingUpdate: false
            };
        case ACTIONS_DELETE_PENDING:
            return {
                ...state,
                pendingDelete: true
            }
        case ACTIONS_DELETE:
            return {
                ...state,
                actions: state.actions.filter((a) => a.id !== Number(action.payload.id)),
                error: '',
                message: action.payload.message,
                pendingDelete: false
            };
        case ACTIONS_UPDATE_STATE_PENDING:
            /*let entities = action.payload.entities;
            entities['count'] = action.payload.entitiesCount;*/
            return {
                ...state
            };
        case ACTIONS_UPDATE_STATE:
            let newActions = state.actions;
            for (let i = 0; i < newActions.length; i++) {
                if (newActions[i].id === Number(action.payload.id)) {
                    newActions[i].state = action.payload.state;
                }
            }
            return {
                ...state,
                actions: newActions
            };
        case ACTIONS_ADD_DEFAULT_PENDING:
            return {
                ...state,
                pendingDefault: true
            }
        case ACTIONS_ADD_DEFAULT:
            return {
                ...state,
                pendingDefault: false
            }
        case ACTIONS_RESET:
            return {
                ...state,
                actions: null,
                action: null,
                message: null,
                pending: false,
                pendingAdd: false,
                pendingDelete: false,
                pendingUpdate: false,
                pendingDefault: false,
            };
        case ACTIONS_ERROR:
            return { ...state, error: action.payload.message };
        default:
            return state;
    }
}
