import React, { Component } from 'react'
import { connect } from 'react-redux'
import filter from 'lodash/filter'
import { bindActionCreators } from 'redux'
import { getNotificationByUser } from '../../actions/notification'
import { navLinks } from '../../data/menu'
import Header from '../../gj_modules/gj-strap/components/partials/Header'
import { logoutUser } from '../../actions/auth'

class HeaderTop extends Component {
    countNotification = () => {
        return filter(this.props.notification, {
            state: 'PENDING'
        }).length;
    };

    render() {
        const { currentUser, menu } = this.props;
        //const notifs = this.countNotification();

        return (
            <Header
                headerTitle="Cicanord - Portail"
                navLinks={navLinks[menu]}
                currentUser={currentUser}
                isUser={true}
                canRegister={false}
                logoutUser={this.props.logoutUser}
                authenticatedUser={currentUser}
				notificationIcon={false}
				logo
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logoutUser,
            getNotificationByUser
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        notification: state.notifications.notification,
        currentUser: state.auth.user
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderTop);
