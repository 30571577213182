import { getData, postData, putData, deleteData } from './index';
import {
    ENTITIES_ALL,
    ENTITIES_ALL_PENDING,
    ENTITIES_ID,
    ENTITIES_ID_PENDING,
    ENTITIES_ADD,
    ENTITIES_ADD_PENDING,
    ENTITIES_UPDATE,
    ENTITIES_UPDATE_PENDING,
    ENTITIES_DELETE,
    ENTITIES_DELETE_PENDING,
    ENTITIES_CONTACT_ADD,
    ENTITIES_CONTACT_ADD_PENDING,
    ENTITIES_CONTACT_UPDATE,
    ENTITIES_CONTACT_UPDATE_PENDING,
    ENTITIES_CONTACT_DELETE,
    ENTITIES_CONTACT_DELETE_PENDING,
	ENTITIES_ACTIVITY_ADD,
	ENTITIES_ACTIVITY_ADD_PENDING,
	ENTITIES_ACTIVITY_DELETE,
	ENTITIES_ACTIVITY_DELETE_PENDING,
    ENTITIES_RESET,
    ENTITIES_ERROR
} from './types';

import { dataToUrl } from '../lib'

export function resetEntities() {
	return dispatch => dispatch({ type: ENTITIES_RESET });
}

export function getEntitiesAll(data) {
    const url = '/entities/all'+dataToUrl(data);
    console.log(url);
    return dispatch => getData(ENTITIES_ALL, ENTITIES_ALL_PENDING, ENTITIES_ERROR, true, url, dispatch);
}

export function getEntityById(id) {
    const url = '/entities/'+id;
    return dispatch => getData(ENTITIES_ID, ENTITIES_ID_PENDING, ENTITIES_ERROR, true, url, dispatch);
}

export function addEntity(data) {
    const url = '/entities/add';
    return dispatch => postData(ENTITIES_ADD, ENTITIES_ADD_PENDING, ENTITIES_ERROR, true, url, dispatch, data);
}

export function updateEntityById(id, data) {
    const url = '/entities/update/'+id;
    return dispatch => postData(ENTITIES_UPDATE, ENTITIES_UPDATE_PENDING, ENTITIES_ERROR, true, url, dispatch, data);
}

export function deleteEntity(id) {
    const url = '/entities/delete/'+id;
    return dispatch => deleteData(ENTITIES_DELETE, ENTITIES_DELETE_PENDING, ENTITIES_ERROR, true, url, dispatch, null);
}

//Contact Entities

export function addEntityContact(id, data) {
    const url = '/entities/addcontact/'+Number(id);
    return dispatch => postData(ENTITIES_CONTACT_ADD, ENTITIES_CONTACT_ADD_PENDING, ENTITIES_ERROR, true, url, dispatch, data);
}

export function updateEntityContact(id, data) {
    const url = '/entities/updatecontact/'+id;
    return dispatch => putData(ENTITIES_CONTACT_UPDATE, ENTITIES_CONTACT_UPDATE_PENDING, ENTITIES_ERROR, true, url, dispatch, data);
}

export function deleteEntityContact(id) {
    const url = '/entities/deletecontact/'+id;
    return dispatch => deleteData(ENTITIES_CONTACT_DELETE, ENTITIES_CONTACT_DELETE_PENDING, ENTITIES_ERROR, true, url, dispatch, null);
}

//Activity Entities

export function addEntityActivity(id, data) {
    const url = '/entities/addactivity/'+Number(id);
    return dispatch => postData(ENTITIES_ACTIVITY_ADD, ENTITIES_ACTIVITY_ADD_PENDING, ENTITIES_ERROR, true, url, dispatch, data);
}

export function deleteEntityActivity(id) {
    const url = '/entities/deleteactivity/'+id;
    return dispatch => deleteData(ENTITIES_ACTIVITY_DELETE, ENTITIES_ACTIVITY_DELETE_PENDING, ENTITIES_ERROR, true, url, dispatch, null);
}