import {
    ENTITIES_ALL,
    ENTITIES_ALL_PENDING,
    ENTITIES_ID,
    ENTITIES_ID_PENDING,
    ENTITIES_ADD,
    ENTITIES_ADD_PENDING,
    ENTITIES_UPDATE,
    ENTITIES_UPDATE_PENDING,
    ENTITIES_DELETE,
    ENTITIES_DELETE_PENDING,
    ENTITIES_CONTACT_ADD,
    ENTITIES_CONTACT_ADD_PENDING,
    ENTITIES_CONTACT_UPDATE,
    ENTITIES_CONTACT_UPDATE_PENDING,
    ENTITIES_CONTACT_DELETE,
    ENTITIES_CONTACT_DELETE_PENDING,
	ENTITIES_ACTIVITY_ADD,
	ENTITIES_ACTIVITY_ADD_PENDING,
	ENTITIES_ACTIVITY_DELETE,
	ENTITIES_ACTIVITY_DELETE_PENDING,
    ENTITIES_RESET,
    ENTITIES_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    entities: null,
    entity: null,
    message: null,
    pending: false,
    pendingAdd: false,
    pendingDelete: false,
    pendingUpdate: false,
	pendingActivityAdd: false,
	pendingActivityDelete: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ENTITIES_ALL_PENDING:
            return {
                ...state,
                pending: true
            }
        case ENTITIES_ALL:
            let entities = action.payload.entities;
            entities['count'] = action.payload.entitiesCount;
            return {
                ...state,
                entities: entities,
                error: '',
                pending: false,
                message: action.payload.message
            };
        case ENTITIES_ADD_PENDING:
            return {
                ...state,
                pendingAdd: true
            }
        case ENTITIES_ADD:
            return {
                ...state,
                pendingAdd: false
            }
        case ENTITIES_DELETE_PENDING:
            return {
                ...state,
                pendingDelete: true
            }
        case ENTITIES_DELETE:
            let newEntities = state.entities;
            if (newEntities !== null) {
                newEntities = state.entities.filter((entity) => entity.Entites_ID !== Number(action.payload.id))
            }
            return {
                ...state,
                entities: newEntities,
                pendingDelete: false,
                error: '',
                message: action.payload.message
            };
        case ENTITIES_ID_PENDING:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    pending: true
                }
            }
        case ENTITIES_ID:
            let entity = action.payload.entity;
            return {
                ...state,
                entity: entity,
                error: '',
                message: action.payload.message
            };
        case ENTITIES_UPDATE_PENDING:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    pendingUpdate: true,
                    message: ''
                }
            }
        case ENTITIES_UPDATE:
            //console.log("contacts_update")
            return {
                ...state,
                entity: {
                    ...state.entity,
                    pendingUpdate: false,
                    message: 'Entité modifié'
                }
            }

		/* ACTIVITY */
		case ENTITIES_ACTIVITY_ADD_PENDING:
            return {
                ...state,
				pendingActivityAdd: true
            }
		case ENTITIES_ACTIVITY_ADD:
			let activities = state.entity.activities;
            activities.push(action.payload.activity);
			return {
				...state,
				entity: {
                    ...state.entity,
					activities:activities
                },
				pendingActivityAdd: false
			}

		case ENTITIES_ACTIVITY_DELETE_PENDING:
			return {
				...state,
				pendingActivityDelete: true
			}
		case ENTITIES_ACTIVITY_DELETE:
			return {
				...state,
				entity: {
                    ...state.entity,
                    activities: state.entity.activities.filter((activity) => activity.SecteursActivites_ID !== Number(action.payload.id)),
                },
				pendingActivityDelete: false
			}

        /* CONTACTS */
        case ENTITIES_CONTACT_ADD_PENDING:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    pendingContactsAdd: true
                }
            }
        case ENTITIES_CONTACT_ADD:
            let contacts = state.entity.persons;
            contacts.push(action.payload.contact);
            return {
                ...state,
                entity: {
                    ...state.entity,
                    persons: contacts,
                    pendingContactsAdd: false
                }
            }
        case ENTITIES_CONTACT_DELETE_PENDING:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    pendingContactsDelete: true
                }
            }
        case ENTITIES_CONTACT_DELETE:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    persons: state.entity.persons.filter((contact) => contact.PersonnesEntites_ID !== Number(action.payload.id)),
                    pendingContactsDelete: false
                },
                error: '',
                message: action.payload.message
            };
        case ENTITIES_CONTACT_UPDATE_PENDING:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    pendingContactsUpdate: true
                }
            }
        case ENTITIES_CONTACT_UPDATE:
            contacts = state.entity.persons;
            for (let i = 0; i < contacts.length; i++) {
                if (contacts[i].PersonnesEntites_ID === Number(action.payload.contact.PersonnesEntites_ID)) {
                    contacts[i] = action.payload.contact;
                }
            }
            return {
                ...state,
                entity: {
                    ...state.entity,
                    persons: contacts,
                    pendingContactsUpdate: false
                }
            }
        case ENTITIES_RESET:
            return {
                ...state,
                ...INITIAL_STATE
            };
        case ENTITIES_ERROR:
            return {
				...state,
				...INITIAL_STATE,
				error: action.payload.message
			};
        default:
            return state;
    }
}
