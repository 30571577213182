import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { getUser } from '../../actions/user';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
//import moment from 'moment';
import history from '../../history';

import { getSearchAll, resetSearch } from '../../actions/searchs'
//import Spinner from '../../gj_modules/gj-strap/components/display/Spinner';
//import { Panel } from '../../gj_modules/gj-strap/components/form';
import FullCenter from '../../gj_modules/gj-strap/components/partials/FullCenter';
import FooterCicanord from '../partials/FooterCicanord';

import { ComplexAll } from '../../gj_modules/gj-strap/components/complex'

import { searchType } from '../../data/constants';

class Search extends Component {
    componentDidMount() {
    }

    renderTd = (e) => {

        let typeFinal = "";

        switch (e.type) {
            case "contact":
                typeFinal = "Contact"
                break;
            case "entity":
                typeFinal = "Entité"
                break;
            case "business":
                typeFinal = "Affaire"
                break;
            case "project":
                typeFinal = "Projet"
                break;
            case "eproject":
                typeFinal = "Projet Externe"
                break;
            default:
                break;
        }

        return {
            id: e.id,
            key: "search_" + e.type + "_" + e.id, //+"_"+e.Entites_Nom,
            typefinal: typeFinal,
            type: e.type,
            name: e.name1 + " " + e.name2
        }
    }

    render() {
        //const { searchs } = this.props;

        let pageDefault = {
            current: 1,
            skip: 0,
            limit: 200,
            totalSize: 0,
            go: false
        };

        const properties = [
            { short: "typefinal", name: "Type", width: "20%", category: "classic" },
            { short: "name", name: "Nom", width: "70%", category: "classic" },
        ];

        const filters = [
            { type: "search", size: 75, name: "searchText", placeholder: "Recherche par nom" },
            { type: "select", size: 25, name: "searchType", placeholder: "Type", options: searchType },
        ]

        let portlet = {
            title: "Recherche",
            links: [
                { name: "Recherche", path: "/sic/search" }
            ]
        }

        if (this.props.searchs.searchs) {
            for (let i = 0; i < this.props.searchs.searchs.length; i++) {
                if (this.props.searchs.searchs[i] && this.props.searchs.searchs[i].name1 === null)
                    this.props.searchs.searchs[i].name1 = "";
                if (this.props.searchs.searchs[i] && this.props.searchs.searchs[i].name2 === null)
                    this.props.searchs.searchs[i].name2 = "";
            }
        }
        return (

            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        getElementsAll={getSearchAll}
                        resetElements={resetSearch}
                        elements={this.props.searchs.searchs}
                        properties={properties}
                        filters={filters}
                        page={pageDefault}
                        renderTd={this.renderTd}
                        pending={this.props.searchs.pending}
                        noDataMessage={"Pas de résultat"}
                        clickAction={(e) => {
                            switch (e.type) {
                                case "contact":
                                    history.push("/sic/contact/" + e.id)
                                    break;
                                case "entity":
                                    history.push("/sic/entity/" + e.id)
                                    break;
                                case "business":
                                    history.push("/sic/business/" + e.id)
                                    break;
                                case "project":
                                    history.push("/sic/project/" + e.id)
                                    break;
                                case "eproject":
                                    history.push("/sic/eproject/" + e.id)
                                    break;
                                default:
                                    break;
                            }
                        }}
                    />
                </FullCenter>
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSearchAll,
            resetSearch
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        searchs: state.searchs,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Search);
