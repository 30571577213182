import React, { Component } from 'react';
//gj-strap
import { gjstrap } from '../../../../settings'
import { Input, Checkbox, Select, TextArea, DateSimple, DatePicker, DateRange, SelectHours, SelectHour, ColorPick, DataTableEdit, Location, File, Radios } from '../form';
//various
import { Row, Col } from 'react-bootstrap';
//design
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';

class Panel extends Component {
    renderSwitch(f, index) {
        const { width } = this.props;

        switch (f.type) {
            case 'price':
                return (
                    <Row key={index}>
                        <Col>
                            <Input
                                name={f.short}
                                type={f.type}
                                placeholder={f.placeholder}
                                validate={f.validate}
                            />
                        </Col>
                        <Col md="auto" className="my-auto">
                            {f.name}
                        </Col>
                    </Row>
				);
            case 'tel':
            case 'number':
            case 'text':
            case 'password':
            case 'email':
                return (
                    <Row key={index}>
                        <Col sm={width[0]} className="my-auto text-right">
                            {f.name}
                        </Col>
                        <Col sm={width[1]}>
                            <Input
                                name={f.short}
                                type={f.type}
                                placeholder={f.placeholder}
                                validate={f.validate}
                            />
                        </Col>
                    </Row>
                );
            case 'location':
                return (
                    <Row key={index}>
                        <Col sm={width[0]} className="my-auto text-right">
                            {f.name}
                        </Col>
                        <Col sm={width[1]}>
                            <Location
                                name={f.short}
                                placeholder={f.placeholder}
                                validate={f.validate}
                            />
                        </Col>
                    </Row>
				);
			case 'file':
				return (
                    <Row key={index}>
                        <Col sm={width[0]} className="my-auto text-right">
                            {f.name}
                        </Col>
                        <Col sm={width[1]}>
                            <File
                                name={f.short}
                                placeholder={f.placeholder}
                                validate={f.validate}
                            />
                        </Col>
                    </Row>
				);
            case 'checkbox':
                return (
                    <Row key={index}>
                        {f.data && f.data.mobile ?
                            <React.Fragment>
                                <Col>
                                    <Checkbox
                                        name={f.short}
                                        label={f.name}
                                        disabled={f.disabled}
                                    />
                                </Col></React.Fragment>
                            :
                            <React.Fragment><Col sm={width[0]} className="my-auto text-right">
                                {f.name}
                            </Col>
                                <Col sm={width[1]}>
                                    <Checkbox
                                        name={f.short}
                                        disabled={f.disabled}
                                    />
                                </Col>
                            </React.Fragment>
                        }
                    </Row>
                )
            case 'radio':
                return (
                    <Row key={index}>
                        <React.Fragment>
                            <Col sm={width[0]} className="my-auto text-right">
                                {f.name}
                            </Col>
                            <Col sm={width[1]}>
                                <Radios
                                    name={f.short}
                                    data={f.data.options}
                                    disabled={f.disabled}
                                    validate={f.validate}
                                />
                            </Col>
                        </React.Fragment>
                    </Row>
                )
            case 'translationtext':

                if(!f.hasOwnProperty("data")){
                    f.data = {}
                }

                return (
                    <Row key={index}>
                        <Col sm={width[0]} className="my-auto text-right">
                            {f.name}
                        </Col>
                        <Col sm={width[1]}>
                            <Row>
                                <Col sm={9} className="pl-3">
                                    <Select
                                        inputId={"select-place-id-" + f.short}
                                        isMulti={f.data.isMulti ? true : false}
                                        isAsync
                                        forceLabel={f.data.forceLabel}
                                        cacheOptions
                                        name={f.short}
                                        validate={f.validate}
                                        noOptionsMessage={input => 'Cherchez'}
                                        loadOptions={f.data.loadOptions}
                                        defaultOptions
                                        placeholder={null} //TODO
                                        isClearable={true}
                                        defaultValue={f.data.defaultValue}
                                        cache={f.data.cache}
                                        getOptionValue={f.data.getOptionValue}
                                        getLabelValue={f.data.getOptionLabel}
                                    />
                                </Col>
                                <Col sm={3}>
                                    <div className="dropdown">
                                        { f.data.id ? (
											<React.Fragment>
												<p
													className="btn btn-actions mt-3"
													onClick={() => {
														gjstrap.translationEditPopup(f.data.origin)
													}}
												>
													<FontAwesomeIcon icon={faEdit} />
												</p>
												<p
													className="btn btn-actions mt-3"
													onClick={() => {
														console.log(f.data.id)
														gjstrap.translationEdit(f.data.id)
													}}
												>
													<FontAwesomeIcon icon={faSearch} />
												</p>
											</React.Fragment>
                                        ) : (
                                            <p
                                                className="btn btn-actions mt-3"
                                                onClick={() => {
                                                    console.log("new")
													gjstrap.translationNewPopup(f.data.origin)
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </p>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            case 'select':
                let placeholderTemp = f.placeholder;

                if (f.data.address) {
                    if (f.data.address.hasOwnProperty("properties")) {
                        placeholderTemp = f.data.address.properties.label;
                    }
                }
                //console.log(f);
                return (
                    <Row key={index}>
                        <Col sm={width[0]} className="my-auto text-right">
                            {f.name}
                        </Col>
                        <Col sm={width[1]}>
                            {f.data.options ? (
                                <Select
                                    name={f.short}
                                    isClearable={true}
                                    options={f.data.options}
                                    validate={f.validate}
                                    styles={f.styles}
                                />
                            ) : (
								f.data.shortcut ? (
									<Row>
                                		<Col sm={9} className="pl-3">
											<Select
												inputId={"select-place-id-" + f.short}
												isMulti={f.data.isMulti}
												isAsync
												forceLabel={f.data.forceLabel}
												//getOptionValue={f.data.getOptionValue}
												//getLabelValue={f.data.getOptionLabel}
												loadOptions={f.data.loadOptions}
												//cacheOptions
												name={f.short}
												validate={f.validate}
												noOptionsMessage={input => 'Cherchez'}
												//defaultOptions
												placeholder={placeholderTemp}
												isClearable={true}
												defaultValue={f.data.defaultValue}
												cache={f.data.cache}
												styles={f.styles}
											/>
										</Col>
										<Col sm={3}>
											<div className="dropdown">
												<React.Fragment>
													<p
														className="btn btn-actions mt-3"
														onClick={() => {
															console.log(f.data.shortcut.id+" - "+f.data.shortcut.type)
															gjstrap.goToModelId(f.data.shortcut.type, f.data.shortcut.id)
														}}
													>
														<FontAwesomeIcon icon={faSearch} />
													</p>
												</React.Fragment>
											</div>
										</Col>
									</Row>
								) : (
									<Select
										inputId={"select-place-id-" + f.short}
										isMulti={f.data.isMulti}
										isAsync
										forceLabel={f.data.forceLabel}
										//getOptionValue={f.data.getOptionValue}
										//getLabelValue={f.data.getOptionLabel}
										loadOptions={f.data.loadOptions}
										//cacheOptions
										name={f.short}
										validate={f.validate}
										noOptionsMessage={input => 'Cherchez'}
										//defaultOptions
										placeholder={placeholderTemp}
										isClearable={true}
										defaultValue={f.data.defaultValue}
										cache={f.data.cache}
										styles={f.styles}

										//getOptionValue={(option)=>option.value}
										//getLabelValue={(option)=>option.label}
										/*validate={nonEmpty('Choisissez un lieu ou saisissez une adresse')}*/
										/*getOptionValue={getOptionValue}*/
										/*getOptionValue={(option)=>{
											return option.value
										}}
										getOptionLabel={option => {
											//console.log(option);
											if (option.label){
												return option.label;
											}else{
												console.log("nolabel");
												return "nolabel"
											}
										}}*/
									/>
								)
                            )}
                        </Col>
                    </Row>
                )
            case 'textarea':
                return (
                    <Row key={index}>
                        <Col sm={width[0]} className="my-3 text-right">
                            {f.name}
                        </Col>
                        <Col sm={width[1]}>
                            <TextArea
                                name={f.short}
                                placeholder={f.placeholder}
                                height={f.data && f.data.height ? f.data.height : null}
                            />
                        </Col>
                    </Row>
				)
			case 'datesimple':
				return(
					<Row key={index}>
						<Col sm={width[0]} className="my-auto text-right">
							{f.name}
						</Col>
						<Col sm={width[1]}>
							<DateSimple
								name={f.short}
                disabled={f.disabled}
								placeholder={f.placeholder}
								validate={f.validate}
							/>
						</Col>
					</Row>
				)
            case 'datepicker':
                return (
                    <Row key={index}>
                        <Col sm={width[0]} className="my-3 text-right">
                            {f.name}
                        </Col>
                        <Col sm={width[1]}>
                            <DatePicker
                                name={f.short}
                                validate={f.validate}
                                anytime={f.data ? f.data.anytime : null}
                                alldays={f.data ? f.data.alldays : null}
                            />
                        </Col>
                    </Row>
                )
            case 'daterange':
                return (
                    <Row key={index}>
                        <Col sm={width[0]} className="my-3 text-right">
                            {f.name}
                        </Col>
                        <Col sm={width[1]}>
                            <DateRange
                                name={f.short}
                                validate={f.validate}
                                anytime={f.data ? f.data.anytime : null}
                                alldays={f.data ? f.data.alldays : null}
                            />
                        </Col>
                    </Row>
                )
            case 'selecthours':
                return (
                    <Row key={index}>
                        <Col sm={width[0]} className="my-3 text-right">
                            {f.name}
                        </Col>
                        <Col sm={width[1]}>
                            <SelectHours
                                name={f.short}
                                validate={f.validate}
                                minTime={f.data ? f.data.minTime : '06:00'}
                                maxTime={f.data ? f.data.maxTime : '21:00'}
                                step={f.data ? f.data.step : 30}
                            />
                        </Col>
                    </Row>
                )
            case 'selecthour':
                return (
                    <Row key={index}>
                        <Col sm={width[0]} className="my-3 text-right">
                            {f.name}
                        </Col>
                        <Col sm={width[1]}>
                            <SelectHour
                                name={f.short}
                                validate={f.validate}
                                minTime={f.data ? f.data.minTime : '06:00'}
                                maxTime={f.data ? f.data.maxTime : '21:00'}
                                step={f.data ? f.data.step : 30}
                            />
                        </Col>
                    </Row>
                )
            case 'colorpicker':
                return(
                    <Row key={index}>
                        <Col sm={width[0]} className="my-3 text-right">
                            {f.name}
                        </Col>
                        <Col sm={width[1]}>
                            <ColorPick
                                name={f.short}
                            />
                        </Col>
                    </Row>
                )
            case 'datatableedit':
                return(
                    <Row key={index}>
                        <Col sm={12}>
                            <DataTableEdit
                                name={f.short}
                                data={f.data}
                            />
                        </Col>
                    </Row>
                )
            case 'spacer':
                return (
                    <hr key={index} style={{ margin: "15px" }} />
                )
            case 'desc':
                return (
                    <Row key={index} style={{marginBottom:"10px", fontStyle:"italic", color:"silver"}}>
                        <Col sm={width[0]} className="my-auto text-right">
                            {f.name}
                        </Col>
                        <Col sm={width[1]}>
                            {f.data.value}
                        </Col>
                    </Row>
                );
            case 'child':
                return (
                    <React.Fragment key={index}>
                        {f.data}
                    </React.Fragment>
                )
            default:
                return (null)
        }
    }

    render() {
        const { inputs } = this.props;

        return (
            <React.Fragment>
                {inputs.map((f, index) =>
                    this.renderSwitch(f, index)
                )}
            </React.Fragment>
        );
    }
}

export default Panel;
