import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Tile, TileContainer, TileHeader, TileBody } from './../../../../gj_modules/gj-strap/components/partials';
import DataTable from './../../../../gj_modules/gj-strap/components/form/DataTable';
import { Form, Input, Select } from './../../../../gj_modules/gj-strap/components/form';
import Pagination from './../../../../gj_modules/gj-strap/components/form/Pagination';
import { Button, Row, Col } from 'react-bootstrap';
import { Calendar } from './../../../../gj_modules/gj-strap/components/display';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { GoogleMaps } from './../../../../gj_modules/gj-strap/components/map';

class ComplexAll extends Component {

    static mode = {
        list: "list",
		map: "map",
        calendar: "calendar",
		custom: "custom"
    }

	constructor(props) {
        super(props);

		let defaultMode = "list"

		if (this.props.mode) {
            if (this.props.mode.length > 1) {
				defaultMode = this.props.mode[0]
			}
		}

		this.state = {
			mode: defaultMode,
			filter: {},
			orderby: {
				name: null,
				way: "desc"
			}
		}
	}

    pageDefault = {
        skip: 0,
        limit: 20,
    }

    page = { // Default paginate configuration
        current: 1,
        skip: this.pageDefault.skip,
        limit: this.pageDefault.limit,
        totalSize: 0,
        go: false
    };

    componentDidMount() {
        const { actions, dispatch, filters, autoload, page } = this.props;

		if(page && page.skip){
			this.page.skip = page.skip
		}

		if(page && page.limit){
			this.page.limit = page.limit
		}

        const { skip, limit } = this.page;

        let { getElementsAll, resetElements, getElementsAllParams, callbackGetAll } = this.props;

        if (actions !== undefined) {
            getElementsAll = actions.getAll;
            resetElements = actions.reset;
        }

        var stateCopy = Object.assign({}, this.state);
        for (let i = 0; i < filters.length; i++) {
            stateCopy.filter[filters[i].name] = ""
        }

        if (getElementsAllParams) {
            for (let [key, value] of Object.entries(getElementsAllParams)) {
                stateCopy.filter[key] = value;
            }
        }

        this.setState(stateCopy);

        const { filter } = this.state;

        this.handleFilterStart();
        this.handleOrderByStart();

        dispatch(resetElements());
		if(autoload !== false){
			dispatch(getElementsAll({ skip, limit, filter })).then(() => {
				if (callbackGetAll) {
					callbackGetAll({
						filter:filter
					});
				}
			});
		}        
    }

    handleFilterStart = () => {
        const { filters } = this.props;

        var stateCopy = Object.assign({}, this.state);

        for (let f of filters) {
            if (f.type === "hidden") {
                stateCopy.filter[f.name] = f.value;
            } else if (f.type === "select") {
                if (f.hasOwnProperty("default")) {
                    stateCopy.filter[f.name] = f.default;
                }
            }
        }
        this.setState(stateCopy);
    }

    handleFilter = e => {
        var stateCopy = Object.assign({}, this.state);
        stateCopy.filter[e.target.name] = e.target.value;
        this.setState(stateCopy);

        this.launchRequest();
    }

    handleOrderByStart = () => {

        const { properties } = this.props;

        for (let p of properties) {
            if (p.orderby) {
                if (p.orderbydefault) {

                    var stateCopy = Object.assign({}, this.state);
                    stateCopy.orderby.name = p.short;

                    let way = "desc";
                    if (p.orderbywaydefault === "asc") {
                        way = "asc"
                    }
                    stateCopy.orderby.way = way;
                    stateCopy.filter["orderbyway"] = way;

                    this.setState(stateCopy);

                }
            }
        }
    }

    handleOrderBy = (e) => {
        var stateCopy = Object.assign({}, this.state);

        let prevOrderby = stateCopy.orderby.name;
        stateCopy.filter["orderby"] = e;
        stateCopy.orderby.name = e;

        if (prevOrderby === e) {
            if (stateCopy.orderby.way === "desc") {
                stateCopy.orderby.way = "asc";
            } else {
                stateCopy.orderby.way = "desc";
            }
        }
        stateCopy.filter["orderbyway"] = stateCopy.orderby.way;

        this.setState(stateCopy);
        this.launchRequest();
    }

    launchRequest = () => {
        const { actions, dispatch } = this.props;
        let { getElementsAll, callbackGetAll } = this.props;

        if (actions !== undefined) {
            getElementsAll = actions.getAll;
        }

        const { skip, limit } = this.page;
        this.page.go = true;
        dispatch(getElementsAll({ skip, limit, filter: this.state.filter })).then(() => {
            if (callbackGetAll) {
                callbackGetAll({
					filter:this.state.filter
				});
            }
        });
    }

    dataTableRender = () => {
        const { elements, properties, noEditGlobal, propertiesTop, renderTd, noDataMessage, clickAction, deleteAction, editAction, viewAction, dataTableClass, pending, collection, focusCustomStyle, focus } = this.props;
        const { skip } = this.page;
        //const { filter } = this.state;

        let array = [];

        if (renderTd) {
            if (elements && elements.length) {
                for (let i = 0; i < elements.length; i++) {
                    let object = {};
                    Object.assign(object, renderTd(elements[i]));

                    object.index = skip + i + 1;

                    if (focusCustomStyle && focusCustomStyle.focusId === object.id) {
                        object.style = focusCustomStyle.style;
                    }

                    array.push(object);
                }
            }
        } else {
            array = collection;
        }

        return (
            <DataTable
                properties={properties}
                propertiesTop={propertiesTop}
                propertiesOrderBy={this.handleOrderBy}
                propertyOrderBy={this.state.orderby}
                collection={array}
                noDataMessage={noDataMessage}
                pending={pending}
                clickAction={clickAction}
                deleteAction={deleteAction}
                editAction={editAction}
                viewAction={viewAction}
                dataTableClass={dataTableClass}
                noEditGlobal={noEditGlobal}
                focus={focus}
            />
        )

    };

    handleButton = (f) => {
        f(this.state.filter);
    }

    renderSwitch(f, index) {
        //const { width } = this.props;

        switch (f.type) {
            case 'text':
            case 'search':
                return (
                    <Form key={index} className={"w-" + f.size + " my-3 " + ((index !== 0) ? "pl-4" : null)}>
                        <Input
                            type={f.type}
                            name={f.name}
                            placeholder={f.placeholder}
                            onChange={this.handleFilter}
                        ></Input>
                    </Form>
                );
            case 'hidden':
                return (
                    <Form key={index} >
                        <Input
                            type="hidden"
                            name={f.name}
                        ></Input>
                    </Form>
                );
            case 'select':

                let placeholderTemp = f.placeholder;

                let selectFunction = (e) => {
                    if (Array.isArray(e)) {
                        e = e.map(e => e.value).join("-");
                    }
                    var stateCopy = Object.assign({}, this.state);
                    stateCopy.filter[f.name] = e;
                    this.setState(stateCopy);
                    this.launchRequest();
                }

                let initialValuesTemp = {};

                if (f.hasOwnProperty("default")) {
                    initialValuesTemp[f.name] = f.default;
                }

                return (
                    <Form initialValues={initialValuesTemp} key={index} className={"w-" + f.size + " my-3 " + ((index !== 0) ? "pl-4" : null)}>
                        {f.options ? (
                            <Select
                                name={f.name}
                                isClearable={true}
                                options={f.options}
                                styles={f.styles}
                                placeholder={f.placeholder}
                                onChange={selectFunction}
                                categoryOptions={f.categoryOptions}
                            />
                        ) : (
                                <Select
                                    inputId={"select-place-id-" + f.short}
                                    isMulti={f.data.isMulti}
                                    isAsync
                                    forceLabel={f.data.forceLabel}
                                    //cacheOptions
                                    name={f.short}
                                    validate={f.validate}
                                    noOptionsMessage={input => 'Cherchez'}
                                    loadOptions={f.data.loadOptions}
                                    defaultOptions
                                    placeholder={placeholderTemp}
                                    isClearable={true}
                                    defaultValue={f.data.defaultValue}
                                    cache={f.data.cache}
                                    getOptionValue={f.data.getOptionValue}
                                    getLabelValue={f.data.getOptionLabel}
                                    onChange={selectFunction}
                                    categoryOptions={f.categoryOptions}
                                />
                            )}
                    </Form>
                );
            case 'button':
                return (
                    <Form key={index} className={"w-" + f.size + " my-3 " + ((index !== 0) ? "pl-4" : null)}>
                        <div className="form-group">
                            <Button onClick={() => this.handleButton(f.fn)} style={{ height: "38px" }} block>
                                {f.icon && <FontAwesomeIcon icon={f.icon} className="mr-2" />}
                                {f.title}
                            </Button>
                        </div>
                    </Form>
                );
            default:
                return (null)
        }
    }

    filterRender = () => {
        const { filters } = this.props;

        return (
            <React.Fragment>
                {filters.map((f, index) =>
                    this.renderSwitch(f, index)
                )}
            </React.Fragment>
        )
    }

    setMode = (m) => {
        var stateCopy = Object.assign({}, this.state);
        stateCopy.mode = m;
        this.setState(stateCopy);
    }

    render() {
        const { actions, elements, collection, dispatch, split, map, mode, calendarEventFunction, calendarEventRenderFunction } = this.props;
        let { getElementsAll } = this.props;

        if (actions !== undefined) {
            getElementsAll = actions.getAll;
        }

        const { filter } = this.state;

        if (elements && elements['count']) {
            this.page.totalSize = elements['count'];
        }

        let size = "12";

        if (split) {
            size = "6";
        }

        //Mode
        let modeSelect = null;
        let modeSelectInitialValues = null;
        let modeCalendar = {}

        if (mode) {
            if (mode.length > 1) {
                modeSelect = [];
                for (let m of mode) {
                    if (m === "list") {
                        modeSelect.push({ value: "list", label: "Liste" })
					}else if(m === "custom"){
						let customName = "Custom"
						if(this.props.modeCustomName){
							customName = this.props.modeCustomName
						}
						modeSelect.push({ value: "custom", label: customName })
					}else if(m === "map"){
						modeSelect.push({ value: "map", label: "Map" })
                    } else if (m === "calendar") {
                        modeSelect.push({ value: "calendar", label: "Calendrier" })
                        //
                        modeCalendar.events = [
                            //{ title:"truc", date:"2021-02-07"}
                        ]
                        if (collection) {
                            for (let c of collection) {
                                modeCalendar.events.push(calendarEventFunction(c))
                            }
                        }
                    }
                }
                modeSelectInitialValues = {
                    mode: modeSelect[0].value
                }
            } else {

            }
        } else {

        }

        return (
            <React.Fragment>
                <TileContainer>
                    <Tile size={"full"}>
                        <TileHeader
                            border={true}
                        >
                            {this.filterRender()}
                        </TileHeader>
                        <TileBody>

                            {Array.isArray(modeSelect) ? (
                                <React.Fragment>
                                    <Row style={{ marginTop: "-35px" }}>
                                        <Col lg="9">
                                        </Col>
                                        <Col lg="3">
                                            <Form initialValues={modeSelectInitialValues}>
                                                <Select
                                                    name={"mode"}
                                                    options={modeSelect}
                                                    placeholder={""}
                                                    onChange={this.setMode}
                                                />
                                            </Form>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            ) : null}

                            {
                                {
                                    "list": <React.Fragment>
                                        <Row>
                                            <Col lg={size}>
												<React.Fragment>

													{this.dataTableRender()}

													{/*(elements)
													? this.dataTableRender()
														: <Spinner />
													*/}

													{this.page.totalSize ? //this.page.totalSize
														<Pagination
															props={{}}
															page={this.page}
															callFunc={(e) => dispatch(getElementsAll({ skip: e.skip, limit: e.limit, filter: filter }))}
														/>
														: null
													}
												</React.Fragment>
                                            </Col>
                                            {split ? (
                                                <Col lg={size}>
                                                    {this.props.children}
                                                </Col>
                                            ) : null}
                                        </Row>
                                    </React.Fragment>,
									"custom": <React.Fragment>
										<Row>
											<Col lg={size}>
												{this.props.custom ? (
													<React.Fragment>
														{this.props.custom}
													</React.Fragment>
												) : null }
											</Col>
											{split ? (
												<Col lg={size}>
													{this.props.children}
												</Col>
											) : null}
										</Row>
									</React.Fragment>,
									"map": <React.Fragment>
										<Row>
											<Col lg={size}>
												{map ? (
													<React.Fragment>
														<GoogleMaps
															options={map}
														/>
													</React.Fragment>
												) : null }
											</Col>
											{split ? (
												<Col lg={size}>
													{this.props.children}
												</Col>
											) : null}
										</Row>
									</React.Fragment>,
                                    "calendar": <React.Fragment>
                                        <Calendar
                                            events={modeCalendar.events}
                                            eventRender={calendarEventRenderFunction}
                                        />
                                    </React.Fragment>
                                }[this.state.mode]
                            }

                        </TileBody>
                    </Tile>
                </TileContainer>
            </React.Fragment>
        )
    }

}

export default connect()(ComplexAll)
