import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import moment from 'moment';
import 'moment/locale/fr';

import './css/stylesheets/old.css';
import './gj_modules/gj-strap/css/gj-strap.scss';
import './css/stylesheets/base.scss';

import Login from './components/auth/Login';
import Register from './components/auth/register/Register';
import RegisterConfirmation from './components/auth/register/RegisterConfirm';
import AccountValidation from './components/auth/register/AccountValidation';
import ForgotPassword from './components/auth/forgotPassword/ForgotPassword';
import ResetPassword from './components/auth/forgotPassword/ResetPassword';
import RequireAuth from './components/auth/require_auth';
import NotFoundPage from './components/pages/NotFoundPage';
import Home from './components/home/Home';
import Contacts from './components/contacts/Contacts';
import Contact from './components/contacts/Contact';
import Businesses from './components/businesses/Businesses';
import Business from './components/businesses/Business';
import BusinessPrint from './components/businesses/BusinessPrint'
import BusinessDirectoriesPrint from './components/businesses/BusinessDirectoriesPrint'
import Entities from './components/entities/Entities';
import Entity from './components/entities/Entity';
import EntityPrint from './components/entities/EntityPrint';
import Projects from './components/projects/Projects';
import Project from './components/projects/Project';
import ProjectPrint from './components/projects/ProjectPrint';
import Users from './components/users/Users';
import User from './components/users/User';
import Eprojects from './components/eprojects/Eprojects';
import Eproject from './components/eprojects/Eproject';
import BusinessesPhasesPrintSearch from './components/utilities/BusinessesPhasesPrintSearch';
import BusinessesPhasesPrint from './components/utilities/BusinessesPhasesPrint';
import BusinessesReferencesPrintSearch from './components/utilities/BusinessesReferencesPrintSearch';
import BusinessesReferencesPrint from './components/utilities/BusinessesReferencesPrint';
import Activities from './components/activities/Activities';
import Sectors from './components/sectors/Sectors';
import Search from './components/search/Search';
import Actions from './components/actions/Actions';
import ActionsPrint from './components/actions/ActionsPrint';
import Preferences from './components/preferences/Preferences';
import Employees from './components/employees/Employees';


export const REACT_APP_ELECTRON = process.env.REACT_APP_ELECTRON;

moment.locale('fr');

class App extends Component {
    render() {
        
        if(REACT_APP_ELECTRON === "true"){
            history.push("/");
        }

        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/register-confirmation" component={RegisterConfirmation} />
                    <Route exact path="/account-validation/:token" component={AccountValidation} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/reset-password/:resetToken" component={ResetPassword} />

                    <Route exact path="/home" component={RequireAuth(Home)} />
                    
                    <Route exact path="/sic/search" component={RequireAuth(Search)} />
                    
                    <Route exact path="/sic/contacts" component={RequireAuth(Contacts)} />
                    <Route exact path="/sic/contact/:id" component={RequireAuth(Contact)} />

                    <Route exact path="/sic/businesses" component={RequireAuth(Businesses)} />
                    <Route exact path="/sic/business/:id" component={RequireAuth(Business)} />
                    <Route exact path="/sic/business/:id/directories" component={RequireAuth(Business)} />
                    <Route exact path="/sic/business/:id/calendar" component={RequireAuth(Business)} />
                    <Route exact path="/sic/business/:id/phases" component={RequireAuth(Business)} />
                    <Route exact path="/sic/business/:id/print" component={RequireAuth(BusinessPrint)} />
                    <Route exact path="/sic/business/:id/print/directories" component={RequireAuth(BusinessDirectoriesPrint)} />

                    <Route exact path="/sic/entities" component={RequireAuth(Entities)} />
                    <Route exact path="/sic/entity/:id/print" component={RequireAuth(EntityPrint)} />
                    <Route exact path="/sic/entity/:id" component={RequireAuth(Entity)} />

                    <Route exact path="/sic/projects" component={RequireAuth(Projects)} />
                    <Route exact path="/sic/project/:id/print" component={RequireAuth(ProjectPrint)} />
                    <Route exact path="/sic/project/:id" component={RequireAuth(Project)} />

                    <Route exact path="/sic/eprojects" component={RequireAuth(Eprojects)} />
                    <Route exact path="/sic/eproject/:id" component={RequireAuth(Eproject)} />

                    <Route exact path="/sic/actions" component={RequireAuth(Actions)} />
                    <Route exact path="/sic/actionsprint/:search" component={RequireAuth(ActionsPrint)} />

                    <Route exact path="/sic/businessesphasesprintsearch" component={RequireAuth(BusinessesPhasesPrintSearch)} />
                    <Route exact path="/sic/businessesphasesprint/:idbusinesses" component={RequireAuth(BusinessesPhasesPrint)} />

                    <Route exact path="/sic/businessesreferencesprintsearch" component={RequireAuth(BusinessesReferencesPrintSearch)} />
                    <Route exact path="/sic/businessesreferencesprint/:idbusinesses" component={RequireAuth(BusinessesReferencesPrint)} />

                    <Route exact path="/sic/activities" component={RequireAuth(Activities)} />
                    <Route exact path="/sic/sectors" component={RequireAuth(Sectors)} />

                    <Route exact path="/sic/users" component={RequireAuth(Users)} />
                    <Route exact path="/sic/user/:id" component={RequireAuth(User)} />
					<Route exact path="/sic/user/:id/calendar" component={RequireAuth(User)} />
                    <Route exact path="/sic/user/:id/businesses" component={RequireAuth(User)} />

                    <Route exact path="/sic/employees" component={RequireAuth(Employees)} />

                    <Route exact path="/profile" component={RequireAuth(User)} />
                    <Route exact path="/profile/calendar" component={RequireAuth(User)} />
                    {/*<Route exact path="/profile/edit" component={RequireAuth(ProfileEdit)} />
                    <Route exact path="/dashboard" component={RequireAuth(Dashboard)} />*/}
                    <Route exact path="/sic/preferences" component={RequireAuth(Preferences)} />

                    <Route component={NotFoundPage} />
                </Switch>
            </Router>
        );
    }
}

export default App;
