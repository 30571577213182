import {
    BUSINESSESPHASES_ALL,
    BUSINESSESPHASES_ALL_PENDING,
    BUSINESSESPHASES_ADD,
    BUSINESSESPHASES_ADD_PENDING,
    BUSINESSESPHASES_UPDATE,
    BUSINESSESPHASES_UPDATE_PENDING,
    BUSINESSESPHASES_DELETE,
    BUSINESSESPHASES_DELETE_PENDING,
    BUSINESSESPHASES_RESET,
    BUSINESSESPHASES_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    businessesphases: null,
    message: null,
    pending: false,
    pendingAdd: false,
    pendingDelete: false,
    pendingUpdate: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case BUSINESSESPHASES_ALL_PENDING:
            return {
                ...state,
                pending: true
            }
        case BUSINESSESPHASES_ALL:
            let businessesphases = action.payload.businessesphases;
            businessesphases['count'] = action.payload.businessesphasesCount;
            return {
                ...state,
                businessesphases: businessesphases,
                error: '',
                message: action.payload.message,
                pending: false
            };
        case BUSINESSESPHASES_ADD_PENDING:
            return {
                ...state,
                pendingAdd: true
            }
        case BUSINESSESPHASES_ADD:
            return {
                ...state,
                error: '',
                message: action.payload.message,
                pendingAdd: false
            };
        case BUSINESSESPHASES_UPDATE_PENDING:
            return {
                ...state,
                pendingUpdate: true
            }
        case BUSINESSESPHASES_UPDATE:
            businessesphases = state.businessesphases;
            for(let i=0; i<businessesphases.length; i++){
                if(businessesphases[i].id === Number(action.payload.id)){
                    businessesphases[i] = Object.assign(businessesphases[i], action.payload.businessesphase);
                }
            }
            return {
                ...state,
                businessesphases:businessesphases,
                error: '',
                message: action.payload.message,
                pendingUpdate: false
            };
        case BUSINESSESPHASES_DELETE_PENDING:
            return {
                ...state,
                pendingDelete: true
            }
        case BUSINESSESPHASES_DELETE:
            return {
                ...state,
                error: '',
                message: action.payload.message,
                pendingDelete: false
            };
        case BUSINESSESPHASES_RESET:
            return {
                ...state,
                businessesphases: null,
                message: null,
                pending: false,
                pendingAdd: false,
                pendingDelete: false,
                pendingUpdate: false
            };
        case BUSINESSESPHASES_ERROR:
            return {
                ...state,
                error: action.payload.message
            };
        default:
            return state;
    }
}
