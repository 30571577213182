import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import FooterCicanord from '../partials/FooterCicanord';

import FullCenter from '../../gj_modules/gj-strap/components/partials/FullCenter';
import { ComplexAll } from '../../gj_modules/gj-strap/components/complex'
import { modalFunction } from '../../gj_modules/gj-strap/components/partials';
import { Panel } from '../../gj_modules/gj-strap/components/form';

import moment from 'moment';
import history from '../../history';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { required } from '../../lib/validate';

import AvatarUser from '../subpartials/AvatarUser'
import { resetUsers, getUsersAll, addUser, deleteUser } from '../../actions/user'

class Users extends Component {
    componentDidMount() {
    }

    renderTd = (e) => {
        return {
            id: e.Utilisateurs_ID,
            avatar: (
                <AvatarUser
                    u={{
                        login: e.Utilisateurs_Login,
                        name: e.Utilisateurs_Nom,
                        surname: e.Utilisateurs_Prenom,
                        userId: e.Utilisateurs_ID,
                        color: e.Utilisateurs_color
                    }}
                />
            ),
            key: e.Utilisateurs_ID,
            login: e.Utilisateurs_Login,
            name: e.Utilisateurs_Nom,
            surname: e.Utilisateurs_Prenom,
            date: e.Utilisateurs_DateConnexion ? moment(e.Utilisateurs_DateConnexion).format("DD/MM/YYYY - hh:mm") : ""
        }
    }

    render() {
        const { users, addUser, deleteUser } = this.props;

        const properties = [
            { short: "avatar", name: "Avatar", width: "10%" },
            { short: "login", name: "Login", width: "18%" },
            { short: "name", name: "Nom", width: "18%" },
            { short: "surname", name: "Prénom", width: "16%" },
            { short: "date", name: "Date", width: "13%" },
            { short: "actions", name: "", width: "9%", category: "actions" }
        ];

        const filters = [
            { type: "search", size: 75, name: "searchUsersByName", placeholder: "Recherche par nom" },
            {
                type: "button",
                size: 25,
                name: "",
                title: "Ajouter",
                icon: faPlusCircle,
                fn: () => {
                    modalFunction({
                        submit: (values) => {
                            if (!users.pendingAdd) {
                                console.log(values)
                                addUser(values).then(
                                    function (response) {
                                        history.push("/sic/user/" + response.response.data.user)
                                    }
                                );
                            }
                        },
                        text: (
                            <Panel
                                width={[3, 9]}
                                inputs={[
                                    { short: "login", name: "Login", type: "text", placeholder: "Login", validate: required() },
                                    { short: "surname", name: "Prénom", type: "text", placeholder: "Prénom", validate: required() },
                                    { short: "name", name: "Nom", type: "text", placeholder: "Nom", validate: required() }
                                ]}
                            />
                        ),
                        header: "Ajouter un utilisateur",
                        state: "user.pendingAdd"
                    }, "popup")
                }
            }
        ]

        let portlet = {
            title: "Utilisateurs",
            links: [
                { name: "Recherche", path: "/sic/users" }
            ]
        }

        return (

            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        getElementsAll={getUsersAll}
                        resetElements={resetUsers}
                        elements={this.props.users.users}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        pending={this.props.users.pending}
                        noDataMessage={`Pas de contacts`}
                        deleteAction={(e) => {
                            modalFunction({
                                btnFunction: () => {
                                    deleteUser(e);
                                    //console.log("delete " + e)
                                },
                                btnText: "Confirmer",
                                text: "Êtes-vous sûr ?",
                                header: "Supprimer un utilisateur",
                                state: "user.pendingDelete"
                            }, "popup")
                        }}
                        viewAction={(id) => (history.push("/sic/user/" + id))}
                    />
                </FullCenter>
                <FooterCicanord menu={"sic"} />
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUsersAll,
            resetUsers,
            addUser,
            deleteUser
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        users: state.user,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Users);
