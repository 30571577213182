import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { Link } from 'react-router-dom';

import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import FooterCicanord from '../partials/FooterCicanord';

//gj-strap
import {Full, Cover} from '../../gj_modules/gj-strap/components/partials/';
import {TileContainer, Tile, TileHeader, TileBody, TileTabs, TileTab} from '../../gj_modules/gj-strap/components/partials/';
import { Form, Panel, PanelButton } from '../../gj_modules/gj-strap/components/form';
import { returnFunction, processSubmit } from '../../gj_modules/gj-strap/lib';

import moment from 'moment';

//import DataTable from 'gj_modules/gj-strap/components/form/DataTable';
import { Row, Col } from 'react-bootstrap';
//Actions
import { resetUsers, getUserById, updateUser, updateUserPassword } from '../../actions/user';
import { getContactsAll } from '../../actions/contacts';
//Current
import UserActions from './UserActions'
import UserBusinesses from './UserBusinesses'

import { rolesSelect } from '../../data/constants';

//design
import "../calendar/calendar.scss"

export const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

class User extends Component {
    componentDidMount() {
        
        const { match, resetUsers, getUserById, auth } = this.props;

        resetUsers();

        const id = match && match.params && match.params.id;

        //console.log(id)

        if (id) {
            getUserById(match.params.id);
        }else{
            getUserById(auth.user.id);
        }
    }

    submitEdit(values){
        const { match, users, updateUser, auth } = this.props;
        let id = match && match.params && match.params.id;

        if(id){
            
        }else{
            id = auth.user.id
        }

        if(users && !users.pendingUpdate){
            updateUser(id, processSubmit(values));
        }
    }

    switchTab = (tab) => {
        const { match } = this.props;
        const id = match && match.params && match.params.id;

        let baseURL = ""+(REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "")+"/sic/user/"+id+"";

        if(id){

        }else{
            baseURL = ""+(REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "")+"/profile";
        }

        switch(tab){
			case "businesses":
				window.history.replaceState(null, null, baseURL+"/businesses")
				break;
            case "actions":
                window.history.replaceState(null, null, baseURL)
				break;
            default:
                break;
        }
    }

    render() {
        const { user, updateUserPassword, match, auth, users, getContactsAll } = this.props;
        let id = match && match.params && match.params.id;

        if(id){
            
        }else{
            id = auth.user.id
        }

        let defaultTab = "actions";
        if(this.props.location.pathname.includes("businesses")){
            defaultTab = "businesses";
        }

        let portlet = {
            title:"Utilisateurs",
            links:[
                {name:"Recherche", path:"/sic/user"},
                //{name:"utilisateur inconnu", path:"/sic/user/"+id}
            ]
        }

        if(user && !user.pending){
            portlet.links[0].name = user.info.Utilisateurs_Prenom+" "+user.info.Utilisateurs_Nom;
        }


        return (
            <React.Fragment>
                <div id="popup"></div>
				<MenuLeft menu={"sic"} />
                <HeaderTop menu={"sic"} />

                {user && !user.pending ? (

                    <Full portlet={portlet}>

                        <Row>
                            <Col lg="9" className="tile-col">

                                 {/* Header */}
                                 <TileContainer>
                                    <Tile size={"full"}>
                                        <TileBody>
                                            <Cover
                                                icon={null}
                                                title={user.info.Utilisateurs_Prenom+" "+user.info.Utilisateurs_Nom}
                                                subtitle={""}
                                            >
                                                {user.info.Utilisateurs_DateConnexion ? "Dernière connexion le "+moment(user.info.Utilisateurs_DateConnexion).format("DD/MM/YYYY")+"" : ""}
                                            </Cover>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>

                                {/* Tabs */}
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileTabs defaultActiveKey={defaultTab} onSelect={this.switchTab} transition={false} id="tabs-bussiness">
                                            <TileTab eventKey="actions" title="Actions">
                                                {/* Actions */}
                                                <UserActions
                                                    user={user}
                                                />
                                            </TileTab>
											<TileTab eventKey="businesses" title="Affaires">
                                                {/* Businesses */}
                                                <UserBusinesses
                                                    user={user}
                                                />
                                            </TileTab>
                                        </TileTabs>
                                    </Tile>
                                </TileContainer>

                            </Col>

                            <Col lg="3" className="tile-col">
                                {/* Basic info */}
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileHeader title="Général" border={true} />
                                        <TileBody>

                                            <Form
                                                onSubmit={values => this.submitEdit(values)}
                                                initialValues={{
                                                    login: user.info.Utilisateurs_Login,
                                                    surname: user.info.Utilisateurs_Prenom || "",
                                                    name: user.info.Utilisateurs_Nom || "",
                                                    email: user.info.Utilisateurs_Email || "",
                                                    role: user.info.Utilisateurs_role,
													color: '#'+user.info.Utilisateurs_color,
													contactId:Number(user.info.Utilisateurs_contactId),
                                                }}
                                            >

                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        { short: "login", name: "Login", type: "text", placeholder: "Login" },
                                                        { short: "surname", name: "Prénom", type: "text", placeholder: "Prénom" },
                                                        { short: "name", name: "Nom", type: "text", placeholder: "Nom" },
                                                        { short: "email", name: "Email", type: "text", placeholder: "Email" },
                                                        {
                                                            short: "role", name: "Rang", type: "select", placeholder: "", data: {
                                                                isMulti: false,
                                                                options: rolesSelect
                                                            }
                                                        },
														{ short: "color", name: "Couleur", type: "colorpicker", placeholder: "" },
														{ 
                                                            short: "contactId", name: "Lien Contact", type: "select", placeholder: "Rechercher", data: {
                                                                isMulti: false,
                                                                isAsync: true,
																defaultValue: { value:Number(user.info.Personnes_ID), label:user.info.Personnes_Prenom+" "+user.info.Personnes_Nom, force:true },
                                                                loadOptions: returnFunction(getContactsAll, "searchContactsByName", 20, "contacts.contacts", "Personnes_ID", ["Personnes_Prenom", "Personnes_Nom", "Entites_Nom"])
                                                            }
                                                        }
                                                    ]}
                                                />

                                                <PanelButton
                                                    pending={users.pendingUpdate}
                                                    message={"Utilisateur mis à jour"}
                                                />
                                            </Form>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>

                                {/* password */}
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileHeader title="Mot de passe" border={true} />
                                        <TileBody>

                                            <Form
                                                onSubmit={values => {
                                                    updateUserPassword(id, values);
                                                }}
                                                /*initialValues={{
                                                    login: user.info.Utilisateurs_Login,
                                                    surname: user.info.Utilisateurs_Prenom || "",
                                                    name: user.info.Utilisateurs_Nom || "",
                                                    role: user.info.Utilisateurs_role,
                                                    color: '#'+user.info.Utilisateurs_color
                                                }}*/
                                            >

                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
                                                        { short: "password", name: "Nouveau mot de passe", type: "text", placeholder: "" }
                                                    ]}
                                                />

                                                <PanelButton
                                                    pending={users.pendingUpdatePassword}
                                                    message={"Mot de passe mis à jour"}
                                                />
                                            </Form>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>
                            </Col>

                        </Row>
                        
                    </Full>
                ) : (
                    <Full>
                        Chargement
                    </Full>
                )}
                <FooterCicanord menu={"sic"}/>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetUsers,
            getUserById,
            updateUser,
			updateUserPassword,
			getContactsAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        users:state.user,
        user:state.user.user,
        auth:state.auth
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(User);