import axios from 'axios';
import { logoutUser } from './auth'

// URL list
export const API_URL = process.env.REACT_APP_API_URL;
export const CLIENT_ROOT_URL = process.env.REACT_APP_CLIENT_ROOT_URL;

//
export var savePdf = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (data, fileName) {

        var binaryLen = data.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = data.charCodeAt(i);
            bytes[i] = ascii;
        }

        var blob = new Blob([bytes], { type: "application/pdf" });
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());

// Manual Request
export function getManualData(isAuthReq, url) {
    const requestUrl = API_URL + url;
    let headers = {};

    if (isAuthReq) {
        headers = {
            headers: {
                Authorization: localStorage['token']
            }
        };
    }

    return axios
        .get(requestUrl, headers)
        .then(response => {
            console.log(response)
            return { response };
        })
        .catch(error => {
            return error;
        });
}

// Get Request
export function getData(action, pending, errorType, isAuthReq, url, dispatch) {
    const requestUrl = API_URL + url;
    let headers = {};

    if (pending !== null) {
        dispatch({ type: pending });
    }

    if (isAuthReq) {
        headers = {
            headers: {
                Authorization: localStorage['token']
            }
        };
    }

    return axios
        .get(requestUrl, headers)
        .then(response => {
            dispatch({
                type: action,
                payload: response.data
            });
            return { response };
        })
        .catch(error => {
            return errorHandler(dispatch, error, errorType);
        });
}

// Post Request
export function postData(action, pending, errorType, isAuthReq, url, dispatch, data, file) {
    const requestUrl = API_URL + url;
    // Origin : CLIENT_ROOT_URL
    let headers = {
        headers: {}
    };

    if (pending !== null) {
        dispatch({ type: pending });
    }

    if (isAuthReq) {
        headers = {
            headers: {
                Authorization: localStorage['token']
            }
        };
    }

    if (file) {
        headers.headers['Content-Type'] = 'multipart/form-data';
    }

    return axios
        .post(requestUrl, data, headers)
        .then(response => {
            dispatch({
                type: action,
                payload: response.data,
                requestData: data // put request data in response so reducers know what is it about
            });
            return { response };
        })
        .catch(error => {
            return errorHandler(dispatch, error, errorType, data);
        });
}

// Put Request
export function putData(action, pending, errorType, isAuthReq, url, dispatch, data) {
    const requestUrl = API_URL + url;
    let headers = {};

    if (pending !== null) {
        dispatch({ type: pending });
    }

    if (isAuthReq) {
        headers = {
            headers: {
                Authorization: localStorage['token']
            }
        };
    }

    return axios
        .put(requestUrl, data, headers)
        .then(response => {
            dispatch({
                type: action,
                payload: response.data,
                requestData: data // put request data in response so reducers know what is it about
            });
            return { response };
        })
        .catch(error => {
            return errorHandler(dispatch, error, errorType, data);
        });
}

// Delete Request
export function deleteData(action, pending, errorType, isAuthReq, url, dispatch) {
    const requestUrl = API_URL + url;
    let headers = {};

    if (pending !== null) {
        dispatch({ type: pending });
    }

    if (isAuthReq) {
        headers = {
            // headers: { Authorization: cookie.load('token') }
            headers: {
                Authorization: localStorage['token']
            }
        };
    }

    return axios
        .delete(requestUrl, headers)
        .then(response => {
            dispatch({
                type: action,
                payload: response.data
            });
            return { response };
        })
        .catch(error => {
            return errorHandler(dispatch, error, errorType);
        });
}

export function errorHandler(dispatch, error, type, requestData) {
    console.error('Error type: ', type);
    console.error(error);

    let errorMessage = error && error.response ? error.response.data : error || 'unknown error';

    console.log(error.response)

    // NOT AUTHENTICATED ERROR
    if (error && ((error.hasOwnProperty("status") && error.status === 401) || (error.response && error.hasOwnProperty("response") && error.response.status === 401))) {
        console.log(errorMessage);
        return dispatch(logoutUser(errorMessage));
    }

    dispatch({
        type,
        payload: errorMessage,
        requestData // put request data in response so reducers know what is it about
    });

    return { error };
}